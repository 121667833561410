import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import type { TypographyOwnProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DotBadge } from '@noah-labs/core-web-ui/src/badges/DotBadge';
import { ElevatedCardListItem } from '@noah-labs/core-web-ui/src/cards/ElevatedCardListItem';
import { StructuredContent } from '@noah-labs/core-web-ui/src/lists/atoms/StructuredContent';
import type { IMessage } from '@novu/notification-center';
import dayjs from 'dayjs';
import type { NotificationPayload } from '../types';
import { NotificationIcon } from './NotificationIcon';

export type TpIMessage = Pick<IMessage, 'content' | 'createdAt' | 'cta' | 'payload' | 'seen'>;
type PpNotificationItem = {
  message: TpIMessage;
  onClick: (message: TpIMessage) => void;
};

export function NotificationItem({ message, onClick }: PpNotificationItem): ReactElement {
  const theme = useTheme();

  const itemSpacing = 1.25;

  const styles = {
    iconContainer: css`
      position: relative;
      min-width: fit-content;
      margin-top: ${theme.spacing(0.7)};
    `,
    messageContainer: css`
      box-shadow: ${theme.shadows[2]};
      border-radius: ${theme.borderRadius.sm};
    `,
    messageContent: css`
      align-items: flex-start;
      padding: ${theme.spacing(0.75, 1.5)};
      position: relative;
      border-radius: ${theme.borderRadius.sm};

      > div {
        min-width: unset;
        padding-right: ${theme.spacing(0.5)};
      }
    `,
    unseenMessageContent: css`
      background-color: #fff2e7;
    `,
  };

  const commonTypographyProps: TypographyOwnProps = {
    variant: 'body2',
    whiteSpace: 'normal',
  };

  const payload = message.payload as NotificationPayload;

  return (
    <ElevatedCardListItem
      buttonSx={{ padding: theme.spacing(0.5) }}
      css={styles.messageContainer}
      spacing={itemSpacing}
      onClick={(): void => onClick(message)}
    >
      <StructuredContent
        css={[styles.messageContent, !message.seen && styles.unseenMessageContent]}
        endText={{
          primary: !message.seen && (
            <DotBadge
              sx={{
                margin: theme.spacing(0.5),
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            />
          ),
        }}
        Icon={<NotificationIcon payload={payload} />}
        iconCss={styles.iconContainer}
        mainText={{
          primary: message.content,
          primaryTypographyProps: {
            ...commonTypographyProps,
            fontWeight: 500,
          },
          secondary: dayjs(message.createdAt).fromNow(),
          secondaryTypographyProps: {
            ...commonTypographyProps,
            color: 'text.secondary',
          },
        }}
      />
    </ElevatedCardListItem>
  );
}
