import type { TpAmount } from '@noah-labs/shared-currencies/src/types';
import { safeBN } from '@noah-labs/shared-tools/src/browser/numbers';

type PpWithdrawFeeAmounts = {
  cryptoAmount: string;
  feeCryptoAmount: TpAmount;
  feeFiatAmount: TpAmount;
  fiatAmount: string;
};

type TpWithdrawFeeAmounts = {
  cryptoAmountWithFee: string;
  fiatAmountWithFee: string;
};

export function getWithdrawFeeAmounts({
  cryptoAmount,
  feeCryptoAmount,
  feeFiatAmount,
  fiatAmount,
}: PpWithdrawFeeAmounts): TpWithdrawFeeAmounts {
  const cryptoAmountWithFee = safeBN(cryptoAmount).plus(safeBN(feeCryptoAmount)).toString();
  const fiatAmountWithFee = safeBN(fiatAmount).plus(safeBN(feeFiatAmount)).toString();

  return {
    cryptoAmountWithFee,
    fiatAmountWithFee,
  };
}
