import { CtaCarousel } from '@noah-labs/core-web-ui/src/carousels/CtaCarousel';
import { getCarouselCardTiming } from '@noah-labs/core-web-ui/src/timing/getCarouselCardTiming';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { TpFFUI } from '@noah-labs/core-web-ui/src/types';
import { assetPaths } from '@noah-labs/fe-assets';
import { Feature } from '@noah-labs/shared-schema-gql';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import dayjs from 'dayjs';
import { useUserLocalStorage } from '../../../hooks';
import { TpAnalyticsEvent, useAnalytics } from '../../analytics';
import { routes as integrationRoutes } from '../../integrations/routes';
import { isQuestComplete } from '../../rewards/data';
import { useQuestStatusById } from '../../rewards/hooks/useQuestStatusById';
import { routes as rewardsRoutes } from '../../rewards/routes';
import { useIsFeatureEnabled } from '../../user/hooks/useIsFeatureEnabled';
import { defaultAccount } from '../../wallet/data/defaultAccount';
import { routes as walletRoutes } from '../../wallet/routes';
import { useHideDepositInfoSlide } from '../hooks/useHideDepositInfoSlide';
import { CarouselSlide } from './CarouselSlide';

const params = {
  accountType: defaultAccount.AccountType,
  currencyCode: defaultAccount.CurrencyCode,
};

type PpFeaturedCarousel = {
  userId: string | undefined;
};
export function FeaturedCarousel({ userId }: PpFeaturedCarousel): React.ReactElement | null {
  const {
    hiddenSlides: {
      bitrefill: bitrefillSlideHidden,
      buyBitcoin: buyBitcoinSlideHidden,
      discord: discordSlideHidden,
      transactions: transactionsSlideHidden,
      warpStone: warpStoneSlideHidden,
      zeroFees: zeroFeesSlideHidden,
    },
    lastSeenDepositSlide,
    setLastSeenDepositSlide,
    setSlideHidden,
  } = useUserLocalStorage(userId);

  const { handleHide, hide: depositSlideHidden } = useHideDepositInfoSlide({
    lastSeenDepositSlide,
    setLastSeenDepositSlide,
  });
  const { track } = useAnalytics();

  const { data: buyBitcoinQuestStatus } = useQuestStatusById('BuyBitcoin');
  const { data: discordQuestStatus } = useQuestStatusById('Discord');
  const { data: bitrefillQuestStatus } = useQuestStatusById('Bitrefill');
  const { data: warpStoneQuestStatus } = useQuestStatusById('WarpStone');

  const isBuyEnabled = useIsFeatureEnabled(TpFFUI.CkoBuyRoutes);
  const isBitrefillEnabled = useIsFeatureEnabled(Feature.Bitrefill);
  const isDepositEnabled = useIsFeatureEnabled(Feature.Deposit);
  const isLightningEnabled = useIsFeatureEnabled(Feature.LnPayment);

  const { countDown: zeroFeesCountdown, isActive: zeroFeesIsActive } = getCarouselCardTiming({
    end: dayjs.tz('2023-10-06 23:59:00', 'Europe/London'),
    start: dayjs.tz('2023-09-25 13:00:00', 'Europe/London'),
  });
  const { countDown: warpStoneCountdown, isActive: warpStoneIsActive } = getCarouselCardTiming({
    end: dayjs(warpStoneQuestStatus?.Quest.Ends),
    start: dayjs(warpStoneQuestStatus?.Quest.Starts),
  });

  const allHidden = [
    bitrefillSlideHidden,
    buyBitcoinSlideHidden,
    discordSlideHidden,
    transactionsSlideHidden,
    warpStoneSlideHidden,
    zeroFeesSlideHidden,
    depositSlideHidden,
  ].every((s) => s === true);

  if (allHidden) {
    return null;
  }

  const ckoSlideCopy =
    !isUndefinedOrNull(buyBitcoinQuestStatus) && isQuestComplete(buyBitcoinQuestStatus)
      ? {
          subtitle:
            'Pay with credit and debit cards, all-time low fees, real-time rewards, and seamless security.',
          title: 'Embrace the future of money',
        }
      : {
          subtitle: `Earn the Starlight Stone and\n2000 NOAH Points, just for you`,
          title: 'Buy bitcoin at low fees',
        };

  const discordSlideShouldBeVisible =
    !isUndefinedOrNull(discordQuestStatus) &&
    dayjs().isAfter(dayjs(discordQuestStatus.Quest.Starts)) &&
    !isQuestComplete(discordQuestStatus);

  const bitrefillSlideShouldBeVisible =
    !isUndefinedOrNull(bitrefillQuestStatus) &&
    isBitrefillEnabled &&
    !isQuestComplete(bitrefillQuestStatus);

  return (
    <CtaCarousel>
      {zeroFeesIsActive && isBuyEnabled && (
        <CarouselSlide
          key="zero-fees-fiesta"
          backgroundImage={`${assetPaths.images}/carousel/cta-bg-10.png`}
          color="pink"
          hidden={zeroFeesSlideHidden}
          href={generatePath(walletRoutes.buy.enterAmount.path, params)}
          subtitle={`Enjoy 0% fees on Bitcoin purchases up to $100\n with NOAH App until Oct 6th!`}
          title={`0% Fees on Bitcoin Purchases - ${zeroFeesCountdown}`}
          onCardClick={(): void =>
            track(TpAnalyticsEvent.CarouselCardClicked, {
              cardName: 'ZeroFeesFiesta',
            })
          }
          onClose={(): void => setSlideHidden('zeroFees', true)}
        />
      )}

      {warpStoneIsActive && isBuyEnabled && (
        <CarouselSlide
          key="warp-stone"
          backgroundImage={`${assetPaths.images}/carousel/cta-bg-9.png`}
          color="pink"
          hidden={warpStoneSlideHidden}
          href={generatePath(walletRoutes.buy.enterAmount.path, params)}
          subtitle="With a modest $20 bitcoin purchase, turbocharge your journey, snatch up the stone, and bag a hefty bonus of 1000 NOAH Points."
          title={`${warpStoneCountdown} | Warp Stone Quest`}
          onCardClick={(): void =>
            track(TpAnalyticsEvent.CarouselCardClicked, {
              cardName: 'WarpStone',
            })
          }
          onClose={(): void => {
            setSlideHidden('warpStone', true);
          }}
        />
      )}

      {isBuyEnabled && dayjs().isAfter(dayjs(buyBitcoinQuestStatus?.Quest.Starts)) && (
        <CarouselSlide
          key="buy-bitcoin"
          backgroundImage={`${assetPaths.images}/carousel/cta-bg-5.png`}
          color="brown"
          hidden={buyBitcoinSlideHidden}
          href={generatePath(walletRoutes.buy.enterAmount.path, params)}
          subtitle={ckoSlideCopy.subtitle}
          title={ckoSlideCopy.title}
          onCardClick={(): void =>
            track(TpAnalyticsEvent.CarouselCardClicked, {
              cardName: 'BuyBitcoin',
            })
          }
          onClose={(): void => {
            setSlideHidden('buyBitcoin', true);
          }}
        />
      )}

      {discordSlideShouldBeVisible && (
        <CarouselSlide
          key="discord"
          backgroundImage={`${assetPaths.images}/carousel/cta-bg-8.png`}
          color="pink"
          hidden={discordSlideHidden}
          href={generatePath(rewardsRoutes.quest.path, { id: 'Discord' })}
          subtitle={`Complete the Discord Quest to connect and join us.\nThat’s 200 NOAH Points!`}
          title="Community is everything"
          onCardClick={(): void =>
            track(TpAnalyticsEvent.CarouselCardClicked, {
              cardName: 'Discord',
            })
          }
          onClose={(): void => {
            setSlideHidden('discord', true);
          }}
        />
      )}

      {isLightningEnabled && (
        <CarouselSlide
          key="transactions"
          backgroundImage={`${assetPaths.images}/carousel/cta-bg-1.png`}
          color="brown"
          hidden={transactionsSlideHidden}
          href={generatePath(walletRoutes.lightningSend.enterAmount.path, params)}
          subtitle={`Instantly send and receive bitcoin\nwith zero fees over the Lightning Network`}
          title="Lightning-Fast Transactions"
          onCardClick={(): void =>
            track(TpAnalyticsEvent.CarouselCardClicked, {
              cardName: 'Transactions',
            })
          }
          onClose={(): void => {
            setSlideHidden('transactions', true);
          }}
        />
      )}

      {bitrefillSlideShouldBeVisible && (
        <CarouselSlide
          key="bitrefill"
          backgroundImage={`${assetPaths.images}/carousel/cta-bg-6.png`}
          color="pink"
          hidden={bitrefillSlideHidden}
          href={generatePath(integrationRoutes.bitrefill.path)}
          subtitle={`Make a purchase with Bitrefill\n to earn 500 NOAH Points`}
          title="Bitrefill Quest"
          onCardClick={(): void =>
            track(TpAnalyticsEvent.CarouselCardClicked, {
              cardName: 'Bitrefill',
            })
          }
          onClose={(): void => {
            setSlideHidden('bitrefill', true);
          }}
        />
      )}

      {isDepositEnabled && (
        <CarouselSlide
          key="deposit"
          backgroundImage={`${assetPaths.images}/carousel/cta-bg-2.png`}
          color="blue"
          hidden={depositSlideHidden}
          href={generatePath(walletRoutes.deposit.base.path, params)}
          subtitle="With 0 fees"
          title="Instant Deposits"
          onCardClick={(): void =>
            track(TpAnalyticsEvent.CarouselCardClicked, {
              cardName: 'Deposit',
            })
          }
          onClose={handleHide}
        />
      )}
    </CtaCarousel>
  );
}
