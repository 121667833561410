import { Balance } from '@noah-labs/core-web-ui/src/balance/Balance';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { SceneHeaderCard } from '@noah-labs/core-web-ui/src/scene/SceneHeaderCard';
import { TabsButton } from '@noah-labs/core-web-ui/src/tabs/TabsButton';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { TextOrSkeleton } from '@noah-labs/core-web-ui/src/typography/TextOrSkeleton';
import { routes } from '../../routes';
import type { TpRewardsTabProps, TpRewardsTabs } from '../../types';

type PpRewardsHeaderCard = {
  activeTab: TpRewardsTabs;
  tabs: TpRewardsTabProps[];
  userPoints: string | undefined;
};

export function RewardsHeader({
  activeTab,
  tabs,
  userPoints,
}: PpRewardsHeaderCard): React.ReactElement {
  return (
    <SceneHeaderCard>
      {/* TODO: add TextIconButton with dialog trigger */}
      <Balance label="Points" PrimaryAmountSlot={<TextOrSkeleton>{userPoints}</TextOrSkeleton>} />
      <TabsButton aria-label="rewards" value={activeTab}>
        {tabs.map((t) => (
          <PrimaryButton
            key={t.value}
            color={activeTab === t.value ? 'primaryLight' : 'ghost'}
            href={generatePath(routes.tabs.path, {
              tab: t.value,
            })}
            size="small"
            value={t.value}
          >
            {t.header}
          </PrimaryButton>
        ))}
      </TabsButton>
    </SceneHeaderCard>
  );
}
