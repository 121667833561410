export enum QRErrors {
  /**
   * NotAllowedError: user denied permission
   * NotFoundError: the device has no attached input devices
   * NotReadableError: couldn't read the device's stream, probably in use by another app.
   * NotSupportedError: usually caused by not secure origins
   */
  NotAllowedError = 'NotAllowedError',
  NotFoundError = 'NotFoundError',
  NotReadableError = 'NotReadableError',
  NotSupportedError = 'NotSupportedError',
}

// see: https://github.com/zxing-js/ngx-scanner/blob/e08c6bfbbd714dfc6a86b1304fbe9b680ea9ae46/projects/zxing-scanner/src/lib/zxing-scanner.component.ts
export function isCameraUnvailable(err: DOMException): boolean {
  const unvailableErrors = Object.values<string>(QRErrors);
  return unvailableErrors.includes(err.name);
}

export function getQRScannerError(error: string): string {
  switch (error) {
    case QRErrors.NotAllowedError:
      return 'Camera has been blocked/disabled.';
    case QRErrors.NotFoundError:
      return 'No camera detected.';
    case QRErrors.NotReadableError:
      return 'Camera in use by another app.';
    case QRErrors.NotSupportedError:
    default:
      return 'Camera is unavailable.';
  }
}
