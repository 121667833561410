import type { FormControlLabelProps, CheckboxProps as MuiCheckboxProps } from '@mui/material';
import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';
import { CheckboxIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CheckboxIcon';
import { CheckedCheckboxIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CheckedCheckboxIcon';
import { Controller, useFormContext } from 'react-hook-form';
import type { PpDefaultListItemText } from '../lists/atoms/DefaultListItemText';
import { DefaultListItemText } from '../lists/atoms/DefaultListItemText';

export type PpCheckbox = Omit<MuiCheckboxProps, 'id' | 'name'> &
  Pick<FormControlLabelProps, 'label'> & {
    name: string;
    primaryTypographyProps?: PpDefaultListItemText['primaryTypographyProps'];
    secondaryLabel?: React.ReactNode;
  };

export function Checkbox({
  label,
  name,
  primaryTypographyProps,
  readOnly,
  secondaryLabel,
  ...rest
}: PpCheckbox): React.ReactElement {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }): React.ReactElement => (
        <FormControlLabel
          {...field}
          disableTypography
          control={
            <MuiCheckbox
              checked={field.value}
              checkedIcon={<CheckedCheckboxIcon />}
              color="success"
              icon={<CheckboxIcon />}
              id={name}
              inputProps={{
                // @ts-expect-error false positive
                'data-qa': `${name}-input`,
              }}
              readOnly={readOnly}
              {...rest}
            />
          }
          label={
            <DefaultListItemText
              noWrap={false}
              primary={label}
              primaryTypographyProps={primaryTypographyProps}
              secondary={secondaryLabel}
            />
          }
          sx={{ pointerEvents: readOnly ? 'none' : 'auto' }}
        />
      )}
    />
  );
}
