/* eslint-disable react/no-array-index-key */
import { Fragment } from 'react';
import { css } from '@emotion/react';
import { Avatar, Divider, ListItem, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StructuredContent } from './atoms/StructuredContent';

type PpSkeletonListItems = {
  rows?: number;
};

export function SkeletonListItems({ rows = 1 }: PpSkeletonListItems): React.ReactElement {
  const theme = useTheme();

  const styles = {
    avatar: css`
      height: ${theme.spacing(4)};
      width: ${theme.spacing(4)};
    `,
    divider: css`
      margin: ${theme.spacing(1, 1.75)};
    `,
    listItem: css`
      padding: theme.spacing(0.75, 0);
    `,
  };

  return (
    <Fragment>
      {new Array(rows).fill(null).map((_, index) => {
        const showDividers = index !== rows - 1;
        return (
          <Fragment key={index}>
            <ListItem disableGutters css={styles.listItem}>
              <StructuredContent
                endText={{
                  primary: <Skeleton variant="text" />,
                  secondary: (
                    <Skeleton
                      sx={{ position: 'relative', px: '1rem', top: theme.spacing(0.5) }}
                      variant="text"
                    />
                  ),
                }}
                Icon={
                  <Avatar css={styles.avatar}>
                    <Skeleton variant="circular" />
                  </Avatar>
                }
                mainText={{
                  primary: (
                    <Skeleton
                      sx={{
                        bottom: theme.spacing(0.5),
                        position: 'relative',
                        px: '6rem',
                      }}
                      variant="text"
                    />
                  ),
                  secondary: <Skeleton variant="text" />,
                }}
                sx={{ padding: theme.spacing(1, 0) }}
              />
            </ListItem>
            {showDividers && <Divider aria-hidden component="li" css={styles.divider} />}
          </Fragment>
        );
      })}
    </Fragment>
  );
}
