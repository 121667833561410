import { Stack } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { ErrorPage } from '@noah-labs/core-web-ui/src/utility/ErrorPage';
import { AccessIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/AccessIcon';
import { AppHeaderData } from '../../../components';

type PpSuccess = {
  title: string;
};

export function SuccessScene({ title }: PpSuccess): React.ReactElement {
  return (
    // TODO: rename or create a new component that has a more appropriate name
    <ErrorPage
      AppHeaderSlot={<AppHeaderData exitButton helpButton />}
      FooterSlot={
        <PrimaryButton data-qa="done-button" href="/">
          Back to NOAH
        </PrimaryButton>
      }
      helpText="Your scan has been registered."
      IconSlot={
        <Stack alignItems="center">
          <AccessIcon color="success" sx={{ fontSize: '4rem' }} />
        </Stack>
      }
      message={title}
    />
  );
}
