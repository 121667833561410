import { isValidChecksumAddress, toChecksumAddress } from '@ethereumjs/util';
import { BitcoinIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/BitcoinIcon';
import { LightningCircleIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/LightningCircleIcon';
import { TronIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/TronIcon';
import type { TpMuiSvgIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/types';
import { mSatsToBtc } from '@noah-labs/shared-currencies/src/conversions';
import type { LightningAddressProxySuccess } from '@noah-labs/shared-schema-gql';
import { CurrencyCode, Network } from '@noah-labs/shared-schema-gql';
import { duration } from '@noah-labs/shared-tools/src/browser/duration';
import { bech32 } from 'bech32';
import bip21 from 'bip21';
import { decode as decodeBolt11 } from 'bolt11';
import WalletValidator from 'multicoin-address-validator';
import type { TpParseAddressData, TpParseAddressDataWCc } from '../types';

// TpLightningAddressTypes does not just refer to LN addresses
type TpLightningAddressTypes = 'lnaddress' | 'lnurl' | 'lnbc';

export type TpUsdStablecoinCurrencyCode =
  | CurrencyCode.USDC
  | CurrencyCode.USDC_TEST
  | CurrencyCode.USDT
  | CurrencyCode.USDT_TEST;

type TpUsdStablecoinNetwork =
  | Network.Ethereum
  | Network.EthereumTestSepolia
  | Network.PolygonPos
  | Network.PolygonTestMumbai
  | Network.Tron
  | Network.TronTestShasta
  | undefined;

type TpBaseAddressData = {
  Icon: TpMuiSvgIcon | null;
  address: string;
  currencyCode: CurrencyCode;
  network: Network | undefined;
  processingTime: number;
};

export type TpEthAddressData = TpBaseAddressData & {
  addressType?: never;
  amount?: string;
  currencyCode: CurrencyCode.ETH | CurrencyCode.ETH_TEST_SEPOLIA;
  description?: never;
  expiryTime?: never;
  lightningAddressProxy?: never;
  lnUrlLink?: never;
  network: Network.Ethereum | Network.EthereumTestSepolia;
  paymentHash?: never;
};

export type TpUsdcAddressData = TpBaseAddressData & {
  addressType?: never;
  amount?: string;
  currencyCode: CurrencyCode.USDC | CurrencyCode.USDC_TEST;
  description?: never;
  expiryTime?: never;
  lightningAddressProxy?: never;
  lnUrlLink?: never;
  network: TpUsdStablecoinNetwork;
  paymentHash?: never;
};

export type TpUsdtAddressData = TpBaseAddressData & {
  addressType?: never;
  amount?: string;
  currencyCode: CurrencyCode.USDT | CurrencyCode.USDT_TEST;
  description?: never;
  expiryTime?: never;
  lightningAddressProxy?: never;
  lnUrlLink?: never;
  network: TpUsdStablecoinNetwork;
  paymentHash?: never;
};

export type TpBitcoinAddressData = TpBaseAddressData & {
  addressType?: never;
  amount?: string;
  currencyCode: CurrencyCode.BTC | CurrencyCode.BTC_TEST;
  description?: never;
  expiryTime?: never;
  lightningAddressProxy?: never;
  lnUrlLink?: never;
  network: Network.Bitcoin | Network.BitcoinTest;
  paymentHash?: never;
};

export type TpLightningAddressData = TpBaseAddressData & {
  addressType: TpLightningAddressTypes;
  amount?: string;
  currencyCode: CurrencyCode.BTC | CurrencyCode.BTC_TEST;
  description?: string;
  expiryTime?: number;
  lightningAddressProxy?: LightningAddressProxySuccess;
  lnUrlLink?: string;
  network: Network.Lightning | Network.LightningTest;
  paymentHash?: string;
};

export type TpPolygonNetworkAddressData = TpBaseAddressData & {
  addressType: never;
  amount?: string;
  description?: never;
  expiryTime?: never;
  lightningAddressProxy?: never;
  lnUrlLink?: never;
  network: Network.PolygonPos | Network.PolygonTestMumbai;
  paymentHash?: never;
};

export type TpTronNetworkAddressData = TpBaseAddressData & {
  addressType: never;
  amount?: string;
  description?: never;
  expiryTime?: never;
  lightningAddressProxy?: never;
  lnUrlLink?: never;
  network: Network.Tron | Network.TronTestShasta;
  paymentHash?: never;
};

export type TpOffNetworkAddressData = TpBaseAddressData & {
  addressType: never;
  amount?: string;
  description?: never;
  expiryTime?: never;
  lightningAddressProxy?: never;
  lnUrlLink?: never;
  network: Network.OffNetwork;
  paymentHash?: never;
};

export type TpAddressData =
  | TpBitcoinAddressData
  | TpLightningAddressData
  | TpEthAddressData
  | TpUsdcAddressData
  | TpUsdtAddressData
  | TpTronNetworkAddressData;

export const errorMsg = 'Please enter a valid request or address and try again.';

function isValidBitcoinAddress(trimmedAddress: string, isProd: boolean): boolean {
  return WalletValidator.validate(trimmedAddress, 'bitcoin', isProd ? 'prod' : 'testnet');
}

function isValidEthereumOrPolygonAddress(address: string, isProd: boolean): boolean {
  return WalletValidator.validate(address, 'eth', isProd ? 'prod' : 'testnet');
}

// issue when validating tron testnet addresses, see: https://github.com/christsim/multicoin-address-validator/issues/92,
// but since Tron addresses are the same on both testnet and mainnet it's safe to default to "prod"
function isValidTronAddress(address: string): boolean {
  return WalletValidator.validate(address, 'trx', 'prod');
}

function isMaybeLNAddress(trimmedAddress: string): boolean {
  // see: https://github.com/fiatjaf/lnurl-rfc/blob/luds/16.md
  const iIdRegex = /\S+@\S+\.\S+/;
  return iIdRegex.test(trimmedAddress);
}

function isMaybeLNURL(trimmedAddress: string): boolean {
  const lnurlRegex = /^lnurl/i;
  return lnurlRegex.test(trimmedAddress);
}

function isMaybeLNPaymentRequest(trimmedAddress: string): boolean {
  const lightningRegex = /^(lightning|lnbc|lntb)/i;
  return lightningRegex.test(trimmedAddress);
}

function isMaybeBIP21(trimmedAddress: string): boolean {
  const bip21Regex = /^bitcoin:/i;
  return bip21Regex.test(trimmedAddress);
}

function validateNetworks(availableNetworks: Network[], networks: Network[]): boolean {
  return availableNetworks.some((n) => networks.includes(n));
}

function hasNetworkAndValidEthOrPolygonAddress({
  address,
  availableNetworks,
  isProd,
}: TpParseAddressData): boolean {
  const networks = [
    Network.Ethereum,
    Network.EthereumTestSepolia,
    Network.PolygonPos,
    Network.PolygonTestMumbai,
  ];
  return (
    validateNetworks(availableNetworks, networks) &&
    isValidEthereumOrPolygonAddress(address, isProd)
  );
}

function hasNetworkAndValidTronAddress({
  address,
  availableNetworks,
}: Pick<TpParseAddressData, 'address' | 'availableNetworks'>): boolean {
  const networks = [Network.Tron, Network.TronTestShasta];
  return validateNetworks(availableNetworks, networks) && isValidTronAddress(address);
}

function handleLNAddress(trimmedAddress: string, isProd: boolean): TpLightningAddressData {
  return {
    address: trimmedAddress,
    addressType: 'lnaddress',
    currencyCode: isProd ? CurrencyCode.BTC : CurrencyCode.BTC_TEST,
    Icon: LightningCircleIcon,
    network: isProd ? Network.Lightning : Network.LightningTest,
    processingTime: duration.seconds(30),
  };
}

function handleLNURL(trimmedAddress: string, isProd: boolean): TpLightningAddressData {
  const decodedLNURL = bech32.decode(trimmedAddress, 1500);
  const url = Buffer.from(bech32.fromWords(decodedLNURL.words)).toString();
  return {
    address: trimmedAddress,
    addressType: 'lnurl',
    currencyCode: isProd ? CurrencyCode.BTC : CurrencyCode.BTC_TEST,
    Icon: LightningCircleIcon,
    lnUrlLink: url,
    network: isProd ? Network.Lightning : Network.LightningTest,
    processingTime: duration.seconds(30),
  };
}

function handleLNPaymentRequest(trimmedAddress: string, isProd: boolean): TpLightningAddressData {
  const result = decodeBolt11(trimmedAddress);
  const descriptionTag = result.tags.find((v) => v.tagName === 'description');
  const paymentHashTag = result.tags.find((v) => v.tagName === 'payment_hash');

  if (!result.paymentRequest) {
    throw new Error(errorMsg);
  }

  return {
    address: trimmedAddress,
    addressType: 'lnbc',
    amount: result.millisatoshis ? mSatsToBtc(result.millisatoshis) : undefined,
    currencyCode: isProd ? CurrencyCode.BTC : CurrencyCode.BTC_TEST,
    description: descriptionTag?.data ? descriptionTag.data.toString() : undefined,
    // result.timeExpireDate is in seconds so convert to ms
    expiryTime: result.timeExpireDate ? duration.seconds(result.timeExpireDate) : undefined,
    Icon: LightningCircleIcon,
    network: isProd ? Network.Lightning : Network.LightningTest,
    paymentHash: paymentHashTag?.data.toString(),
    processingTime: duration.seconds(30),
  };
}

// Bech32 addresses start with bc1 or tb1 and should be lowercase
// ref: https://en.bitcoin.it/wiki/List_of_address_prefixes
function transformBech32Address(address: string): string {
  const isBech32 = address.match(/^(bc1|tb1)/i);

  if (!isBech32) {
    return address;
  }

  return address.toLowerCase();
}

function handleEthereumOrPolygonAddress({
  address,
  currencyCode,
}: Pick<TpParseAddressDataWCc<TpUsdStablecoinCurrencyCode>, 'address' | 'currencyCode'>):
  | TpUsdcAddressData
  | TpUsdtAddressData {
  return {
    address,
    currencyCode,
    Icon: null,
    network: undefined,
    processingTime: duration.minutes(30),
  };
}

function handleTronAddress({
  address,
  currencyCode,
  isProd,
}: Pick<
  TpParseAddressDataWCc<TpUsdStablecoinCurrencyCode>,
  'address' | 'currencyCode' | 'isProd'
>): TpUsdcAddressData | TpUsdtAddressData {
  return {
    address,
    currencyCode,
    Icon: TronIcon,
    network: isProd ? Network.Tron : Network.TronTestShasta,
    processingTime: duration.minutes(30),
  };
}

function handleBitcoinAddress(
  trimmedAddress: string,
  isProd: boolean,
  amount?: string
): TpBitcoinAddressData {
  return {
    address: transformBech32Address(trimmedAddress),
    amount,
    currencyCode: isProd ? CurrencyCode.BTC : CurrencyCode.BTC_TEST,
    Icon: BitcoinIcon,
    network: isProd ? Network.Bitcoin : Network.BitcoinTest,
    processingTime: duration.minutes(30),
  };
}

function handleBIP21(trimmedAddress: string, isProd: boolean): TpAddressData {
  const { address, options } = bip21.decode(trimmedAddress);
  if (!address) {
    throw new Error(errorMsg);
  }

  const params = options as typeof options & { lightning?: string };
  if (params.lightning) {
    return handleLNPaymentRequest(params.lightning.toLowerCase(), isProd);
  }

  if (!isValidBitcoinAddress(address, isProd)) {
    throw new Error(errorMsg);
  }

  return handleBitcoinAddress(address, isProd, params.amount?.toString());
}

function trimAddress(addressInput: string): string {
  const stripRegex = /^(lightning|lnurl|lnbc|lntb):/i;
  return addressInput.trim().replace(stripRegex, '');
}

function handleAddresses(trimmedAddress: string, isProd: boolean): TpAddressData | undefined {
  if (isMaybeLNAddress(trimmedAddress)) {
    return handleLNAddress(trimmedAddress, isProd);
  }

  if (isMaybeLNURL(trimmedAddress)) {
    return handleLNURL(trimmedAddress, isProd);
  }

  if (isMaybeLNPaymentRequest(trimmedAddress)) {
    return handleLNPaymentRequest(trimmedAddress, isProd);
  }

  if (isMaybeBIP21(trimmedAddress)) {
    return handleBIP21(trimmedAddress, isProd);
  }

  if (isValidBitcoinAddress(trimmedAddress, isProd)) {
    return handleBitcoinAddress(trimmedAddress, isProd);
  }

  return undefined;
}

export function parseAddressData(addressInput: string, isProd: boolean): TpAddressData {
  const trimmedAddress = trimAddress(addressInput);
  if (!trimmedAddress) {
    throw new Error(errorMsg);
  }

  const addressData = handleAddresses(trimmedAddress, isProd);

  if (!addressData) {
    throw new Error(errorMsg);
  }

  return addressData;
}

export function parseBtcAddressData({
  address,
  isProd,
}: Pick<TpParseAddressData, 'address' | 'isProd'>): TpAddressData | undefined {
  const trimmedAddress = trimAddress(address);
  if (!trimmedAddress) {
    return undefined;
  }

  return handleAddresses(trimmedAddress, isProd);
}

function parseUsdStablecoinAddressData({
  address,
  availableNetworks,
  currencyCode,
  isProd,
}: TpParseAddressDataWCc<TpUsdStablecoinCurrencyCode>): TpAddressData | undefined {
  if (hasNetworkAndValidEthOrPolygonAddress({ address, availableNetworks, isProd })) {
    const checksumAddress = isValidChecksumAddress(address) ? address : toChecksumAddress(address);
    return handleEthereumOrPolygonAddress({ address: checksumAddress, currencyCode });
  }

  if (hasNetworkAndValidTronAddress({ address, availableNetworks })) {
    return handleTronAddress({ address, currencyCode, isProd });
  }

  return undefined;
}

export function parseUsdcAddressData(addressData: TpParseAddressData): TpAddressData | undefined {
  const currencyCode = addressData.isProd ? CurrencyCode.USDC : CurrencyCode.USDC_TEST;
  return parseUsdStablecoinAddressData({ ...addressData, currencyCode });
}

export function parseUsdtAddressData(addressData: TpParseAddressData): TpAddressData | undefined {
  const currencyCode = addressData.isProd ? CurrencyCode.USDT : CurrencyCode.USDT_TEST;
  return parseUsdStablecoinAddressData({ ...addressData, currencyCode });
}
