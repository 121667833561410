import type { SVGProps } from 'react';

export function SvgOutlinedCircleClose(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M1.963 12C1.963 6.457 6.457 1.963 12 1.963S22.037 6.457 22.037 12 17.544 22.037 12 22.037C6.457 22.037 1.963 17.544 1.963 12M12 4.037a7.963 7.963 0 1 0 0 15.926 7.963 7.963 0 0 0 0-15.926"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M15.733 8.267a1.037 1.037 0 0 1 0 1.466L13.467 12l2.266 2.267a1.037 1.037 0 0 1-1.466 1.466L12 13.467l-2.267 2.266a1.037 1.037 0 0 1-1.466-1.466L10.533 12 8.267 9.733a1.037 1.037 0 1 1 1.466-1.466L12 10.533l2.267-2.266a1.037 1.037 0 0 1 1.466 0"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
