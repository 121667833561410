import { useEffect, useState } from 'react';
import { safeBN } from '@noah-labs/shared-tools/src/browser/numbers';
import { defaultAccount } from '../../wallet/data';
import { useLifetimeBalance } from '../../wallet/hooks/useLifetimeBalance';

type TpUseShowDepositInfoSlide = {
  handleHide: () => void;
  hide: boolean;
};

type PpUseShowDepositInfoSlide = {
  lastSeenDepositSlide: string;
  setLastSeenDepositSlide: (value: string) => void;
};

export function useHideDepositInfoSlide({
  lastSeenDepositSlide,
  setLastSeenDepositSlide,
}: PpUseShowDepositInfoSlide): TpUseShowDepositInfoSlide {
  const [hide, setHide] = useState<boolean>(true);

  const { cryptoAmount } = useLifetimeBalance({
    accountType: defaultAccount.AccountType,
    currencyCode: defaultAccount.CurrencyCode,
  });

  useEffect(() => {
    if (!cryptoAmount) {
      return;
    }

    const lifetimeBalance = safeBN(cryptoAmount);
    if (lifetimeBalance.gt(0)) {
      setHide(true);
      return;
    }

    const dateNow = new Date().toLocaleDateString('en', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    const lastShownTime = new Date(lastSeenDepositSlide).getTime();
    const timeNow = new Date(dateNow).getTime();
    const diffInTime = timeNow - lastShownTime;
    const diffInDays = Math.round(diffInTime / (1000 * 60 * 60 * 24));

    if (diffInDays < 7) {
      setHide(true);
      return;
    }

    setHide(false);
  }, [cryptoAmount, lastSeenDepositSlide, setLastSeenDepositSlide]);

  return {
    handleHide: (): void => {
      const dateNow = new Date().toLocaleDateString('en', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      setLastSeenDepositSlide(dateNow);
    },
    hide,
  };
}
