import { styled, useTheme } from '@mui/material/styles';
import { useDesktop } from '@noah-labs/core-web-ui/src/hooks/useDesktop';
import { AppLogo } from '@noah-labs/core-web-ui/src/images';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { Helmet } from 'react-helmet';
import { AppHeaderData, NavBarData, SidebarNavData } from '../../../../components';
import { useUserInit } from '../../../user/data/useUserInit';
import { routes } from '../../routes';
import { Bitrefill as BitrefillIframe } from '../components';

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));

export function BitrefillScene(): React.ReactElement {
  const { data } = useUserInit();
  const theme = useTheme();

  const isDesktop = useDesktop();

  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeaderData
          avatarButton
          disableBalancing
          disableFade
          helpButton
          notificationCenterButton
          backgroundColor={theme.palette.grayscale.offWhite}
          fullWidth={isDesktop}
        >
          {!isDesktop && <AppLogo />}
        </AppHeaderData>
      }
      BottomNavbarSlot={<NavBarData />}
      dataQa="bitrefill"
      mainSxProps={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: `${theme.spacing(3)} !important`,
      }}
      SideNavbarSlot={<SidebarNavData />}
    >
      <Helmet>
        <title>{routes.bitrefill.title}</title>
      </Helmet>
      <StyledDiv>
        <BitrefillIframe email={data?.userProfile.Email} />
      </StyledDiv>
    </AppContainer>
  );
}
