import { useCallback } from 'react';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { getQRScannerError } from '@noah-labs/core-web-ui/src/qr/isCameraAvailable';
import { getErrorMessage } from '@noah-labs/core-web-ui/src/tools/getErrorMessage';
import { parseMarketingUrl } from '@noah-labs/core-web-ui/src/tools/parseMarketingUrl';
import { Route, useHistory, useParams } from 'react-router-dom';
import { AuthRouteData } from '../auth';
import { Marketing } from './controllers';
import { routes } from './routes';
import { MarketingScanScene } from './scenes';

export function Router(): React.ReactElement {
  const { code } = useParams<{ code?: string }>();
  const history = useHistory();
  const pushAlert = usePushAlert();

  const handleAddress = useCallback(
    async (qrInput: string) => {
      try {
        const marketingUrl = parseMarketingUrl(qrInput);
        if (!marketingUrl) {
          throw new Error('Sorry, that’s not a valid NOAH promo code');
        }

        history.push(marketingUrl);
      } catch (error) {
        pushAlert({
          dismissable: true,
          key: 'scanError',
          message: getErrorMessage(error),
          preventDuplicate: true,
          severity: 'error',
        });
      }
    },
    [history, pushAlert]
  );

  return (
    <Switch404>
      <Route
        exact
        path={routes.scan.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.scan}>
            <MarketingScanScene
              pageTitle={routes.scan.title}
              onSuccess={handleAddress}
              onUnavailable={(error): void =>
                pushAlert({
                  dismissable: true,
                  key: 'scanError',
                  message: getQRScannerError(error),
                  preventDuplicate: true,
                  severity: 'error',
                })
              }
            />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.base.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.base}>
            <Marketing code={code} />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
