import { Fragment } from 'react';
import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ElevatedCard } from '@noah-labs/core-web-ui/src/cards/ElevatedCard';
import type { TpPaymentCard } from '../../../types';
import { TpPaymentMethod } from '../../../types';
import { PaymentMethod } from '../PaymentMethod';

export type PpRemoveConfirmation = {
  card: TpPaymentCard;
};

export function RemoveConfirmation({ card }: PpRemoveConfirmation): React.ReactElement {
  const theme = useTheme();
  const styles = {
    card: css`
      padding-left: ${theme.spacing(2)};
      margin: ${theme.spacing(3, 0, 0.5)};
      flex-wrap: wrap;
    `,
  };
  return (
    <Fragment>
      <Typography>This will not affect any pending orders.</Typography>
      <ElevatedCard css={styles.card} elevation={3}>
        <PaymentMethod method={TpPaymentMethod.Card} paymentCard={card} />
      </ElevatedCard>
    </Fragment>
  );
}
