import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { SvgIconProps } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { BalancedStack } from '@noah-labs/core-web-ui/src/layout/BalancedStack';

type PpSocialAuthButton = {
  icon: React.FC<SvgIconProps>;
  label: string;
  onClick: () => Promise<void>;
};

export function SocialAuthButton({
  icon: Icon,
  label,
  onClick,
}: PpSocialAuthButton): React.ReactElement {
  const isMountedRef = useRef(true);
  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );

  const [isLoading, setIsLoading] = useState(false);
  const onClickLoading = useCallback(async () => {
    try {
      setIsLoading(true);
      await onClick();
    } finally {
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    }
  }, [onClick]);

  return (
    <PrimaryButton
      loading={isLoading}
      sx={{ boxShadow: 2 }}
      variant="text"
      onClick={onClickLoading}
    >
      <BalancedStack StartIcons={<Icon />}>{label}</BalancedStack>
    </PrimaryButton>
  );
}
