import React, { useCallback, useRef } from 'react';
import { Stack } from '@mui/material';
import { Balance } from '@noah-labs/core-web-ui/src/balance/Balance';
import { LearnMoreButton } from '@noah-labs/core-web-ui/src/buttons/LearnMoreButton';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { FooterContentText } from '@noah-labs/core-web-ui/src/layout/FooterContentText';
import { ListSection } from '@noah-labs/core-web-ui/src/lists/ListSection';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { LockIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/LockIcon';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';
import type { Promotion } from '@noah-labs/shared-schema-gql';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import { VerifyAccountDialog } from '../../../components/dialogs/VerifyAccountDialog';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { PaymentMethodsPicker, TransactionSummaryCard } from '../components';
import { CheckoutFeeDialog, ProcessingTimeDialog } from '../components/dialogs';
import { CryptoVolatilityDialog } from '../components/dialogs/CryptoVolatilityDialog';
import type { StBuyRouter } from '../controllers';
import type { TpPaymentMethodChange } from '../types';

export type PpConfirmBuyScene = {
  CryptoPriceSlot: React.ReactElement;
  FeeAmountSlot: React.ReactNode;
  NetCryptoAmountSlot: React.ReactNode;
  TotalFiatAmountSlot: React.ReactNode;
  applePayEnabled: boolean;
  backTo: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  ctaButtonLabel?: string;
  feePromotion?: Promotion;
  googlePayEnabled: boolean;
  isLoading: boolean;
  kycRequired: boolean;
  onConfirm: () => void;
  onPaymentMethodChange: ({ card, type }: TpPaymentMethodChange) => void;
  onSubmitCardDetailsRedirect: string;
  pageTitle: string;
} & Pick<StBuyRouter, 'paymentMethod' | 'selectedPaymentCard'>;

const { feePercentage } = webConfigBrowser.cko;

export function ConfirmBuyScene({
  applePayEnabled,
  backTo,
  cryptoCurrency,
  CryptoPriceSlot,
  ctaButtonLabel = 'Continue',
  FeeAmountSlot,
  feePromotion,
  googlePayEnabled,
  isLoading,
  kycRequired,
  NetCryptoAmountSlot,
  onConfirm,
  onPaymentMethodChange,
  onSubmitCardDetailsRedirect,
  pageTitle,
  paymentMethod,
  selectedPaymentCard,
  TotalFiatAmountSlot,
}: PpConfirmBuyScene): React.ReactElement {
  const cryptoVolatilityDialog = useRef<TpDialogToggle>(null);
  const feeInfoDialog = useRef<TpDialogToggle>(null);
  const processingTimeDialog = useRef<TpDialogToggle>(null);
  const kycDialog = useRef<TpDialogToggle>(null);

  const handleConfirm = useCallback((): void => {
    if (kycRequired) {
      kycDialog.current?.toggle();
      return;
    }
    onConfirm();
  }, [kycRequired, onConfirm]);

  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={
        <Stack spacing={2}>
          <FooterContentText>
            Market conditions may affect the exact amount of bitcoin you receive. If the bitcoin
            value changes significantly, the order may be cancelled & refunded.{' '}
            <LearnMoreButton onClick={(): void => cryptoVolatilityDialog.current?.toggle()} />
          </FooterContentText>
          <PrimaryButton
            color="primaryBrand"
            data-qa="confirm-button"
            disabled={!paymentMethod}
            endIcon={<LockIcon />}
            loading={isLoading}
            onClick={handleConfirm}
          >
            {ctaButtonLabel}
          </PrimaryButton>
        </Stack>
      }
      AppHeaderSlot={
        <AppHeaderData disableFade exitButton helpButton backTo={backTo}>
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa="confirm"
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SceneMain maxWidth="xs">
        <Balance PrimaryAmountSlot={TotalFiatAmountSlot} />
        <Stack mt={6} spacing={3}>
          <TransactionSummaryCard
            ActionAmount={NetCryptoAmountSlot}
            actionAmountLabel="Purchasing"
            cryptoCurrency={cryptoCurrency}
            CryptoPriceSlot={CryptoPriceSlot}
            FeeAmountSlot={FeeAmountSlot}
            feeLabel={`Fee (${feePercentage}%)`}
            feePromotion={!!feePromotion}
            FinalAmount={TotalFiatAmountSlot}
            finalAmountLabel="Total spend"
            processingTime="Instant"
            onFeeInfoClick={(): void => feeInfoDialog.current?.toggle()}
            onProcessingTimeClick={(): void => processingTimeDialog.current?.toggle()}
          />
          <ListSection title="Payment Method" titleVariant="paragraphBodySBold">
            <PaymentMethodsPicker
              applePayEnabled={applePayEnabled}
              googlePayEnabled={googlePayEnabled}
              paymentMethod={paymentMethod}
              selectedPaymentCard={selectedPaymentCard}
              onChange={onPaymentMethodChange}
              onSubmitCardDetailsRedirect={onSubmitCardDetailsRedirect}
            />
          </ListSection>
        </Stack>
        <CryptoVolatilityDialog ref={cryptoVolatilityDialog} />

        <ProcessingTimeDialog ref={processingTimeDialog} action="buying" />
        <CheckoutFeeDialog ref={feeInfoDialog} action="buying" promoId={feePromotion?.PromoID} />
        <VerifyAccountDialog ref={kycDialog} action="buy" />
      </SceneMain>
    </AppContainer>
  );
}
