import { Balance } from '@noah-labs/core-web-ui/src/balance/Balance';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-currencies';
import { CurrencyUnit } from '@noah-labs/shared-schema-gql';
// eslint-disable-next-line no-restricted-imports
import { generatePath, useParams } from 'react-router-dom';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { useWalletParams } from '../../data';
import { useInAppReviewTrigger } from '../../hooks';
import { useCurrencyAmounts } from '../../hooks/useCurrencyAmounts';
import { routes } from '../../routes';
import { CompleteBuyScene } from '../../scenes';
import { TpCheckoutPaymentStatus } from '../../types';
import type { StBuyRouter } from './BuyRouter';

export function Complete({ state }: TpStateMachine<StBuyRouter>): React.ReactElement {
  // TODO: we should pass the txid in the URL and get the real tx data from our API
  // sessionStorage is not reliable enough for this
  const { CurrencyCode, params } = useWalletParams();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const { paymentStatus } = useParams<{ paymentStatus: TpCheckoutPaymentStatus }>();
  const { fiatPaymentCurrency } = useUserFiatCurrency();

  const enableInAppReviewTrigger = paymentStatus === TpCheckoutPaymentStatus.success;
  useInAppReviewTrigger(enableInAppReviewTrigger);

  const { cryptoAmount, fiatAmount } = state;

  const Amounts = useCurrencyAmounts({
    cryptoAmount,
    cryptoCurrency,
    cryptoUnit: CurrencyUnit.Default,
    fiatAmount: fiatAmount === '' ? undefined : fiatAmount,
    fiatCurrency: fiatPaymentCurrency,
    nonZeroCrypto: true,
    strikeThrough: paymentStatus === TpCheckoutPaymentStatus.failure,
  });

  let transactionUrl;

  if (typeof state.transactionId === 'string') {
    transactionUrl = generatePath(routes.account.transactions.path, {
      ...params,
      id: state.transactionId,
    });
  }

  return (
    <CompleteBuyScene
      BalanceSlot={<Balance {...Amounts} />}
      paymentStatus={paymentStatus}
      transactionUrl={transactionUrl}
    />
  );
}
