import { useCallback, useRef } from 'react';
import { useDialogs } from '@noah-labs/core-web-ui/src/dialogs/useDialogs';
import { useDesktop } from '@noah-labs/core-web-ui/src/hooks/useDesktop';
import type { Network } from '@noah-labs/shared-schema-gql';
import { canSkipSignature } from '../../../utils';
import { useUserLimitsQuery } from '../../user/data/user.generated';
import { useSigning } from '../context/useSigning';
import type { TpPayoutSignable, TpWithdrawOrderSignable } from '../types';
import type { FnOnSign, TpOnSign } from './Sign';
import { Sign } from './Sign';

const SIGN_DIALOG_KEY = 'sign';

type TpSignWithdrawal = {
  loading: boolean;
  sign: (onSign: FnOnSign) => Promise<void>;
};

export function useSignWithdrawal({
  network,
  payload,
}: {
  network?: Network | null;
  payload: TpWithdrawOrderSignable | TpPayoutSignable;
}): TpSignWithdrawal {
  const { closeDialog, pushDialog } = useDialogs();
  const { sdStatus } = useSigning();
  const isDesktop = useDesktop();

  const { data: userLimitsData, isFetched } = useUserLimitsQuery({
    currencyCodes: [payload.CurrencyCode],
  });

  const payloadRef = useRef(payload);
  payloadRef.current = payload;

  return {
    loading: !isFetched,
    sign: useCallback(
      async (onSign) => {
        // This should never happen if the consumer of this hook checks the loading state
        if (!userLimitsData) {
          throw Error('userLimitsData is undefined');
        }

        /**
         * IF the withdraw request satisfies the signing limits, we can skip the signature
         */
        if (
          canSkipSignature({
            amount: payloadRef.current.Amount,
            currency: payloadRef.current.CurrencyCode,
            network,
            policies: userLimitsData.userLimit.Limits,
          })
        ) {
          await onSign({});
          return;
        }

        /**
         * For now, we can just proceed with the withdraw if the user has not setup a pin
         */
        if (sdStatus?.pinSetupRequired) {
          await onSign({});
          return;
        }

        pushDialog({
          Content: (
            <Sign
              payload={payloadRef.current}
              onSign={async (signPayload: TpOnSign): Promise<void> => {
                await onSign(signPayload);
                closeDialog(SIGN_DIALOG_KEY);
              }}
            />
          ),
          DialogProps: {
            fullScreen: !isDesktop,
          },
          key: SIGN_DIALOG_KEY,
          low: isDesktop,
        });
      },
      [pushDialog, closeDialog, userLimitsData, network, sdStatus?.pinSetupRequired, isDesktop]
    ),
  };
}
