import type { ReactElement } from 'react';
import type { PpWC } from '@noah-labs/core-web-ui/src/types';
import type { PpUseRestricted } from '../hooks/useRestrictedFeature';
import { useRestrictedFeature } from '../hooks/useRestrictedFeature';

type PpRestrictedFeature = PpWC & PpUseRestricted;

/**
 * The `RestrictedFeature` component is a conditional wrapper that determines whether its children should be rendered
 * based on a set of feature requirements provided in the `needs` prop and the user's available features.
 *
 * Usage:
 * - See featureTest function for more details
 *
 * @param {ReactElement} children - The content or component(s) you want to conditionally render based on feature flags.
 * @param {Feature[]} needs - An array of feature requirements. Can be a mix of single features and arrays of features.
 *
 * @returns {ReactElement | null} - Returns the children if the user meets the feature requirements, otherwise returns null.
 */
export function RestrictedFeature({ children, needs }: PpRestrictedFeature): ReactElement | null {
  const hasAllNeededFeatures = useRestrictedFeature({ needs });
  return hasAllNeededFeatures ? (children as ReactElement) : null;
}
