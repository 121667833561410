import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import type { PpQRScanner } from '@noah-labs/core-web-ui/src/qr/QRScanner';
import { QRScanner } from '@noah-labs/core-web-ui/src/qr/QRScanner';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';

export type PpScanScene = PpQRScanner & {
  pageTitle: string;
};

export function MarketingScanScene({ pageTitle, ...rest }: PpScanScene): React.ReactElement {
  const theme = useTheme();
  const backgroundColor = theme.palette.common.black;
  const fontColor = theme.palette.common.white;

  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeaderData
          backButton
          exitButton
          helpButton
          backgroundColor={backgroundColor}
          fontColor={fontColor}
        >
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
      }
      backgroundColor={backgroundColor}
      dataQa="scan"
      fontColor={fontColor}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SceneMain dense>
        <Stack direction="column" spacing={4} sx={{ alignItems: 'center' }}>
          <Typography variant="paragraphBodyS">Scan a promo QR code</Typography>
          <QRScanner {...rest} />
        </Stack>
      </SceneMain>
    </AppContainer>
  );
}
