import { useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import type { TpSelectOption } from '@noah-labs/core-web-ui/src/forms/SearchSelect';
import { SearchSelect } from '@noah-labs/core-web-ui/src/forms/SearchSelect';
import type { PpPreferredCurrency, TpPreferredCurrencyFormOptions } from './types';

export function PreferredFiatCurrencyForm<T extends TpPreferredCurrencyFormOptions>({
  items,
  name = 'Search Currencies',
  onChange,
  title,
}: PpPreferredCurrency<T>): React.ReactElement {
  const handleChange = useCallback(
    (value: TpSelectOption | null) => {
      onChange(value?.value as T);
    },
    [onChange]
  );

  return (
    <Stack spacing={3}>
      {title && (
        <Typography color="text.light" textAlign="center" variant="paragraphBodyS">
          {title}
        </Typography>
      )}
      <form>
        <SearchSelect
          id={name}
          loading={false}
          options={items as TpSelectOption[]}
          onChange={handleChange}
        />
      </form>
    </Stack>
  );
}
