import { fuzzy } from 'fast-fuzzy';

export function cardholderNameValidation(
  value: string | undefined,
  fullName: string | undefined,
  nameMatchThreshold: string
): boolean {
  if (!fullName) {
    return true;
  }

  if (!value) {
    return true;
  }

  // setting useSellers to true means than for John Doe, J. D. would pass, which is not ideal
  const result = fuzzy(value, fullName, { useSellers: false });

  return result > parseFloat(nameMatchThreshold);
}
