import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import type { CardProps } from '@mui/material';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import successIconEffect from '@noah-labs/fe-shared-ui-assets/lotties/check-lottie-data.json';
import failedIconEffect from '@noah-labs/fe-shared-ui-assets/lotties/cross-lottie-data.json';
import pendingIconEffect from '@noah-labs/fe-shared-ui-assets/lotties/pending-lottie-data.json';
import ReactLottie from 'lottie-react';
import type { PpWC } from '../types';
import { ElevatedCard } from './ElevatedCard';

export enum TpAvatarStatus {
  failed = 'failed',
  pending = 'pending',
  success = 'success',
}

export type PpStatusCard = {
  cardCss?: SerializedStyles;
  enableAnimation?: boolean;
  status: TpAvatarStatus;
} & CardProps &
  PpWC;

const lottieStatusEffect = {
  [TpAvatarStatus.success]: successIconEffect,
  [TpAvatarStatus.failed]: failedIconEffect,
  [TpAvatarStatus.pending]: pendingIconEffect,
};

export function StatusElevatedCard(props: PpStatusCard): React.ReactElement {
  const { cardCss, children, enableAnimation, status } = props;

  const theme = useTheme();
  const styles = {
    animation: css`
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: ${theme.spacing(1)};
      width: 74px;
      height: 74px;
    `,
    avatar: css`
      position: relative;
      align-self: center;
      margin-top: -45px;
      height: 90px;
      width: 90px;
    `,
    cardBase: css`
      border-radius: ${theme.borderRadius.xl};
      margin: ${theme.spacing(6, 0, 3)};
      padding: ${theme.spacing(0, 3, 3)};
      overflow: visible;
    `,
    green: css`
      // not a color from ds
      background-color: #b2e6cf;
    `,
    halfCircle: css`
      position: absolute;
      width: 90px;
      height: 45px;
      border-top-left-radius: 45px;
      border-top-right-radius: 45px;
      backdrop-filter: blur(3px);
    `,
    red: css`
      background: rgba(217, 83, 54, 0.44);
    `,
  };

  const halfCircleStyles = [styles.halfCircle];
  const isSuccessOrPending = status === TpAvatarStatus.pending || status === TpAvatarStatus.success;

  if (isSuccessOrPending) {
    halfCircleStyles.push(styles.green);
  } else {
    halfCircleStyles.push(styles.red);
  }

  return (
    <ElevatedCard css={[styles.cardBase, cardCss]} elevation={3}>
      <Stack>
        <div css={styles.avatar}>
          <div css={halfCircleStyles} />
          {enableAnimation && (
            <ReactLottie
              animationData={lottieStatusEffect[status]}
              css={styles.animation}
              loop={false}
            />
          )}
        </div>
        {children}
      </Stack>
    </ElevatedCard>
  );
}
