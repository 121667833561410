import React, { useRef } from 'react';
import { InfoTooltip } from '@noah-labs/core-web-ui/src/buttons/InfoTooltip';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import {
  ListItem,
  ListItemEndContent,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
} from '@noah-labs/core-web-ui/src/lists/ListItem';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';
import { CurrencyCode } from '@noah-labs/shared-schema-gql';
import { BitcoinTransactionFeesDialog, UsdStablecoinsFeeDialog } from '../dialogs';

export type PpNetworkFeeItem = {
  PrimaryAmountSlot: React.ReactNode;
  SecondaryAmountSlot?: React.ReactNode;
  cryptoCurrency: TpCryptoCurrencyUI;
};

export function NetworkFeeItem({
  cryptoCurrency,
  PrimaryAmountSlot,
  SecondaryAmountSlot,
}: PpNetworkFeeItem): React.ReactElement {
  const feeInfoDialog = useRef<TpDialogToggle>(null);

  let Dialog: React.ReactElement;

  switch (cryptoCurrency.code) {
    case CurrencyCode.USDC:
    case CurrencyCode.USDC_TEST:
    case CurrencyCode.USDT:
    case CurrencyCode.USDT_TEST:
      Dialog = <UsdStablecoinsFeeDialog ref={feeInfoDialog} currencyCode={cryptoCurrency.code} />;
      break;

    case CurrencyCode.BTC:
    case CurrencyCode.BTC_TEST:
    default:
      Dialog = <BitcoinTransactionFeesDialog ref={feeInfoDialog} />;
  }

  return (
    <ListItem>
      <ListItemStartContent dataQa="fee">
        <InfoTooltip
          color="text.light"
          variant="paragraphBodyS"
          onClick={(): void => feeInfoDialog.current?.toggle()}
        >
          Network Fee
        </InfoTooltip>
      </ListItemStartContent>
      <ListItemEndContent dataQa="fee">
        <ListItemPrimaryText variant="paragraphBodySBold">{PrimaryAmountSlot}</ListItemPrimaryText>
        <ListItemSecondaryText>{SecondaryAmountSlot}</ListItemSecondaryText>
      </ListItemEndContent>
      {Dialog}
    </ListItem>
  );
}
