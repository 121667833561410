import { useDestinations } from '@noah-labs/core-services';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useUserInit } from '../data/useUserInit';
import { useConsentOnSubmit } from '../hooks/useConsentOnSubmit';
import { useUserError } from '../hooks/useUserError';
import { ConsentScene } from '../scenes';

type PpConsent = {
  logoutTo?: string;
  nextAction?: () => void;
  nextActionDirty: () => void;
};

export function Consent({ logoutTo, nextAction, nextActionDirty }: PpConsent): React.ReactElement {
  const { data, userSettingsConsent } = useUserInit();
  const email = data?.userProfile.Email || '';

  const { data: { groupedDestinations } = {} } = useDestinations([
    webConfigBrowser.segment.writeKey,
    webConfigBrowser.analytics.cdnUrl,
  ]);

  const { error, handleConsentSubmit } = useConsentOnSubmit({
    groupedDestinations,
    nextAction,
    nextActionDirty,
  });

  const { ApiErrorScene } = useUserError(error);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <ConsentScene
      destinations={groupedDestinations}
      email={email}
      logoutTo={logoutTo}
      userSettingsConsent={userSettingsConsent}
      variant="settings"
      onSubmit={handleConsentSubmit}
    />
  );
}
