import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import type { NumberFormatPropsBase, NumberFormatValues } from 'react-number-format';
import NumberFormat from 'react-number-format';
import type { PpInputFieldAtom } from './atoms/InputFieldAtom';
import { InputFieldAtom } from './atoms/InputFieldAtom';

type TpInputFieldAtomProps = Omit<
  PpInputFieldAtom,
  'ref' | 'value' | 'defaultValue' | 'type' | 'size'
>;

export type PpFormatInputField = NumberFormatPropsBase & {
  InputFieldAtomProps: TpInputFieldAtomProps;
};
export function FormatInputField({
  InputFieldAtomProps,
  ...formatProps
}: PpFormatInputField): React.ReactElement {
  const { formState, getValues, setValue } = useFormContext();

  const handleValueChange = useCallback(
    (values: NumberFormatValues) => {
      setValue(InputFieldAtomProps.name, values.value);
    },
    [InputFieldAtomProps.name, setValue]
  );

  const numberFormatProps: NumberFormatPropsBase = {
    allowNegative: false,
    customInput: InputFieldAtom,
    onValueChange: handleValueChange,
    thousandSeparator: true,
    value: getValues(InputFieldAtomProps.name),
    ...formatProps,
  };

  const errorMessage = formState.errors[InputFieldAtomProps.name]?.message?.toString();
  const mergedInputFieldAtomProps: TpInputFieldAtomProps = {
    ...InputFieldAtomProps,
    errorMessage,
  };

  return <NumberFormat {...numberFormatProps} {...mergedInputFieldAtomProps} />;
}
