import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { TpCheckoutTokenResponse, TpGooglePaySessionData } from '@noah-labs/core-services';
import { useRequestPaymentToken as useRequestCkoPaymentToken } from '@noah-labs/core-services';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import type { CountryCode } from '@noah-labs/shared-schema-gql';
import { useMutation, useQuery } from 'react-query';
import useScript from 'react-script-hook';
import type { TpSessionValues } from '../types';

const allowedCardNetworks: google.payments.api.CardNetwork[] = [
  'AMEX',
  'DISCOVER',
  'INTERAC',
  'JCB',
  'MASTERCARD',
  'VISA',
];

const allowedAuthMethods: google.payments.api.CardAuthMethod[] = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];

const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
};

const baseCardPaymentMethod: google.payments.api.IsReadyToPayPaymentMethodSpecification = {
  parameters: {
    allowedAuthMethods,
    allowedCardNetworks,
  },
  type: 'CARD',
};

const scriptUrl = 'https://pay.google.com/gp/p/js/pay.js';

function instanceOfPaymentsError(error: unknown): error is google.payments.api.PaymentsError {
  if (typeof error !== 'object' || !error) {
    return false;
  }
  return 'statusCode' in error && 'statusMessage' in error;
}

type PpGooglePay = {
  countryCode: CountryCode | null | undefined;
  environment: 'PRODUCTION' | 'TEST';
  gateway: string;
  gatewayMerchantId: string;
  merchantId: string;
};

export type TpGooglePay = {
  getGooglePayCkoToken: (values: TpSessionValues) => Promise<TpCheckoutTokenResponse | undefined>;
  isReady: boolean;
  paymentError: google.payments.api.PaymentsError | null | unknown;
};

export function useGooglePay({
  countryCode,
  environment,
  gateway,
  gatewayMerchantId,
  merchantId,
}: PpGooglePay): TpGooglePay {
  const [loading, error] = useScript({ src: scriptUrl });
  const client = useRef<google.payments.api.PaymentsClient | null>(null);

  const { error: ckoPaymentTokenError, mutateAsync: getCkoPaymentToken } =
    useRequestCkoPaymentToken();

  const cardPaymentMethodConfig = useMemo(() => {
    const tokenizationSpecification: google.payments.api.PaymentMethodTokenizationSpecification = {
      parameters: {
        gateway,
        gatewayMerchantId,
      },
      type: 'PAYMENT_GATEWAY',
    };

    return {
      tokenizationSpecification,
      ...baseCardPaymentMethod,
    };
  }, [gateway, gatewayMerchantId]);

  useEffect(() => {
    if (loading || error || client.current) {
      return;
    }

    try {
      client.current = new google.payments.api.PaymentsClient({
        environment,
      });
    } catch (err) {
      logger.error('Google Pay client failed to initialize', err);
    }
  }, [loading, error, environment]);

  const { data: isReady } = useQuery(
    ['googlepay/IsGooglePayReady'],
    async () => {
      const isReadyToPayRequest: google.payments.api.IsReadyToPayRequest = {
        ...baseRequest,
        allowedPaymentMethods: [cardPaymentMethodConfig],
      };

      const res = await client.current?.isReadyToPay(isReadyToPayRequest);
      return Boolean(res?.result);
    },
    {
      enabled: Boolean(client.current && countryCode),
    }
  );

  const handleStartSession = useCallback(
    async ({
      amount,
      fiatCurrencyCode,
    }: TpSessionValues): Promise<TpGooglePaySessionData | undefined> => {
      if (!client.current) {
        return undefined;
      }

      if (!countryCode) {
        return undefined;
      }

      const paymentRequest: google.payments.api.PaymentDataRequest = {
        ...baseRequest,
        allowedPaymentMethods: [cardPaymentMethodConfig],
        merchantInfo: {
          merchantId,
        },
        transactionInfo: {
          countryCode,
          currencyCode: fiatCurrencyCode,
          totalPrice: amount,
          totalPriceLabel: 'Total',
          totalPriceStatus: 'FINAL',
        },
      };

      logger.debug('Google Pay payment request', paymentRequest);

      try {
        const paymentData = await client.current.loadPaymentData(paymentRequest);
        const { token } = paymentData.paymentMethodData.tokenizationData;
        return JSON.parse(token) as TpGooglePaySessionData;
      } catch (err) {
        if (instanceOfPaymentsError(err)) {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw err;
        }
        logger.error('Google Pay session was interrupted', err);
        return undefined;
      }
    },
    [countryCode, merchantId, cardPaymentMethodConfig]
  );

  /**
   * Starts the Google Pay user session.
   * @param sessionValues - values to initiate the session
   * @returns payment data. Contains the token to be exchanged with Checkout
   */
  const { error: sessionError, mutateAsync: startSession } = useMutation<
    TpGooglePaySessionData | undefined,
    google.payments.api.PaymentsError,
    TpSessionValues,
    unknown
  >(['googlepay/GetPaymentToken'], handleStartSession);

  /**
   * Requests a payment token from Checkout
   */
  const getGooglePayCkoToken = useCallback(
    async (sessionValues: TpSessionValues): Promise<TpCheckoutTokenResponse | undefined> => {
      try {
        const tokenData = await startSession(sessionValues);
        if (!tokenData) {
          return undefined;
        }

        const ckoPaymentToken = await getCkoPaymentToken({
          token_data: tokenData,
          type: 'googlepay',
        });
        return ckoPaymentToken.data;
      } catch {
        // Handled by sessionError or ckoPaymentTokenError
        return undefined;
      }
    },
    [startSession, getCkoPaymentToken]
  );

  return {
    getGooglePayCkoToken,
    isReady: Boolean(isReady),
    paymentError: sessionError || ckoPaymentTokenError,
  };
}
