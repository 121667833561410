import { Redirect, Route, Switch } from 'react-router-dom';
import type { PpWC } from '../types';
import { NotFoundPage } from '../utility/NotFound';

type PpSwitch404 = {
  /**
   * Either displays the 404 page on the current route,
   * or, if redirect is true, redirects to the 404 url
   */
  redirect?: boolean;
};

export function Switch404({ children, redirect = false }: PpWC<PpSwitch404>): React.ReactElement {
  return (
    <Switch>
      {children}
      {redirect && <Route render={(): React.ReactElement => <Redirect to="/404" />} />}
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
