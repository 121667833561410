import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { ErrorPage } from '@noah-labs/core-web-ui/src/utility/ErrorPage';
import { AppHeaderData } from '../../../components';
import { defaultAccount, useWalletParams } from '../data';
import { routes } from '../routes';

export type PpExpiredInvoiceScene = {
  pageTitle: string;
};

export function ExpiredInvoiceScene({ pageTitle }: PpExpiredInvoiceScene): React.ReactElement {
  const { params } = useWalletParams({
    accountType: defaultAccount.AccountType,
    currencyCode: defaultAccount.CurrencyCode,
  });
  return (
    <ErrorPage
      AppHeaderSlot={<AppHeaderData backButton exitButton helpButton />}
      FooterSlot={
        <PrimaryButton data-qa="done-button" href={generatePath(routes.address.text.path, params)}>
          Start over
        </PrimaryButton>
      }
      helpText="Ask the sender to create a new one and try again."
      message="This invoice has expired"
      pageTitle={pageTitle}
    />
  );
}
