import { useState } from 'react';
import { NavBar } from '@noah-labs/core-web-ui/src/menus/NavBar';
import { NavBarItem } from '@noah-labs/core-web-ui/src/menus/NavBarItem';
import { useLocation } from 'react-router-dom';
import { NavItems } from './NavItems';

export function NavBarData(): React.ReactElement {
  const { pathname } = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(pathname);

  return (
    <NavBar
      value={selectedMenu}
      onChange={(_, newValue: string): void => {
        setSelectedMenu(newValue);
      }}
    >
      <NavItems Component={NavBarItem} />
    </NavBar>
  );
}
