import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import type { PpQRScanner } from '@noah-labs/core-web-ui/src/qr/QRScanner';
import { QRScanner } from '@noah-labs/core-web-ui/src/qr/QRScanner';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import { routes } from '../routes';

export function AddressScanScene(props: PpQRScanner): React.ReactElement {
  const theme = useTheme();
  const backgroundColor = theme.palette.common.black;
  const fontColor = theme.palette.common.white;

  const [appHeaderHeight, setAppHeaderHeight] = useState(0);
  const appHeaderRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function updateAppHeaderHeight(): void {
      const { height } = appHeaderRef.current?.getBoundingClientRect() || { height: 0 };
      setAppHeaderHeight(height);
    }

    updateAppHeaderHeight();
  }, []);

  return (
    <AppContainer
      AppHeaderSlot={
        <div ref={appHeaderRef}>
          <AppHeaderData
            backButton
            exitButton
            helpButton
            backgroundColor={backgroundColor}
            fontColor={fontColor}
          >
            <AppHeaderTitle>{routes.address.scan.title}</AppHeaderTitle>
          </AppHeaderData>
        </div>
      }
      backgroundColor={backgroundColor}
      dataQa="scan"
      fontColor={fontColor}
      mainSxProps={{
        paddingBottom: `${theme.spacing(0)} !important`,
      }}
    >
      <Helmet>
        <title>{routes.address.scan.title}</title>
      </Helmet>
      <SceneMain style={{ padding: 0 }}>
        <QRScanner {...props} appHeaderHeight={appHeaderHeight} />
      </SceneMain>
    </AppContainer>
  );
}
