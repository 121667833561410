import { useContext } from 'react';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { Feature } from '@noah-labs/shared-schema-gql';
import { useAuth } from '../../auth/hooks/useAuth';
import { useFeature } from '../../user/hooks/useFeature';
import type { CxSigning } from './SigningProvider';
import { SigningContext } from './SigningProvider';

export function useSigning(): CxSigning {
  const context = useContext(SigningContext);
  const fpin = useFeature(Feature.Pin);
  const { isAuthenticated } = useAuth();

  if (!fpin?.Enabled && isAuthenticated) {
    return {} as CxSigning;
  }
  if (context === undefined) {
    return logger.fatal('useSigning must be used within a SigningProvider');
  }
  return context;
}
