import type { UiText } from '@ory/client';
import { HttpStatusCode, isAxiosError } from 'axios';
import type {
  OryContinueWith,
  OryContinueWithVerification,
  OryNoSecurityKeyError,
  OryRedirectBrowserError,
  OrySessionRefreshRequiredError,
  OryUpdateFlowError,
} from '../types';
import { getPropertyByPath } from './utils';

const ACCOUNT_HAS_NO_SECURITY_KEY_MESSAGE_ID = 4000015;

export function isContinueWithVerification(
  item: OryContinueWith
): item is OryContinueWithVerification {
  if (item.action === 'show_verification_ui') {
    return true;
  }
  return false;
}

export function isOryUpdateFlowError(error: unknown): error is OryUpdateFlowError {
  if (!isAxiosError<unknown>(error)) {
    return false;
  }
  if (error.response === undefined) {
    return false;
  }
  const { data, status } = error.response;
  return status === HttpStatusCode.Gone && getPropertyByPath(data, ['use_flow_id']) !== undefined;
}

export function isRedirectBrowserError(error: unknown): error is OryRedirectBrowserError {
  if (!isAxiosError(error)) {
    return false;
  }

  if (
    error.response?.status !== HttpStatusCode.UnprocessableEntity ||
    !('redirect_browser_to' in error.response.data)
  ) {
    return false;
  }
  return true;
}

export function isNoSecurityKeyError(error: unknown): error is OryNoSecurityKeyError {
  if (!isAxiosError(error) || !error.response) {
    return false;
  }

  const { data } = error.response;
  const uiMessages = getPropertyByPath(data, ['ui', 'messages']) as UiText[] | undefined;

  if (!Array.isArray(uiMessages)) {
    return false;
  }

  return uiMessages.some((m) => m.id === ACCOUNT_HAS_NO_SECURITY_KEY_MESSAGE_ID);
}

export function isSessionRefreshRequiredError(
  error: unknown
): error is OrySessionRefreshRequiredError {
  if (!isAxiosError(error) || !error.response) {
    return false;
  }

  const { data } = error.response;
  const errorId = getPropertyByPath(data, ['error', 'id']);

  if (errorId === 'session_refresh_required') {
    return true;
  }

  return false;
}
