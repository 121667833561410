/* eslint-disable react/no-multi-comp */
import { useCallback } from 'react';
import Fingerprint from '@mui/icons-material/Fingerprint';
import { SvgIcon } from '@mui/material';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { MenuItemSwitch } from '@noah-labs/core-web-ui/src/menus/MenuItemSwitch';
import {
  getIsWebAuthnSupported,
  isWebAuthnCanceledByUserError,
  useOryWebAuthnToggle,
} from '@noah-labs/fe-shared-data-access-auth';
import { TpAnalyticsEvent, useAnalytics } from '../../analytics';

function FingerprintIcon(): React.ReactElement {
  return <SvgIcon component={Fingerprint} data-qa="FingerprintIcon" />;
}

export function SignInWithBiometricToggle(): React.ReactElement {
  const pushAlert = usePushAlert();
  const analytics = useAnalytics();
  const { isWebAuthnEnabled, loading, toggleWebAuthn } = useOryWebAuthnToggle();

  const toggleWithLoading = useCallback(async () => {
    try {
      await toggleWebAuthn();
      analytics.track(
        isWebAuthnEnabled ? TpAnalyticsEvent.WebAuthnDisabled : TpAnalyticsEvent.WebAuthnEnabled
      );
    } catch (error) {
      if (isWebAuthnCanceledByUserError(error)) {
        return;
      }
      if (error instanceof Error) {
        pushAlert({
          dismissable: true,
          key: 'biometricToggleError',
          message: error.message,
          preventDuplicate: true,
          severity: 'error',
        });
      }
    }
  }, [analytics, isWebAuthnEnabled, pushAlert, toggleWebAuthn]);

  return (
    <MenuItemSwitch
      checked={isWebAuthnEnabled}
      dataQa="biometric-sign-in-toggle"
      disabled={loading || !(isWebAuthnEnabled || getIsWebAuthnSupported())}
      icon={FingerprintIcon}
      label="Sign in with biometric"
      onClick={toggleWithLoading}
    />
  );
}
