import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { InlineLinkOrButton } from '@noah-labs/core-web-ui/src/buttons/InlineLinkOrButton';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneImage } from '@noah-labs/core-web-ui/src/scene/SceneImage';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { webLinks } from '@noah-labs/shared-constants';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import KycId from '../../../images/kyc-id.svg';

export type PpKycReminderScene = {
  handleNotNowClick: () => void;
  handleYesClick: () => void;
  image?: string;
  paragraph?: string;
  showFaqLink?: boolean;
  title?: string;
};

export function KycReminderScene({
  handleNotNowClick,
  handleYesClick,
  image = KycId,
  paragraph = 'Please complete KYC verification to unlock all the features of the NOAH app.',
  showFaqLink,
  title = 'Please complete identity verification to continue',
}: PpKycReminderScene): React.ReactElement {
  return (
    <AppContainer
      AppFooterSlot={
        <ButtonGroup>
          <PrimaryButton color="primaryBrand" data-qa="yes-btn" onClick={handleYesClick}>
            Yes
          </PrimaryButton>
          <PrimaryButton data-qa="not-now-btn" variant="text" onClick={handleNotNowClick}>
            Not now
          </PrimaryButton>
        </ButtonGroup>
      }
      AppHeaderSlot={<AppHeaderData exitButton helpButton />}
      dataQa="kyc-reminder"
      mainSxProps={{
        textAlign: 'center',
      }}
    >
      <Helmet>
        <title>Please complete identity verification to continue</title>
      </Helmet>
      <SceneMain>
        <SceneImage alt="Please complete identity verification to continue" src={image} />
        <SceneTitleLarge>{title}</SceneTitleLarge>
        <SceneParagraph>{paragraph}</SceneParagraph>
        {showFaqLink && (
          <SceneParagraph>
            Read our{' '}
            <InlineLinkOrButton href={webLinks.faq.base} target="_blank">
              FAQ
            </InlineLinkOrButton>{' '}
            to find out more
          </SceneParagraph>
        )}
      </SceneMain>
    </AppContainer>
  );
}
