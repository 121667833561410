import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { InputField } from '@noah-labs/core-web-ui/src/forms/InputField';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeader } from '@noah-labs/core-web-ui/src/layout/AppHeader';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import type { ErrorOption, Resolver, UseFormSetError } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ResendVerificationCode } from '../components/ResendVerificationCode';
import { useAuthError } from '../hooks/useAuthError';
import { routes } from '../routes';
import type { TpForgottenEmailForm } from './ForgottenPasswordEnterEmail';

export type TpForgottenCodeForm = {
  code: string;
  root?: { serverError: void };
};

export type TpForgottenCode = {
  root?: { serverError: void };
};

const forgottenCodeFormId = 'forgotten-code-form';

const defaultValues: TpForgottenCodeForm = { code: '' };

const schema = yup.object({
  code: yup.string().required('Recovery code is a required field'),
});

export type PpForgottenPasswordEnterCodeScene = {
  email: string;
  error: ErrorOption | undefined;
  helpButton?: React.ReactNode;
  loading?: boolean;
  onResend: (
    values: TpForgottenEmailForm,
    setError: UseFormSetError<{
      root?:
        | {
            serverError: void;
          }
        | undefined;
    }>
  ) => Promise<void>;
  onVerify: (
    values: TpForgottenCodeForm,
    setError: UseFormSetError<TpForgottenCodeForm>
  ) => Promise<void>;
};

export function ForgottenPasswordEnterCodeScene({
  email,
  error,
  helpButton,
  loading,
  onResend,
  onVerify,
}: PpForgottenPasswordEnterCodeScene): React.ReactElement {
  const [isResending, setIsResending] = useState(false);

  const methods = useForm<TpForgottenCodeForm>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema) as Resolver<TpForgottenCodeForm>,
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = methods;

  useAuthError({ error: errors.root?.serverError });

  useEffect(() => {
    if (!error) {
      return;
    }
    setError('root.serverError', error);
  }, [setError, error]);

  const onResendCallback = useCallback(async () => {
    try {
      setIsResending(true);
      await onResend({ email }, setError);
    } finally {
      setIsResending(false);
    }
  }, [email, onResend, setError]);

  return (
    <FormProvider {...methods}>
      <AppContainer
        wideFooter
        AppFooterSlot={
          <PrimaryButton
            color="primaryBrand"
            disabled={isSubmitting || loading}
            form={forgottenCodeFormId}
            loading={isSubmitting || loading}
            type="submit"
          >
            Continue
          </PrimaryButton>
        }
        AppHeaderSlot={<AppHeader backTo={routes.signIn.path} endIconsSlot={helpButton} />}
        dataQa="forgotten-code"
      >
        <Helmet>
          <title>Check your email</title>
        </Helmet>
        <SceneHeader>
          <SceneTitleLarge>Check your email</SceneTitleLarge>
          <SceneParagraph>
            An email containing a recovery code has been sent to the email address you provided. If
            you have not received an email, check the spelling of the address and make sure to use
            the address you registered with.
          </SceneParagraph>
        </SceneHeader>
        <SceneMain>
          <Stack justifyContent="center" spacing={4}>
            <form
              id={forgottenCodeFormId}
              onSubmit={handleSubmit((values) => onVerify(values, setError))}
            >
              <InputField
                fullWidth
                required
                dataQa="recovery-code"
                inputProps={{
                  'aria-label': 'Recovery code',
                  inputMode: 'numeric',
                }}
                label="Recovery code"
                name="code"
                placeholder=""
              />
            </form>
            <ResendVerificationCode loading={isResending} onResend={onResendCallback} />
          </Stack>
        </SceneMain>
      </AppContainer>
    </FormProvider>
  );
}
