import { useEffect, useState } from 'react';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import type { VerificationFlow } from '@ory/client';
import { useOry } from '../data';
import { getOryFlowId } from '../utils';

export function useOryVerificationFlow(): VerificationFlow | undefined {
  const { ory } = useOry();

  const [flow, setFlow] = useState<VerificationFlow | undefined>(undefined);

  const flowId = getOryFlowId();
  useEffect(() => {
    if (!flowId) {
      return;
    }

    (async (): Promise<void> => {
      const { data: verificationFlow } = await ory.getVerificationFlow({
        id: flowId,
      });
      setFlow(verificationFlow);
    })().catch((error) => {
      logger.error(error);
    });
  }, [ory, flowId]);

  return flow;
}
