import { useMemo } from 'react';
import { SortDirection } from '@noah-labs/shared-schema-gql';
import { duration } from '@noah-labs/shared-tools/src/browser/duration';
import { useUserInit } from '../../../user/data/useUserInit';
import { useInfiniteTransactionsQuery, useWalletParams } from '../../data';
import { useCryptoUnit } from '../../hooks/useCryptoUnit';
import { usePrimaryCurrency } from '../../hooks/usePrimaryCurrency';
import { TransactionList } from './TransactionList';

export function TransactionsData(): React.ReactElement {
  const userInit = useUserInit();
  const { CurrencyCode } = useWalletParams();
  const { data, fetchNextPage, hasNextPage, isFetched, isFetchingNextPage } =
    useInfiniteTransactionsQuery(
      {
        filter: {
          // TODO: this _should_ work but currently some tx's don't have this field
          // AccountType: {
          //   eq: AccountType,
          // },
          Currency: {
            eq: CurrencyCode,
          },
        },
        limit: 20,
        sortDirection: SortDirection.DESC,
      },
      {
        getNextPageParam: (prevPage) => {
          if (prevPage.transactions.nextToken) {
            return { nextToken: prevPage.transactions.nextToken };
          }
          return undefined;
        },
        refetchInterval: duration.seconds(3),
      }
    );

  const allTransactions = useMemo(
    () => data?.pages.flatMap((page) => page.transactions.items),
    [data?.pages]
  );

  const { primaryCurrency } = usePrimaryCurrency();
  const cryptoUnit = useCryptoUnit();

  return (
    <TransactionList
      cryptoUnit={cryptoUnit}
      hasNextPage={hasNextPage}
      isFetched={isFetched}
      isFetchingNextPage={isFetchingNextPage}
      primaryCurrency={primaryCurrency}
      transactions={allTransactions}
      userID={userInit.data?.userProfile.UserID || ''}
      onNextPage={fetchNextPage}
    />
  );
}
