import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { useToggleDialog } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { manualRampEta } from '../../data';

export const ProcessingTimeBankDialog = forwardRef<TpDialogToggle>((_, ref): JSX.Element => {
  const { open, toggle } = useToggleDialog({ ref });

  return (
    <LowDialog open={open} onClose={toggle}>
      <DialogTitle>Processing time</DialogTitle>
      <DialogContent>
        <DialogContentText paragraph>
          After confirmation, the funds will be transferred to your linked bank account, which can
          take up to {manualRampEta}.
        </DialogContentText>
        <DialogContentText>
          We are committed to keeping you informed throughout the process and aim to provide a
          smooth and timely transfer of funds from your bitcoin sale to your personal bank account.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton color="primaryBrand" onClick={toggle}>
          Got it
        </PrimaryButton>
      </DialogActions>
    </LowDialog>
  );
});

ProcessingTimeBankDialog.displayName = ProcessingTimeBankDialog.name;
