import { AccountType, CurrencyCode } from '@noah-labs/shared-schema-gql';
import { useParams } from 'react-router-dom';
import { getReferralUrl } from '../../../utils';
import { useUserInit } from '../../user/data/useUserInit';
import { selectAccount, useAccountsQuery } from '../../wallet/data';
import { BadgesTabPanel, QuestsTabPanel } from '../components';
import { selectQuestsAndBadges, useQuestsAndBadgesQuery } from '../data';
import { RewardsScene } from '../scenes';
import { TpRewardsTabs } from '../types';

export function Rewards(): React.ReactElement {
  const params = useParams<{ tab: TpRewardsTabs }>();
  const { data: userData, features } = useUserInit();
  const { data: questsAndBadgesData } = useQuestsAndBadgesQuery(undefined, {
    select: (data) => selectQuestsAndBadges(data, features),
  });
  const { data: account } = useAccountsQuery(undefined, {
    select: (data) =>
      selectAccount({
        AccountType: AccountType.Current,
        CurrencyCode: CurrencyCode.NOAH,
        data,
      }),
  });
  const userPoints = account?.Balance?.Lifetime;

  const tabs = [
    {
      header: 'Earn Points',
      panel: (
        <QuestsTabPanel
          activeQuests={questsAndBadgesData?.activeQuests}
          completedBadges={questsAndBadgesData?.completedBadges}
          referralUrl={getReferralUrl(userData?.userProfile.UsernameDisplay)}
        />
      ),
      value: TpRewardsTabs.Quests,
    },
    {
      header: 'Rewards & Perks',
      panel: <BadgesTabPanel badges={questsAndBadgesData?.badges} />,
      value: TpRewardsTabs.Badges,
    },
  ];
  return <RewardsScene activeTab={params.tab} tabs={tabs} userPoints={userPoints} />;
}
