import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { Route } from 'react-router-dom';
import { AuthRouteData } from '../auth';
import { Confirm, Confirmed } from './controllers';
import { routes } from './routes';

export function Router(): React.ReactElement {
  return (
    <Switch404>
      <Route
        exact
        path={routes.confirm.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.confirm}>
            <Confirm />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.confirmed.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.confirmed}>
            <Confirmed />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
