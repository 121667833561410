import { Divider, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ElevatedCardListItem } from '@noah-labs/core-web-ui/src/cards/ElevatedCardListItem';
import { ListSection } from '@noah-labs/core-web-ui/src/lists/ListSection';
import { getPayoutFeeAndEta } from '../../data';
import type { TpPaymentBank, TpPaymentCard } from '../../types';
import { TpPaymentMethod } from '../../types';
import { ManagePaymentMethodsLink } from '../buttons';
import { PaymentMethod } from './PaymentMethod';

export type PpSavedPayoutMethodsList = {
  onPayoutMethodClick: (payoutMethod: TpPaymentCard | TpPaymentBank) => void;
  savedPayoutMethods: Array<TpPaymentCard | TpPaymentBank>;
};

export function SavedPayoutMethodsList({
  onPayoutMethodClick,
  savedPayoutMethods,
}: PpSavedPayoutMethodsList): React.ReactElement {
  const theme = useTheme();
  const buttonSx = {
    ':hover': {
      backgroundColor: 'inherit',
    },
    flexWrap: 'wrap',
    padding: theme.spacing(1.25, 2),
  };

  return (
    <Stack spacing={2}>
      <ListSection
        title="Saved payout methods"
        titleColor="text.dark"
        TitleEndSlot={<ManagePaymentMethodsLink />}
      >
        {savedPayoutMethods.map((method) => (
          <ElevatedCardListItem
            key={method.id}
            buttonSx={buttonSx}
            disabled={!method.payoutSupported}
            spacing={1}
            onClick={(): void => {
              onPayoutMethodClick(method);
            }}
          >
            <Stack divider={<Divider />} spacing={1} width="100%">
              {method.payoutMethod &&
                (method.payoutMethod === TpPaymentMethod.Card ? (
                  <PaymentMethod
                    method={method.payoutMethod}
                    paymentCard={method as TpPaymentCard}
                  />
                ) : (
                  <PaymentMethod
                    method={method.payoutMethod}
                    paymentBank={method as TpPaymentBank}
                  />
                ))}

              {method.estimateHours && (
                <Typography color="text.light" variant="paragraphBodyS">
                  {getPayoutFeeAndEta(method)}
                </Typography>
              )}
              {!method.payoutSupported && (
                <Typography variant="paragraphBodyS">Not supported for sell</Typography>
              )}
            </Stack>
          </ElevatedCardListItem>
        ))}
      </ListSection>
    </Stack>
  );
}
