import type { SVGProps } from 'react';

export function SvgArrowRightThickVariant(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 25 25"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M2.5 12.65c0-.98.762-1.774 1.702-1.774h11.909l-4.12-3.592a1.827 1.827 0 0 1-.213-2.499 1.655 1.655 0 0 1 2.398-.222l7.714 6.726c.387.337.61.835.61 1.36s-.223 1.024-.61 1.361l-7.714 6.726a1.655 1.655 0 0 1-2.398-.222 1.83 1.83 0 0 1 .213-2.5l4.12-3.59H4.202c-.94 0-1.702-.795-1.702-1.775Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
