import { useEffect } from 'react';
import { disableRefetchRetry } from '@noah-labs/core-services';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useAuth } from '../../auth/hooks/useAuth';
import { TpAuthStatus } from '../../auth/types';
import { useIntercomIdentifyHashQuery } from '../data/analytics.generated';
import { useAnalyticsContext } from './useAnalyticsContext';

export function useIdentify(): void {
  const ajs = useAnalyticsContext();

  /**
   * Setup identify tracking callback
   */
  const { authStatus, data } = useAuth();
  const { email, userId, verified } = data || {};

  const { data: userHash } = useIntercomIdentifyHashQuery(undefined, {
    enabled: Boolean(userId),
    ...disableRefetchRetry,
  });
  useEffect(() => {
    /**
     * Don't call if disabled
     */
    if (!webConfigBrowser.analytics.enabled || !webConfigBrowser.analytics.identify) {
      logger.debug('identify not called: analytics disabled');
      return;
    }

    /**
     * Don't call until we know the user is definitely authenticaed
     */
    if (authStatus !== TpAuthStatus.authenticated) {
      logger.debug('identify not called: user is not authed');
      return;
    }

    /**
     * Calls to identify can be throttled by destinations so only do it once all critical data is available
     * We need the user to:
     * - be authenticated
     * - have a userid
     * - have an email
     * - have the userHash for intercom
     */
    if (!userId || !email || !userHash?.intercomIdentifyHash.UserHash) {
      logger.debug('identify not called: userId, email or userHash is not not available');
      return;
    }

    /**
     * User profile updates are sent from our BE (more reliably) so no need to send extra info here
     */
    logger.debug('identify user');
    void ajs.identify(
      userId,
      {
        email,
        email_verified: verified,
        user_id: userId,
      },
      {
        integrations: {
          Intercom: {
            user_hash: userHash.intercomIdentifyHash.UserHash,
          },
        },
      }
    );
  }, [ajs, email, userId, authStatus, userHash?.intercomIdentifyHash.UserHash, verified]);
}
