import { useCallback } from 'react';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import type { RegistrationFlow, SettingsFlow } from '@ory/client';
import type { AxiosError } from 'axios';
import { HttpStatusCode, isAxiosError } from 'axios';
import { useOry } from '../data';
import type { AuthSetError } from '../types';
import {
  findOryUiNode,
  getOryCsrfToken,
  getOryFlowId,
  isSessionRefreshRequiredError,
} from '../utils';
import { getOryUiError } from './utils';

type TpUseOryUpdatePasswordFlowCallback = {
  onUpdate: (
    values: { password: string },
    setError: AuthSetError<{ password: string }>
  ) => Promise<SettingsFlow | undefined>;
};

export function useOryUpdatePasswordFlowCallback(): TpUseOryUpdatePasswordFlowCallback['onUpdate'] {
  const { ory } = useOry();

  return useCallback(
    async (values, setError): Promise<SettingsFlow | undefined> => {
      try {
        const flowId = getOryFlowId() || '';
        const { data: flow } = await ory.getSettingsFlow({
          id: flowId,
        });

        const csrfToken = getOryCsrfToken(flow.ui.nodes);
        await ory.updateSettingsFlow({
          flow: flow.id,
          updateSettingsFlowBody: {
            csrf_token: csrfToken,
            method: 'password',
            password: values.password,
          },
        });

        return flow;
      } catch (error: unknown) {
        logger.error(error);
        if (!isAxiosError(error)) {
          setError('root.serverError', {
            message: 'Something went wrong.',
            type: 'custom',
          });
          return undefined;
        }

        if (isSessionRefreshRequiredError(error)) {
          setError('root.serverError', {
            message: 'Please login again to reset your password.',
            type: 'custom',
          });
          return undefined;
        }

        const oryError = error as AxiosError<RegistrationFlow>;
        if (oryError.response?.status !== HttpStatusCode.BadRequest) {
          setError('root.serverError', {
            message: 'Something went wrong.',
            type: 'custom',
          });
          return undefined;
        }

        const { ui } = oryError.response.data;
        const uiServerMessage = getOryUiError(ui.messages);
        if (uiServerMessage) {
          setError('root.serverError', uiServerMessage);
        }

        const passwordNode = findOryUiNode(ui.nodes, 'password');
        if (passwordNode?.messages && passwordNode.messages.length > 0) {
          setError('password', {
            message: passwordNode.messages[0].text,
            type: 'value',
          });
        }
        return undefined;
      }
    },
    [ory]
  );
}
