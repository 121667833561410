import { SidebarNav } from '@noah-labs/core-web-ui/src/menus/SidebarNav';
import { SidebarNavItem } from '@noah-labs/core-web-ui/src/menus/SidebarNavItem';
import { useUserInit } from '../../modules/user/data/useUserInit';
import { NavItems } from './NavItems';

export function SidebarNavData(): React.ReactElement {
  const { data } = useUserInit();
  return (
    <SidebarNav title={data?.userProfile.UsernameDisplay}>
      <NavItems Component={SidebarNavItem} />
    </SidebarNav>
  );
}
