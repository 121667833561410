import { useLocation } from 'react-router-dom';
import { useUserInit } from '../user/data/useUserInit';
import type { PpAuthRouteData } from './components';
import { AuthRoute } from './components';
import { useAuth } from './hooks/useAuth';

export function AuthRouteData({
  authRedirect = '/',
  featureRedirect = '/feature-disabled',
  ...rest
}: PpAuthRouteData): React.ReactElement {
  const { pathname } = useLocation();
  const { data: auth, isAuthenticated } = useAuth();
  const { features } = useUserInit();

  return (
    <AuthRoute
      {...rest}
      authGroups={auth?.authGroups}
      authRedirect={authRedirect}
      featureRedirect={featureRedirect}
      isAuthenticated={isAuthenticated}
      isVerified={auth?.verified}
      pathname={pathname}
      search={window.location.search}
      userFeatures={features}
    />
  );
}
