import { useUserInit } from '../data/useUserInit';
import { ProfileDetailsScene } from '../scenes';

export function ProfileDetails(): React.ReactElement {
  const { data } = useUserInit();

  return (
    <ProfileDetailsScene
      email={data?.userProfile.Email}
      personalName={data?.userProfile.PersonalName}
    />
  );
}
