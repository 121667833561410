//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgEthereum } from '../icons/Ethereum';

export function EthereumIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgEthereum} data-qa="EthereumIcon">
      <svg />
    </SvgIcon>
  );
}
