import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { TpMuiSvgIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/types';
import type { PpWOC } from '../types';
import { TextOrSkeleton } from '../typography/TextOrSkeleton';

export type PpAddressWithIcon = PpWOC & {
  Icon: TpMuiSvgIcon | null | undefined;
  containerStyles?: SerializedStyles;
  noWrap?: boolean;
};

export function AddressWithIcon({
  children,
  containerStyles,
  Icon,
  noWrap = true,
}: PpAddressWithIcon): React.ReactElement {
  const theme = useTheme();
  const styles = {
    addressDisplayContainer: css`
      align-items: center;
      justify-content: center;
      width: 100%;
      color: ${theme.palette.grayscale[600]};
    `,
    icon: css`
      font-size: 1.125rem;
    `,
    textWrap: css`
      overflow: auto;
      word-wrap: break-word;
      word-break: break-word;
    `,
  };

  return (
    <Stack css={[styles.addressDisplayContainer, containerStyles]} direction="row" spacing={1}>
      {Icon && <Icon css={styles.icon} />}
      <Typography css={!noWrap && styles.textWrap} noWrap={noWrap} variant="paragraphBodyS">
        <TextOrSkeleton sx={{ px: '6em' }}>{children}</TextOrSkeleton>
      </Typography>
    </Stack>
  );
}
