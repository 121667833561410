import React from 'react';
import { Alert } from '@mui/material';
import type { TpMoonpayTransactionStatus } from '@noah-labs/core-services';
import { AlertLink } from '@noah-labs/core-web-ui/src/alerts/AlertLink';
import { GetInTouchLink } from '../../../../components';

type PpAlert = {
  moonpayUrl: string | undefined;
  status: TpMoonpayTransactionStatus | undefined;
};
export function AlertBuy({ moonpayUrl, status }: PpAlert): React.ReactElement | null {
  switch (status) {
    case 'completed':
      return (
        <Alert severity="info">
          Your crypto should soon be credited to your bitcoin wallet.{' '}
          <AlertLink href={moonpayUrl} target="_blank">
            View your MoonPay order
          </AlertLink>
          .
        </Alert>
      );
    case 'failed':
      return (
        <Alert severity="error">
          Your transaction has failed because we weren&#39;t able to verify your identity. You have
          not been charged, if you need help, please{' '}
          <GetInTouchLink color="inherit" underline="always" /> or{' '}
          <AlertLink href={moonpayUrl} target="_blank">
            view your MoonPay order
          </AlertLink>
          .
        </Alert>
      );
    case 'pending':
      return (
        <Alert severity="info">
          This should arrive within a few hours. On-chain transaction times vary depending on
          network conditions.{' '}
          <AlertLink href={moonpayUrl} target="_blank">
            View your order status here.
          </AlertLink>
        </Alert>
      );

    default:
      return null;
  }
}
