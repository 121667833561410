import {
  useOryLinkedProviders,
  useOrySocialLinkCallback,
  useOrySocialUnLinkCallback,
} from '@noah-labs/fe-shared-data-access-auth';
import { LinkedAccountsScene } from '../scenes';

export function LinkedAccounts(): React.ReactElement {
  const { availableProviders, linkedProviders } = useOryLinkedProviders();
  const onLinkAccount = useOrySocialLinkCallback();
  const onUnLinkAccount = useOrySocialUnLinkCallback();

  return (
    <LinkedAccountsScene
      availableProviders={availableProviders}
      linkedProvidersInitial={linkedProviders}
      onLinkAccount={onLinkAccount}
      onUnLinkAccount={onUnLinkAccount}
    />
  );
}
