import type { CurrencyCode, AccountType as TpAccountType } from '@noah-labs/shared-schema-gql';
import { selectFromFilterWithFallback } from '../data/accounts';
import { useAccountsQueryPoll } from '../data/useAccountsQueryPoll';

type PpUseLifetimeBalance = {
  accountType: TpAccountType;
  currencyCode: CurrencyCode;
};

type TpUseLifetimeBalance = {
  cryptoAmount: string | undefined;
};

export function useLifetimeBalance({
  accountType,
  currencyCode,
}: PpUseLifetimeBalance): TpUseLifetimeBalance {
  const { data: account } = useAccountsQueryPoll(undefined, {
    select: (data) =>
      selectFromFilterWithFallback({
        AccountType: accountType,
        CurrencyCode: currencyCode,
        data,
      }),
  });
  const cryptoAmount = account?.Balance?.Lifetime;

  return {
    cryptoAmount,
  };
}
