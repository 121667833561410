import { forwardRef } from 'react';
import {
  Alert,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import { LearnMoreButton } from '@noah-labs/core-web-ui/src/buttons/LearnMoreButton';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import { useToggleDialog } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { webLinks } from '@noah-labs/shared-constants';
import { CurrencyCode } from '@noah-labs/shared-schema-gql';
import { getStablecoinsDialogLabel } from '../../utils/getStablecoinsDialogLabel';

export type PpUsdStablecoinsFeeDialog = {
  currencyCode: CurrencyCode;
  initialOpen?: boolean;
};

function getLearnMoreLink(currencyCode: CurrencyCode): string {
  switch (currencyCode) {
    case CurrencyCode.USDC:
    case CurrencyCode.USDC_TEST:
      return webLinks.faq.usdcEthereumFee;

    case CurrencyCode.USDT:
    case CurrencyCode.USDT_TEST:
      return webLinks.faq.usdtEthereumFee;

    default:
      return '';
  }
}

export const UsdStablecoinsFeeDialog = forwardRef<TpDialogToggle, PpUsdStablecoinsFeeDialog>(
  ({ currencyCode, initialOpen }, ref): JSX.Element => {
    const { open, toggle } = useToggleDialog({ initialOpen, ref });

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle>${currencyCode} Transaction fees</DialogTitle>
        <DialogContent>
          <DialogContentText paragraph>
            Whenever you send or receive {currencyCode} ({getStablecoinsDialogLabel(currencyCode)}),
            you are charged a fee that goes to the miners - the individuals or entities responsible
            for processing and ensuring the security of transactions.
          </DialogContentText>

          <DialogContentText>
            {currencyCode} transaction fees on each network fluctuate based on factors such as
            network congestion, transaction size, and complexity. It’s essential to be aware of
            these fluctuations, especially during peak network traffic times.
          </DialogContentText>

          <Stack marginTop={3} spacing={3}>
            <Alert severity="warning">
              Stablecoins, despite their name, aren’t always stable. Transact or invest with
              caution.
            </Alert>

            <LearnMoreButton
              href={getLearnMoreLink(currencyCode)}
              target="_blank"
              variant="paragraphBodyM"
            >
              Learn more about transaction fees.
            </LearnMoreButton>
          </Stack>
        </DialogContent>
        <DialogActions>
          <PrimaryButton color="primaryBrand" onClick={toggle}>
            Got It
          </PrimaryButton>
        </DialogActions>
      </LowDialog>
    );
  }
);

UsdStablecoinsFeeDialog.displayName = UsdStablecoinsFeeDialog.name;
