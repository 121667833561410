import { oryWebAuthnBufferEncode } from './utils';

type ExtendedAuthenticatorResponse = AuthenticatorResponse & {
  authenticatorData: ArrayBuffer;
  signature: ArrayBuffer;
  userHandle: ArrayBuffer;
};

type OryWebAuthnCredentials = {
  id: string;
  rawId: string;
  response: {
    authenticatorData: string;
    clientDataJSON: string;
    signature: string;
    userHandle: string;
  };
  type: string;
};

export async function getOryWebAuthnCredentials(
  opt: CredentialRequestOptions
): Promise<OryWebAuthnCredentials> {
  const credential = await navigator.credentials.get(opt);

  const { id, rawId, response, type } = credential as PublicKeyCredential & {
    response: ExtendedAuthenticatorResponse;
  };

  return {
    id,
    rawId: oryWebAuthnBufferEncode(rawId),
    response: {
      authenticatorData: oryWebAuthnBufferEncode(response.authenticatorData),
      clientDataJSON: oryWebAuthnBufferEncode(response.clientDataJSON),
      signature: oryWebAuthnBufferEncode(response.signature),
      userHandle: oryWebAuthnBufferEncode(response.userHandle),
    },
    type,
  };
}
