/* eslint-disable react/no-multi-comp */
import { Fragment } from 'react';
import type { TextFieldProps } from '@mui/material';
import { DialogContent, InputAdornment, TextField, Typography } from '@mui/material';
import { SearchIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/SearchIcon';
import type { PpWC } from '../../types';

type ACTextFieldProps = TextFieldProps & {
  dataQa?: string;
  rootProps: React.HTMLAttributes<HTMLDivElement>;
};

export function ACTextField({
  dataQa,
  inputProps,
  rootProps,
  ...rest
}: ACTextFieldProps): React.ReactElement {
  return (
    <div {...rootProps}>
      <TextField
        {...rest}
        autoFocus
        fullWidth
        InputProps={{
          inputProps: {
            ...inputProps,
            'data-qa': dataQa,
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Select or search"
      />
    </div>
  );
}

export function ACContent({ children }: PpWC): React.ReactElement {
  return <DialogContent sx={{ p: 0 }}>{children}</DialogContent>;
}

type PpACNoOptions = {
  children?: React.ReactNode;
};

export function ACNoOptions({ children }: PpACNoOptions): React.ReactElement {
  return (
    <Typography sx={{ p: 2 }}>
      {children || 'No options match your search, please try again.'}
    </Typography>
  );
}

type PpAcOptionLabel = {
  label: string;
  labelStart?: string;
};
export function ACOptionLabel({ label, labelStart }: PpAcOptionLabel): React.ReactElement {
  return (
    <Fragment>
      {labelStart && (
        <Typography
          component="span"
          sx={{
            display: 'inline-block',
            minWidth: '6ch',
          }}
        >
          {labelStart}
        </Typography>
      )}
      {label}
    </Fragment>
  );
}
