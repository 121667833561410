import { AuthGroups, Feature } from '@noah-labs/shared-schema-gql';
import { TpRewardsTabs } from './types';

const tabs = Object.keys(TpRewardsTabs).join('|').toLowerCase();

const base = '/rewards';
const tabBase = `${base}/:tab(${tabs})`;

const groups = new Set([AuthGroups.personal_basic]);

export const routes = {
  badge: {
    feature: Feature.UIRewards,
    groups,
    path: `${base}/${TpRewardsTabs.Badges}/:id`,
    title: 'Badge',
  },
  base: {
    feature: Feature.UIRewards,
    groups,
    path: base,
    title: 'Rewards',
  },
  inviteFriends: {
    feature: Feature.UIRewards,
    groups,
    path: `${base}/invite-friends`,
    title: 'Invite friends',
  },
  quest: {
    feature: Feature.UIRewards,
    groups,
    path: `${base}/${TpRewardsTabs.Quests}/:id`,
    title: 'Quest',
  },
  tabs: {
    feature: Feature.UIRewards,
    groups,
    path: tabBase,
    title: 'Rewards',
  },
};
