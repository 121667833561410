/* eslint-disable typescript-sort-keys/string-enum */

import type { QuestTaskRequirement } from '@noah-labs/shared-schema-gql';

export enum TpAnalyticsEvent {
  // Welcome
  CreateAccountClicked = 'Welcome Create Account Button Click',
  LoginClicked = 'Welcome Log In Button Click',

  // Cards and misc
  CarouselCardClicked = 'Carousel Card Clicked',
  ReferAFriendClicked = 'Refer Friend Button Click',
  SocialReferralCardClicked = 'Social Referral Card Clicked',
  ReferralLinkCopied = 'Referral Link Copied',

  // Wallet
  BuyClicked = 'Buy Button Clicked',
  SellClicked = 'Sell Button Clicked',
  ReceiveClicked = 'Receive Button Clicked',
  SendClicked = 'Send Button Clicked',
  LNInvoiceUrlCopied = 'LN Invoice Url Copied',

  // Rewards
  TaskClick = 'Quest Task Clicked',
  BadgeClicked = 'Badge Clicked',
  QuestClicked = 'Quest Clicked',

  // Rewards Only
  SocialCardClicked = 'Social Media Card Clicked',

  // Social Auth
  SocialAuthLink = 'Social Auth Toggle Link Clicked',
  SocialAuthUnlink = 'Social Auth Toggle Unlink Clicked',

  // KYC
  KycVerifyClicked = 'KYC Verify Button Clicked',

  // WebAuthn
  WebAuthnEnabled = 'WebAuthn Toggle Enabled',
  WebAuthnDisabled = 'WebAuthn Toggle Disabled',

  // Security PIN
  RecoveryPhraseDownloadClicked = 'Recovery Phrase Download Button Clicked',
  SecretPhraseCopyClicked = 'Secret Phrase Copy Button Clicked',

  // Password
  NewPasswordLinkClicked = 'New Password Link Clicked',

  // Withdraw
  WithdrawOrderStarted = 'Withdraw Order Started',
  WithdrawFeeQuoteExpired = 'Withdraw Fee Quote Expired',
  WithdrawSubmittedWithUnexpiredFeeQuote = 'Withdraw Submitted With Unexpired Fee Quote',
  WithdrawFeeIncreased = 'Withdraw Fee Increased',
  WithdrawCanceledAfterFeeIncrease = 'Withdraw Canceled After Fee Increase',
  WithdrawSubmittedAfterFeeIncrease = 'Withdraw Submitted After Fee Increase',
}

export type TpEventProperties = {
  [TpAnalyticsEvent.KycVerifyClicked]: undefined;
  [TpAnalyticsEvent.CreateAccountClicked]: undefined;
  [TpAnalyticsEvent.LoginClicked]: undefined;
  [TpAnalyticsEvent.BuyClicked]: undefined;
  [TpAnalyticsEvent.SellClicked]: undefined;
  [TpAnalyticsEvent.ReceiveClicked]: undefined;
  [TpAnalyticsEvent.SendClicked]: undefined;
  [TpAnalyticsEvent.LNInvoiceUrlCopied]: undefined;
  [TpAnalyticsEvent.CarouselCardClicked]: {
    cardName: string;
  };
  [TpAnalyticsEvent.ReferAFriendClicked]: undefined;
  [TpAnalyticsEvent.TaskClick]: {
    requirement: QuestTaskRequirement;
  };
  [TpAnalyticsEvent.BadgeClicked]: {
    badgeLocked: boolean;
    id: string;
  };
  [TpAnalyticsEvent.QuestClicked]: {
    id: string;
  };
  [TpAnalyticsEvent.SocialReferralCardClicked]: {
    platform: string;
  };
  [TpAnalyticsEvent.ReferralLinkCopied]: undefined;
  [TpAnalyticsEvent.SocialCardClicked]: {
    socialMedia: 'Twitter' | 'Discord' | 'Telegram';
  };
  [TpAnalyticsEvent.SocialAuthLink]: {
    provider: string;
  };
  [TpAnalyticsEvent.SocialAuthUnlink]: {
    provider: string;
  };
  [TpAnalyticsEvent.WebAuthnEnabled]: undefined;
  [TpAnalyticsEvent.WebAuthnDisabled]: undefined;
  [TpAnalyticsEvent.RecoveryPhraseDownloadClicked]: undefined;
  [TpAnalyticsEvent.SecretPhraseCopyClicked]: undefined;
  [TpAnalyticsEvent.NewPasswordLinkClicked]: undefined;
  [TpAnalyticsEvent.WithdrawOrderStarted]: undefined;
  [TpAnalyticsEvent.WithdrawFeeQuoteExpired]: undefined;
  [TpAnalyticsEvent.WithdrawSubmittedWithUnexpiredFeeQuote]: undefined;
  [TpAnalyticsEvent.WithdrawFeeIncreased]: undefined;
  [TpAnalyticsEvent.WithdrawCanceledAfterFeeIncrease]: undefined;
  [TpAnalyticsEvent.WithdrawSubmittedAfterFeeIncrease]: undefined;
};
