import type { IncodeCaptureType } from '@noah-labs/core-services';
import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import type { CountryCode } from '@noah-labs/shared-schema-gql';
import { KycApprovalStatus } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AuthRouteData } from '../auth';
import { useUserInit } from '../user/data/useUserInit';
import { Complete } from './controllers/Complete';
import { KycReminder } from './controllers/Reminder';
import { Sumsub } from './controllers/Sumsub';
import { routes } from './routes';
import type { IdTypeOption, TpUserKycInput } from './types';

export type StKycRouter = {
  captureTypes?: IncodeCaptureType[];
  country?: CountryCode;
  countryName?: string;
  finishAction?: string;
  idType?: IdTypeOption;
  incodeApiBaseUrl?: string;
  liveness?: boolean;
  onboardingStatus?: string;
  sardineToken?: string;
  sessionExpiry?: number;
  userExists?: boolean;
  userKycInput?: Omit<TpUserKycInput, 'LastStep'>;
  verificationId?: string | null;
};

export function KycRouter(): React.ReactElement {
  const { data: userData } = useUserInit();

  /**
   * Should only redirect to our complete path if
   * - user has a KycVerification.ApprovalStatus
   * - AND the status is not 'pending'
   */
  let redirectToComplete = false;
  if (
    userData?.userProfile.KycVerification.ApprovalStatus &&
    userData.userProfile.KycVerification.ApprovalStatus !== KycApprovalStatus.Pending
  ) {
    redirectToComplete = true;
  }

  return (
    <Switch404>
      <Route
        exact
        path={routes.base.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={redirectToComplete && routes.complete.path}
            route={routes.base}
          >
            <Sumsub />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.complete.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.complete}>
            <Complete />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.reminder.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.reminder}>
            <KycReminder />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
