import React, { useEffect, useState } from 'react';
import { getErrorType, isAppSyncError, isGraphqlError } from '@noah-labs/core-services';
import { AlertLink } from '@noah-labs/core-web-ui/src/alerts/AlertLink';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { ErrorPage } from '@noah-labs/core-web-ui/src/utility/ErrorPage';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { NoahErrorType } from '@noah-labs/shared-schema-gql';
import { ApiUnknown } from '../../../components';
import { getErrorPageProps } from '../../../errors';
import type { TpUseError } from '../../../types';
import { routes as authRoutes } from '../../auth/routes';
import { routes as userRoutes } from '../../user/routes';
import { useWalletParams } from '../data';
import { LightningError } from '../errors';
import { routes } from '../routes';

export function useWalletError(error: unknown, showFullPageError?: boolean): TpUseError {
  const [apiErrorScene, setApiErrorScene] = useState<React.ReactElement | null>(null);
  const pushAlert = usePushAlert();

  const { AccountType, CurrencyCode } = useWalletParams();

  useEffect(() => {
    if (!error) {
      setApiErrorScene(null);
      return;
    }

    logger.error(error);

    if (showFullPageError) {
      const detailedError = error as Error;
      let errorProps = getErrorPageProps();

      if (detailedError.name === LightningError.ErrorName) {
        errorProps = {
          helpText: detailedError.message,
          message: LightningError.Title,
        };
      }

      setApiErrorScene(<ErrorPage {...errorProps} />);
      return;
    }

    if (isGraphqlError(error)) {
      error.response.errors.forEach((innerError) => {
        if (!isAppSyncError(innerError) || !innerError.message) {
          return;
        }
        const errorType = getErrorType(innerError.message);
        const errorProps = getErrorPageProps(errorType);
        switch (errorType) {
          case NoahErrorType.InvalidEmail: {
            setApiErrorScene(
              <ErrorPage
                {...errorProps}
                FooterSlot={
                  <PrimaryButton color="primaryBrand" href={authRoutes.signIn.path}>
                    Login
                  </PrimaryButton>
                }
              />
            );

            break;
          }
          case NoahErrorType.UnsupportedCurrency: {
            setApiErrorScene(<ErrorPage {...errorProps} />);
            break;
          }

          case NoahErrorType.TransactionDetailsMissing: {
            setApiErrorScene(
              <ErrorPage
                FooterSlot={
                  <PrimaryButton
                    color="primaryBrand"
                    href={generatePath(routes.account.base.path, {
                      accountType: AccountType,
                      currencyCode: CurrencyCode,
                    })}
                  >
                    Go to account
                  </PrimaryButton>
                }
                helpText="Please go to your account page and check for a pending transaction."
                message="The transaction details are missing"
              />
            );

            break;
          }

          case NoahErrorType.PolicyViolation: {
            pushAlert({
              key: 'policyViolationError',
              message: (
                <React.Fragment>
                  You have reached your limit on this transaction.{' '}
                  <AlertLink href={userRoutes.settings.accountLimits.path}>
                    Check your limits
                  </AlertLink>
                </React.Fragment>
              ),
              severity: 'error',
            });
            setApiErrorScene(null);

            break;
          }

          default:
            pushAlert(ApiUnknown);
            break;
        }
      });

      return;
    }

    /**
     * If the error was not handled above, set to a generic error,
     * no need to show the user potentially confusing errors
     */
    pushAlert(ApiUnknown);
    setApiErrorScene(null);
  }, [AccountType, CurrencyCode, error, pushAlert, showFullPageError]);

  return { ApiErrorScene: apiErrorScene };
}
