import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { LearnMoreButton } from '@noah-labs/core-web-ui/src/buttons/LearnMoreButton';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import { useToggleDialog } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { type TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { BulletedList } from '@noah-labs/core-web-ui/src/lists/BulletedList';
import { webLinks } from '@noah-labs/shared-constants';

export type PpBitcoinNetworkDialog = {
  initialOpen?: boolean;
};

export const BitcoinNetworkDialog = forwardRef<TpDialogToggle, PpBitcoinNetworkDialog>(
  ({ initialOpen }, ref): JSX.Element => {
    const { open, toggle } = useToggleDialog({ initialOpen, ref });
    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle>Choose the right network for your transfer</DialogTitle>
        <DialogContent>
          <DialogContentText paragraph>
            Cryptocurrency transactions occur over specific networks, each with its own fees and
            processing times. Make sure the chosen network matches the recipient’s to avoid issues.
          </DialogContentText>
          <DialogContentText variant="paragraphBodyLBold">Bitcoin networks</DialogContentText>
          <BulletedList>
            <li>
              <DialogContentText>
                <DialogContentText component="span" variant="paragraphBodyMBold">
                  On-chain:{' '}
                </DialogContentText>
                secure but pricier. Takes 15 mins to 1 hour.
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                <DialogContentText component="span" variant="paragraphBodyMBold">
                  Lightning:{' '}
                </DialogContentText>
                fast, low fees. Best for small transfers.
              </DialogContentText>
            </li>
          </BulletedList>
          <LearnMoreButton
            href={webLinks.blog.compareOnChainOffChain}
            target="_blank"
            variant="paragraphBodyM"
          >
            Learn more about on-chain and lightning transactions.
          </LearnMoreButton>
        </DialogContent>
        <DialogActions>
          <PrimaryButton color="primaryBrand" onClick={toggle}>
            Got it
          </PrimaryButton>
        </DialogActions>
      </LowDialog>
    );
  }
);

BitcoinNetworkDialog.displayName = BitcoinNetworkDialog.name;
