import { duration } from '@noah-labs/shared-tools/src/browser/duration';

export function getTransactionTimeText(delay: number): string {
  let delayText = 'Transfers to this address can take 10\xa0mins\xa0-\xa01hr.';

  if (delay < duration.minutes(10)) {
    delayText = 'Transfers to this address can take up to 10 mins.';
  }
  if (delay < duration.minutes(1)) {
    delayText = 'Transfers to this address should take a few seconds.';
  }

  return delayText;
}
