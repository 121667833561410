import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { ErrorPage } from '@noah-labs/core-web-ui/src/utility/ErrorPage';
import { AppHeaderData } from '../../../components';

export type PpFailedScene = {
  backTo: string;
  errorHelpText: string;
  errorMessage?: string;
};

export function FailedScene({
  backTo,
  errorHelpText,
  errorMessage,
}: PpFailedScene): React.ReactElement {
  return (
    <ErrorPage
      AppHeaderSlot={<AppHeaderData exitButton helpButton />}
      FooterSlot={<PrimaryButton href={backTo}>Close</PrimaryButton>}
      helpText={errorHelpText}
      message={errorMessage}
    />
  );
}
