import { useCallback } from 'react';
import { useDestinations } from '@noah-labs/core-services';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useConsentOnSubmit } from '../hooks/useConsentOnSubmit';
import { useUserError } from '../hooks/useUserError';
import type { StRegistration } from '../RestrictedRouter';
import type { TpConsentFormValues } from '../scenes';
import { MarketingUpdatesScene } from '../scenes/MarketingUpdates';

type PpMarketingUpdates = {
  nextAction: () => void;
  nextActionDirty: () => void;
} & TpStateMachine<StRegistration>;

export function MarketingUpdates({
  nextAction,
  nextActionDirty,
  state,
}: PpMarketingUpdates): React.ReactElement {
  const { data: { groupedDestinations } = {} } = useDestinations([
    webConfigBrowser.segment.writeKey,
    webConfigBrowser.analytics.cdnUrl,
  ]);

  const { error, handleConsentSubmit } = useConsentOnSubmit({
    groupedDestinations,
    nextAction,
    nextActionDirty,
  });

  const handleSubmit = useCallback(
    async (emailContact) => {
      const values: TpConsentFormValues = { ...state, EmailContact: emailContact };
      await handleConsentSubmit(values, state.isDirty);
    },
    [handleConsentSubmit, state]
  );

  const { ApiErrorScene } = useUserError(error);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return <MarketingUpdatesScene onSubmit={handleSubmit} />;
}
