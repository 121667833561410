import { useCallback, useEffect, useMemo } from 'react';
import { Link, Stack } from '@mui/material';
import type { TpNormalisedDestinations } from '@noah-labs/core-services';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { ContactOptions } from '@noah-labs/core-web-ui/src/consent-manager/ContactOptions';
import { CookieOptions } from '@noah-labs/core-web-ui/src/consent-manager/CookieOptions';
import { TermsOptions } from '@noah-labs/core-web-ui/src/consent-manager/TermsOptions';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { FooterContentText } from '@noah-labs/core-web-ui/src/layout/FooterContentText';
import { ListSection } from '@noah-labs/core-web-ui/src/lists/ListSection';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import type { TpSkeletonText } from '@noah-labs/core-web-ui/src/types';
import { TextOrSkeleton } from '@noah-labs/core-web-ui/src/typography/TextOrSkeleton';
import { webLinks } from '@noah-labs/shared-constants';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { AppHeaderData } from '../../../components';
import type { TpUserSettingsConsentQuery } from '../data/useUserInit';
import { routes } from '../routes';

export type TpConsentFormValues = {
  Cookies: {
    advertising: boolean;
    functional: boolean;
    marketingAndAnalytics: boolean;
  };
  EmailContact: boolean;
  Terms: boolean;
};
export type PpConsentScene = {
  destinations: TpNormalisedDestinations | undefined;
  email: TpSkeletonText;
  logoutTo?: string;
  onSubmit: (values: TpConsentFormValues, isDirty: boolean) => Promise<void> | void;
  userSettingsConsent: TpUserSettingsConsentQuery | undefined | null;
  variant: 'settings' | 'registration';
};
export function ConsentScene({
  destinations,
  email,
  logoutTo,
  onSubmit,
  userSettingsConsent,
  variant,
}: PpConsentScene): React.ReactElement {
  /**
   * needs useMemo else cause the form to reset on each render
   */
  const defaultValues = useMemo<TpConsentFormValues>(
    () => ({
      /**
       * functional cookies are required and are exempt from GDPR consent requirements.
       * advertising, analytics and marketing cookies need explicit consent.
       */
      Cookies: {
        advertising: userSettingsConsent?.Cookies?.advertising ?? false,
        functional: true,
        marketingAndAnalytics: userSettingsConsent?.Cookies?.marketingAndAnalytics ?? false,
      },
      EmailContact: userSettingsConsent?.EmailContact ?? false,
      Terms: true,
    }),
    [
      userSettingsConsent?.Cookies?.advertising,
      userSettingsConsent?.Cookies?.marketingAndAnalytics,
      userSettingsConsent?.EmailContact,
    ]
  );
  const methods = useForm<TpConsentFormValues>({ defaultValues });

  let buttonLabel = 'Continue';
  let buttonDisabled = false;
  let backButton = false;
  let showContactField = false;
  let cookiesTitle;

  switch (variant) {
    case 'settings':
      buttonDisabled = !methods.formState.isDirty;
      backButton = true;
      buttonLabel = 'Save';
      showContactField = true;
      cookiesTitle = 'Cookies';
      break;
    default:
      break;
  }

  useEffect(() => {
    /**
     * since the user's prefs are loaded from an api, this can come after the form
     * already has default values, hence we need to reset it with the new ones
     * however, we don't want to reset whilst the form is submitting else the button will go to disabled state
     */
    if (methods.formState.isSubmitting) {
      return;
    }
    methods.reset(defaultValues);
  }, [defaultValues, methods]);

  const handleSubmit = useCallback(
    async (values: TpConsentFormValues) => {
      await onSubmit(values, methods.formState.isDirty);
    },
    [methods.formState.isDirty, onSubmit]
  );

  return (
    <AppContainer
      footerHasContent
      AppFooterSlot={
        <Stack spacing={3}>
          {logoutTo && (
            <FooterContentText>
              Not <TextOrSkeleton>{email}</TextOrSkeleton>?{' '}
              <Link href={logoutTo}>Log&nbsp;out and start over.</Link>
            </FooterContentText>
          )}
          <PrimaryButton
            disabled={buttonDisabled}
            form={routes.consent.id}
            loading={methods.formState.isSubmitting}
            type="submit"
          >
            {buttonLabel}
          </PrimaryButton>
        </Stack>
      }
      AppHeaderSlot={<AppHeaderData helpButton backButton={backButton} />}
      dataQa={routes.consent.id}
    >
      <Helmet>
        <title>{routes.consent.title}</title>
      </Helmet>
      <SceneHeader>
        <SceneTitleLarge>{routes.consent.title}</SceneTitleLarge>
        <SceneParagraph>
          Please select your privacy preferences below. Your preferences can be changed at any time.
        </SceneParagraph>
      </SceneHeader>
      <SceneMain>
        <Stack spacing={4}>
          <FormProvider {...methods}>
            <form id={routes.consent.id} onSubmit={methods.handleSubmit(handleSubmit)}>
              <Stack spacing={2}>
                {showContactField && (
                  <ListSection title="Contact">
                    <ContactOptions />
                  </ListSection>
                )}
                <ListSection title={cookiesTitle}>
                  {destinations && <CookieOptions {...destinations} />}
                </ListSection>
                <ListSection>
                  <TermsOptions
                    privacyPolicyPath={webLinks.privacyPolicy.path}
                    termsAndConditionsPath={webLinks.termsAndConditions.path}
                  />
                </ListSection>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </SceneMain>
    </AppContainer>
  );
}
