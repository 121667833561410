import { useEffect } from 'react';
import { usePrevious } from '@noah-labs/core-web-ui/src/hooks/usePrevious';
import { isBrowser } from '@noah-labs/shared-tools/src/browser/utils';
import { useLocation } from 'react-router-dom';
import { useAnalyticsContext } from './useAnalyticsContext';

export function usePageView(): void {
  const ajs = useAnalyticsContext();
  const { pathname } = useLocation();
  const href = isBrowser ? window.document.referrer : undefined;
  const referrer = usePrevious(href);

  /**
   * Always run the effect - we need page view to be called to ensure that intercom loads as expected
   */
  useEffect(() => {
    /**
     * This is a bit hacky but this useEffect is always trigger before the <Helmet> component renders on the new page.
     * Hence, we just use a timeout to put the analytics call on to the next tick, when the page title has been updated.
     */
    const t = setTimeout(() => {
      void ajs.page({ referrer });
    });
    return (): void => clearTimeout(t);
  }, [ajs, pathname, referrer]);
}
