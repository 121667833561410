import React from 'react';
import { Divider, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { TpLimitAndUsage } from '@noah-labs/core-services';
import { ElevatedCard } from '@noah-labs/core-web-ui/src/cards/ElevatedCard';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import type { CountLimit } from '@noah-labs/shared-schema-gql';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import { AccountLimit } from '../components';

export type PpAccountLimitsScene = {
  accountDepositDay: TpLimitAndUsage | undefined;
  accountWithdrawDay: TpLimitAndUsage | undefined;
  btcWithdrawTxsDay: CountLimit | null | undefined;
  ethWithdrawTxsDay: CountLimit | null | undefined;
  lnReceiveDay: TpLimitAndUsage | undefined;
  lnReceiveSingle: TpLimitAndUsage | undefined;
  lnSendDay: TpLimitAndUsage | undefined;
  lnSendSingle: TpLimitAndUsage | undefined;
};

const StyledElevatedCard = styled(ElevatedCard)(({ theme }) => ({
  padding: theme.spacing(2, 2),
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1.5, 0),
}));

export function AccountLimitsScene({
  accountDepositDay,
  accountWithdrawDay,
  btcWithdrawTxsDay,
  ethWithdrawTxsDay,
  lnReceiveDay,
  lnReceiveSingle,
  lnSendDay,
  lnSendSingle,
}: PpAccountLimitsScene): React.ReactElement {
  const ethMaxTxsDay = ethWithdrawTxsDay?.Limit.toString();
  const btcMaxTxsDay = btcWithdrawTxsDay?.Limit.toString();

  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeaderData backButton helpButton>
          <AppHeaderTitle>Account Limits</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa="account-limits"
    >
      <Helmet>
        <title>Account Limits</title>
      </Helmet>
      <SceneMain dense>
        <Stack spacing={1.5}>
          <StyledElevatedCard>
            <AccountLimit
              limit={accountDepositDay?.limit}
              title="Daily deposits"
              unlimited={Boolean(accountDepositDay && !accountDepositDay.limit)}
              usage={accountDepositDay?.usage}
            />
          </StyledElevatedCard>

          <StyledElevatedCard>
            <AccountLimit
              fiatCurrency={accountWithdrawDay?.fiatCurrency}
              limit={accountWithdrawDay?.limit}
              title="Daily withdrawals"
              unlimited={Boolean(accountWithdrawDay && !accountWithdrawDay.limit)}
              usage={accountWithdrawDay?.usage}
            />
          </StyledElevatedCard>

          <StyledElevatedCard>
            <AccountLimit
              secondaryContent={ethMaxTxsDay ? `Max ${ethMaxTxsDay} transactions` : undefined}
              title="Sending stablecoins (daily)"
              // returns unlimited in case it's null
              unlimited={typeof ethWithdrawTxsDay === 'object' && !ethWithdrawTxsDay}
            />
            <StyledDivider />
            <AccountLimit
              secondaryContent={btcMaxTxsDay ? `Max ${btcMaxTxsDay} transactions` : undefined}
              title="Sending bitcoin (daily)"
              // returns unlimited in case it's null
              unlimited={typeof btcWithdrawTxsDay === 'object' && !btcWithdrawTxsDay}
            />
          </StyledElevatedCard>

          <StyledElevatedCard>
            <AccountLimit
              fiatCurrency={lnSendSingle?.fiatCurrency}
              limit={lnSendSingle?.limit}
              title="Sending Lightning payments (per transaction)"
              unlimited={Boolean(lnSendSingle && !lnSendSingle.limit)}
            />
            <StyledDivider />
            <AccountLimit
              fiatCurrency={lnSendDay?.fiatCurrency}
              limit={lnSendDay?.limit}
              title="Sending Lightning payments (daily)"
              unlimited={Boolean(lnSendDay && !lnSendDay.limit)}
              usage={lnSendDay?.usage}
            />
          </StyledElevatedCard>

          <StyledElevatedCard>
            <AccountLimit
              limit={lnReceiveSingle?.limit}
              title="Receiving Lightning payments (per transaction)"
              unlimited={Boolean(lnReceiveSingle && !lnReceiveSingle.limit)}
            />
            <StyledDivider />
            <AccountLimit
              limit={lnReceiveDay?.limit}
              title="Receiving Lightning payments (daily)"
              unlimited={Boolean(lnReceiveDay && !lnReceiveDay.limit)}
              usage={lnReceiveDay?.usage}
            />
          </StyledElevatedCard>
        </Stack>
      </SceneMain>
    </AppContainer>
  );
}
