//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgChevronDown } from '../icons/ChevronDown';

export function ChevronDownIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgChevronDown} data-qa="ChevronDownIcon">
      <svg />
    </SvgIcon>
  );
}
