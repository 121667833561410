import { useCallback, useMemo } from 'react';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { Feature, FiatPaymentMethodTokenProvider } from '@noah-labs/shared-schema-gql';
import type { FrameCardTokenizedEvent } from 'frames-react';
import { useQueryClient } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useCountriesForFeature } from '../../../hooks';
import { getUserFullDisplayName } from '../../../utils';
import { useFiatPaymentMethodProviderSaveMutation } from '../../wallet/data';
import { EnterPaymentInfoScene } from '../../wallet/scenes';
import { useCountryFromCode } from '../data/useCountryFromCode';
import { useUserInit } from '../data/useUserInit';
import { BillingDefaults } from '../hooks/useBillingDefaults';
import { useUserError } from '../hooks/useUserError';
import { routes } from '../routes';

const cardsQueryId = ['FiatPaymentMethods'];

export function NewPaymentMethod(): React.ReactElement {
  const { data: userData } = useUserInit();
  const { data: countriesData } = useCountriesForFeature(
    userData?.userProfile.HomeAddress?.CountryCode,
    [[Feature.CkoBuy, Feature.CkoSell]]
  );
  const country = useCountryFromCode(userData?.userProfile.HomeAddress?.CountryCode);
  const match = useRouteMatch(routes.settings.newPaymentMethod.path);
  const fullName = getUserFullDisplayName(userData?.userProfile.PersonalName);
  const queryClient = useQueryClient();
  const pushAlert = usePushAlert();
  const history = useHistory();

  const {
    error: saveCardError,
    isLoading: saveCardLoading,
    mutateAsync: saveCard,
  } = useFiatPaymentMethodProviderSaveMutation();

  const onCardTokenized = useCallback(
    async (cardTokenized: FrameCardTokenizedEvent) => {
      try {
        await saveCard({
          Input: {
            Provider: FiatPaymentMethodTokenProvider.Checkout,
            SaveForFutureUse: true,
            Token: cardTokenized.token,
          },
        });

        await queryClient.invalidateQueries(cardsQueryId);

        pushAlert({
          key: 'saveCardSuccessful',
          message: 'Payment method added',
          severity: 'success',
        });

        history.push(routes.settings.paymentMethods.path);
      } catch (err) {
        // handled in useUserError
      }
    },
    [history, pushAlert, queryClient, saveCard]
  );

  const defaultAddress = useMemo(() => {
    if (!userData?.userProfile.HomeAddress || !country) {
      return undefined;
    }

    return BillingDefaults(userData.userProfile.HomeAddress, country);
  }, [country, userData?.userProfile.HomeAddress]);

  const { ApiErrorScene } = useUserError(saveCardError);

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <EnterPaymentInfoScene
      countries={countriesData?.countries}
      ctaLabel="Save card"
      defaultAddress={defaultAddress}
      fullName={fullName}
      isLoading={saveCardLoading}
      isVisible={!!match?.isExact}
      pageTitle={routes.settings.newPaymentMethod.title}
      saveToggle={false}
      visiblePath={routes.settings.newPaymentMethod.path}
      onCardTokenized={onCardTokenized}
    />
  );
}
