import type { GridProps } from '@mui/material';
import { Grid } from '@mui/material';
import type { PpWC } from '../types';

export type PpFormItem = {
  fullWidth?: boolean;
  sx?: GridProps['sx'];
};

export function FormItem({ children, fullWidth, sx }: PpWC<PpFormItem>): React.ReactElement {
  return (
    <Grid item sm={fullWidth ? undefined : 6} sx={sx} xs={12}>
      {children}
    </Grid>
  );
}
