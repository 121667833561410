import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { Route, useRouteMatch } from 'react-router-dom';
import { AuthRouteData } from '../../../auth';
import { routes } from '../../routes';
import { Account } from './Account';
import { Transaction } from './Transaction';

export function AccountRouter(): React.ReactElement {
  const match = useRouteMatch<{ id: string }>(routes.account.transactions.path);

  return (
    <Switch404>
      <Route
        path={routes.account.base.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.account.base}>
            <Account />
            <Transaction id={match?.params.id} />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
