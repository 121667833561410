import { type CardProps, type ListItemButtonProps } from '@mui/material';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';
import { CurrencyAvatar } from '../images';
import {
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemEndContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
} from '../lists/ListItem';

export type PpAccountCard = CardProps & {
  PrimaryAmountSlot: React.ReactNode;
  SecondaryAmountSlot: React.ReactNode;
  buttonProps?: ListItemButtonProps;
  cryptoCurrency: TpCryptoCurrencyUI;
  defaultAccount?: boolean;
  href: string;
};

export function AccountCard({
  buttonProps,
  cryptoCurrency,
  defaultAccount = false,
  href,
  PrimaryAmountSlot,
  SecondaryAmountSlot,
  ...props
}: PpAccountCard): React.ReactElement {
  return (
    <ListItem>
      <ListItemButton href={href} {...buttonProps}>
        <ListItemCard
          data-qa={defaultAccount ? 'default-account' : `account-${cryptoCurrency.code}`}
          {...props}
        >
          <ListItemContent>
            <ListItemIcon>
              <CurrencyAvatar Icon={cryptoCurrency.Icon} />
            </ListItemIcon>

            <ListItemStartContent>
              <ListItemPrimaryText>{cryptoCurrency.label}</ListItemPrimaryText>
            </ListItemStartContent>

            <ListItemEndContent>
              <ListItemPrimaryText>{PrimaryAmountSlot}</ListItemPrimaryText>
              <ListItemSecondaryText>{SecondaryAmountSlot}</ListItemSecondaryText>
            </ListItemEndContent>
          </ListItemContent>
        </ListItemCard>
      </ListItemButton>
    </ListItem>
  );
}
