import { useState } from 'react';
import type { SafeWrapper } from '@noah-labs/core-services';
import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { Route } from 'react-router-dom';
import { AuthRouteData } from '../auth';
import { routes as walletRoutes } from '../wallet/routes';
import { CreatePinRecover } from './controllers';
import { EnterSecretPhrase } from './controllers/EnterSecretPhrase';
import { routes } from './routes';
import { PinSuccessScene } from './scenes/PinSuccess';

/**
 * Users attempt to recover their wallet/pin with their secret phrase.
 */
export function RecoverPinWithSecretPhraseRouter(): React.ReactElement {
  const [phrase, setPhrase] = useState<SafeWrapper<string>>();

  return (
    <Switch404>
      <Route
        path={routes.forgotPin.enterPhrase.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.forgotPin.enterPhrase}>
            <EnterSecretPhrase setPhrase={setPhrase} successTo={routes.forgotPin.createPin.path} />
          </AuthRouteData>
        )}
      />

      <Route
        path={routes.forgotPin.createPin.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.forgotPin.createPin}>
            <CreatePinRecover phrase={phrase} successTo={routes.forgotPin.success.path} />
          </AuthRouteData>
        )}
      />

      <Route
        path={routes.forgotPin.success.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.forgotPin.success}>
            <PinSuccessScene
              pageTitle="PIN Updated"
              sceneDescription="You can now use your new PIN to verify transactions for added security."
              sceneTitle="PIN updated successfully"
              successTo={walletRoutes.base.path}
            />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
