import React from 'react';
import { css } from '@emotion/react';
import type { TypographyOwnProps } from '@mui/material';
import { Divider, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { InfoTooltip } from '@noah-labs/core-web-ui/src/buttons/InfoTooltip';
import { ElevatedCard } from '@noah-labs/core-web-ui/src/cards/ElevatedCard';
import { StructuredContent } from '@noah-labs/core-web-ui/src/lists/atoms/StructuredContent';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';

export type PpTransactionSummaryNew = {
  ActionAmount: React.ReactNode;
  CryptoPriceSlot: React.ReactElement;
  FeeAmountSlot: React.ReactNode;
  FinalAmount: React.ReactNode;
  actionAmountLabel: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  feeLabel: string;
  feePromotion?: boolean;
  finalAmountLabel: string;
  onActionClick?: () => void;
  onFeeInfoClick: () => void;
  onProcessingTimeClick?: () => void;
  processingTime?: string;
  sellReference?: string;
};

const mainTextProps: TypographyOwnProps = {
  color: 'grayscale.500',
  variant: 'paragraphBodyS',
};

const endTextProps: TypographyOwnProps = {
  color: 'primary.main',
  variant: 'paragraphBodySBold',
};

export function TransactionSummaryCard({
  ActionAmount,
  actionAmountLabel,
  cryptoCurrency,
  CryptoPriceSlot,
  FeeAmountSlot,
  feeLabel,
  feePromotion,
  FinalAmount,
  finalAmountLabel,
  onActionClick,
  onFeeInfoClick,
  onProcessingTimeClick,
  processingTime,
  sellReference,
}: PpTransactionSummaryNew): React.ReactElement {
  const theme = useTheme();

  const styles = {
    container: css`
      padding: ${theme.spacing(2, 2)};
    `,
    item: css`
      min-height: ${theme.spacing(4.5)};
    `,
  };

  return (
    <ElevatedCard css={styles.container}>
      <Stack spacing={1.5}>
        <StructuredContent
          css={styles.item}
          dataQa="price"
          endText={{
            primary: CryptoPriceSlot,
            primaryTypographyProps: endTextProps,
          }}
          mainText={{
            primary: `${cryptoCurrency.code} Price`,
            primaryTypographyProps: mainTextProps,
          }}
        />
        <Divider />
        <StructuredContent
          css={styles.item}
          dataQa="fee"
          endText={{
            primary: FeeAmountSlot,
            primaryTypographyProps: {
              ...endTextProps,
              ...(feePromotion && { color: 'brand.main' }),
            },
          }}
          mainText={{
            disableTypography: true,
            primary: (
              <InfoTooltip {...mainTextProps} onClick={onFeeInfoClick}>
                {feeLabel}
              </InfoTooltip>
            ),
          }}
        />
        <Divider />
        <StructuredContent
          css={styles.item}
          dataQa={actionAmountLabel.toLowerCase()}
          endText={{
            primary: ActionAmount,
            primaryTypographyProps: endTextProps,
          }}
          mainText={{
            disableTypography: !actionAmountLabel,
            primary: onActionClick ? (
              <InfoTooltip {...mainTextProps} onClick={onActionClick}>
                {actionAmountLabel}
              </InfoTooltip>
            ) : (
              actionAmountLabel
            ),
            primaryTypographyProps: mainTextProps,
          }}
        />
        <Divider />
        {processingTime && onProcessingTimeClick && (
          <React.Fragment>
            <StructuredContent
              css={styles.item}
              dataQa="processingTime"
              endText={{
                primary: processingTime,
                primaryTypographyProps: endTextProps,
              }}
              mainText={{
                disableTypography: true,
                primary: (
                  <InfoTooltip {...mainTextProps} onClick={onProcessingTimeClick}>
                    Processing Time
                  </InfoTooltip>
                ),
              }}
            />
            <Divider />
          </React.Fragment>
        )}
        {sellReference && (
          <React.Fragment>
            <StructuredContent
              css={styles.item}
              dataQa="reference"
              endText={{
                primary: sellReference,
                primaryTypographyProps: {
                  noWrap: false,
                  paddingLeft: theme.spacing(3),
                  variant: 'paragraphBodySBold',
                },
              }}
              mainText={{
                primary: 'Reference',
                primaryTypographyProps: mainTextProps,
              }}
            />
            <Divider />
          </React.Fragment>
        )}
        <StructuredContent
          css={styles.item}
          dataQa="total"
          endText={{
            primary: FinalAmount,
            primaryTypographyProps: endTextProps,
          }}
          mainText={{
            primary: finalAmountLabel,
            primaryTypographyProps: endTextProps,
          }}
        />
      </Stack>
    </ElevatedCard>
  );
}
