import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { useToggleDialog } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';

type PpReceiveInitialDialog = {
  initialOpen: boolean;
};

export const ReceiveInitialDialog = forwardRef<TpDialogToggle, PpReceiveInitialDialog>(
  ({ initialOpen }, ref): JSX.Element => {
    const { open, toggle } = useToggleDialog({ initialOpen, ref });

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle>Receiving bitcoin is easy!</DialogTitle>
        <DialogContent>
          <DialogContentText paragraph>Here are some things you should know:</DialogContentText>
          <DialogContentText color="text.dark" component="h3" variant="paragraphBodyMBold">
            Your lightning address
          </DialogContentText>
          <DialogContentText paragraph>
            Receive bitcoin instantly and with 0 fees. Use this address if someone has a lightning-
            compatible wallet and wants to send you smaller amounts (under $200)!
          </DialogContentText>
          <DialogContentText color="text.dark" component="h3" variant="paragraphBodyMBold">
            Your on-chain address
          </DialogContentText>
          <DialogContentText paragraph>
            Compatible with all bitcoin wallets. On-chain payments can take up to 1 hour & incur
            higher fees but are more secure.
          </DialogContentText>
          <DialogContentText color="text.dark" component="h3" variant="paragraphBodyMBold">
            More options
          </DialogContentText>
          <DialogContentText paragraph>
            You can also find more ways to receive bitcoin under the ‘more options’ button.
          </DialogContentText>
          <DialogContentText>
            If you are unfamiliar with any of the terms on this screen, click the ‘i’ button next to
            it and we’ll explain what it means.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton color="primaryBrand" onClick={toggle}>
            Got it
          </PrimaryButton>
        </DialogActions>
      </LowDialog>
    );
  }
);
ReceiveInitialDialog.displayName = ReceiveInitialDialog.name;
