import { Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import type { PpAppHeader } from '@noah-labs/core-web-ui/src/layout/AppHeader';
import { AppHeader } from '@noah-labs/core-web-ui/src/layout/AppHeader';
import type { PpWOC } from '@noah-labs/core-web-ui/src/types';
import {
  AvatarIconButtonData,
  ExitIconButtonData,
  HelpIconButtonData,
  ScanIconButtonData,
} from '../buttons';
import { NotificationCenterButtonData } from '../buttons/NotificationCenterButtonData';

export type PPAppHeaderData = Omit<PpAppHeader, 'endIconsSlot'> & {
  avatarButton?: boolean;
  exitButton?: boolean;
  helpButton?: boolean;
  notificationCenterButton?: boolean;
  scanButton?: boolean;
} & PpWOC;
export function AppHeaderData({
  avatarButton = false,
  children,
  exitButton = false,
  fullWidth,
  helpButton = false,
  notificationCenterButton = false,
  scanButton = false,
  ...rest
}: PPAppHeaderData): React.ReactElement {
  // helpButton needs edge=end, unless there is an exitButton, scanButton or avatarButton
  // exitButton needs edge=end, unless there is an scanButtonm or avatarButton
  // scanButton needs edge=end, unless there is an avatarButton

  const theme = useTheme();

  const avatarMarginLeft =
    helpButton || notificationCenterButton || exitButton || scanButton
      ? theme.spacing(0.625)
      : undefined;

  return (
    <AppHeader
      fullWidth={fullWidth}
      {...rest}
      endIconsSlot={
        <Fragment>
          {helpButton && (
            <HelpIconButtonData
              edge={
                exitButton || scanButton || avatarButton || notificationCenterButton
                  ? undefined
                  : 'end'
              }
            />
          )}
          {notificationCenterButton && (
            <NotificationCenterButtonData
              edge={exitButton || scanButton || avatarButton ? undefined : 'end'}
            />
          )}
          {exitButton && (
            <ExitIconButtonData edge={scanButton || avatarButton ? undefined : 'end'} />
          )}
          {scanButton && <ScanIconButtonData edge={avatarButton ? undefined : 'end'} />}
          {avatarButton && (
            <AvatarIconButtonData showLnAddress={fullWidth} sx={{ ml: avatarMarginLeft, p: 0 }} />
          )}
        </Fragment>
      }
    >
      {children}
    </AppHeader>
  );
}
