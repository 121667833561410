import type {
  CurrencyCode,
  TransactionDirection,
  TransactionStatus,
  TransferSourceType,
} from '@noah-labs/shared-schema-gql';

export enum NotificationType {
  FAILED_BUY = 'failed-buy',
  FAILED_EXTERNAL_DEPOSIT = 'failed-external-deposit',
  FAILED_EXTERNAL_WITHDRAW = 'failed-external-withdraw',
  FAILED_INTERNAL_DEPOSIT = 'failed-internal-deposit',
  FAILED_INTERNAL_WITHDRAW = 'failed-internal-withdraw',
  FAILED_SELL = 'failed-sell',
  NEW_REFERRAL = 'new-referral',
  QUEST_COMPLETED = 'quest-completed',
  SUCCESSFUL_BUY = 'successful-buy',
  SUCCESSFUL_EXTERNAL_DEPOSIT = 'successful-external-deposit',
  SUCCESSFUL_EXTERNAL_WITHDRAW = 'successful-external-withdraw',
  SUCCESSFUL_INTERNAL_DEPOSIT = 'successful-internal-deposit',
  SUCCESSFUL_INTERNAL_WITHDRAW = 'successful-internal-withdraw',
  SUCCESSFUL_SELL = 'successful-sell',
  USER_SIGNED_UP = 'user-signed-up',
}

export type NotificationPayload = {
  type?: NotificationType;
};

export type TransactionNotification = NotificationPayload & {
  currency: CurrencyCode;
  direction: TransactionDirection;
  sourceType?: TransferSourceType;
  status: TransactionStatus;
};

export type QuestCompletedNotification = NotificationPayload & {
  badgeId: string;
};
