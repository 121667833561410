import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { IconButton, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { PpWC } from '@noah-labs/core-web-ui/src/types';
import { CloseDialogIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CloseDialogIcon';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';
import type { Network } from '@noah-labs/shared-schema-gql';
import { getReceiveCryptoData } from '../../utils/getReceiveCryptoData';

type PpQRCodeArea = {
  cryptoCurrency: TpCryptoCurrencyUI;
  dataQa?: string;
  network?: Network;
  onClose?: () => void;
} & PpWC;

export function QRCodeArea({
  children,
  cryptoCurrency,
  dataQa,
  network,
  onClose,
}: PpQRCodeArea): ReactElement {
  const theme = useTheme();

  const { borderCss, cardCss } = getReceiveCryptoData(cryptoCurrency.code, network);

  const styles = {
    border: css`
      padding: ${theme.spacing(0.25, 0.25)};
      border-radius: ${theme.borderRadius.md};
    `,
    card: css`
      border-radius: ${theme.borderRadius.md};
      color: ${theme.palette.grayscale[100]};
      position: relative;
    `,
    closeBtn: css`
      color: ${theme.palette.grayscale[100]};
      background-color: 'transparent';
      position: absolute;
      top: ${theme.spacing(0)};
      right: ${theme.spacing(0)};
    `,
  };

  const cardStyles = [styles.card, cardCss];
  const borderStyles = [styles.border, borderCss];

  return (
    <div css={borderStyles} data-qa={dataQa}>
      <Stack css={cardStyles}>
        {onClose && (
          <IconButton css={styles.closeBtn} data-qa="exit-button" onClick={onClose}>
            <CloseDialogIcon />
          </IconButton>
        )}
        {children}
      </Stack>
    </div>
  );
}
