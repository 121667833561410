import { useCallback } from 'react';
import { SafeWrapper } from '@noah-labs/core-services';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { useHistory } from 'react-router-dom';
import type { TpPinForm } from '../../../utils';
import { useSigning } from '../../signing/context';
import { CreatePinScene } from '../scenes';

export type PpCreatePin = {
  backTo?: string;
  canSkip?: boolean;
  setPhrase: (phrase: SafeWrapper<string>) => void;
  successTo: string;
};

export function CreatePin({
  backTo,
  canSkip,
  setPhrase,
  successTo,
}: PpCreatePin): React.ReactElement {
  const history = useHistory();
  const { createNewWallet } = useSigning();

  /**
   * Generates a new wallet and persists the phrase
   */
  const onSubmit = useCallback(
    async ({ pin }: TpPinForm): Promise<void> => {
      try {
        const mnemonic = await createNewWallet(new SafeWrapper(pin));
        if (!mnemonic) {
          return;
        }

        setPhrase(mnemonic);
        history.push(successTo);
      } catch (error) {
        logger.error(error);
      }
    },
    [createNewWallet, history, setPhrase, successTo]
  );

  return (
    <CreatePinScene backTo={backTo} canSkip={canSkip} exitTo={successTo} onSubmit={onSubmit} />
  );
}
