import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { moonpayApi } from '@noah-labs/core-services';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import { AppLogo } from '@noah-labs/core-web-ui/src/images';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeader } from '@noah-labs/core-web-ui/src/layout/AppHeader';
import { CryptoAmount } from '@noah-labs/core-web-ui/src/numbers/CryptoAmount';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-currencies';
import { Network, NoahErrorType, SignMoonpayUrlInputAction } from '@noah-labs/shared-schema-gql';
import { isBrowser } from '@noah-labs/shared-tools/src/browser/utils';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../../components';
import { createAppSyncError } from '../../../../errors';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { networkForEnv } from '../../../../utils/networks';
import { useUserInit } from '../../../user/data/useUserInit';
import { MoonpaySell } from '../../components';
import {
  findDepositAddress,
  selectFromFilterWithFallback,
  useAccountCreateMutation,
  useAccountsQueryPoll,
  useMoonpaySignatureQuery,
  useWalletParams,
} from '../../data';
import { useWalletError } from '../../hooks';
import { useCryptoUnit } from '../../hooks/useCryptoUnit';
import { routes } from '../../routes';

export function SellWithMoonpay(): React.ReactElement {
  const theme = useTheme();

  const { AccountType, CurrencyCode, params } = useWalletParams();
  const userInit = useUserInit();
  const email = userInit.data?.userProfile.Email || '';

  const cryptoUnit = useCryptoUnit();
  const { fiatCurrency } = useUserFiatCurrency();
  const network = networkForEnv(Network.Bitcoin);

  const origin = isBrowser ? window.location.origin : '';

  const { data: account } = useAccountsQueryPoll(undefined, {
    select: (data) => selectFromFilterWithFallback({ AccountType, CurrencyCode, data }),
  });

  const {
    data: accountCreateData,
    error: accountCreateErr,
    isIdle: accountCreateIdle,
    mutate: accountCreate,
  } = useAccountCreateMutation();

  const address = findDepositAddress(accountCreateData?.accountCreate);

  useEffect(() => {
    // If accountCreate has run or there is already an address, don't do anything
    if (!accountCreateIdle || address) {
      return;
    }
    // Otherwise, create a new account
    accountCreate({
      Input: {
        AccountType,
        CurrencyCode,
        DepositAddressRequest: {
          Create: true,
          Network: network,
        },
      },
    });
  }, [AccountType, CurrencyCode, accountCreate, accountCreateIdle, address, network]);

  const redirectUrl = generatePath(routes.sell.moonpay.confirm.path, params);
  const moonPayUrlParams = {
    AccountType,
    Action: SignMoonpayUrlInputAction.Sell,
    ApiKey: moonpayApi.getPublishableKey(),
    ColorCode: theme.palette.primary.main,
    CryptoCurrencyCode: CurrencyCode,
    Domain: btoa(origin), // WAF does not like the url param
    FiatCurrencyCode: fiatCurrency.code,
    Network: network,
    Url: redirectUrl,
    WalletAddress: address || '',
  };

  const { data: signatureData } = useMoonpaySignatureQuery(
    {
      Input: moonPayUrlParams,
    },
    {
      enabled: Boolean(address && email),
    }
  );

  const invalidEmailError = !email ? createAppSyncError(NoahErrorType.InvalidEmail) : null;
  const { ApiErrorScene } = useWalletError(accountCreateErr || invalidEmailError);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const pageTitle = routes.sell.moonpay.base.title;

  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeader>
          <AppLogo />
        </AppHeader>
      }
      dataQa="sell"
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SceneMain>
        <LowDialog
          open
          PaperProps={{
            // Has a max-height property which allows space for the app header
            sx: { height: 1 },
          }}
        >
          <AppHeaderData disableFade exitButton>
            {pageTitle}
          </AppHeaderData>
          <Typography paragraph textAlign="center" variant="paragraphBodyS">
            Your {cryptoCurrency.label} balance is{' '}
            <CryptoAmount
              amount={account?.Balance?.Available}
              currency={cryptoCurrency}
              currencyUnit={cryptoUnit}
            />
          </Typography>
          <MoonpaySell
            queryParams={signatureData?.signMoonpayUrl.Url}
            redirectURL={`${origin}${redirectUrl}`}
          />
        </LowDialog>
      </SceneMain>
    </AppContainer>
  );
}
