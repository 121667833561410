import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { AppLogo } from '@noah-labs/core-web-ui/src/images';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeader } from '@noah-labs/core-web-ui/src/layout/AppHeader';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import type { ErrorOption, Resolver, UseFormSetError } from 'react-hook-form';
import * as yup from 'yup';
import { PasswordFormItem } from '../../auth';
import { useAuthError } from '../../auth/hooks/useAuthError';

export type TpForgottenPasswordForm = {
  password: string;
  root?: { serverError: void };
};

export type TpForgottenPassword = {
  root?: { serverError: void };
};

const forgottenPasswordFormId = 'forgotten-password-form';

const defaultValues: TpForgottenPasswordForm = { password: '' };

const schema = yup.object({
  password: yup
    .string()
    .required('Password is a required field.')
    .min(8, 'Password must be at least 8 characters.'),
});

export type PpForgottenPasswordNewPasswordScene = {
  error: ErrorOption | undefined;
  helpButton?: React.ReactNode;
  loading: boolean;
  onUpdate: (
    values: TpForgottenPasswordForm,
    setError: UseFormSetError<TpForgottenPasswordForm>
  ) => Promise<void>;
};

export function ForgottenPasswordNewPasswordScene({
  error,
  helpButton,
  loading,
  onUpdate,
}: PpForgottenPasswordNewPasswordScene): React.ReactElement {
  const methods = useForm<TpForgottenPasswordForm>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema) as Resolver<TpForgottenPasswordForm>,
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = methods;

  useAuthError({ error: errors.root?.serverError });

  useEffect(() => {
    if (!error) {
      return;
    }
    setError('root.serverError', error);
  }, [setError, error]);

  return (
    <FormProvider {...methods}>
      <AppContainer
        wideFooter
        AppFooterSlot={
          <PrimaryButton
            color="primaryBrand"
            disabled={isSubmitting || loading}
            form={forgottenPasswordFormId}
            loading={isSubmitting}
            type="submit"
          >
            Continue
          </PrimaryButton>
        }
        AppHeaderSlot={
          <AppHeader disableBalancing endIconsSlot={helpButton}>
            <AppLogo />
          </AppHeader>
        }
        dataQa="forgotten-code"
      >
        <Helmet>
          <title>Choose a new password</title>
        </Helmet>
        <SceneHeader>
          <SceneTitleLarge>Choose a new password</SceneTitleLarge>
          <SceneParagraph>
            Create a new password that is at least 8 characters long. A strong password has a
            combination of letters, digits and punctuation marks.
          </SceneParagraph>
        </SceneHeader>
        <SceneMain>
          <form
            id={forgottenPasswordFormId}
            onSubmit={handleSubmit((values) => onUpdate(values, setError))}
          >
            <PasswordFormItem variant="label" />
          </form>
        </SceneMain>
      </AppContainer>
    </FormProvider>
  );
}
