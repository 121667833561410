import { useMemo } from 'react';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { Feature } from '@noah-labs/shared-schema-gql';
import type { FrameCardTokenizedEvent } from 'frames-react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useCountriesForFeature } from '../../../../hooks';
import { getUserFullDisplayName } from '../../../../utils';
import { useCountryFromCode } from '../../../user/data/useCountryFromCode';
import { useUserInit } from '../../../user/data/useUserInit';
import { BillingDefaults } from '../../../user/hooks/useBillingDefaults';
import { routes } from '../../routes';
import { EnterPaymentInfoScene } from '../../scenes';
import { TpPaymentMethod } from '../../types';
import { parseCardScheme } from '../../utils/utils';
import type { StBuyRouter } from './BuyRouter';

type PpEnterPaymentInfo = TpStateMachine<StBuyRouter>;

export function EnterPaymentInfo({ updateState }: PpEnterPaymentInfo): React.ReactElement {
  const { data: userData } = useUserInit();
  const { data: countriesData } = useCountriesForFeature(
    userData?.userProfile.HomeAddress?.CountryCode,
    [Feature.CkoBuy]
  );
  const country = useCountryFromCode(userData?.userProfile.HomeAddress?.CountryCode);
  const match = useRouteMatch(routes.buy.payment.path);
  const history = useHistory();
  const { state: locationState } = useLocation<{ successTo: string } | undefined>();

  const fullName = getUserFullDisplayName(userData?.userProfile.PersonalName);

  const defaultAddress = useMemo(() => {
    if (!userData?.userProfile.HomeAddress || !country) {
      return undefined;
    }

    return BillingDefaults(userData.userProfile.HomeAddress, country);
  }, [country, userData?.userProfile.HomeAddress]);

  const onCardTokenized = useMemo(() => {
    if (typeof locationState?.successTo !== 'string') {
      return undefined;
    }

    return async (cardTokenized: FrameCardTokenizedEvent, save: boolean) => {
      updateState({
        paymentMethod: TpPaymentMethod.Card,
        savePaymentCard: save,
        selectedPaymentCard: {
          billingAddress: undefined,
          id: cardTokenized.token,
          issuer: cardTokenized.issuer,
          last4: cardTokenized.last4,
          scheme: parseCardScheme(cardTokenized.scheme || ''),
          type: 'new',
        },
      });

      history.push(locationState.successTo);
    };
  }, [locationState?.successTo, updateState, history]);

  return (
    <EnterPaymentInfoScene
      saveToggle
      countries={countriesData?.countries}
      ctaLabel="Add card"
      defaultAddress={defaultAddress}
      fullName={fullName}
      isVisible={!!match?.isExact}
      pageTitle={routes.buy.payment.title}
      visiblePath={routes.buy.payment.path}
      onCardTokenized={onCardTokenized}
    />
  );
}
