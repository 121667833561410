import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useUserLocalStorage } from '../../../hooks';
import { useAuth } from '../../auth/hooks/useAuth';
import { useUserInit } from '../../user/data/useUserInit';
import { routes as walletRoutes } from '../../wallet/routes';
import { KycReminderOrigin } from '../controllers/Reminder';
import { routes } from '../routes';

export function useLoginKycReminder(): void {
  const { addSignOutSubscriber, data: auth } = useAuth();
  const history = useHistory();
  const isWalletPath = useRouteMatch(walletRoutes.base.path);
  const { data: userInit } = useUserInit();
  const {
    isLoaded: isStorageLoaded,
    kycReminderWasShownOnLogin,
    setKycReminderWasShownOnLogin,
  } = useUserLocalStorage(userInit?.userProfile.UserID);

  useEffect(
    () =>
      addSignOutSubscriber('kycReminder', () => {
        setKycReminderWasShownOnLogin(false);
      }),
    [addSignOutSubscriber, setKycReminderWasShownOnLogin]
  );

  useEffect(() => {
    if (!userInit?.userProfile || !auth) {
      return;
    }

    const attemptedOrHasKyc = !!userInit.userProfile.KycVerification.ApprovalStatus;

    if (
      !isStorageLoaded ||
      !isWalletPath?.isExact ||
      attemptedOrHasKyc ||
      kycReminderWasShownOnLogin
    ) {
      return;
    }

    history.push({
      pathname: routes.reminder.path,
      search: new URLSearchParams({ origin: KycReminderOrigin.Login }).toString(),
    });
    setKycReminderWasShownOnLogin(true);
  }, [
    userInit?.userProfile,
    history,
    isStorageLoaded,
    isWalletPath?.isExact,
    kycReminderWasShownOnLogin,
    setKycReminderWasShownOnLogin,
    auth,
  ]);
}
