import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies/src/types';
import type { AccountType as TpAccountType } from '@noah-labs/shared-schema-gql';
import { useAvailableBalance } from '../../hooks/useAvailableBalance';
import { useCurrencyAmounts } from '../../hooks/useCurrencyAmounts';
import type { TpPaymentBank, TpPaymentCard, TpPaymentMethod } from '../../types';
import { AvailableBalanceWithPaymentMethod } from './AvailableBalanceWithPaymentMethod';

export type PpAvailableBalanceWithPaymentMethodData = {
  AccountType: TpAccountType;
  cryptoCurrency: TpCryptoCurrencyUI;
  fiatCurrency: TpFiatCurrency;
  href?: string;
  method: TpPaymentMethod | undefined;
  onPaymentMethodClick?: () => void;
  paymentBank?: TpPaymentBank;
  paymentCard?: TpPaymentCard;
};

export function AvailableBalanceWithPaymentMethodData({
  AccountType,
  cryptoCurrency,
  fiatCurrency,
  href,
  method,
  onPaymentMethodClick,
  paymentBank,
  paymentCard,
}: PpAvailableBalanceWithPaymentMethodData): React.ReactElement {
  const availableBalanceData = useAvailableBalance({
    AccountType,
    cryptoCurrency,
    fiatCurrency,
  });

  const { PrimaryAmountSlot, SecondaryAmountSlot } = useCurrencyAmounts({
    ...availableBalanceData,
  });

  return (
    <AvailableBalanceWithPaymentMethod
      cryptoCurrency={cryptoCurrency}
      href={href}
      method={method}
      paymentBank={paymentBank}
      paymentCard={paymentCard}
      PrimaryAmountSlot={PrimaryAmountSlot}
      SecondaryAmountSlot={SecondaryAmountSlot}
      onPaymentMethodClick={onPaymentMethodClick}
    />
  );
}
