import { useEffect, useState } from 'react';
import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { Route } from 'react-router-dom';
import { AuthRouteData } from '../../../auth';
import { useWalletParams } from '../../data';
import { useAddress } from '../../hooks';
import { routes } from '../../routes';
import { AddressManual } from './AddressManual';
import { AddressScan } from './AddressScan';

export function AddressRouter(): React.ReactElement {
  const { params } = useWalletParams();
  const { scannerUnavailable, setData } = useAddress();
  const [scannedAddress, setScannedAddress] = useState('');

  useEffect(() => () => setData(undefined), [setData]);

  return (
    <Switch404>
      <Route
        exact
        path={routes.address.scan.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={scannerUnavailable && generatePath(routes.address.text.path, params)}
            route={routes.address.scan}
          >
            <AddressScan
              handleScannedAddress={(address: string): void => setScannedAddress(address)}
            />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.address.text.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.address.text}>
            <AddressManual scannedAddress={scannedAddress} />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
