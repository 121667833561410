import { useEffect, useMemo, useState } from 'react';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import { useLocation } from 'react-router-dom';
import { useSigning } from '../../signing/context';
import { useWalletError } from '../../wallet/hooks';
import { PinSuccessScene } from '../scenes/PinSuccess';

export type PpRevokePin = {
  successTo: string;
};

export type TpParams = {
  documentType: string;
  revoke: boolean;
};

function getParams(search: string): TpParams {
  const params = new URLSearchParams(search);
  const documentType = params.get('documentType');
  const revoke = params.get('revoke');

  if (documentType !== 'NonCustodyKey') {
    throw new Error('Invalid document type');
  }

  return {
    documentType,
    revoke: revoke ? JSON.parse(revoke) : false,
  };
}

export function RevokePin({ successTo }: PpRevokePin): React.ReactElement {
  const { isJwtFetched, revoke: revokeFn, sdStatus } = useSigning();
  const { search } = useLocation();

  const [actioned, setActioned] = useState(false);
  const [error, setError] = useState<unknown>();
  const { ApiErrorScene } = useWalletError(error, true);

  const params = useMemo(() => {
    try {
      return getParams(search);
    } catch (err) {
      setError(err);
      return undefined;
    }
  }, [search]);

  const isServiceReady = isJwtFetched && !isUndefinedOrNull(sdStatus);

  useEffect(() => {
    if (error || actioned || !isServiceReady || !params) {
      return;
    }

    const { documentType, revoke } = params;

    if (documentType !== 'NonCustodyKey') {
      setError('Invalid document type');
      return;
    }

    void (async (): Promise<void> => {
      try {
        await revokeFn({ documentType, revoke });
        setActioned(true);
      } catch (err) {
        setError(err);
      }
    })();
  }, [isServiceReady, actioned, error, params, revokeFn]);

  if (actioned) {
    return (
      <PinSuccessScene
        pageTitle="PIN Revocation Started"
        sceneDescription="Your PIN will be revoked in 7 days. You will be able to create a new PIN when you login after the time has expired."
        sceneTitle="PIN Revocation Started"
        successTo={successTo}
      />
    );
  }

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return <LoadingPage />;
}
