import { Link } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { AppLogo } from '@noah-labs/core-web-ui/src/images';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeader } from '@noah-labs/core-web-ui/src/layout/AppHeader';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { useOry } from '@noah-labs/fe-shared-data-access-auth';
import { Helmet } from 'react-helmet';

export type PpForgottenPasswordSuccessScene = {
  helpButton?: React.ReactNode;
};

export function ForgottenPasswordSuccessScene({
  helpButton,
}: PpForgottenPasswordSuccessScene): React.ReactElement {
  const { returnTo } = useOry();
  const returnToFromParams = new URLSearchParams(window.location.search).get('returnTo');
  const successReturnTo = returnToFromParams || returnTo;

  return (
    <AppContainer
      wideFooter
      AppFooterSlot={
        <PrimaryButton
          color="primaryBrand"
          data-qa="continue-button"
          href={successReturnTo}
          LinkComponent={Link}
        >
          Continue to Noah
        </PrimaryButton>
      }
      AppHeaderSlot={
        <AppHeader disableBalancing endIconsSlot={helpButton}>
          <AppLogo />
        </AppHeader>
      }
      dataQa="forgotten-code"
    >
      <Helmet>
        <title>Success</title>
      </Helmet>
      <SceneHeader>
        <SceneTitleLarge>Success, your password has been changed</SceneTitleLarge>
        <SceneParagraph>Your new password is ready for you to use!</SceneParagraph>
      </SceneHeader>
    </AppContainer>
  );
}
