import React from 'react';
import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import {
  StatusElevatedCard,
  TpAvatarStatus,
} from '@noah-labs/core-web-ui/src/cards/StatusElevatedCard';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import { SceneParagraph } from '@noah-labs/core-web-ui/src/scene/Typography';
import { webConfigBrowser } from '../../../../webConfigBrowser';

export type PpComplete = {
  enableAnimation?: boolean;
  onDismiss: () => void;
  open: boolean;
};

export function Complete({
  enableAnimation = webConfigBrowser.settings.enableLoaderAnimations,
  onDismiss,
  open,
}: PpComplete): React.ReactElement {
  const theme = useTheme();
  const styles = {
    card: css`
      margin: ${theme.spacing(7, 0, 5)};
      padding: ${theme.spacing(0, 3, 8)};
    `,
    copyContent: css`
      margin-top: ${theme.spacing(2.5)};
      text-align: center;
    `,
    title: css`
      margin: ${theme.spacing(0, 4)};
    `,
  };

  return (
    <LowDialog
      fullWidth
      dataQa="bitrefill-complete"
      fullScreen={window.innerHeight < 600} // content gets squished on small screens
      open={open}
      PaperProps={{
        sx: { padding: 3 },
      }}
    >
      <StatusElevatedCard
        cardCss={styles.card}
        enableAnimation={enableAnimation}
        status={TpAvatarStatus.success}
      >
        <Stack css={styles.copyContent} spacing={1}>
          <Typography color="primary.main" css={styles.title} variant="headingL">
            Payment Sent
          </Typography>
          <SceneParagraph>An email will be sent to you with your purchase details</SceneParagraph>
        </Stack>
      </StatusElevatedCard>

      <PrimaryButton color="primary" data-qa="close-btn" onClick={onDismiss}>
        <Typography>Close</Typography>
      </PrimaryButton>
    </LowDialog>
  );
}
