import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { AppLogo } from '@noah-labs/core-web-ui/src/images';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeader } from '@noah-labs/core-web-ui/src/layout/AppHeader';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';

type PpSignedOut = {
  signinUrl: string;
};

export function SignedOutScene({ signinUrl }: PpSignedOut): React.ReactElement {
  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeader>
          <AppLogo css={{ margin: '0 auto' }} />
        </AppHeader>
      }
      dataQa="signed-out"
    >
      <SceneHeader textAlign="center">
        <SceneTitleLarge>You have been signed&nbsp;out</SceneTitleLarge>
        <SceneParagraph>
          Sorry, your session has expired, you have been signed out for security reasons. Please log
          in again to continue.
        </SceneParagraph>
      </SceneHeader>
      <SceneMain>
        <PrimaryButton color="primaryBrand" data-qa="signin-button" href={signinUrl}>
          Login
        </PrimaryButton>
      </SceneMain>
    </AppContainer>
  );
}
