import { CryptoAmount } from '@noah-labs/core-web-ui/src/numbers/CryptoAmount';
import { FiatAmount } from '@noah-labs/core-web-ui/src/numbers/FiatAmount';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-currencies';
import type { TpAmount, TpFiatCurrency } from '@noah-labs/shared-currencies/src/types';
import type { FiatAmount as TpFiatAmount } from '@noah-labs/shared-schema-gql';
import { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { isNonZero, safeBN } from '@noah-labs/shared-tools/src/browser/numbers';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import * as yup from 'yup';
import { z } from 'zod';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { billingAddressSchema } from '../components/forms/BillingAddresses/schema';
import type { TpAmountForm } from '../scenes';
import type { TpAllowance, TpWithdrawalAllowance } from '../types';
import { getCryptoWithdrawalLimitsSchema } from './getCryptoWithdrawalLimitsSchema';
import type { TpCryptoAmountUnit } from './utils';

const { feePercentage, minimumFiatAmount } = webConfigBrowser.cko;

const MAX_FIAT_AMOUNT = 10000000;

function maxSafetyAmount(fiatCurrency: TpFiatCurrency): yup.StringSchema {
  return yup.string().test({
    message: () => (
      <span>
        Maximum amount is <FiatAmount amount={MAX_FIAT_AMOUNT} currency={fiatCurrency} />
      </span>
    ),
    test: (value) => safeBN(value).lt(safeBN(MAX_FIAT_AMOUNT)),
  });
}

type TpCheckoutOrBankFiatAmount = {
  fiatCurrency: TpFiatCurrency;
  minFiatAmount: string;
  prefix: string;
};
function minRampFiatAmount({
  fiatCurrency,
  minFiatAmount,
  prefix,
}: TpCheckoutOrBankFiatAmount): yup.StringSchema {
  return yup.string().test({
    message: () => (
      <span>
        {prefix} <FiatAmount amount={minFiatAmount} currency={fiatCurrency} />
      </span>
    ),
    test: (value) => {
      if (!isNonZero(value)) {
        return true;
      }

      return safeBN(value).gte(minFiatAmount);
    },
  });
}

/**
 * validation schemas for all enter amount flows
 */
type TpGetLnSendSchema = {
  allowance: TpAllowance | undefined;
  fiatCurrency: TpFiatCurrency;
  maxAmounts: TpCryptoAmountUnit | undefined;
  minAmounts: TpCryptoAmountUnit | undefined;
};
export function getLnSendSchema({
  allowance,
  fiatCurrency,
  maxAmounts,
  minAmounts,
}: TpGetLnSendSchema): yup.ObjectSchema<Partial<TpAmountForm>> {
  return yup.object<Partial<TpAmountForm>>({
    cryptoAmount: yup.lazy(() => {
      let schema = getCryptoWithdrawalLimitsSchema(allowance);
      if (!isUndefinedOrNull(maxAmounts)) {
        schema = schema.test({
          message: ({ value }) => {
            if (!isNonZero(value as string)) {
              return null;
            }
            return (
              <span>
                Maximum amount is{' '}
                <CryptoAmount
                  amount={maxAmounts.amountBtc}
                  currency={cryptoCurrencyFromCode(maxAmounts.code)}
                  currencyUnit={maxAmounts.unit}
                />
              </span>
            );
          },
          test: (valueBtc) => safeBN(valueBtc).lte(maxAmounts.amountBtc),
        });
      }

      if (!isUndefinedOrNull(minAmounts)) {
        schema = schema.test({
          message: ({ value }) => {
            if (!isNonZero(value as string)) {
              return null;
            }
            return (
              <span>
                Minimum amount is{' '}
                <CryptoAmount
                  amount={minAmounts.amountBtc}
                  currency={cryptoCurrencyFromCode(minAmounts.code)}
                  currencyUnit={minAmounts.unit}
                />
              </span>
            );
          },
          test: (valueBtc) => {
            if (!isNonZero(valueBtc)) {
              return false;
            }
            return safeBN(valueBtc).gte(minAmounts.amountBtc);
          },
        });
      }

      return schema;
    }),
    fiatAmount: maxSafetyAmount(fiatCurrency),
  });
}

type TpGetLnReceiveSchema = {
  fiatCurrency: TpFiatCurrency;
  maxLnSingleSendFiat: TpFiatAmount | undefined | null;
};
export function getLnReceiveSchema({
  fiatCurrency,
  maxLnSingleSendFiat,
}: TpGetLnReceiveSchema): yup.ObjectSchema<Partial<TpAmountForm>> {
  return yup.object<Partial<TpAmountForm>>({
    cryptoAmount: yup.lazy(() => {
      if (
        !isUndefinedOrNull(maxLnSingleSendFiat) &&
        !isUndefinedOrNull(maxLnSingleSendFiat.CryptoAmounts)
      ) {
        const cryptoAmount = maxLnSingleSendFiat.CryptoAmounts[0];
        const AmountSlot = (
          <CryptoAmount
            amount={cryptoAmount.Amount}
            currency={cryptoCurrencyFromCode(cryptoAmount.Currency)}
            currencyUnit={CurrencyUnit.Default}
          />
        );

        return yup.string().test({
          message: () => (
            <span>
              Maximum amount for a single lightning transaction is {AmountSlot}. Consider using an
              onchain transfer
            </span>
          ),
          test: (value) => safeBN(value).lte(cryptoAmount.Amount),
        });
      }

      return yup.string();
    }),
    fiatAmount: maxSafetyAmount(fiatCurrency),
  });
}

type TpGetOnchainWithdrawSchema = {
  allowance: TpWithdrawalAllowance | undefined;
  feeCryptoAmount: TpAmount;
  fiatCurrency: TpFiatCurrency;
  minAmounts: TpCryptoAmountUnit | undefined;
};
export function getOnchainWithdrawSchema({
  allowance,
  feeCryptoAmount,
  fiatCurrency,
  minAmounts,
}: TpGetOnchainWithdrawSchema): yup.ObjectSchema<Partial<TpAmountForm>> {
  return yup.object<Partial<TpAmountForm>>({
    cryptoAmount: yup.lazy(() => {
      // We need to set this first so we can override the other error messages
      if (allowance?.withdrawalRemainingTxs?.eq(0)) {
        return yup
          .string()
          .test('remainingTxs', 'Sorry, you have reached your daily send limits.', () => false);
      }

      let schema = getCryptoWithdrawalLimitsSchema(allowance?.accountAllowanceCrypto);

      schema = schema.test('WithdrawFee', '', function Test(cryptoAmount) {
        if (
          isUndefinedOrNull(feeCryptoAmount) ||
          isUndefinedOrNull(allowance?.balanceUserCrypto) ||
          isUndefinedOrNull(cryptoAmount)
        ) {
          return false;
        }

        const cryptoAmountWithFee = safeBN(cryptoAmount).plus(feeCryptoAmount);
        if (cryptoAmountWithFee.lte(allowance.balanceUserCrypto)) {
          return true;
        }

        return this.createError({
          message:
            'Your balance is too low to cover the sending amount and fees. Please adjust the amount or add funds to continue.',
        });
      });

      if (!isUndefinedOrNull(minAmounts)) {
        schema = schema.test({
          message: ({ value }) => {
            if (!isNonZero(value as string)) {
              return null;
            }
            return (
              <span>
                Minimum amount is{' '}
                <CryptoAmount
                  amount={minAmounts.amountBtc}
                  currency={cryptoCurrencyFromCode(minAmounts.code)}
                  currencyUnit={minAmounts.unit}
                />
              </span>
            );
          },
          test: (valueBtc) => safeBN(valueBtc).gte(minAmounts.amountBtc),
        });
      }

      return schema;
    }),
    fiatAmount: maxSafetyAmount(fiatCurrency),
  });
}

export function getCkoBuyAmountSchema(
  fiatCurrency: TpFiatCurrency
): yup.ObjectSchema<Partial<TpAmountForm>> {
  return yup.object<Partial<TpAmountForm>>({
    fiatAmount: minRampFiatAmount({
      fiatCurrency,
      minFiatAmount: minimumFiatAmount,
      prefix: 'Minimum buy amount is',
    }).concat(maxSafetyAmount(fiatCurrency)),
    // this value may be null on the first load while the crypto price is being fetched
    secondaryAmount: yup.string().required(),
  });
}

type TpGetCkoSellAmountSchema = {
  allowance: TpAllowance | undefined;
  fiatCurrency: TpFiatCurrency;
  minFiatCurrencyAmount: string | undefined;
};
export function getCkoSellAmountSchema({
  allowance,
  fiatCurrency,
  minFiatCurrencyAmount,
}: TpGetCkoSellAmountSchema): yup.ObjectSchema<Partial<TpAmountForm>> {
  return yup.object<Partial<TpAmountForm>>({
    cryptoAmount: getCryptoWithdrawalLimitsSchema(allowance, {
      buffer: feePercentage,
      bufferInfo: '(fees included)',
    }),
    fiatAmount: minRampFiatAmount({
      fiatCurrency,
      minFiatAmount: minFiatCurrencyAmount || minimumFiatAmount,
      prefix: 'Minimum sell amount is',
    }).concat(maxSafetyAmount(fiatCurrency)),
    // this value may be null on the first load while the crypto price is being fetched
    secondaryAmount: yup.string().required(),
  });
}

const baseBankFormSchema = z.object({
  accountCurrency: z.string().min(1, 'Account currency is required'),
  accountCurrencyName: z.string().min(1, 'Account currency is required'),
  accountHolderName: z.string().min(1, 'Account holder name is required'),
  billingAddress: billingAddressSchema,
  saveAccount: z.boolean(),
});

const swiftRegex = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;

export const defaultBankFormSchema = z
  .object({
    accountNumber: z.string().min(1, 'IBAN is required'),
    bankCode: z.string().regex(swiftRegex, 'Invalid SWIFT/BIC'),
  })
  .merge(baseBankFormSchema);

export const USBankFormSchema = z
  .object({
    accountNumber: z.string().min(1, 'Account number is required'),
    bankCode: z.string().min(1, 'Wire routing number is required'),
  })
  .merge(baseBankFormSchema);

export const SortCodeFormSchema = z
  .object({
    accountNumber: z.string().regex(/^\d{8}$/, 'Invalid account number'),
    bankCode: z.string().regex(/^\d{6}$/, 'Invalid sort code'),
  })
  .merge(baseBankFormSchema);
