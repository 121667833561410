import {
  moonpayTxReceiptUrl,
  useMoonpaySellQuoteQuery,
  useMoonpaySellTransactionQuery,
} from '@noah-labs/core-services';
import { Balance } from '@noah-labs/core-web-ui/src/balance/Balance';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { useNavigation } from '@noah-labs/core-web-ui/src/hooks/useNavigation';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { cryptoCurrencyFromCode, fiatCurrencyFromString } from '@noah-labs/fe-shared-ui-currencies';
import { getEnvCurrencyCode } from '@noah-labs/shared-currencies';
import { isProd } from '../../../../webConfigBrowser';
import { AvatarWithTransactionStatus } from '../../components/transactions/AvatarWithTransactionStatus';
import { useCurrencyAmounts } from '../../hooks/useCurrencyAmounts';
import { CompleteMoonpayScene } from '../../scenes';
import { TpTransactionType } from '../../types';
import { AlertSell } from './AlertSell';
import { getAvatarStatus } from './getAvatarStatus';
import { getTitles } from './getTitles';

export function Complete(): React.ReactElement {
  const { exitTo } = useNavigation();
  const searchParams = new URLSearchParams(window.location.search);
  const transactionId = searchParams.get('transactionId') || '';

  const { data: transactionData } = useMoonpaySellTransactionQuery(transactionId);

  const baseCurrencyCode = transactionData?.baseCurrency.code;
  const quoteCurrencyCode = transactionData?.quoteCurrency.code;
  const cryptoCode = getEnvCurrencyCode(baseCurrencyCode, isProd);
  const cryptoCurrency = cryptoCurrencyFromCode(cryptoCode);
  const fiatCurrency = fiatCurrencyFromString(quoteCurrencyCode);
  const cryptoAmount = transactionData?.baseCurrencyAmount;

  const { data: quoteData } = useMoonpaySellQuoteQuery({
    baseCurrencyAmount: cryptoAmount,
    baseCurrencyCode,
    quoteCurrencyCode,
  });

  const Amounts = useCurrencyAmounts({
    cryptoAmount,
    cryptoCurrency,
    fiatAmount: quoteData?.quoteCurrencyAmount,
    fiatCurrency,
  });

  if (!transactionData) {
    return <LoadingPage />;
  }

  const { pageTitle } = getTitles(transactionData.status);

  return (
    <CompleteMoonpayScene
      AlertSlot={
        <AlertSell
          moonpayUrl={moonpayTxReceiptUrl('sell', transactionData.id)}
          status={transactionData.status}
        />
      }
      AvatarSlot={
        cryptoCurrency.Icon ? (
          <AvatarWithTransactionStatus
            CurrencyIcon={cryptoCurrency.Icon}
            transactionStatus={getAvatarStatus(transactionData.status)}
            transactionType={TpTransactionType.Sell}
          />
        ) : undefined
      }
      BalanceSlot={<Balance {...Amounts} />}
      CtaSlot={
        <PrimaryButton data-qa="done-button" href={exitTo}>
          Done
        </PrimaryButton>
      }
      sceneTitle={pageTitle}
      transactionId={transactionData.id}
    />
  );
}
