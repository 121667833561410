import { useEffect } from 'react';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { getCardIssuerNotificationAlert } from '../controllers/buy/utils';
import { useCardIssuerNotificationsQuery } from '../data';

export function useCardIssuerNotifications(cardIssuerName: string | null | undefined): void {
  const pushAlert = usePushAlert();
  const { data } = useCardIssuerNotificationsQuery(
    { Input: { IssuerName: cardIssuerName ?? '' } },
    { enabled: Boolean(cardIssuerName) }
  );

  useEffect(() => {
    if (!data?.cardIssuerNotifications || data.cardIssuerNotifications.length === 0) {
      return;
    }

    data.cardIssuerNotifications.forEach((notification) => {
      if (!notification) {
        return;
      }

      const alertProps = getCardIssuerNotificationAlert(notification);

      if (!alertProps) {
        return;
      }

      pushAlert(alertProps);
    });
  }, [data?.cardIssuerNotifications, pushAlert]);
}
