import type { TpFiatCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';
import { fiatCurrencyFromCode } from '@noah-labs/fe-shared-ui-currencies';
import { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { useUserInit } from '../modules/user/data/useUserInit';

type TpUseUserFiatCurrency = {
  fiatCurrency: TpFiatCurrencyUI;
  fiatPaymentCurrency: TpFiatCurrencyUI;
};

export function useUserFiatCurrency(): TpUseUserFiatCurrency {
  const { data: userData } = useUserInit();
  const fiatCurrency = userData?.userProfile.FiatCurrencyCode || FiatCurrencyCode.USD;
  const fiatPaymentCurrency = userData?.userProfile.FiatPaymentCurrencyCode || FiatCurrencyCode.USD;

  const fiatCurrencyData = fiatCurrencyFromCode(fiatCurrency);
  const fiatPaymentCurrencyData = fiatCurrencyFromCode(fiatPaymentCurrency);

  return {
    fiatCurrency: fiatCurrencyData,
    fiatPaymentCurrency: fiatPaymentCurrencyData,
  };
}
