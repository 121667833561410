import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDesktop } from '@noah-labs/core-web-ui/src/hooks/useDesktop';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { Feature } from '@noah-labs/shared-schema-gql';
import { Helmet } from 'react-helmet';
import { AppHeaderData, NavBarData, SidebarNavData } from '../../../components';
import { RestrictedFeature } from '../../user/components/RestrictedFeature';
import { routes } from '../routes';

export type PpAccountsScene = {
  AccountsSlot: React.ReactElement;
  ActionsMenuSlot: React.ReactElement;
  MarketingSlot?: React.ReactElement;
  TotalBalanceSlot: React.ReactElement;
};

export function AccountsScene({
  AccountsSlot,
  ActionsMenuSlot,
  MarketingSlot,
  TotalBalanceSlot,
}: PpAccountsScene): React.ReactElement {
  const isDesktop = useDesktop();
  const theme = useTheme();

  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeaderData
          avatarButton
          disableBalancing
          helpButton
          notificationCenterButton
          backgroundColor={theme.palette.grayscale.offWhite}
          fullWidth={isDesktop}
        />
      }
      BottomNavbarSlot={<NavBarData />}
      dataQa="wallet"
      mainSxProps={{ display: 'flex', flexDirection: 'column' }}
      SideNavbarSlot={<SidebarNavData />}
    >
      <Helmet>
        <title>{routes.base.title}</title>
      </Helmet>
      <SceneHeader>
        <Stack spacing={5}>
          {TotalBalanceSlot}
          {ActionsMenuSlot}
        </Stack>
      </SceneHeader>
      <RestrictedFeature needs={[Feature.UIPromotions]}>
        <SceneMain sx={{ overflowX: 'hidden' }}>{MarketingSlot}</SceneMain>
      </RestrictedFeature>
      <SceneMain>{AccountsSlot}</SceneMain>
    </AppContainer>
  );
}
