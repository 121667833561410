import { CircularProgress } from '@mui/material';
import { InlineLinkOrButton } from '@noah-labs/core-web-ui/src/buttons/InlineLinkOrButton';
import { SceneParagraph } from '@noah-labs/core-web-ui/src/scene/Typography';

type PpResendVerificationCode = {
  loading: boolean;
  onResend: () => Promise<void>;
};

export function ResendVerificationCode({
  loading,
  onResend,
}: PpResendVerificationCode): React.ReactElement {
  return (
    <SceneParagraph>
      Didn’t receive an email?{' '}
      {loading ? (
        <CircularProgress size={14} sx={{ color: 'brand.light' }} />
      ) : (
        <InlineLinkOrButton onClick={onResend}>Resend code.</InlineLinkOrButton>
      )}
    </SceneParagraph>
  );
}
