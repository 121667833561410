import { useCallback } from 'react';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import type { LoginFlow } from '@ory/client';
import type { AxiosError } from 'axios';
import { HttpStatusCode, isAxiosError } from 'axios';
import { useOry } from '../data';
import type { AuthSetError, SocialProvider } from '../types';
import { assignLocationAndWait, getOryCsrfToken } from '../utils';
import { isRedirectBrowserError } from '../utils/oryTypeGuards';
import { getOryUiError } from './utils';

export function useOrySocialSignInCallback(returnTo: string): (
  provider: SocialProvider,
  setError: AuthSetError<{
    email: string;
    password: string;
  }>
) => Promise<void> {
  const { ory } = useOry();

  return useCallback(
    async (provider, setError): Promise<void> => {
      try {
        const { data: flow } = await ory.createBrowserLoginFlow({
          returnTo,
        });

        const csrfToken = getOryCsrfToken(flow.ui.nodes);

        await ory.updateLoginFlow({
          flow: flow.id,
          updateLoginFlowBody: {
            csrf_token: csrfToken,
            method: 'oidc',
            provider,
          },
        });
        await assignLocationAndWait(returnTo);
      } catch (error: unknown) {
        logger.error(error);

        if (!isAxiosError(error)) {
          setError('root.serverError', {
            message: 'Something went wrong.',
            type: 'custom',
          });
          return;
        }

        const oryError: AxiosError<LoginFlow> = error;
        if (isRedirectBrowserError(oryError)) {
          await assignLocationAndWait(oryError.response.data.redirect_browser_to);
          return;
        }

        if (oryError.response?.status !== HttpStatusCode.BadRequest) {
          setError('root.serverError', {
            message: 'Something went wrong.',
            type: 'custom',
          });
          return;
        }

        const { ui } = oryError.response.data;
        const uiServerMessage = getOryUiError(ui.messages);
        if (uiServerMessage) {
          setError('root.serverError', uiServerMessage);
        }
      }
    },
    [ory, returnTo]
  );
}
