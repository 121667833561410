import type { TpFeatureNeeds } from '@noah-labs/core-web-ui/src/utility/featureTest';
import { featureTest } from '@noah-labs/core-web-ui/src/utility/featureTest';
import { useUserInit } from '../data/useUserInit';

export type PpUseRestricted = {
  needs: TpFeatureNeeds;
};
/**
 * Custom hook to determine if the current user has the necessary features enabled.
 *
 * Usage:
 * - See featureTest function for more details
 *
 * @param {PpUseRestricted} props - The feature requirements.
 *
 * @returns {boolean} - Returns true if the user meets the feature requirements, otherwise returns false.
 */
export function useRestrictedFeature({ needs }: PpUseRestricted): boolean {
  const { features } = useUserInit();

  return featureTest({ needs, userFeatures: features });
}
