import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleMedium } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';

export type PpSelectCrypto = {
  AccountsSlot: React.ReactElement;
  backTo: string;
  subTitle: string;
  title: string;
};

export function SelectCryptoScene({
  AccountsSlot,
  backTo,
  subTitle,
  title,
}: PpSelectCrypto): React.ReactElement {
  return (
    <AppContainer
      AppHeaderSlot={<AppHeaderData exitButton helpButton backTo={backTo} />}
      dataQa="select-crypto"
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SceneHeader dense>
        <SceneTitleMedium>{title}</SceneTitleMedium>
        <SceneParagraph>{subTitle}</SceneParagraph>
      </SceneHeader>
      <SceneMain dense>{AccountsSlot}</SceneMain>
    </AppContainer>
  );
}
