import type { TpMoonpayTransactionStatus } from '@noah-labs/core-services';
import { TpAvatarStatus } from '@noah-labs/core-web-ui/src/cards/StatusElevatedCard';
import { TransactionStatus } from '@noah-labs/shared-schema-gql';
import { TpCheckoutPaymentStatus, TpCheckoutPayoutStatus } from '../../types';

export function getAvatarStatus(mpStatus: TpMoonpayTransactionStatus): TransactionStatus {
  switch (mpStatus) {
    case 'failed':
      return TransactionStatus.Failed;
    case 'completed':
      return TransactionStatus.Settled;
    case 'pending':
    case 'waitingAuthorization':
    case 'waitingPayment':
    default:
      return TransactionStatus.Pending;
  }
}

export function getCheckoutAvatarStatus(
  status: TpCheckoutPaymentStatus | TpCheckoutPayoutStatus
): TpAvatarStatus {
  switch (status) {
    case TpCheckoutPaymentStatus.pending:
    case TpCheckoutPayoutStatus.pending:
      return TpAvatarStatus.pending;
    case TpCheckoutPaymentStatus.failure:
    case TpCheckoutPayoutStatus.failure:
      return TpAvatarStatus.failed;
    case TpCheckoutPaymentStatus.success:
    case TpCheckoutPayoutStatus.success:
    default:
      return TpAvatarStatus.success;
  }
}
