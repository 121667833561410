import { Typography } from '@mui/material';
import { useDesktop } from '@noah-labs/core-web-ui/src/hooks/useDesktop';
import { AppLogoWithoutFullStop } from '@noah-labs/core-web-ui/src/images';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { TextWithIcon } from '@noah-labs/core-web-ui/src/typography/TextWithIcon';
import { Helmet } from 'react-helmet';
import { AppHeaderData, NavBarData, SidebarNavData } from '../../../components';
import { RewardsHeader } from '../components';
import { routes } from '../routes';
import type { TpRewardsTabProps, TpRewardsTabs } from '../types';

export type PpRewardsScene = {
  activeTab: TpRewardsTabs;
  tabs: TpRewardsTabProps[];
  userPoints: string | undefined;
};

export function RewardsScene({ activeTab, tabs, userPoints }: PpRewardsScene): React.ReactElement {
  const pageTitle = `NOAH ${routes.base.title}`;
  const isDesktop = useDesktop();

  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeaderData
          avatarButton
          disableBalancing
          disableFade
          helpButton
          notificationCenterButton
          fullWidth={isDesktop}
          // design request
          sx={{
            position: 'static',
          }}
        >
          <TextWithIcon>
            <AppLogoWithoutFullStop />
            <Typography noWrap variant="subHeadingM">
              Rewards
            </Typography>
          </TextWithIcon>
        </AppHeaderData>
      }
      BottomNavbarSlot={<NavBarData />}
      dataQa="rewards"
      SideNavbarSlot={<SidebarNavData />}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <RewardsHeader activeTab={activeTab} tabs={tabs} userPoints={userPoints} />

      <SceneMain dense>
        {tabs.map((t) => (
          <div key={t.value} hidden={activeTab !== t.value} role="tabpanel">
            {t.panel}
          </div>
        ))}
      </SceneMain>
    </AppContainer>
  );
}
