import { useState } from 'react';
import type { SafeWrapper } from '@noah-labs/core-services';
import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { Route } from 'react-router-dom';
import { AuthRouteData } from '../auth';
import { routes as walletRoutes } from '../wallet/routes';
import { SecretPhrase } from './controllers';
import { CreatePin } from './controllers/CreatePin';
import { routes } from './routes';
import { PinSuccessScene } from './scenes/PinSuccess';

/**
 * Used when a pin setup is required, i.e. the secret document is either missing or revoked
 * The user will be shown a new secret phrase they need to backup.
 */
export function CreatePinRouter(): React.ReactElement {
  const [phrase, setPhrase] = useState<SafeWrapper<string>>();

  return (
    <Switch404>
      <Route
        path={routes.setupPin.createPin.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.setupPin.createPin}>
            <CreatePin
              backTo={routes.settings.securityPin.path}
              setPhrase={setPhrase}
              successTo={routes.setupPin.secretPhrase.path}
            />
          </AuthRouteData>
        )}
      />

      <Route
        path={routes.setupPin.secretPhrase.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.setupPin.secretPhrase}>
            <SecretPhrase phrase={phrase} successTo={routes.setupPin.success.path} />
          </AuthRouteData>
        )}
      />

      <Route
        path={routes.setupPin.success.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.setupPin.success}>
            <PinSuccessScene
              pageTitle="PIN Created"
              sceneDescription="You can now use your new PIN to verify transactions for added security."
              sceneTitle="New PIN created successfully"
              successTo={walletRoutes.base.path}
            />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
