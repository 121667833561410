import { useCallback, useEffect, useState } from 'react';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { compareStrings } from '@noah-labs/shared-tools/src/browser/strings';
import { useQueryClient } from 'react-query';
import { WELCOME_STORIES_PROMO_ID } from '../../data/constants';
import {
  useMarketingPromoDismissMutation,
  useUserMarketingPromosQuery,
} from '../../data/welcome.generated';
import { WelcomeStoriesDialog } from './WelcomeStoriesDialog';

export function WelcomeStoriesDialogData(): React.ReactElement {
  const { data: promoData } = useUserMarketingPromosQuery(undefined, {
    select: (data) =>
      data.userMarketingPromos.Promos?.find((promo) =>
        compareStrings(promo?.PromoID, WELCOME_STORIES_PROMO_ID)
      ),
  });
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { mutateAsync } = useMarketingPromoDismissMutation();

  useEffect(() => {
    setOpen(Boolean(promoData));
  }, [promoData]);

  const handleClose = useCallback(async () => {
    try {
      setOpen(false);
      await mutateAsync({ Input: { PromoID: WELCOME_STORIES_PROMO_ID } });
      await queryClient.invalidateQueries(['UserMarketingPromos']);
    } catch (error) {
      logger.error('failed to mutate UserMarketingPromos', error);
    }
  }, [mutateAsync, queryClient]);

  return <WelcomeStoriesDialog open={open} onClose={handleClose} />;
}
