import { usePublicAppSyncRequest } from '@noah-labs/core-services';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';
import type {
  ValidateReferralCodeQuery,
  ValidateReferralCodeQueryVariables,
} from './auth.generated';
import { ValidateReferralCodeDocument } from './auth.generated';

export function useValidateReferralCodePublicQuery<
  TData = ValidateReferralCodeQuery,
  TError = unknown
>(
  variables: ValidateReferralCodeQueryVariables,
  options?: UseQueryOptions<ValidateReferralCodeQuery, TError, TData>
): UseQueryResult<TData> {
  return useQuery<ValidateReferralCodeQuery, TError, TData>(
    ['ValidateReferralCode', variables],
    usePublicAppSyncRequest<ValidateReferralCodeQuery, ValidateReferralCodeQueryVariables>(
      ValidateReferralCodeDocument
    ).bind(null, variables),
    options
  );
}
