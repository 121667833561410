import { AvailableBalance } from '@noah-labs/core-web-ui/src/balance/AvailableBalance';
import type { TpCryptoCurrencyUI, TpFiatCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';
import type { AccountType as TpAccountType } from '@noah-labs/shared-schema-gql';
import { useAvailableBalance } from '../../hooks/useAvailableBalance';
import { useCurrencyAmounts } from '../../hooks/useCurrencyAmounts';

type PpAvailableBalanceData = {
  AccountType: TpAccountType;
  ContentSlot?: React.ReactNode;
  cryptoCurrency: TpCryptoCurrencyUI;
  fiatCurrency: TpFiatCurrencyUI;
};

export function AvailableBalanceData({
  AccountType,
  ContentSlot,
  cryptoCurrency,
  fiatCurrency,
}: PpAvailableBalanceData): React.ReactElement {
  const availableBalanceData = useAvailableBalance({
    AccountType,
    cryptoCurrency,
    fiatCurrency,
  });

  const Amounts = useCurrencyAmounts({
    ...availableBalanceData,
  });

  return (
    <AvailableBalance ContentSlot={ContentSlot} cryptoCurrency={cryptoCurrency} {...Amounts} />
  );
}
