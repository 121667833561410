import { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { AuthRouteData } from '../auth';
import { EditPaymentMethod, NewPaymentMethod, PaymentMethods } from './controllers';
import { routes } from './routes';

export function PaymentMethodsRouter(): React.ReactElement {
  return (
    <Fragment>
      {/* preload new payment form because it uses checkout Frames */}
      <Route
        exact
        path={[routes.settings.paymentMethods.path, routes.settings.newPaymentMethod.path]}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.settings.newPaymentMethod}>
            <NewPaymentMethod />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.settings.editPaymentMethod.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.settings.editPaymentMethod}>
            <EditPaymentMethod />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.settings.paymentMethods.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.settings.paymentMethods}>
            <PaymentMethods />
          </AuthRouteData>
        )}
      />
    </Fragment>
  );
}
