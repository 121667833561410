import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { Route } from 'react-router-dom';
import { AuthRouteData } from '../auth';
import { NeedsKycRouter } from '../kyc/utils/NeedsKycRouter';
import { BitrefillScene } from './bitrefill/scenes/Bitrefill';
import { routes } from './routes';

export function Router(): React.ReactElement {
  return (
    <Switch404>
      <Route
        path={routes.bitrefill.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.bitrefill}>
            <NeedsKycRouter bypassIfUserHasBalance>
              <BitrefillScene />
            </NeedsKycRouter>
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
