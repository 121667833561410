import { safeBN } from '@noah-labs/shared-tools/src/browser/numbers';
import type { TpAmount, TpSimpleAmount } from './types';

export type TpSlippage = {
  slippage: string;
  type: 'positive' | 'negative';
};
export function withSlippage(amount: null | undefined, slippage: TpSlippage): undefined;
export function withSlippage(amount: string | number, slippage: TpSlippage): string;
export function withSlippage(amount: TpAmount, slippage: TpSlippage): TpSimpleAmount;
export function withSlippage(amount: TpAmount, slippage: TpSlippage): TpSimpleAmount {
  if (!amount) {
    return undefined;
  }
  const percent = safeBN(slippage.slippage).dividedBy(100);
  const one = safeBN(1);
  const factor = slippage.type === 'positive' ? one.plus(percent) : one.minus(percent);
  return safeBN(amount).times(factor).toString();
}
