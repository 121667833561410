import React from 'react';
import { Stack } from '@mui/material';
import { AddressWithCopy } from '@noah-labs/core-web-ui/src/address/AddressWithCopy';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { useShare } from '@noah-labs/core-web-ui/src/hooks/useShare';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import { TpAnalyticsEvent, useAnalytics } from '../../analytics';
import { routes } from '../routes';

export type PpInviteFriendsScene = {
  referralUrl: string | undefined;
};

export function InviteFriendsScene({ referralUrl }: PpInviteFriendsScene): React.ReactElement {
  const { handleSharing, isSupported } = useShare({ text: referralUrl });
  const { track } = useAnalytics();

  const ShareButton = isSupported ? (
    <PrimaryButton disabled={!referralUrl} onClick={handleSharing}>
      Share Referral Link
    </PrimaryButton>
  ) : undefined;

  return (
    <AppContainer
      AppHeaderSlot={<AppHeaderData helpButton backTo={routes.base.path} />}
      dataQa="invite-friends"
    >
      <Helmet>
        <title>{routes.inviteFriends.title}</title>
      </Helmet>
      <SceneHeader>
        <SceneTitleLarge>Invite friends, earn points!</SceneTitleLarge>
        <SceneParagraph>
          Earn NOAH points and redeem for rewards. Copy your code and share with friends!
        </SceneParagraph>
      </SceneHeader>
      <SceneMain>
        <Stack spacing={3.5}>
          <AddressWithCopy
            address={referralUrl}
            truncate={false}
            onCopy={(): void => track(TpAnalyticsEvent.ReferralLinkCopied)}
          />
          {ShareButton}
        </Stack>
      </SceneMain>
    </AppContainer>
  );
}
