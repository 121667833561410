import { getfilterCountryForFeature } from '@noah-labs/core-services';
import type { TpCountryFilterFunction, TpCountryNeeds } from '@noah-labs/core-services';
import { webConfigBrowser } from '../webConfigBrowser';

export function getFilterCountryForFeatureData(needs: TpCountryNeeds): TpCountryFilterFunction {
  return getfilterCountryForFeature({
    needs,
    prohibitedCountries: webConfigBrowser.regions.ProhibitedCountries,
    restrictedCountries: webConfigBrowser.regions.RestrictedRegions,
  });
}
