import type { SafeWrapper } from '@noah-labs/core-services';
import { SecretPhraseScene } from '../scenes';

export type PpSecretPhrase = {
  phrase: SafeWrapper<string> | undefined;
  successTo: string;
};

export function SecretPhrase({ phrase, successTo }: PpSecretPhrase): React.ReactElement {
  return <SecretPhraseScene phrase={phrase} successTo={successTo} />;
}
