import type { ListItemTextProps } from '@mui/material';
import { CardContent } from '@mui/material';
import { StructuredContent } from '../lists/atoms/StructuredContent';
import { ElevatedCard } from './ElevatedCard';

type PpContentCard = {
  ContentSlot?: React.ReactNode;
  Icon: React.ReactElement;
  endText?: ListItemTextProps;
  mainText?: ListItemTextProps;
};

export function ContentCard({
  ContentSlot,
  endText,
  Icon,
  mainText,
}: PpContentCard): React.ReactElement {
  return (
    <ElevatedCard>
      <CardContent>
        <StructuredContent
          endText={{
            primaryTypographyProps: {
              variant: 'paragraphBodySBold',
            },
            secondaryTypographyProps: {
              variant: 'paragraphBodyS',
            },
            ...endText,
          }}
          Icon={Icon}
          mainText={{
            primaryTypographyProps: {
              color: 'text.light',
              variant: 'paragraphBodyS',
            },
            ...mainText,
          }}
        />
        {ContentSlot}
      </CardContent>
    </ElevatedCard>
  );
}
