//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgWhatsapp } from '../icons/Whatsapp';

export function WhatsappIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgWhatsapp} data-qa="WhatsappIcon">
      <svg />
    </SvgIcon>
  );
}
