import { useCallback } from 'react';
import { webConfigBrowser } from '../../../webConfigBrowser';
import type { TpAnalyticsEvent, TpEventProperties } from '../data/events';
import { useAnalyticsContext } from './useAnalyticsContext';

type TpUseAnalytics = {
  track: <T extends TpAnalyticsEvent>(eventName: T, properties?: TpEventProperties[T]) => void;
};

export function useAnalytics(): TpUseAnalytics {
  const ajs = useAnalyticsContext();

  const track = useCallback(
    <T extends TpAnalyticsEvent>(eventName: T, properties?: TpEventProperties[T]): void => {
      if (!webConfigBrowser.analytics.track) {
        return;
      }
      // we have to 'override' the location data because if the track also caused a page navigation, the Segment SDK reports the new (wrong) location data for the click.
      void ajs.track(eventName, properties ?? {}, {
        context: {
          page: {
            path: window.location.pathname,
            search: window.location.search,
            url: window.location.href,
          },
        },
      });
    },
    [ajs]
  );

  return {
    track,
  };
}
