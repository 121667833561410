import type { SVGProps } from 'react';

export function SvgApplepayLight(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg {...props} fill="none" viewBox="0 0 166 106" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M138.778 9.296H26.743q-.7 0-1.4.003a58 58 0 0 0-1.179.018c-.857.023-1.72.074-2.567.226a8.7 8.7 0 0 0-2.44.804 8.2 8.2 0 0 0-3.586 3.587c-.397.78-.65 1.58-.803 2.44-.153.847-.204 1.71-.227 2.566q-.014.59-.018 1.179-.003.7-.002 1.4v62.928l.002 1.4q.003.59.018 1.179c.023.855.074 1.719.226 2.565.154.86.407 1.66.804 2.44.39.768.9 1.47 1.509 2.078.609.61 1.31 1.12 2.078 1.51.78.397 1.58.65 2.44.805.846.15 1.71.202 2.566.225q.59.014 1.18.016.699.005 1.4.004h112.034q.7.001 1.399-.004a66 66 0 0 0 1.18-.016c.855-.023 1.719-.074 2.567-.225a8.6 8.6 0 0 0 2.438-.806 8.2 8.2 0 0 0 3.587-3.587c.399-.78.651-1.58.804-2.44.153-.846.202-1.71.226-2.565q.015-.59.017-1.178.005-.701.004-1.401V21.519q.001-.7-.004-1.4a52 52 0 0 0-.017-1.179c-.024-.856-.073-1.72-.226-2.566a8.6 8.6 0 0 0-.804-2.44 8.22 8.22 0 0 0-3.587-3.587 8.7 8.7 0 0 0-2.438-.804c-.848-.152-1.712-.203-2.567-.226a59 59 0 0 0-1.18-.018q-.7-.004-1.399-.003"
        fill="#000"
      />
      <path
        d="m138.778 12.209 1.378.002q.56.003 1.122.017c.653.018 1.417.053 2.129.18a5.8 5.8 0 0 1 1.637.535 5.3 5.3 0 0 1 2.312 2.315c.252.494.42 1.01.531 1.634.127.705.163 1.47.18 2.128q.014.554.017 1.121.005.689.004 1.378v62.928c0 .46 0 .918-.004 1.387q-.002.556-.017 1.113c-.017.656-.053 1.422-.182 2.134a5.6 5.6 0 0 1-.531 1.629 5.27 5.27 0 0 1-2.315 2.314 5.7 5.7 0 0 1-1.626.532c-.727.13-1.522.165-2.122.182a63 63 0 0 1-1.137.015q-.688.005-1.376.004H26.725q-.678.001-1.368-.004a62 62 0 0 1-1.114-.015c-.613-.017-1.41-.052-2.13-.18a5.7 5.7 0 0 1-1.638-.537 5.2 5.2 0 0 1-1.337-.973 5.2 5.2 0 0 1-.972-1.338 5.7 5.7 0 0 1-.533-1.637c-.128-.711-.163-1.474-.181-2.125q-.014-.56-.017-1.117l-.002-1.095V21.23l.002-1.092q.003-.56.017-1.12c.017-.652.053-1.414.182-2.131a5.7 5.7 0 0 1 .533-1.634 5.27 5.27 0 0 1 2.313-2.311 5.7 5.7 0 0 1 1.632-.533c.713-.128 1.477-.163 2.132-.181q.56-.014 1.117-.017l1.382-.002z"
        fill="#fff"
      />
      <path
        d="M51.779 38.683c1.168-1.461 1.961-3.424 1.752-5.43-1.71.086-3.798 1.13-5.006 2.592-1.085 1.253-2.046 3.297-1.795 5.219 1.92.166 3.838-.96 5.049-2.38Zm1.731 2.756c-2.79-.166-5.16 1.583-6.492 1.583s-3.372-1.5-5.578-1.459c-2.87.042-5.534 1.665-6.991 4.247-2.996 5.165-.79 12.826 2.123 17.032 1.415 2.08 3.12 4.372 5.368 4.29 2.123-.084 2.955-1.375 5.536-1.375 2.579 0 3.328 1.375 5.576 1.333 2.33-.041 3.788-2.082 5.203-4.165 1.623-2.372 2.287-4.663 2.33-4.788-.043-.042-4.495-1.75-4.536-6.872-.042-4.288 3.495-6.328 3.662-6.455-1.998-2.954-5.12-3.287-6.201-3.371m24.281-5.804c6.062 0 10.282 4.178 10.282 10.261 0 6.105-4.307 10.304-10.434 10.304h-6.71v10.673h-4.85V35.635zm-6.863 16.496h5.564c4.221 0 6.624-2.273 6.624-6.213s-2.403-6.191-6.602-6.191h-5.586zM89.34 60.4c0-3.983 3.052-6.43 8.465-6.732l6.234-.368v-1.754c0-2.532-1.711-4.048-4.568-4.048-2.706 0-4.395 1.299-4.806 3.334H90.25c.26-4.113 3.767-7.144 9.395-7.144 5.52 0 9.049 2.923 9.049 7.49v15.695h-4.482v-3.745h-.107c-1.321 2.532-4.2 4.134-7.187 4.134-4.46 0-7.577-2.77-7.577-6.862Zm14.699-2.056v-1.797l-5.607.346c-2.793.195-4.373 1.43-4.373 3.377 0 1.992 1.645 3.29 4.156 3.29 3.269 0 5.824-2.25 5.824-5.216m8.884 16.906v-3.788c.346.086 1.125.086 1.515.086 2.165 0 3.334-.909 4.048-3.246 0-.044.411-1.386.411-1.408L110.672 44.1h5.065l5.758 18.53h.087l5.758-18.53h4.936l-8.53 23.964c-1.947 5.52-4.199 7.296-8.918 7.296-.39 0-1.559-.044-1.905-.109Z"
        fill="#000"
      />
    </svg>
  );
}
