import type { TpCountriesForSelect, TpCountryNeeds } from '@noah-labs/core-services';
import { useCountriesForSelect } from '@noah-labs/core-services';
import type { CountryCode } from '@noah-labs/shared-schema-gql';
import type { UseQueryResult } from 'react-query';
import { getFilterCountryForFeatureData } from '../utils/countries';

/**
 * This hook returns a list of countries based on specific features e.g CkoBuy, CkoSell -
 * and the user's default country validated against the returned list. It can be used for scenarios
 * where the user's country needs to be prefilled based on certain features.
 *
 * @param {CountryCode} userCountry - The user's country.
 * @param {TpCountryNeeds} needs - An array of feature requirements.
 */
export function useCountriesForFeature(
  userCountry: CountryCode | null | undefined,
  needs: TpCountryNeeds
): UseQueryResult<TpCountriesForSelect> {
  return useCountriesForSelect(userCountry, getFilterCountryForFeatureData(needs));
}
