import { NotificationType } from './types';

export const headerHeight = '59px';
export const notificationListDesktopHeight = '600px';
export const notificationListMobileHeight = '100vh';

export const transactionNotificationTypes: NotificationType[] = [
  NotificationType.FAILED_BUY,
  NotificationType.FAILED_EXTERNAL_DEPOSIT,
  NotificationType.FAILED_EXTERNAL_WITHDRAW,
  NotificationType.FAILED_INTERNAL_DEPOSIT,
  NotificationType.FAILED_INTERNAL_WITHDRAW,
  NotificationType.FAILED_SELL,
  NotificationType.SUCCESSFUL_BUY,
  NotificationType.SUCCESSFUL_EXTERNAL_DEPOSIT,
  NotificationType.SUCCESSFUL_EXTERNAL_WITHDRAW,
  NotificationType.SUCCESSFUL_INTERNAL_DEPOSIT,
  NotificationType.SUCCESSFUL_INTERNAL_WITHDRAW,
  NotificationType.SUCCESSFUL_SELL,
];
