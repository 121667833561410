import { useEffect, useState } from 'react';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { useOry } from '../data';
import type { AuthError } from '../types';
import { getOryFlowId } from '../utils';
import { getOryUiError } from './utils';

export function useOrySignInFlowError(): AuthError | undefined {
  const { ory } = useOry();

  const [flowError, setFlowError] = useState<AuthError | undefined>(undefined);

  useEffect(() => {
    const flowId = getOryFlowId();
    if (!flowId) {
      return;
    }

    (async (): Promise<void> => {
      const { data: flow } = await ory.getLoginFlow({
        id: flowId,
      });
      const uiError = getOryUiError(flow.ui.messages);
      setFlowError(uiError);
    })().catch((error) => {
      logger.error(error);
    });
  }, [ory]);

  return flowError;
}
