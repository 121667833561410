import type { ReactElement } from 'react';
import type { NotificationPayload } from '../types';
import {
  isNewReferralNotification,
  isQuestCompletedNotification,
  isTransactionNotification,
} from '../utils/checkNotificationType';
import { MarketingNotificationIcon } from './MarketingNotificationIcon';
import { NewReferralNotificationIcon } from './NewReferralNotificationIcon';
import { QuestCompletedNotificationIcon } from './QuestCompletedNotificationIcon';
import { TransactionNotificationIcon } from './TransactionNotificationIcon';

type PpNotificationIcon = {
  payload: NotificationPayload;
};

const defaultIcon = <MarketingNotificationIcon />;

export function NotificationIcon({ payload }: PpNotificationIcon): ReactElement {
  if (!payload.type) {
    return defaultIcon;
  }

  if (isTransactionNotification(payload)) {
    return <TransactionNotificationIcon payload={payload} />;
  }

  if (isNewReferralNotification(payload)) {
    return <NewReferralNotificationIcon />;
  }

  if (isQuestCompletedNotification(payload)) {
    return <QuestCompletedNotificationIcon badgeId={payload.badgeId} />;
  }

  return defaultIcon;
}
