import { useEffect } from 'react';
import { Balance } from '@noah-labs/core-web-ui/src/balance/Balance';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-currencies';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { useWalletParams } from '../../data';
import { useInAppReviewTrigger } from '../../hooks';
import { useCurrencyAmounts } from '../../hooks/useCurrencyAmounts';
import { routes } from '../../routes';
import { CompleteScene } from '../../scenes';
import type { StWithdrawRouter } from './WithdrawRouter';

export function Complete({
  resetState,
  state,
}: TpStateMachine<StWithdrawRouter>): React.ReactElement {
  const { CurrencyCode, params } = useWalletParams();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const { fiatCurrency } = useUserFiatCurrency();

  /**
   * End of the flow so reset the flow state machine
   */
  useEffect(() => (): void => resetState(), [resetState]);

  const enableInAppReviewTrigger = !!state.withdrawOrderId;
  useInAppReviewTrigger(enableInAppReviewTrigger);

  const Amounts = useCurrencyAmounts({
    cryptoAmount: state.cryptoAmount,
    cryptoCurrency,
    fiatAmount: state.fiatAmount,
    fiatCurrency,
    roundDown: true,
  });

  return (
    <CompleteScene
      BalanceSlot={<Balance {...Amounts} />}
      processingTimeMs={state.payeeData.processingTime}
      sceneTitle={routes.withdraw.complete.title}
      transactionUrl={generatePath(routes.account.base.path, params)}
    />
  );
}
