//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgFacebookSquare } from '../icons/FacebookSquare';

export function FacebookSquareIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgFacebookSquare} data-qa="FacebookSquareIcon">
      <svg />
    </SvgIcon>
  );
}
