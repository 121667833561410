import { forwardRef, useCallback } from 'react';
import { DialogContent, DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ElevatedCardListItem } from '@noah-labs/core-web-ui/src/cards/ElevatedCardListItem';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import {
  type TpDialogToggle,
  useToggleDialog,
} from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { List } from '@noah-labs/core-web-ui/src/lists/List';
import { ListSection } from '@noah-labs/core-web-ui/src/lists/ListSection';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { useHistory } from 'react-router-dom';
import { useWalletParams } from '../../../data';
import { routes } from '../../../routes';
import type { TpPaymentCard, TpPaymentMethodChange } from '../../../types';
import { TpPaymentMethod } from '../../../types';
import { ManagePaymentMethodsLink } from '../../buttons';
import { PaymentMethod } from '../PaymentMethod';

export type PpPaymentMethodsDialog = {
  applePayEnabled: boolean;
  googlePayEnabled: boolean;
  handleChange: ({ card, type }: TpPaymentMethodChange) => void;
  initialOpen?: boolean;
  onSubmitCardDetailsRedirect: string;
  paymentCards?: TpPaymentCard[];
};

export const PaymentMethodsDialog = forwardRef<TpDialogToggle, PpPaymentMethodsDialog>(
  (
    {
      applePayEnabled,
      googlePayEnabled,
      handleChange,
      initialOpen,
      onSubmitCardDetailsRedirect,
      paymentCards,
    },
    ref
  ) => {
    const theme = useTheme();
    const { open, toggle } = useToggleDialog({ initialOpen, ref });

    const buttonSx = {
      ':hover': {
        backgroundColor: 'inherit',
      },
      flexWrap: 'wrap',
      padding: theme.spacing(0, 2),
    };
    const { params } = useWalletParams();
    const history = useHistory();

    const onNewPaymentCard = useCallback((): void => {
      history.push(generatePath(routes.buy.payment.path, params), {
        successTo: onSubmitCardDetailsRedirect,
      });
      toggle();
    }, [history, onSubmitCardDetailsRedirect, params, toggle]);

    return (
      <LowDialog dataQa="payment-methods" open={open} onClose={toggle}>
        <DialogTitle>Choose a payment method</DialogTitle>
        <DialogContent>
          <List disablePadding data-qa="payment-methods" sx={{ margin: theme.spacing(2, 0, 3) }}>
            {applePayEnabled && (
              <ElevatedCardListItem
                buttonSx={buttonSx}
                dataQa="apple-pay"
                spacing={1}
                onClick={(): void => handleChange({ type: TpPaymentMethod.ApplePay })}
              >
                <PaymentMethod method={TpPaymentMethod.ApplePay} />
              </ElevatedCardListItem>
            )}

            {googlePayEnabled && (
              <ElevatedCardListItem
                buttonSx={buttonSx}
                dataQa="google-pay"
                spacing={1}
                onClick={(): void => handleChange({ type: TpPaymentMethod.GooglePay })}
              >
                <PaymentMethod method={TpPaymentMethod.GooglePay} />
              </ElevatedCardListItem>
            )}

            <ElevatedCardListItem
              buttonSx={buttonSx}
              dataQa="new-payment-card"
              spacing={1}
              onClick={onNewPaymentCard}
            >
              <PaymentMethod method={TpPaymentMethod.Card} paymentCard={undefined} />
            </ElevatedCardListItem>
          </List>

          {paymentCards && (
            <ListSection
              title="Saved methods"
              titleColor="text.dark"
              TitleEndSlot={<ManagePaymentMethodsLink />}
            >
              {paymentCards.map((card, i) => (
                <ElevatedCardListItem
                  key={card.id}
                  buttonSx={buttonSx}
                  dataQa={`saved-card-${i}`}
                  spacing={1}
                  onClick={(): void =>
                    handleChange({
                      card,
                      type: TpPaymentMethod.Card,
                    })
                  }
                >
                  <PaymentMethod method={TpPaymentMethod.Card} paymentCard={card} />
                </ElevatedCardListItem>
              ))}
            </ListSection>
          )}
        </DialogContent>
      </LowDialog>
    );
  }
);
PaymentMethodsDialog.displayName = 'PaymentMethodsDialog';
