import type { TpAmount } from '@noah-labs/shared-currencies/src/types';
import { safeBN } from '@noah-labs/shared-tools/src/browser/numbers';
import BigNumber from 'bignumber.js';

type TpCalculateFee = {
  amount: TpAmount;
  fee: TpAmount;
  feeMin?: TpAmount;
  promotion?: TpAmount; // added promotion as an optional argument
};

export function calculateFee({ amount, fee, feeMin, promotion }: TpCalculateFee): BigNumber {
  // calculate fee as (amount * fee) / 100
  let feeAmount = safeBN(amount).multipliedBy(safeBN(fee)).dividedBy(100);

  // if feeMin exists, ensure feeAmount is at least feeMin
  if (feeMin) {
    feeAmount = BigNumber.max(feeAmount, feeMin);
  }

  if (promotion) {
    feeAmount = feeAmount.minus(safeBN(promotion));
  }

  return BigNumber.max(feeAmount, 0);
}
