import { useCallback, useMemo } from 'react';
import { getAppType, isLocalStorageAvailable } from '../utils';

const AUTOCOMPLETE_EMAIL_KEY = 'core-auth/last-entered-email';

function isAutocompleteEnabled(): boolean {
  if (!isLocalStorageAvailable()) {
    return false;
  }
  const appType = getAppType();

  return appType === 'twa' || appType === 'ios-native';
}

export function useEmailForAutocomplete(): {
  autocompleteEmail: undefined | string;
  onUpdateAutocomplete: (email: string | null) => void;
} {
  const autocompleteEmail = useMemo(
    () =>
      isAutocompleteEnabled()
        ? localStorage.getItem(AUTOCOMPLETE_EMAIL_KEY) ?? undefined
        : undefined,
    []
  );

  const onUpdateAutocomplete = useCallback((email: string | null) => {
    if (email === null) {
      localStorage.removeItem(AUTOCOMPLETE_EMAIL_KEY);
      return;
    }
    if (!isAutocompleteEnabled()) {
      return;
    }
    localStorage.setItem(AUTOCOMPLETE_EMAIL_KEY, email);
  }, []);

  return { autocompleteEmail, onUpdateAutocomplete };
}
