import { useCallback } from 'react';
import { useOry } from '../data';
import { getOryCsrfToken } from '../utils';

type TpOrySignInCallback = (values: { email: string; password: string }) => Promise<void>;

export function useOrySignInCallback(): TpOrySignInCallback {
  const { ory, returnTo } = useOry();

  return useCallback(
    async (values): Promise<void> => {
      const { data: flow } = await ory.createBrowserLoginFlow({
        returnTo,
      });

      const csrfToken = getOryCsrfToken(flow.ui.nodes);

      await ory.updateLoginFlow({
        flow: flow.id,
        updateLoginFlowBody: {
          csrf_token: csrfToken,
          identifier: values.email,
          method: 'password',
          password: values.password,
        },
      });
    },
    [ory, returnTo]
  );
}
