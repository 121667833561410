import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Stack } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { InputField } from '@noah-labs/core-web-ui/src/forms/InputField';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeader } from '@noah-labs/core-web-ui/src/layout/AppHeader';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import type { ErrorOption, Resolver, UseFormSetError } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useAuthError } from '../hooks/useAuthError';
import { routes } from '../routes';

export type TpForgottenEmailForm = {
  email: string;
  root?: { serverError: void };
};

export type TpForgottenEmail = {
  root?: { serverError: void };
};

const forgottenEmailFormId = 'forgotten-email-form';

const defaults: TpForgottenEmailForm = { email: '' };

const schema = yup.object({
  email: yup.string().email().required('Email is a required field'),
});

export type PpForgottenPasswordEnterEmailScene = {
  email: string | null;
  error: ErrorOption | undefined;
  helpButton?: React.ReactNode;
  loading?: boolean;
  onRecover: (
    values: TpForgottenEmailForm,
    setError: UseFormSetError<TpForgottenEmailForm>
  ) => Promise<void>;
};

export function ForgottenPasswordEnterEmailScene({
  email,
  error,
  helpButton,
  loading,
  onRecover,
}: PpForgottenPasswordEnterEmailScene): React.ReactElement {
  const defaultValues = useMemo(
    () => ({
      email: email || defaults.email,
    }),
    [email]
  );

  const methods = useForm<TpForgottenEmailForm>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema) as Resolver<TpForgottenEmailForm>,
    values: defaultValues,
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = methods;

  useAuthError({ error: errors.root?.serverError });

  useEffect(() => {
    if (!error) {
      return;
    }
    setError('root.serverError', error);
  }, [setError, error]);

  return (
    <FormProvider {...methods}>
      <AppContainer
        wideFooter
        AppFooterSlot={
          <PrimaryButton
            color="primaryBrand"
            disabled={isSubmitting || loading}
            form={forgottenEmailFormId}
            loading={isSubmitting || loading}
            type="submit"
          >
            Continue
          </PrimaryButton>
        }
        AppHeaderSlot={<AppHeader backButton endIconsSlot={helpButton} />}
        dataQa="forgotten-email"
      >
        <Helmet>
          <title>Forgotten your password?</title>
        </Helmet>
        <SceneHeader>
          <SceneTitleLarge>Forgotten your password?</SceneTitleLarge>
          <SceneParagraph>
            We’ll send you an email with your recovery code if your email is registered in our
            system.
          </SceneParagraph>
        </SceneHeader>
        <SceneMain>
          <Stack justifyContent="center" spacing={4}>
            <form
              id={forgottenEmailFormId}
              onSubmit={handleSubmit((values) => onRecover(values, setError))}
            >
              <InputField
                fullWidth
                required
                dataQa="email"
                inputProps={{
                  'aria-label': 'Email',
                  inputMode: 'email',
                }}
                label="Email"
                name="email"
                placeholder=""
              />
            </form>
            <SceneParagraph>
              Remembered your password? <Link href={routes.signIn.path}>Sign in</Link>
            </SceneParagraph>
          </Stack>
        </SceneMain>
      </AppContainer>
    </FormProvider>
  );
}
