import React from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { LearnMoreButton } from '@noah-labs/core-web-ui/src/buttons/LearnMoreButton';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { webLinks } from '@noah-labs/shared-constants';

export type PpCreatePinReminderDialog = {
  blocking?: boolean;
  onSkip: () => void;
  setupTo: string;
};

export function CreatePinReminderDialog({
  blocking,
  onSkip,
  setupTo,
}: PpCreatePinReminderDialog): React.ReactElement {
  return (
    <React.Fragment>
      <DialogTitle>
        {blocking
          ? 'Set up your new security PIN to access this feature'
          : 'Set up your new security PIN'}
      </DialogTitle>
      <DialogContent sx={{ mb: 3 }}>
        <DialogContentText paragraph>
          We’ve introduced a security PIN to keep your account safe and strongly protected, this
          will be used for authorising transactions. Set up your PIN now or later in settings.
        </DialogContentText>
        <DialogContentText>
          <LearnMoreButton href={webLinks.blog.enclave} target="_blank">
            Learn more about your security PIN.
          </LearnMoreButton>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonGroup>
          <PrimaryButton color="primaryBrand" data-qa="setup-pin-button" href={setupTo}>
            Set up my PIN now
          </PrimaryButton>
          <PrimaryButton data-qa="skip-button" variant="text" onClick={(): void => onSkip()}>
            Skip and do later
          </PrimaryButton>
        </ButtonGroup>
      </DialogActions>
    </React.Fragment>
  );
}
