import React, { useCallback, useRef } from 'react';
import { Stack } from '@mui/material';
import { ChevronDownButton } from '@noah-labs/core-web-ui/src/buttons/ChevronDownButton';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { AppHeaderSubtitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderSubtitle';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-currencies';
import type { TpSlippage } from '@noah-labs/shared-currencies/src/calculations';
import { truncateAmount } from '@noah-labs/shared-currencies/src/truncateAmount';
import type { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { CurrencyDisplayType, CurrencyUnit, Network } from '@noah-labs/shared-schema-gql';
import BigNumber from 'bignumber.js';
import { useHistory } from 'react-router-dom';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { networkForEnv } from '../../../../utils/networks';
import { webConfigBrowser } from '../../../../webConfigBrowser';
import { useFiatCurrencyFromCode } from '../../../user/data/useFiatCurrencyFromCode';
import { AvailableBalanceWithPaymentMethodData } from '../../components';
import { CryptoPrice } from '../../components/atoms/CryptoPrice';
import { PaymentCurrencyDialog } from '../../components/dialogs/PaymentCurrencyDialog';
import { getManualRampCurrencyConfig, useCryptoPrice, useWalletParams } from '../../data';
import { useWithdrawalAllowance } from '../../hooks/useWithdrawalAllowance';
import { routes } from '../../routes';
import type { TpAmountForm } from '../../scenes';
import { EnterAmountScene } from '../../scenes';
import { TpPaymentMethod } from '../../types';
import { getCkoSellAmountSchema } from '../../utils/validation';
import type { StSellRouter } from './SellRouter';

const { minimumFiatAmount: amountPlaceholder, slippagePercentage } = webConfigBrowser.cko;

const slippage: TpSlippage = {
  slippage: slippagePercentage,
  type: 'negative',
};

type PpEnterAmount = TpStateMachine<StSellRouter>;

export function EnterAmount({ state, updateState }: PpEnterAmount): React.ReactElement {
  const { AccountType, CurrencyCode, params } = useWalletParams();
  const history = useHistory();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const sellFiatCurrency = useFiatCurrencyFromCode(state.fiatCurrencyCode);
  const { fiatCurrency } = useUserFiatCurrency();
  const paymentCurrencyDialog = useRef<TpDialogToggle>(null);

  const { data: cryptoData } = useCryptoPrice({
    cryptoCurrency,
    fiatCurrency: sellFiatCurrency,
    priceProvider: 'sell',
    slippage,
  });

  const onPaymentMethodClick = useCallback(() => {
    history.push(generatePath(routes.sell.payoutMethod.path, params), {
      successTo: generatePath(routes.sell.enterAmount.path, params),
    });
  }, [history, params]);

  const onSubmit = useCallback(
    ({ cryptoAmount, fiatAmount }: TpAmountForm) => {
      updateState({
        cryptoAmount: truncateAmount({
          amount: cryptoAmount,
          decimalPlaces: cryptoCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
        fiatAmount: truncateAmount({
          amount: fiatAmount,
          decimalPlaces: sellFiatCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
      });

      history.push(generatePath(routes.sell.confirm.path, params));
    },
    [history, params, cryptoCurrency, sellFiatCurrency.decimals, updateState]
  );

  const onCurrencyChange = useCallback(
    (currency: FiatCurrencyCode) => {
      updateState({
        fiatCurrencyCode: currency,
      });
    },
    [updateState]
  );

  const isBankPayoutMethod = state.payoutMethod === TpPaymentMethod.BankTransfer;
  const isCardPayoutMethod = state.payoutMethod === TpPaymentMethod.Card;

  const manualRampCurrencyConfig = getManualRampCurrencyConfig(state.fiatCurrencyCode);
  const currencyData = isBankPayoutMethod ? manualRampCurrencyConfig : undefined;

  const allowance = useWithdrawalAllowance({
    accountType: AccountType,
    cryptoCurrency,
    network: networkForEnv(Network.Bitcoin),
  });
  const schema = getCkoSellAmountSchema({
    allowance: allowance?.accountAllowanceCrypto,
    fiatCurrency: sellFiatCurrency,
    minFiatCurrencyAmount: currencyData?.MinimumFiatAmount,
  });

  return (
    <EnterAmountScene
      amountRequired
      disableSwitch
      amountPlaceholder={amountPlaceholder}
      backTo={routes.base.path}
      ContentSlot={
        <React.Fragment>
          <AvailableBalanceWithPaymentMethodData
            AccountType={AccountType}
            cryptoCurrency={cryptoCurrency}
            fiatCurrency={sellFiatCurrency}
            method={state.payoutMethod}
            paymentBank={state.selectedPayoutBank}
            paymentCard={state.selectedPayoutCard}
            onPaymentMethodClick={onPaymentMethodClick}
          />
          <PaymentCurrencyDialog
            ref={paymentCurrencyDialog}
            fiatCurrency={fiatCurrency.code}
            fiatPaymentCurrency={sellFiatCurrency.code}
            onCurrencyChange={onCurrencyChange}
          />
        </React.Fragment>
      }
      cryptoAmount={state.cryptoAmount}
      cryptoCurrency={cryptoCurrency}
      cryptoUnit={CurrencyUnit.Default}
      ctaButtonDisabled={!schema}
      ctaButtonLabel="Preview sell"
      fiatAmount={state.fiatAmount}
      fiatCurrency={sellFiatCurrency}
      htmlHeadTitle={routes.sell.enterAmount.title}
      PageTitleSlot={
        <Stack>
          <AppHeaderTitle>Sell bitcoin</AppHeaderTitle>
          <AppHeaderSubtitle>
            <CryptoPrice
              cryptoCurrencyCode={cryptoCurrency.code}
              fiatCurrency={sellFiatCurrency}
              price={cryptoData?.price}
            />
          </AppHeaderSubtitle>
        </Stack>
      }
      priceProvider="sell"
      primaryCurrency={CurrencyDisplayType.Fiat}
      slippage={slippage}
      SwitchCurrencySlot={
        isCardPayoutMethod ? (
          <ChevronDownButton onClick={(): void => paymentCurrencyDialog.current?.toggle()} />
        ) : undefined
      }
      yupSchema={schema}
      onBlurValues={updateState}
      onSubmit={onSubmit}
    />
  );
}
