import { useCallback } from 'react';
import {
  getOryFlowId,
  useOryRecoveryFlowError,
  useOryRecoverySendCodeFlowCallback,
  useOryRecoveryVerifyCodeFlowCallback,
} from '@noah-labs/fe-shared-data-access-auth';
import type { ErrorOption } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { orySessionKey } from '../hooks/useAuth';
import { ForgottenPasswordEnterCodeScene, ForgottenPasswordEnterEmailScene } from '../scenes';

type PpForgottenPassword = {
  helpButton?: React.ReactNode;
  returnTo: string;
};

export function ForgottenPassword({
  helpButton,
  returnTo,
}: PpForgottenPassword): React.ReactElement {
  const queryClient = useQueryClient();
  const history = useHistory();

  const onAfterRecovery = useCallback(
    async (flowId: string) => {
      await queryClient.invalidateQueries(orySessionKey);

      history.push({
        pathname: returnTo,
        search: new URLSearchParams({ flow: flowId }).toString(),
      });
    },
    [queryClient, history, returnTo]
  );

  const onVerify = useOryRecoveryVerifyCodeFlowCallback(onAfterRecovery);
  const recoverySendCodeFlowCallback = useOryRecoverySendCodeFlowCallback(returnTo);
  const flowId = getOryFlowId();

  const onRecover = useCallback(
    async (
      values: { email: string },
      setError: (name: `root.${string}`, error: ErrorOption) => void
    ) => {
      const recoveryFlowId = await recoverySendCodeFlowCallback(values, setError);
      if (recoveryFlowId === undefined) {
        return;
      }

      const params = { email: values.email, flow: recoveryFlowId };
      history.replace({
        search: new URLSearchParams(params).toString(),
      });
    },
    [history, recoverySendCodeFlowCallback]
  );

  const flowError = useOryRecoveryFlowError();

  const email = new URL(window.location.href).searchParams.get('email');

  if (flowId && email) {
    return (
      <ForgottenPasswordEnterCodeScene
        email={email}
        error={flowError}
        helpButton={helpButton}
        onResend={onRecover}
        onVerify={onVerify}
      />
    );
  }

  return (
    <ForgottenPasswordEnterEmailScene
      email={email}
      error={flowError}
      helpButton={helpButton}
      onRecover={onRecover}
    />
  );
}
