//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgLightningCircle } from '../icons/LightningCircle';

export function LightningCircleIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgLightningCircle} data-qa="LightningCircleIcon">
      <svg />
    </SvgIcon>
  );
}
