import type { SvgIconProps } from '@mui/material';
import { Switch } from '@mui/material';
import { DefaultListItemText } from '../lists/atoms/DefaultListItemText';
import { PrimaryMenuIcon, PrimaryMenuItem } from './PrimaryMenuItem';

export type PpMenuItemSwitch = {
  checked: boolean;
  dataQa?: string;
  disabled?: boolean;
  icon: React.FC<SvgIconProps>;
  label: string;
  onClick: () => void;
};

export function MenuItemSwitch({
  checked,
  dataQa,
  disabled,
  icon: Icon,
  label,
  onClick,
}: PpMenuItemSwitch): React.ReactElement {
  return (
    <PrimaryMenuItem chevron={false} dataQa={dataQa} disabled={disabled} onClick={onClick}>
      <PrimaryMenuIcon>
        <Icon />
      </PrimaryMenuIcon>
      <DefaultListItemText primary={label} />
      <Switch aria-checked={checked} checked={checked} />
    </PrimaryMenuItem>
  );
}
