import { css } from '@emotion/react';
import { BottomNavigation, Container, Stack } from '@mui/material';
import type { BottomNavigationProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type PpNavBar = Pick<BottomNavigationProps, 'children' | 'value' | 'onChange'>;

export function NavBar({ children, onChange, value }: PpNavBar): React.ReactElement {
  const theme = useTheme();
  const styles = {
    container: css`
      max-width: 372px;
    `,
    nav: css`
      height: auto;
      max-height: 55px;
      width: 100%;
      margin: 0 auto;
      padding: ${theme.spacing(0, 2.25)};
      justify-content: center;
    `,
  };

  return (
    <Container css={styles.container} maxWidth={false}>
      <BottomNavigation component="nav" css={styles.nav} value={value} onChange={onChange}>
        <Stack direction="row" spacing={3}>
          {children}
        </Stack>
      </BottomNavigation>
    </Container>
  );
}
