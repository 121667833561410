import { Feature } from '@noah-labs/shared-schema-gql';
import { useIsFeatureEnabled } from '../../user/hooks/useIsFeatureEnabled';

type PpUsePaymentMethodsEnabled = {
  applePayReady: boolean;
  googlePayReady: boolean;
};

type TpUsePaymentMethodsEnabled = {
  applePayEnabled: boolean;
  googlePayEnabled: boolean;
};

export function usePaymentMethodsEnabled({
  applePayReady,
  googlePayReady,
}: PpUsePaymentMethodsEnabled): TpUsePaymentMethodsEnabled {
  const isApplePayFeatureEnabled = useIsFeatureEnabled(Feature.ApplePay);
  const isGooglePayFeatureEnabled = useIsFeatureEnabled(Feature.GooglePay);
  const applePayEnabled = isApplePayFeatureEnabled && applePayReady;
  const googlePayEnabled = isGooglePayFeatureEnabled && googlePayReady;

  return {
    applePayEnabled,
    googlePayEnabled,
  };
}
