import { Typography } from '@mui/material';
import type { PpWC } from '../types';

export function AppHeaderSubtitle({ children }: PpWC): React.ReactElement {
  return (
    <Typography color="text.light" component="h2" textAlign="center" variant="paragraphBodyS">
      {children}
    </Typography>
  );
}
