/* eslint-disable react/no-unstable-nested-components */

import type { ReactElement } from 'react';
import { KycApprovalStatus } from '@noah-labs/shared-schema-gql';
import type { RouteProps } from 'react-router-dom';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../../auth/hooks/useAuth';
import { useUserInit } from '../../user/data/useUserInit';
import { useUserAvailableFiatBalance } from '../../wallet/data/useUserAvailableFiatBalance';
import type { KycReminderOrigin } from '../controllers/Reminder';
import { routes } from '../routes';

type PpNeedsKyc = RouteProps & {
  bypassIfUserHasBalance?: boolean;
  origin?: KycReminderOrigin;
};

export function NeedsKycRouter({
  bypassIfUserHasBalance,
  origin,
  ...props
}: PpNeedsKyc): ReactElement | null {
  const user = useUserInit();
  const { data: auth } = useAuth();
  const { availableBalance, isFetched: isBalanceFetched } = useUserAvailableFiatBalance();
  const userHasBalance = Number(availableBalance) > 0;

  if (!user.data?.userProfile || !auth || !isBalanceFetched) {
    return null;
  }

  const hasKyc =
    user.data.userProfile.KycVerification.ApprovalStatus === KycApprovalStatus.Approved;

  if (!hasKyc && bypassIfUserHasBalance && userHasBalance) {
    return <Route {...props} />;
  }

  const reminderPath = origin
    ? {
        pathname: routes.reminder.path,
        search: new URLSearchParams({ origin }).toString(),
      }
    : routes.reminder.path;

  if (!hasKyc) {
    return <Route component={(): React.ReactElement => <Redirect to={reminderPath} />} />;
  }

  return <Route {...props} />;
}
