import { useCallback, useState } from 'react';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { getQuestBadgeSrc } from '../../data';

type PpQuestBadge = {
  badgeId?: string | undefined | null;
  imageCss?: SerializedStyles | SerializedStyles[];
};

export function QuestBadge({ badgeId, imageCss }: PpQuestBadge): React.ReactElement {
  const [hasError, setHasError] = useState(false);

  const handleError = useCallback(() => {
    if (hasError) {
      return;
    }
    setHasError(true);
  }, [hasError]);

  const baseStyle = css`
    display: block;
    object-fit: contain;
  `;
  const imgStyle =
    imageCss ||
    css`
      width: 60px;
      height: 80px;
    `;

  if (!badgeId || hasError) {
    return <div css={[baseStyle, imgStyle]} />;
  }

  return (
    <img
      alt={badgeId}
      css={[baseStyle, imgStyle]}
      src={getQuestBadgeSrc(badgeId)}
      onError={handleError}
    />
  );
}
