import type { UiNode } from '@ory/client';
import type { InputUiNode } from '../types';

function isCodeUiNode(node: UiNode): node is InputUiNode<string> {
  return node.group === 'code' && 'name' in node.attributes && node.attributes.name === 'code';
}

export function getOryVerificationCode(nodes: UiNode[]): string | undefined {
  const verificationCodeNode = nodes.find(isCodeUiNode);
  return verificationCodeNode?.attributes.value;
}
