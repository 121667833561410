import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { NoahHologram } from '../images';
import { AppContainer } from '../layout/AppContainer';
import { AppHeader } from '../layout/AppHeader';
import { SceneImage } from '../scene/SceneImage';
import { SceneMain } from '../scene/SceneMain';
import { SceneParagraph, SceneSubTitle, SceneTitleLarge } from '../scene/Typography';

export type PpDisabledPage = {
  ActionButton?: React.ReactElement;
  AppHeaderSlot?: React.ReactElement;
  description: string | React.ReactElement;
  title: string;
};

const DefaultAppHeader = <AppHeader backButton />;

// A generic disabled feature page
export function DisabledPage({
  ActionButton,
  AppHeaderSlot = DefaultAppHeader,
  description,
  title,
}: PpDisabledPage): React.ReactElement {
  const history = useHistory();

  return (
    <AppContainer
      AppFooterSlot={
        ActionButton || (
          <PrimaryButton
            color="primaryBrand"
            data-qa="go-back"
            onClick={(): void => history.goBack()}
          >
            Back
          </PrimaryButton>
        )
      }
      AppHeaderSlot={AppHeaderSlot}
      dataQa="disabled"
      mainSxProps={{ textAlign: 'center' }}
    >
      <Helmet>
        <title>Feature disabled</title>
      </Helmet>
      <SceneMain>
        <SceneImage alt="" src={NoahHologram} />
        <SceneTitleLarge>Sorry!</SceneTitleLarge>
        <SceneSubTitle>{title}</SceneSubTitle>
        <SceneParagraph>{description}</SceneParagraph>
      </SceneMain>
    </AppContainer>
  );
}
