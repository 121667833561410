import { Grid } from '@mui/material';
import type { PpWC } from '../types';

export function FormGrid({ children }: PpWC): React.ReactElement {
  return (
    <Grid container spacing={2.5}>
      {children}
    </Grid>
  );
}
