import type { TpMoonpayTransactionStatus } from '@noah-labs/core-services';
import { moonpayTxReceiptUrl, useMoonpayTransactionQuery } from '@noah-labs/core-services';
import { Balance } from '@noah-labs/core-web-ui/src/balance/Balance';
import { useNavigation } from '@noah-labs/core-web-ui/src/hooks/useNavigation';
import { useSearchParams } from '@noah-labs/core-web-ui/src/hooks/useSearchParams';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { cryptoCurrencyFromCode, fiatCurrencyFromString } from '@noah-labs/fe-shared-ui-currencies';
import { AvatarWithTransactionStatus } from '../../components/transactions/AvatarWithTransactionStatus';
import { useWalletParams } from '../../data';
import { useCurrencyAmounts } from '../../hooks/useCurrencyAmounts';
import { CompleteMoonpayScene } from '../../scenes';
import { TpTransactionType } from '../../types';
import { AlertBuy } from './AlertBuy';
import { getAvatarStatus } from './getAvatarStatus';
import { getCta } from './getCta';
import { getTitles } from './getTitles';

export function CompleteWithMoonpay(): React.ReactElement {
  const { exitTo } = useNavigation();
  const searchParams = useSearchParams();
  const transactionId = searchParams?.get('transactionId') || '';

  const { data: transactionData } = useMoonpayTransactionQuery(transactionId);

  // Use the latest status from the transaction api call, else use the status given in the querystring
  const spStatus = searchParams?.get('transactionStatus') || undefined;
  const transactionStatus =
    transactionData?.status || (spStatus as TpMoonpayTransactionStatus | undefined);

  const { CurrencyCode } = useWalletParams();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const fiatCurrency = fiatCurrencyFromString(transactionData?.fiatCurrencyCode);
  const Amounts = useCurrencyAmounts({
    cryptoAmount: transactionData?.quoteCurrencyAmount,
    cryptoCurrency,
    fiatAmount: transactionData?.totalBaseCurrencyAmount,
    fiatCurrency,
  });

  const { pageTitle, subtitle } = getTitles(transactionStatus);

  if (!transactionData) {
    return <LoadingPage />;
  }

  return (
    <CompleteMoonpayScene
      AlertSlot={
        <AlertBuy
          moonpayUrl={moonpayTxReceiptUrl('buy', transactionData.id)}
          status={transactionStatus}
        />
      }
      AvatarSlot={
        cryptoCurrency.Icon ? (
          <AvatarWithTransactionStatus
            CurrencyIcon={cryptoCurrency.Icon}
            transactionStatus={getAvatarStatus(transactionData.status)}
            transactionType={TpTransactionType.Buy}
          />
        ) : undefined
      }
      BalanceSlot={<Balance {...Amounts} />}
      CtaSlot={getCta(transactionStatus, exitTo, moonpayTxReceiptUrl('buy', transactionData.id))}
      sceneSubtitle={subtitle}
      sceneTitle={pageTitle}
      transactionId={transactionData.id}
    />
  );
}
