import {
  getLnSendLimitAndUsage,
  getWithdrawalDayLimitAndUsage,
  unlimited,
} from '@noah-labs/core-services';
import { Network, PolicyPeriod } from '@noah-labs/shared-schema-gql';
import { networkForEnv } from '../../../utils/networks';
import { useUserLimitsQuery } from '../data/user.generated';
import type { PpAccountLimitsScene } from '../scenes/AccountLimits';
import { AccountLimitsScene } from '../scenes/AccountLimits';

export function AccountLimits(): React.ReactElement {
  const { data: limitsAndUsageData } = useUserLimitsQuery(undefined, {
    select: (data): PpAccountLimitsScene => ({
      accountDepositDay: unlimited,
      accountWithdrawDay: getWithdrawalDayLimitAndUsage(data.userLimit.Limits),
      btcWithdrawTxsDay: getWithdrawalDayLimitAndUsage(
        data.userLimit.Limits,
        networkForEnv(Network.Bitcoin)
      ).count,
      ethWithdrawTxsDay: getWithdrawalDayLimitAndUsage(
        data.userLimit.Limits,
        networkForEnv(Network.Ethereum)
      ).count,
      lnReceiveDay: unlimited,
      lnReceiveSingle: unlimited,
      lnSendDay: getLnSendLimitAndUsage(data.userLimit.Limits, PolicyPeriod.DAY),
      lnSendSingle: getLnSendLimitAndUsage(data.userLimit.Limits, PolicyPeriod.SINGLE),
    }),
  });

  const props = limitsAndUsageData || {
    accountDepositDay: undefined,
    accountWithdrawDay: undefined,
    btcWithdrawTxsDay: undefined,
    ethWithdrawTxsDay: undefined,
    lnReceiveDay: undefined,
    lnReceiveSingle: undefined,
    lnSendDay: undefined,
    lnSendSingle: undefined,
  };

  return <AccountLimitsScene {...props} />;
}
