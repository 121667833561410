import type { TpAmount } from '@noah-labs/shared-currencies/src/types';
import { safeBN } from '@noah-labs/shared-tools/src/browser/numbers';

const thresholdFeeAmount = '0.01';

export function hasFeeIncreased(
  latestFeeFiatAmount: TpAmount,
  submittedFeeFiatAmount: TpAmount
): boolean {
  const feeDiff = safeBN(latestFeeFiatAmount).minus(safeBN(submittedFeeFiatAmount));
  return feeDiff.isGreaterThan(thresholdFeeAmount);
}
