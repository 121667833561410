import { Box, Stack, Typography } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { useNavigation } from '@noah-labs/core-web-ui/src/hooks/useNavigation';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import type { TpAddressData } from '@noah-labs/core-web-ui/src/tools/parseAddressData';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import { TransactionSummary } from '../components';
import type { TpTransactionDataQa } from '../types';

export type PpCompleteMoonpayScene = {
  AlertSlot?: React.ReactNode;
  AvatarSlot?: React.ReactElement;
  BalanceSlot: React.ReactElement;
  CtaSlot?: React.ReactElement;
  addressData?: TpAddressData;
  dataQa?: TpTransactionDataQa;
  sceneSubtitle?: React.ReactNode;
  sceneTitle: string;
  transactionId: string;
};

export function CompleteMoonpayScene({
  addressData,
  AlertSlot,
  AvatarSlot,
  BalanceSlot,
  CtaSlot,
  dataQa,
  sceneSubtitle,
  sceneTitle,
  transactionId,
}: PpCompleteMoonpayScene): React.ReactElement {
  const { exitTo } = useNavigation();
  return (
    <AppContainer
      AppFooterSlot={
        CtaSlot ?? (
          <PrimaryButton data-qa="done-button" href={exitTo}>
            Done
          </PrimaryButton>
        )
      }
      AppHeaderSlot={<AppHeaderData exitButton helpButton />}
      dataQa="complete"
    >
      <Helmet>
        <title>{sceneTitle}</title>
      </Helmet>
      <SceneMain>
        <Stack justifyContent="center" justifyItems="center" textAlign="center">
          <Typography color="text.dark" variant="h5">
            {sceneTitle}
          </Typography>
          {typeof sceneSubtitle === 'string' ? (
            <Box mt={2}>
              <Typography variant="paragraphBodyS">{sceneSubtitle}</Typography>
            </Box>
          ) : (
            sceneSubtitle
          )}
        </Stack>
        <Stack marginTop={4} spacing={2}>
          <TransactionSummary
            AvatarSlot={AvatarSlot}
            dataQa={dataQa}
            description={addressData?.description}
            expiryTime={addressData?.expiryTime}
            transactionId={transactionId}
          >
            {BalanceSlot}
          </TransactionSummary>
          {AlertSlot}
        </Stack>
      </SceneMain>
    </AppContainer>
  );
}
