import type { SxProps, Theme } from '@mui/material';
import { AvatarIconButton } from '@noah-labs/core-web-ui/src/buttons/AvatarIconButton';
import { Feature } from '@noah-labs/shared-schema-gql';
import { useUserInit } from '../../modules/user/data/useUserInit';
import { useRestrictedFeature } from '../../modules/user/hooks/useRestrictedFeature';
import { useUserInitials } from '../../modules/user/hooks/useUserInitial';
import { routes } from '../../modules/user/routes';

type PpAvatarIconButtonData = {
  showLnAddress?: boolean;
  sx: SxProps<Theme>;
};

export function AvatarIconButtonData({
  showLnAddress,
  sx,
}: PpAvatarIconButtonData): React.ReactElement {
  const { data, lnAddress } = useUserInit();

  const userInitials = useUserInitials();
  const displayLnAddress = useRestrictedFeature({
    needs: [!!showLnAddress, [Feature.LnInvoice, Feature.LnPayment]],
  });

  return (
    <AvatarIconButton
      avatarContent={userInitials}
      href={routes.settings.base.path}
      showLnAddress={displayLnAddress}
      sx={sx}
      userLnAddress={lnAddress}
      usernameDisplay={data?.userProfile.UsernameDisplay}
    />
  );
}
