import React, { Fragment } from 'react';
import { ButtonBase, Stack } from '@mui/material';
import type { TpCryptoCurrencyUI, TpFiatCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';
import type { TpAmount, TpFiatCurrency } from '@noah-labs/shared-currencies/src/types';
import type { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { CurrencyDisplayType } from '@noah-labs/shared-schema-gql';
import { CurrencySwitchButton } from '../buttons/CurrencySwitchButton';
import { CryptoAmount } from '../numbers/CryptoAmount';
import { FiatAmount } from '../numbers/FiatAmount';

export type PpAmountFieldSecondary = {
  SwitchCurrencySlot?: React.ReactElement;
  amount: TpAmount;
  cryptoUnit: CurrencyUnit;
  currency: TpCryptoCurrencyUI | TpFiatCurrency;
  disableSwitch?: boolean;
  helperText?: React.ReactNode;
  onCurrencySwitch?: () => void;
  primaryCurrencyType: CurrencyDisplayType | null | undefined;
};
export function AmountFieldSecondary({
  amount,
  cryptoUnit,
  currency,
  disableSwitch,
  helperText,
  onCurrencySwitch,
  primaryCurrencyType,
  SwitchCurrencySlot,
}: PpAmountFieldSecondary): React.ReactElement {
  return (
    <Fragment>
      <Stack alignItems="center" component="span" data-qa="secondary-amount">
        <ButtonBase disabled={disableSwitch} onClick={onCurrencySwitch}>
          {primaryCurrencyType === CurrencyDisplayType.Fiat ? (
            <CryptoAmount
              roundDown
              amount={amount}
              currency={currency as TpCryptoCurrencyUI}
              currencyUnit={cryptoUnit}
            />
          ) : (
            <FiatAmount roundDown amount={amount} currency={currency as TpFiatCurrencyUI} />
          )}
        </ButtonBase>
        {helperText}
      </Stack>
      {!disableSwitch && <CurrencySwitchButton onClick={onCurrencySwitch} />}
      {disableSwitch && SwitchCurrencySlot}
    </Fragment>
  );
}
