import { useSearchParams } from '@noah-labs/core-web-ui/src/hooks/useSearchParams';
import { ErrorPage } from '@noah-labs/core-web-ui/src/utility/ErrorPage';
import { useLogOryFlowError } from '@noah-labs/fe-shared-data-access-auth';

export function AuthError(): React.ReactElement {
  const params = useSearchParams();
  const id = params?.get('id');
  useLogOryFlowError(id);

  return <ErrorPage message="Something went wrong" />;
}
