import { useRadioGroup } from '@mui/material/RadioGroup';
import type { PpRadioButtonUI } from './RadioButtonUI';
import { RadioButtonUI } from './RadioButtonUI';

type PpRadioButton = Omit<PpRadioButtonUI, 'checked'>;

export function RadioButton(props: PpRadioButton): React.ReactElement {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    // eslint-disable-next-line react/destructuring-assignment
    checked = radioGroup.value === props.value;
  }

  return <RadioButtonUI checked={checked} {...props} />;
}
