import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { useUserInit } from '../data/useUserInit';
import { useUserError } from '../hooks/useUserError';
import { FullNameScene } from '../scenes';

export function FullName(): React.ReactElement {
  const { data, error, isLoading } = useUserInit();

  const { ApiErrorScene } = useUserError(error);

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <FullNameScene
      firstName={data?.userProfile.PersonalName?.FirstName || ''}
      lastName={data?.userProfile.PersonalName?.LastName || ''}
      middleName={data?.userProfile.PersonalName?.MiddleName || ''}
    />
  );
}
