import { AlertLink } from '@noah-labs/core-web-ui/src/alerts/AlertLink';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { routes as walletRoutes } from '../../modules/wallet/routes';
import { getDefaults } from '../../utils';

const { accountType, cryptoCurrency } = getDefaults();

type TpBuyBitcoinLink = {
  text?: string;
};

export function BuyBitcoinLink({ text = 'Buy bitcoin' }: TpBuyBitcoinLink): React.ReactElement {
  return (
    <AlertLink
      aria-label="buy-bitcoin"
      href={generatePath(walletRoutes.buy.base.path, {
        accountType,
        currencyCode: cryptoCurrency.code,
      })}
    >
      {text}
    </AlertLink>
  );
}
