import { useEffect, useState } from 'react';
import { countryFromCode } from '@noah-labs/core-services';
import type { Country, CountryCode } from '@noah-labs/shared-schema-gql';

export function useCountryFromCode(code: CountryCode | null | undefined): Country | undefined {
  const [country, setCountry] = useState<Country>();

  useEffect((): void => {
    async function getCountryFromCode(): Promise<void> {
      if (!code) {
        return;
      }
      const countryObj = await countryFromCode(code);
      if (!countryObj) {
        return;
      }
      setCountry(countryObj);
    }
    void getCountryFromCode();
  }, [code]);

  return country;
}
