import { AuthGroups } from '@noah-labs/shared-schema-gql';

const base = '/apps';

const groups = new Set([AuthGroups.personal_basic]);

export const routes = {
  base: {
    groups,
    path: base,
    title: 'Connected apps',
  },
  confirm: {
    groups,
    path: `${base}/connect/:botSource/:botSourceId/:otp`,
    title: 'Confirm connection to NOAH',
  },
  confirmed: {
    groups,
    path: `${base}/confirmed`,
    title: 'Connection confirmed to NOAH',
  },
};
