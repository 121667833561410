import { Fragment, useCallback } from 'react';
import { HeaderIconButton } from '@noah-labs/core-web-ui/src/buttons/HeaderIconButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeader } from '@noah-labs/core-web-ui/src/layout/AppHeader';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { CloseIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CloseIcon';
import { useHistory } from 'react-router-dom';
import { HelpIconButtonData } from '../../../../components/buttons';
import { TransactionDetails } from '../transactions/TransactionDetails';
import type { PpTransactionDetails } from '../transactions/TransactionDetails';

export type PpTransactionDialog = PpTransactionDetails & {
  backTo?: string;
  open?: boolean;
};

export function TransactionDialog({
  backTo,
  open = false,
  ...txProps
}: PpTransactionDialog): React.ReactElement {
  const history = useHistory();

  const handleClose = useCallback(() => {
    if (typeof backTo === 'string') {
      history.push(backTo);
    } else {
      history.goBack();
    }
  }, [history, backTo]);

  return (
    <LowDialog fullScreen keepMounted open={open}>
      <AppContainer
        AppHeaderSlot={
          <AppHeader
            disableFade
            backgroundColor="transparent"
            endIconsSlot={
              <Fragment>
                <HelpIconButtonData />
                <HeaderIconButton
                  aria-label="exit"
                  data-qa="exit-button"
                  edge="end"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </HeaderIconButton>
              </Fragment>
            }
          >
            <AppHeaderTitle>{txProps.created}</AppHeaderTitle>
          </AppHeader>
        }
        dataQa="transaction-detail"
      >
        <SceneMain>
          <TransactionDetails {...txProps} />
        </SceneMain>
      </AppContainer>
    </LowDialog>
  );
}
