import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneIcon } from '@noah-labs/core-web-ui/src/scene/SceneIcon';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { CircleTickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CircleTickIcon';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';

export type PpConfirmedScene = {
  backTo: string;
};

export function ConfirmedScene({ backTo }: PpConfirmedScene): React.ReactElement {
  return (
    <AppContainer
      AppFooterSlot={
        <PrimaryButton color="primary" href={backTo}>
          Close
        </PrimaryButton>
      }
      AppHeaderSlot={<AppHeaderData exitButton helpButton />}
    >
      <Helmet>
        <title>Discord connection confirmed</title>
      </Helmet>
      <SceneMain>
        <SceneIcon>
          <CircleTickIcon color="success" />
        </SceneIcon>
        <SceneTitleLarge>Connection confirmed</SceneTitleLarge>
        <SceneParagraph>Please go back to Discord to finish the connection process.</SceneParagraph>
        <SceneParagraph>You will now be able to use NOAH on Discord.</SceneParagraph>
      </SceneMain>
    </AppContainer>
  );
}
