//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgArrowRightThickVariant } from '../icons/ArrowRightThickVariant';

export function ArrowRightThickVariantIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgArrowRightThickVariant} data-qa="ArrowRightThickVariantIcon">
      <svg />
    </SvgIcon>
  );
}
