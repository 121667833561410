import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { useToggleDialog } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';

type PpProcessingTimeDialog = {
  action: 'buying' | 'selling';
};

export const ProcessingTimeDialog = forwardRef<TpDialogToggle, PpProcessingTimeDialog>(
  ({ action }, ref): JSX.Element => {
    const { open, toggle } = useToggleDialog({ ref });

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle>Processing time</DialogTitle>
        <DialogContent>
          <DialogContentText paragraph>
            When {action} Bitcoin through our crypto wallet app, it typically takes around 10
            minutes for the transaction to be confirmed on the Bitcoin blockchain.
          </DialogContentText>

          <DialogContentText paragraph>
            After confirmation, the funds will be transferred to your linked bank account, which may
            take a few minutes to a few business days, depending on your bank’s processing
            procedures.
          </DialogContentText>

          <DialogContentText>
            We are committed to keeping you informed throughout the process and aim to provide a
            smooth and timely transfer of funds from your Bitcoin sale to your personal bank
            account.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PrimaryButton color="primaryBrand" onClick={toggle}>
            Got It
          </PrimaryButton>
        </DialogActions>
      </LowDialog>
    );
  }
);

ProcessingTimeDialog.displayName = ProcessingTimeDialog.name;
