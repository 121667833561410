import { transactionNotificationTypes } from '../constants';
import type {
  NotificationPayload,
  QuestCompletedNotification,
  TransactionNotification,
} from '../types';
import { NotificationType } from '../types';

export function isTransactionNotification(
  payload: NotificationPayload
): payload is TransactionNotification {
  return Boolean(payload.type && transactionNotificationTypes.includes(payload.type));
}

export function isNewReferralNotification(payload: NotificationPayload): boolean {
  return payload.type === NotificationType.NEW_REFERRAL;
}

export function isQuestCompletedNotification(
  payload: NotificationPayload
): payload is QuestCompletedNotification {
  return payload.type === NotificationType.QUEST_COMPLETED;
}
