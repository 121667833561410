import { useMemo } from 'react';
import { TpFFUI } from '@noah-labs/core-web-ui/src/types';
import type { CurrencyCode as TpCurrencyCode } from '@noah-labs/shared-schema-gql';
import { Feature } from '@noah-labs/shared-schema-gql';
import { useFeature } from '../../user/hooks/useFeature';
import { TpTransactionType } from '../types';

export type TpUseAvailableCurrencyActions = {
  [key in TpTransactionType]: boolean | undefined;
};

export function useAvailableCurrencyActions(
  currencyCode: TpCurrencyCode
): TpUseAvailableCurrencyActions {
  const ckoBuyRoutesFeature = useFeature(TpFFUI.CkoBuyRoutes);
  const ckoSellRoutesFeature = useFeature(TpFFUI.CkoSellRoutes);
  const depositRoutesFeature = useFeature(TpFFUI.DepositRoutes);

  const withdrawFeature = useFeature(Feature.Withdraw);

  const availableCurrencyActions = useMemo(
    () => ({
      [TpTransactionType.Buy]: ckoBuyRoutesFeature?.Enabled,
      [TpTransactionType.Deposit]: depositRoutesFeature?.Enabled,
      [TpTransactionType.Sell]: ckoSellRoutesFeature?.Enabled,
      [TpTransactionType.Withdraw]:
        withdrawFeature?.Enabled && withdrawFeature.Currencies?.includes(currencyCode),
    }),
    [
      ckoBuyRoutesFeature?.Enabled,
      ckoSellRoutesFeature?.Enabled,
      withdrawFeature,
      depositRoutesFeature,
      currencyCode,
    ]
  );

  return availableCurrencyActions;
}
