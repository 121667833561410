import { useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { InputField } from '@noah-labs/core-web-ui/src/forms/InputField';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeader } from '@noah-labs/core-web-ui/src/layout/AppHeader';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import type { ErrorOption, Resolver, UseFormSetError } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ResendVerificationCode } from '../components/ResendVerificationCode';
import { useAuthError } from '../hooks/useAuthError';
import { routes } from '../routes';

export type TpVerifyAccountForm = {
  root?: { serverError: void };
  verificationCode: string;
};

export type TpResendCode = {
  root?: { serverError: void };
};

const verifyAccountFormId = 'verify-account-form';

const defaultValues: TpVerifyAccountForm = { verificationCode: '' };

const schema = yup.object({
  verificationCode: yup.string().required('Verification code is a required field'),
});

export type PpVerifyEmailEnterCodeScene = {
  code: string | null;
  email: string;
  error: ErrorOption | undefined;
  helpButton?: React.ReactNode;
  loading: boolean;
  onResend: (email: string, setError: UseFormSetError<TpResendCode>) => Promise<void>;
  onVerify: (
    values: TpVerifyAccountForm,
    setError: UseFormSetError<TpVerifyAccountForm>
  ) => Promise<void>;
};

export function VerifyEmailEnterCodeScene({
  code,
  email,
  error,
  helpButton,
  loading,
  onResend,
  onVerify,
}: PpVerifyEmailEnterCodeScene): React.ReactElement {
  const [isResending, setIsResending] = useState(false);

  const values = useMemo(
    () => ({
      verificationCode: code || defaultValues.verificationCode,
    }),
    [code]
  );

  const methods = useForm<TpVerifyAccountForm>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema) as Resolver<TpVerifyAccountForm>,
    values,
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = methods;

  useAuthError({ error: errors.root?.serverError });

  useEffect(() => {
    if (!error) {
      return;
    }
    setError('root.serverError', error);
  }, [setError, error]);

  const onResendCallback = useCallback(async () => {
    try {
      setIsResending(true);
      await onResend(email, setError);
    } finally {
      setIsResending(false);
    }
  }, [email, onResend, setError]);

  return (
    <FormProvider {...methods}>
      <AppContainer
        wideFooter
        AppFooterSlot={
          <PrimaryButton
            color="primaryBrand"
            disabled={isSubmitting || loading}
            form={verifyAccountFormId}
            loading={isSubmitting || loading}
            type="submit"
          >
            Continue
          </PrimaryButton>
        }
        AppHeaderSlot={<AppHeader backTo={routes.signOut.path} endIconsSlot={helpButton} />}
        dataQa="verify-account"
      >
        <Helmet>
          <title>Verify your email</title>
        </Helmet>
        <SceneHeader>
          <SceneTitleLarge>Verify your email</SceneTitleLarge>
          <SceneParagraph>
            To verify your email, we’ve sent a verification code to {email}.
          </SceneParagraph>
        </SceneHeader>
        <SceneMain>
          <Stack justifyContent="center" spacing={4}>
            <form id={verifyAccountFormId} onSubmit={handleSubmit((v) => onVerify(v, setError))}>
              <InputField
                fullWidth
                required
                dataQa="verification-code"
                inputProps={{
                  'aria-label': 'Verification code',
                  inputMode: 'numeric',
                }}
                label="Verification code"
                name="verificationCode"
                placeholder=""
              />
            </form>
            <ResendVerificationCode loading={isResending} onResend={onResendCallback} />
          </Stack>
        </SceneMain>
      </AppContainer>
    </FormProvider>
  );
}
