import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneImage } from '@noah-labs/core-web-ui/src/scene/SceneImage';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import DiscordNoahImage from '../../../images/discord-noah-connect.svg';

export type PpConfirmScene = {
  discordConnectCreated: string;
  discordUsername: string;
  isCancelling: boolean;
  isConfirming: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export function ConfirmScene({
  discordConnectCreated,
  discordUsername,
  isCancelling,
  isConfirming,
  onCancel,
  onConfirm,
}: PpConfirmScene): React.ReactElement {
  return (
    <AppContainer
      AppFooterSlot={
        <ButtonGroup>
          <PrimaryButton color="primaryBrand" loading={isConfirming} onClick={onConfirm}>
            Confirm
          </PrimaryButton>
          <PrimaryButton loading={isCancelling} variant="text" onClick={onCancel}>
            Cancel
          </PrimaryButton>
        </ButtonGroup>
      }
      AppHeaderSlot={<AppHeaderData exitButton helpButton />}
      mainSxProps={{ textAlign: 'center' }}
    >
      <Helmet>
        <title>Confirm link to Discord account</title>
      </Helmet>
      <SceneMain>
        <SceneImage alt="" src={DiscordNoahImage} />
        <SceneTitleLarge>Confirm connection</SceneTitleLarge>
        <SceneParagraph>
          You are about to connect your NOAH account to Discord requested by{' '}
          <strong>{discordUsername}</strong> on{' '}
          <strong>{dayjs(discordConnectCreated).toString()}</strong>.
        </SceneParagraph>
      </SceneMain>
    </AppContainer>
  );
}
