import { useCallback, useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';
import { FormItem } from '@noah-labs/core-web-ui/src/forms/FormItem';
import { InputField } from '@noah-labs/core-web-ui/src/forms/InputField';

const hiddenFormItemStyle = {
  height: 0,
  overflow: 'hidden',
  padding: 0,
};

type PpPasswordFormItemProps = { hidden?: boolean; variant?: 'label' | 'placeholder' };

export function PasswordFormItem({
  hidden = false,
  variant = 'placeholder',
}: PpPasswordFormItemProps): React.ReactElement {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => setShowPassword((show) => !show), []);
  const handleMouseDownPassword = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  return (
    <FormItem fullWidth sx={hidden ? hiddenFormItemStyle : undefined}>
      <InputField
        fullWidth
        autoComplete="current-password"
        dataQa="password"
        inputProps={{
          'aria-hidden': hidden ? 'true' : 'false',
          'aria-label': 'password',
          tabIndex: hidden ? -1 : undefined,
        }}
        // the duplication is two different props
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                tabIndex={hidden ? -1 : undefined}
                onClick={handleClickShowPassword}
                // this way input field won't loose focus on click
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label={variant === 'label' ? 'Password' : undefined}
        name="password"
        placeholder={variant === 'placeholder' ? 'Password*' : ''}
        required={!hidden}
        type={showPassword ? 'text' : 'password'}
      />
    </FormItem>
  );
}
