import { withSlippage } from '@noah-labs/shared-currencies/src/calculations';
import { safeBN } from '@noah-labs/shared-tools/src/browser/numbers';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import * as yup from 'yup';
import type { TpAllowance } from '../types';

type TpBuffer = {
  buffer: string;
  bufferInfo: string;
};

export function getCryptoWithdrawalLimitsSchema(
  allowance: TpAllowance | undefined,
  buffer?: TpBuffer
): yup.StringSchema {
  return yup.string().test('allowance', '', function Test(value) {
    /**
     * In cases where there might be slippage (ie. sell flow), we don't allow using 100% of the allowance
     */
    const input = buffer
      ? withSlippage(value, {
          slippage: buffer.buffer,
          type: 'positive',
        })
      : value;

    if (isUndefinedOrNull(allowance) || isUndefinedOrNull(value)) {
      return false;
    }

    const allowed = allowance.amount;
    if (safeBN(input).lte(allowed)) {
      return true;
    }
    let message = '';
    switch (allowance.reason) {
      case 'PolicyLimit':
        message = 'Sorry, you have reached your transaction limits';
        break;
      case 'Balance':
        message = 'Sorry, you don’t have enough funds for this';
        break;
      default:
    }

    if (buffer && buffer.bufferInfo) {
      message = `${message} ${buffer.bufferInfo}`;
    }

    message = `${message}.`;
    return this.createError({
      message,
    });
  });
}
