import { useCallback, useEffect, useState } from 'react';
import { disableRefetchRetry } from '@noah-labs/core-services';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { getErrorMessage } from '@noah-labs/core-web-ui/src/tools/getErrorMessage';
import type {
  TpAddressData,
  TpLightningAddressData,
} from '@noah-labs/core-web-ui/src/tools/parseAddressData';
import { parseMarketingUrl } from '@noah-labs/core-web-ui/src/tools/parseMarketingUrl';
import { type CurrencyCode, Feature } from '@noah-labs/shared-schema-gql';
import type { UseFormSetError } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ApiUnknown } from '../../../../components';
import { isProd } from '../../../../webConfigBrowser';
import { useFeature } from '../../../user/hooks/useFeature';
import { LnurlErrorAlert } from '../../components';
import { useLnAddressProxyQuery, useWalletParams } from '../../data';
import { useAddress, useAddressError, useRecentAddresses } from '../../hooks';
import { routes } from '../../routes';
import type { TpAddressForm } from '../../scenes';
import { AddressManualScene } from '../../scenes';
import { handleParseAddress, isAddressCustomError } from '../../utils/address';
import { getNextUrl } from '../../utils/getNextUrl';

type PpAddressManual = {
  scannedAddress: string;
};

export function AddressManual({ scannedAddress }: PpAddressManual): React.ReactElement {
  const withdrawFF = useFeature(Feature.Withdraw);
  const { CurrencyCode, params } = useWalletParams();
  const history = useHistory();
  const pushAlert = usePushAlert();
  const [lnData, setLnData] = useState<TpAddressData | undefined>();

  const { data, scannerUnavailable, setData } = useAddress();
  const recentPayees = useRecentAddresses();

  const address = lnData?.address;
  const lnUrlLink = lnData?.lnUrlLink;
  const addressType = lnData?.addressType;

  const isLnAddress = addressType === 'lnaddress';
  const isLnUrl = addressType === 'lnurl';

  const {
    data: lnAddressData,
    error: lnAddressError,
    isFetching: isLnAddressFetching,
  } = useLnAddressProxyQuery(
    {
      Input: {
        LightningAddress: isLnAddress ? address : undefined,
        LnurlLink: lnUrlLink,
      },
    },
    {
      enabled: isLnAddress || isLnUrl,
      ...disableRefetchRetry,
    }
  );

  useEffect(() => {
    if (!isLnAddressFetching) {
      return;
    }
    setData(undefined);
  }, [isLnAddressFetching, setData]);

  useEffect(
    () => () => {
      setData(undefined);
    },
    [setData]
  );

  useEffect(() => {
    if (!lnAddressData) {
      return;
    }

    if (!lnData) {
      return;
    }

    switch (lnAddressData.lightningAddressProxy.__typename) {
      case 'LightningAddressProxySuccess': {
        // safe to do a cast here because we know it's a ln address or lnurl at this point
        const currentlnData = lnData as TpLightningAddressData;
        const lnAddressProxy = {
          ...currentlnData,
          lightningAddressProxy: lnAddressData.lightningAddressProxy,
        };
        setData(lnAddressProxy);
        break;
      }

      case 'LnurlError':
        setData(undefined);
        pushAlert(LnurlErrorAlert(lnAddressData.lightningAddressProxy));
        break;

      default:
        pushAlert(ApiUnknown);
    }
  }, [lnAddressData, lnData, pushAlert, setData]);

  const handleAddress = useCallback(
    (
      addressInput: string,
      currencyCode: CurrencyCode,
      setError: UseFormSetError<TpAddressForm>
    ) => {
      try {
        if (!withdrawFF?.Networks) {
          return;
        }

        if (!addressInput) {
          setData(undefined);
          return;
        }

        const marketingUrl = parseMarketingUrl(addressInput);
        if (marketingUrl) {
          history.push(marketingUrl);
          return;
        }

        const { addressData, isLnAddressOrLnUrl } = handleParseAddress({
          address: addressInput,
          availableNetworks: withdrawFF.Networks,
          currencyCode,
          isProd,
        });

        if (!isLnAddressOrLnUrl) {
          setData(addressData);
          return;
        }

        setLnData(addressData);
      } catch (err) {
        setData(undefined);

        const error = getErrorMessage(err);

        if (isAddressCustomError(error)) {
          setError('address', {
            type: error,
          });
          return;
        }

        pushAlert({
          dismissable: true,
          key: 'addressError',
          message: getErrorMessage(error),
          preventDuplicate: true,
          severity: 'error',
        });
      }
    },
    [history, withdrawFF?.Networks, pushAlert, setData, setLnData]
  );

  const handleNextUrl = useCallback(() => {
    if (!data) {
      return;
    }
    const nextUrl = getNextUrl(data);
    history.push(nextUrl);
  }, [history, data]);

  const handleRedirectToScan = useCallback(() => {
    history.push(generatePath(routes.address.scan.path, params));
  }, [history, params]);

  const { ApiErrorScene } = useAddressError(lnAddressError);

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <AddressManualScene
      addressData={data}
      currencyCode={CurrencyCode}
      handleAddress={handleAddress}
      handleNextUrl={handleNextUrl}
      handleRedirectToScan={handleRedirectToScan}
      isLoading={isLnAddressFetching}
      recentAddresses={recentPayees}
      scannedAddress={scannedAddress}
      scannerUnavailable={scannerUnavailable}
    />
  );
}
