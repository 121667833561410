import { useCallback } from 'react';
import {
  IncorrectMnemonicError,
  InvalidMnemonicError,
  SafeWrapper,
} from '@noah-labs/core-services';
import type { UseFormSetError } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import type { TpPhraseForm } from '../../../utils';
import { useSigning } from '../../signing/context';
import { EnterSecretPhraseScene } from '../scenes';

export type PpEnterSecretPhrase = {
  setPhrase: (phrase: SafeWrapper<string>) => void;
  successTo: string;
};

export function EnterSecretPhrase({
  setPhrase,
  successTo,
}: PpEnterSecretPhrase): React.ReactElement {
  const history = useHistory();
  const { validateSecretPhrase } = useSigning();

  /**
   * Validates and persist the phrase in state
   */
  const onSubmit = useCallback(
    async ({ phrase }: TpPhraseForm, setError: UseFormSetError<TpPhraseForm>): Promise<void> => {
      try {
        const wp = new SafeWrapper(phrase.trim().toLowerCase());
        await validateSecretPhrase(wp);
        setPhrase(wp);
      } catch (err) {
        switch (true) {
          case err instanceof IncorrectMnemonicError:
          case err instanceof InvalidMnemonicError:
            setError('phrase', { message: (err as Error).message });
            return;
          default:
            return;
        }
      }

      history.push(successTo);
    },
    [history, setPhrase, validateSecretPhrase, successTo]
  );

  return <EnterSecretPhraseScene onSubmit={onSubmit} />;
}
