import { CryptoAmount } from '@noah-labs/core-web-ui/src/numbers/CryptoAmount';
import { FiatAmount } from '@noah-labs/core-web-ui/src/numbers/FiatAmount';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';
import type { TpAmount, TpFiatCurrency } from '@noah-labs/shared-currencies/src/types';
import type { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { CurrencyDisplayType } from '@noah-labs/shared-schema-gql';
import { isNonZero } from '@noah-labs/shared-tools/src/browser/numbers';
import { useCryptoUnit } from './useCryptoUnit';
import { usePrimaryCurrency } from './usePrimaryCurrency';

export type PpCurrencyAmounts = {
  cryptoAmount: TpAmount;
  cryptoCurrency: TpCryptoCurrencyUI | undefined;
  cryptoUnit?: CurrencyUnit;
  fiatAmount: TpAmount;
  fiatCurrency: TpFiatCurrency | undefined;
  isCryptoApprox?: boolean;
  isFiatApprox?: boolean;
  nonZeroCrypto?: boolean;
  nonZeroFiat?: boolean;
  primaryCurrency?: CurrencyDisplayType;
  roundDown?: boolean;
  signDisplay?: Intl.NumberFormatOptions['signDisplay'];
  strikeThrough?: boolean;
};

export type TpCurrencyAmounts = {
  PrimaryAmountSlot: React.ReactNode;
  SecondaryAmountSlot: React.ReactNode;
};

export function useCurrencyAmounts({
  cryptoAmount,
  cryptoCurrency,
  cryptoUnit,
  fiatAmount,
  fiatCurrency,
  isCryptoApprox,
  isFiatApprox,
  nonZeroCrypto = false,
  nonZeroFiat = false,
  primaryCurrency,
  roundDown,
  signDisplay,
  strikeThrough = false,
}: PpCurrencyAmounts): TpCurrencyAmounts {
  const { primaryCurrency: userPrimaryCurrency } = usePrimaryCurrency();
  const userCryptoUnit = useCryptoUnit();
  const resCryptoUnit = cryptoUnit || userCryptoUnit;
  const resPrimaryCurrency = primaryCurrency || userPrimaryCurrency;

  const CA = (
    <CryptoAmount
      amount={cryptoAmount}
      approx={isCryptoApprox}
      currency={cryptoCurrency}
      currencyUnit={resCryptoUnit}
      fallback={cryptoCurrency && `Confirming ${cryptoCurrency.code}`}
      fallbackCheck={(value): boolean => nonZeroCrypto && !isNonZero(value)}
      roundDown={roundDown}
      signDisplay={signDisplay}
      strikeThrough={strikeThrough}
    />
  );

  const FA = (
    <FiatAmount
      amount={fiatAmount}
      approx={isFiatApprox}
      currency={fiatCurrency}
      fallback={fiatCurrency && `Confirming ${fiatCurrency.code}`}
      fallbackCheck={(value): boolean => nonZeroFiat && !isNonZero(value)}
      roundDown={roundDown}
      signDisplay={signDisplay}
      strikeThrough={strikeThrough}
    />
  );

  if (resPrimaryCurrency === CurrencyDisplayType.Crypto) {
    return {
      PrimaryAmountSlot: CA,
      SecondaryAmountSlot: FA,
    };
  }

  return {
    PrimaryAmountSlot: FA,
    SecondaryAmountSlot: CA,
  };
}
