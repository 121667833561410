import { useEffect, useState } from 'react';
import { getErrorType, isAppSyncError, isGraphqlError } from '@noah-labs/core-services';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { ErrorPage } from '@noah-labs/core-web-ui/src/utility/ErrorPage';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { NoahErrorType } from '@noah-labs/shared-schema-gql';
import { ApiUnknown } from '../../../components';
import { getErrorPageProps } from '../../../errors';
import type { TpUseError } from '../../../types';
import {
  ApiInvalidUsername,
  ApiUsernameExists,
  ApiUsernameProfanity,
  ApiUserProfileExists,
  ApiUserProfileUpdate,
} from '../components';

export function useUserError(error: unknown): TpUseError {
  const [apiErrorScene, setApiErrorScene] = useState<React.ReactElement | null>(null);
  const pushAlert = usePushAlert();

  useEffect(() => {
    if (!error) {
      setApiErrorScene(null);
      return;
    }

    logger.error(error);

    if (isGraphqlError(error)) {
      error.response.errors.forEach((innerError) => {
        if (!isAppSyncError(innerError) || !innerError.errorType) {
          return;
        }
        const errorType = getErrorType(innerError.errorType);
        switch (errorType) {
          case NoahErrorType.InvalidUsername: {
            pushAlert(ApiInvalidUsername);
            break;
          }
          case NoahErrorType.UserIdExists: {
            pushAlert(ApiUserProfileExists);
            break;
          }
          case NoahErrorType.UsernameExists: {
            pushAlert(ApiUsernameExists);
            break;
          }
          case NoahErrorType.UserProfileUpdateFailed: {
            pushAlert(ApiUserProfileUpdate);
            break;
          }
          case NoahErrorType.InvalidMessage: {
            pushAlert(ApiUsernameProfanity);
            break;
          }
          default:
            pushAlert(ApiUnknown);
            break;
        }
      });
      return;
    }

    /**
     * If the error was not handled above, set to a generic error,
     * no need to show the user potentially confusing errors
     */
    setApiErrorScene(<ErrorPage {...getErrorPageProps()} />);
  }, [error, pushAlert]);

  return { ApiErrorScene: apiErrorScene };
}
