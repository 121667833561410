import type { TpBillingAddressSchema } from '@noah-labs/fe-shared-util-validation-schemas';
import { billingAddressSchema as schema } from '@noah-labs/fe-shared-util-validation-schemas';
import { z } from 'zod';

export type TpBillingAddressForm = TpBillingAddressSchema & {
  CountryName: string;
};

export const fieldNames = {
  City: 'billingAddress.City',
  CountryCode: 'billingAddress.CountryCode',
  CountryName: 'billingAddress.CountryName',
  PostCode: 'billingAddress.PostCode',
  State: 'billingAddress.State',
  Street: 'billingAddress.Street',
  Street2: 'billingAddress.Street2',
};

export const emptyBillingAddress: TpBillingAddressForm = {
  City: '',
  CountryCode: '',
  CountryName: '',
  PostCode: '',
  State: '',
  Street: '',
  Street2: '',
};

export const billingAddressSchema = schema.extend({
  CountryName: z.string().trim().min(1, 'Country is required'),
});
