import { Fragment } from 'react';
import { css } from '@emotion/react';
import { Link } from '@mui/material';
import { ElevatedCard } from '../cards/ElevatedCard';
import { ConsentOption } from './ConsentOption';

type PpTermsOptions = {
  privacyPolicyPath: string;
  termsAndConditionsPath: string;
};
export function TermsOptions({
  privacyPolicyPath,
  termsAndConditionsPath,
}: PpTermsOptions): React.ReactElement {
  const styles = {
    link: css`
      /* this is needed because of the option being readonly */
      pointer-events: all;
    `,
  };
  return (
    <ElevatedCard sx={{ p: 2 }}>
      <ConsentOption
        checked
        readOnly
        label={{
          primary: <Fragment>Terms &amp; Conditions (required)</Fragment>,
          secondary: (
            <Fragment>
              By continuing, you agree to NOAH’s{' '}
              <Link
                css={styles.link}
                href={termsAndConditionsPath}
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                terms &amp; conditions
              </Link>{' '}
              and{' '}
              <Link
                css={styles.link}
                href={privacyPolicyPath}
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                privacy policy
              </Link>
              .
            </Fragment>
          ),
        }}
        name="Terms"
      />
    </ElevatedCard>
  );
}
