import type { LoginFlow, UiText } from '@ory/client';
import type { AuthError, OryUiTextError } from '../types';
import { AuthErrorType } from '../types';

const ORY_PREPARE_WEB_AUTHN_DEVICE_MESSAGE_ID = 1010012;

export function isOryPrepareWebAuthnDeviceFlowState(flow: LoginFlow): boolean {
  return flow.ui.messages?.some((m) => m.id === ORY_PREPARE_WEB_AUTHN_DEVICE_MESSAGE_ID) ?? false;
}

export function isUiTextError(message: UiText): message is OryUiTextError {
  return message.type === 'error';
}

function getOryTextErrorType(error: OryUiTextError): AuthErrorType {
  if (error.id === 4000007) {
    return AuthErrorType.IdentifierExists;
  }
  return AuthErrorType.Unspecified;
}

function oryTextErrorToFormError(error: OryUiTextError): AuthError {
  return {
    message: error.text,
    type: getOryTextErrorType(error),
  };
}

export function getOryUiError(messages: UiText[] | undefined): AuthError | undefined {
  const uiError = messages?.find<OryUiTextError>(isUiTextError);
  if (!uiError) {
    return undefined;
  }
  return oryTextErrorToFormError(uiError);
}
