import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { ErrorPage } from '@noah-labs/core-web-ui/src/utility/ErrorPage';
import { AppHeaderData } from '../../../components';

type PpFailedScene = {
  helpText?: string;
  message: string;
};

export function FailedScene(props: PpFailedScene): React.ReactElement {
  return (
    <ErrorPage
      AppHeaderSlot={<AppHeaderData exitButton helpButton />}
      FooterSlot={
        <PrimaryButton data-qa="done-button" href="/">
          Back to NOAH
        </PrimaryButton>
      }
      {...props}
    />
  );
}
