import type { ReactText } from 'react';
import type { LinkProps } from '@mui/material';
import { InlineLinkOrButton } from '@noah-labs/core-web-ui/src/buttons/InlineLinkOrButton';
import { webConfigBrowser } from '../../webConfigBrowser';

const {
  intercom: { customLauncherSelector },
} = webConfigBrowser;

type PpGetInTouchLink = {
  text?: ReactText;
} & LinkProps;

export function GetInTouchLink({ text, ...rest }: PpGetInTouchLink): React.ReactElement {
  return (
    <InlineLinkOrButton
      aria-label="help"
      className={customLauncherSelector}
      // set button explicitly - doesn't have an onClick prop but does have an onClick handler
      component="button"
      data-qa={customLauncherSelector}
      id={customLauncherSelector}
      {...rest}
    >
      {text ?? 'get in touch'}
    </InlineLinkOrButton>
  );
}
