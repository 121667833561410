import type { ChangeEvent } from 'react';
import { useCallback } from 'react';
import { RadioGroup, Stack, Typography } from '@mui/material';
import { RadioButton } from '@noah-labs/core-web-ui/src/forms/RadioButton';
import type { PpPreferredCurrency, TpPreferredCurrencyFormOptions } from './types';

export function PreferredCryptoCurrencyForm<T extends TpPreferredCurrencyFormOptions>({
  hideValues,
  items,
  name,
  onChange,
  title,
  value,
}: PpPreferredCurrency<T>): React.ReactElement {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value as T);
    },
    [onChange]
  );
  return (
    <Stack spacing={2}>
      {title && (
        <Typography color="text.light" textAlign="center" variant="paragraphBodyS">
          {title}
        </Typography>
      )}
      <form>
        <RadioGroup name={name} value={value} onChange={handleChange}>
          {items.map(({ icon: Icon, ...item }) => (
            <RadioButton
              key={item.value}
              hideValue={hideValues}
              label={item.label}
              LabelIcon={Icon && <Icon sx={{ fontSize: '2rem' }} />}
              value={item.value}
              variant="card"
            />
          ))}
        </RadioGroup>
      </form>
    </Stack>
  );
}
