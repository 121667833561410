//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgWalletReceive } from '../icons/WalletReceive';

export function WalletReceiveIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgWalletReceive} data-qa="WalletReceiveIcon">
      <svg />
    </SvgIcon>
  );
}
