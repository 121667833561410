import type { FormEventHandler } from 'react';
import { Grid, Stack } from '@mui/material';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { FormItem } from '@noah-labs/core-web-ui/src/forms/FormItem';
import { InputField } from '@noah-labs/core-web-ui/src/forms/InputField';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { routes } from '../routes';
import { PasswordFormItem } from './PasswordFormItem';

export type TpSignInState = 'initial' | 'webauthn' | 'password';

const FORM_ID = 'sign-in-form';

const stateCtaLabel: Readonly<Record<TpSignInState, string>> = {
  initial: 'Continue',
  password: 'Login',
  webauthn: 'Continue with biometric login',
};

type PpSignInBodyProps = {
  loading: boolean;
  onGoToPasswordLogin: () => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
  state: TpSignInState;
};

export function SignInBody({
  loading,
  onGoToPasswordLogin,
  onSubmit,
  state,
}: PpSignInBodyProps): React.ReactElement {
  const history = useHistory();
  const { getValues } = useFormContext();

  const isPasswordInputHidden = state !== 'password';

  const email = getValues('email');

  return (
    <Stack spacing={2}>
      <form id={FORM_ID} onSubmit={onSubmit}>
        <Grid container spacing={isPasswordInputHidden ? 0 : 1}>
          <FormItem fullWidth>
            <InputField
              fullWidth
              required
              autoComplete="username"
              dataQa="email"
              inputProps={{
                'aria-label': 'email',
              }}
              name="email"
              placeholder="Email address*"
              type="email"
            />
          </FormItem>
          <PasswordFormItem hidden={isPasswordInputHidden} />
        </Grid>
      </form>
      <ButtonGroup>
        <PrimaryButton
          color="primaryBrand"
          disabled={loading}
          form={FORM_ID}
          loading={loading}
          type="submit"
        >
          {stateCtaLabel[state]}
        </PrimaryButton>
        {state === 'password' && (
          <PrimaryButton
            color="primaryBrand"
            data-qa="forgot-password-link"
            href={history.createHref({
              pathname: routes.recovery.path,
              search: new URLSearchParams({ email }).toString(),
            })}
            variant="text"
          >
            Forgot Password?
          </PrimaryButton>
        )}
        {state === 'webauthn' && (
          <PrimaryButton variant="text" onClick={onGoToPasswordLogin}>
            Login with password instead
          </PrimaryButton>
        )}
      </ButtonGroup>
    </Stack>
  );
}
