import { forwardRef, useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { Box, DialogContent, DialogTitle, Stack, Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import {
  type TpDialogToggle,
  useToggleDialog,
} from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import type { CurrencyUnit, FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { useOptimisticUserInitOptions } from '../../../user/data/useOptimisticUserInitOptions';
import {
  useUpdateUserCurrencyUnitSettingMutation,
  useUpdateUserFiatCurrencyCodeSettingMutation,
} from '../../../user/data/user.generated';
import { fiatCurrencyItems, unitCurrencyItems } from '../../data/currencies';
import { PreferredCryptoCurrencyForm } from '../forms/PreferredCryptoCurrencyForm';
import { PreferredFiatCurrencyForm } from '../forms/PreferredFiatCurrencyForm';

enum TpCurrencyDisplay {
  fiatCurrency = 'fiatCurrency',
  unitCurrency = 'unitCurrency',
}

const currencyTabs = [
  {
    label: 'Display currency',
    value: TpCurrencyDisplay.fiatCurrency,
  },
  {
    label: 'Bitcoin',
    value: TpCurrencyDisplay.unitCurrency,
  },
];

type TpCurrencies = {
  fiatCurrency: FiatCurrencyCode | undefined;
  unitCurrency: CurrencyUnit | undefined;
};

export type PpPreferredCurrenciesDialog = {
  currencies: TpCurrencies;
  initialOpen?: boolean;
};

const title = 'Choose your preferred\n display currencies';

export const PreferredCurrenciesDialog = forwardRef<TpDialogToggle, PpPreferredCurrenciesDialog>(
  ({ currencies, initialOpen }, ref): JSX.Element => {
    const theme = useTheme();
    const [currentTabPanel, setCurrentTabPanel] = useState(TpCurrencyDisplay.fiatCurrency);

    const { open, toggle } = useToggleDialog({ initialOpen, ref });

    const updateOptions = useOptimisticUserInitOptions();

    const { mutate: mutateFiatCurrencyCode } =
      useUpdateUserFiatCurrencyCodeSettingMutation(updateOptions);
    const { mutate: mutateDisplayUnit } = useUpdateUserCurrencyUnitSettingMutation(updateOptions);

    const handleDisplayUnitChange = useCallback(
      (value: CurrencyUnit) => {
        mutateDisplayUnit({ Input: { DisplayUnit: value } });
        toggle();
      },
      [mutateDisplayUnit, toggle]
    );

    const handleFiatCurrencyCodeChange = useCallback(
      (value: FiatCurrencyCode) => {
        mutateFiatCurrencyCode({
          Input: { FiatCurrencyCode: value },
        });
        toggle();
      },
      [mutateFiatCurrencyCode, toggle]
    );

    const styles = {
      content: css`
        padding: ${theme.spacing(1.5, 0, 3)};
      `,
    };

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', pt: 2 }}>
            <Tabs
              aria-label="preferred currencies"
              value={currentTabPanel}
              variant="fullWidth"
              onChange={(_, newValue: TpCurrencyDisplay): void => setCurrentTabPanel(newValue)}
            >
              {currencyTabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
          <Stack css={styles.content}>
            <div hidden={currentTabPanel !== TpCurrencyDisplay.fiatCurrency}>
              {currencies.fiatCurrency && (
                <PreferredFiatCurrencyForm
                  items={fiatCurrencyItems}
                  name="fiatCurrency"
                  title="Set your preferred currency for viewing your balances"
                  value={currencies.fiatCurrency}
                  onChange={handleFiatCurrencyCodeChange}
                />
              )}
            </div>
            <div hidden={currentTabPanel !== TpCurrencyDisplay.unitCurrency}>
              {currencies.unitCurrency && (
                <PreferredCryptoCurrencyForm
                  hideValues
                  items={unitCurrencyItems}
                  name="unitCurrency"
                  title="Selected unit will be shown in your wallet and transaction details."
                  value={currencies.unitCurrency}
                  onChange={handleDisplayUnitChange}
                />
              )}
            </div>
          </Stack>
        </DialogContent>
      </LowDialog>
    );
  }
);

PreferredCurrenciesDialog.displayName = PreferredCurrenciesDialog.name;
