import type { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { StatusBadge } from '@noah-labs/core-web-ui/src/badges/StatusBadge';
import { Avatar, AvatarBadge, AvatarIcon } from '@noah-labs/core-web-ui/src/images';
import { PlusCircleThickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/PlusCircleThickIcon';
import { ReferralIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ReferralIcon';

export function NewReferralNotificationIcon(): ReactElement {
  const theme = useTheme();

  return (
    <Avatar>
      <AvatarIcon>
        <ReferralIcon sx={{ color: 'warning.dark' }} />
      </AvatarIcon>
      <AvatarBadge>
        <StatusBadge
          badgeColor={theme.palette.system.green.main}
          badgeSize={2}
          Icon={<PlusCircleThickIcon />}
        />
      </AvatarBadge>
    </Avatar>
  );
}
