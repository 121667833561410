import { useTheme } from '@mui/material/styles';
import { StatusBadge } from '@noah-labs/core-web-ui/src/badges/StatusBadge';
import { DepositIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/DepositIcon';
import { FailIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/FailIcon';
import { PendingIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/PendingIcon';
import { PlusCircleThickIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/PlusCircleThickIcon';
import { SellIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/SellIcon';
import { WithdrawIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/WithdrawIcon';
import { TransactionStatus } from '@noah-labs/shared-schema-gql';
import { TpTransactionType } from '../../../types';

export type PpTransactionStatusBadge = {
  size?: number;
  status: TransactionStatus;
  type: TpTransactionType;
};
export function TransactionStatusBadge({
  size = 3,
  status,
  type,
}: PpTransactionStatusBadge): React.ReactElement | null {
  const theme = useTheme();

  switch (true) {
    case status === TransactionStatus.Failed:
      return (
        <StatusBadge
          badgeColor={theme.palette.system.red.main}
          badgeSize={size}
          Icon={<FailIcon />}
        />
      );

    case status === TransactionStatus.Pending:
      return (
        <StatusBadge
          badgeColor={theme.palette.grayscale[400]}
          badgeSize={size}
          Icon={<PendingIcon />}
        />
      );

    case type === TpTransactionType.Sell:
      return (
        <StatusBadge
          badgeColor={theme.palette.system.blue.main}
          badgeSize={size}
          Icon={<SellIcon />}
        />
      );

    case type === TpTransactionType.Buy:
      return (
        <StatusBadge
          badgeColor={theme.palette.system.green.main}
          badgeSize={size}
          Icon={<PlusCircleThickIcon />}
        />
      );

    case type === TpTransactionType.Withdraw:
      return (
        <StatusBadge
          badgeColor={theme.palette.system.green.main}
          badgeSize={size}
          Icon={<WithdrawIcon />}
        />
      );

    case type === TpTransactionType.Deposit:
      return (
        <StatusBadge
          badgeColor={theme.palette.system.green.main}
          badgeSize={size}
          Icon={<DepositIcon />}
        />
      );

    default:
      return null;
  }
}
