import { Balance } from '@noah-labs/core-web-ui/src/balance/Balance';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-currencies';
import { CurrencyDisplayType, CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { useParams } from 'react-router-dom';
import { useFiatCurrencyFromCode } from '../../../user/data/useFiatCurrencyFromCode';
import { useWalletParams } from '../../data';
import { useCurrencyAmounts } from '../../hooks/useCurrencyAmounts';
import { routes } from '../../routes';
import { CompleteSellScene } from '../../scenes';
import { TpCheckoutPayoutStatus } from '../../types';
import type { StSellRouter } from './SellRouter';

export function Complete({ state }: TpStateMachine<StSellRouter>): React.ReactElement {
  // TODO: we should pass the txid in the URL and get the real tx data from our API
  // sessionStorage is not reliable enough for this
  const { CurrencyCode, params } = useWalletParams();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const fiatCurrency = useFiatCurrencyFromCode(state.fiatCurrencyCode);
  const { payoutStatus } = useParams<{ payoutStatus: TpCheckoutPayoutStatus }>();

  const { cryptoAmount, fiatAmount, payoutMethod } = state;

  const Amounts = useCurrencyAmounts({
    // set these to undefined when blank, so that they render a skeleton
    cryptoAmount: cryptoAmount === '' ? undefined : cryptoAmount,
    cryptoCurrency,
    cryptoUnit: CurrencyUnit.Default,
    fiatAmount: fiatAmount === '' ? undefined : fiatAmount,
    fiatCurrency,
    primaryCurrency: CurrencyDisplayType.Fiat,
    strikeThrough: payoutStatus === TpCheckoutPayoutStatus.failure,
  });

  return (
    <CompleteSellScene
      BalanceSlot={<Balance PrimaryAmountSlot={Amounts.PrimaryAmountSlot} />}
      payoutMethod={payoutMethod}
      payoutStatus={payoutStatus}
      transactionsUrl={generatePath(routes.account.base.path, params)}
    />
  );
}
