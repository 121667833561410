import { duration } from '@noah-labs/shared-tools/src/browser/duration';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import type {
  UserEstimateBalanceQuery,
  UserEstimateBalanceQueryVariables,
} from './wallet.generated';
import { useUserEstimateBalanceQuery } from './wallet.generated';

export function useUserEstimateBalanceQueryPoll<TData = UserEstimateBalanceQuery, TError = unknown>(
  variables: UserEstimateBalanceQueryVariables,
  options?: UseQueryOptions<UserEstimateBalanceQuery, TError, TData>
): UseQueryResult<TData, TError> {
  return useUserEstimateBalanceQuery(variables, {
    refetchInterval: duration.seconds(3),
    ...options,
  });
}
