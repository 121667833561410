import React from 'react';
import { useStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { Route } from 'react-router-dom';
import { AuthRouteData } from '../../../auth';
import { useWalletParams } from '../../data';
import { routes } from '../../routes';
import { EnterAmount } from '../lightning-receive/EnterAmount';
import { ViewInvoice } from '../lightning-receive/ViewInvoice';
import { Receive } from './Receive';
import { SelectNetwork } from './SelectNetwork';
import type { StReceiveRouter } from './types';

const emptyState = {
  fiatAmount: '',
  network: undefined,
  paymentRequest: '',
  publicID: '',
};

export function ReceiveRouter(): React.ReactElement {
  const { params } = useWalletParams();

  const sm = useStateMachine<StReceiveRouter>({
    emptyState,
    name: 'ReceiveRouter',
  });

  const viewInvoiceInvalid = !sm.state.paymentRequest;

  return (
    <Switch404>
      <Route
        exact
        path={routes.receive.network.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.receive.network}>
            <SelectNetwork {...sm} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.receive.base.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.receive.base}>
            <Receive {...sm} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.receive.lightning.enterAmount.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.receive.lightning.enterAmount}>
            <EnterAmount {...sm} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.receive.lightning.viewInvoice.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            // redirect to the beginning of the flow if the payment request has not yet been generated
            invalidRedirect={
              viewInvoiceInvalid && generatePath(routes.receive.lightning.enterAmount.path, params)
            }
            route={routes.receive.lightning.viewInvoice}
          >
            <ViewInvoice {...sm} />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
