import { useCallback } from 'react';
import type { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { useHistory } from 'react-router-dom';
import { useUserFiatCurrency } from '../../../hooks/useUserFiatCurrency';
import { PreferredFiatCurrencyForm } from '../../wallet/components/forms/PreferredFiatCurrencyForm';
import { fiatCurrencyItems } from '../../wallet/data';
import { useOptimisticUserInitOptions } from '../data/useOptimisticUserInitOptions';
import { useUpdateUserFiatCurrencyCodeSettingMutation } from '../data/user.generated';
import { useUserError } from '../hooks/useUserError';
import { UserSettingOptionsScene } from '../scenes';

type PpDisplayCurrencySetting = {
  route: {
    id?: string;
    title: string;
  };
};

export function DisplayCurrencySetting({ route }: PpDisplayCurrencySetting): React.ReactElement {
  const updateOptions = useOptimisticUserInitOptions();
  const { error: mutateError, mutate: mutateFiatCurrencyCode } =
    useUpdateUserFiatCurrencyCodeSettingMutation(updateOptions);
  const { ApiErrorScene } = useUserError(mutateError);
  const { fiatCurrency } = useUserFiatCurrency();
  const history = useHistory();

  const handleChange = useCallback(
    (value: FiatCurrencyCode) => {
      mutateFiatCurrencyCode({
        Input: {
          FiatCurrencyCode: value,
        },
      });
      history.goBack();
    },
    [mutateFiatCurrencyCode, history]
  );

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <UserSettingOptionsScene
      hideValues
      CurrencyForm={PreferredFiatCurrencyForm}
      dataQa={route.id}
      formTitle="Set your preferred currency for viewing your balances"
      handleChange={handleChange}
      items={fiatCurrencyItems}
      pageTitle={route.title}
      value={fiatCurrency.code}
    />
  );
}
