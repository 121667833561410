import type { SVGProps } from 'react';

export function SvgAmexLight(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg {...props} fill="none" viewBox="0 0 48 24" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#FBFAF8" height={24} rx={2} width={48} />
      <path
        clipRule="evenodd"
        d="M19.658 11.045h2.431L18.455 3h-2.808L12 11.045h2.383l.705-1.643h3.865zm8.922 0h-1.884L24.46 5.09v5.954h-2.14V3h3.44l1.908 5.126L29.565 3h3.404v8.045h-2.14V5.09zm-5.123 1.91v1.804h-4.765v1.31h4.656v1.759h-4.655v1.368h4.764V21h-6.929v-8.045zM33.001 21H30.29l-2.018-2.494L26.266 21h-2.638l3.391-4.012-3.403-4.033h2.71l2.006 2.471 2.006-2.47h2.639l-3.404 3.987zM17.008 4.896l-1.18 2.758H18.2z"
        fill="#006FAC"
        fillRule="evenodd"
      />
    </svg>
  );
}
