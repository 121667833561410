import { useCallback } from 'react';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { isAxiosError } from 'axios';
import { useOry } from '../data';
import type { SocialProvider } from '../types';
import { getOryCsrfToken } from '../utils';
import { getAccountLinkedProviders } from '../utils/getAccountLinkedProviders';
import { isSessionRefreshRequiredError } from '../utils/oryTypeGuards';

export function useOrySocialUnLinkCallback(): (
  provider: SocialProvider
) => Promise<SocialProvider[] | undefined> {
  const { ory } = useOry();

  return useCallback(
    async (provider) => {
      try {
        const { data: flow } = await ory.createBrowserSettingsFlow({
          returnTo: window.location.href,
        });

        const csrfToken = getOryCsrfToken(flow.ui.nodes);
        const { data: settingsFlow } = await ory.updateSettingsFlow({
          flow: flow.id,
          updateSettingsFlowBody: {
            csrf_token: csrfToken,
            method: 'oidc',
            unlink: provider,
          },
        });

        return getAccountLinkedProviders(settingsFlow.ui.nodes);
      } catch (error) {
        logger.error(error);
        if (!isAxiosError(error)) {
          return undefined;
        }
        if (isSessionRefreshRequiredError(error)) {
          throw Error('Please login again to unlink an account');
        }
        return undefined;
      }
    },
    [ory]
  );
}
