import React, { useState } from 'react';
import { Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ChevronDownIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronDownIcon';
import { CloseButton } from '../buttons/CloseButton';
import { LowDialog } from '../dialogs/LowDialog';
import type { PpInputField } from './InputField';
import { InputField } from './InputField';
import type { TpSelectOption } from './SearchSelect';
import { SearchSelect } from './SearchSelect';

export type PpSelectDialog = Pick<
  PpInputField,
  'name' | 'label' | 'fullWidth' | 'size' | 'startSlot' | 'disabled'
> & {
  dataQa?: string;
  isFetched: boolean;
  onChange: (value: TpSelectOption | null) => void;
  options: TpSelectOption[] | undefined;
  required?: boolean;
};

export function DialogSelectField({
  dataQa = '',
  disabled,
  fullWidth,
  isFetched,
  label,
  name,
  onChange,
  options,
  required,
  ...props
}: PpSelectDialog): React.ReactElement {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  return (
    <React.Fragment>
      <InputField
        dataQa={name}
        endSlot={<ChevronDownIcon sx={{ color: theme.palette.grayscale[400] }} />}
        fullWidth={fullWidth}
        InputProps={{
          readOnly: true,
        }}
        label={label}
        name={name}
        required={required}
        onClick={(): void => {
          if (disabled) {
            return;
          }
          setOpen(true);
        }}
        {...props}
      />
      <LowDialog fullWidth keepMounted tallScreen data-qa={`${dataQa}-select-dialog`} open={open}>
        <CloseButton onClick={(): void => setOpen(false)} />
        <Container maxWidth="sm" sx={{ overflow: 'auto' }}>
          <SearchSelect
            id={name}
            loading={!isFetched}
            options={options}
            onChange={(value): void => {
              onChange(value);
              setOpen(false);
            }}
          />
        </Container>
      </LowDialog>
    </React.Fragment>
  );
}
