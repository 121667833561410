import { Link } from '@mui/material';
import { LinkBehavior } from '@noah-labs/core-web-ui/src/links/LinkBehavior';
import { useLocation } from 'react-router-dom';
import { routes as userRoutes } from '../../../user/routes';

export function ManagePaymentMethodsLink(): React.ReactElement {
  const location = useLocation();

  return (
    <Link
      aria-label="Manage payment methods"
      component={LinkBehavior}
      // @ts-expect-error false positive
      href={{
        pathname: userRoutes.settings.paymentMethods.path,
        state: { from: location.pathname },
      }}
      variant="paragraphBodySBold"
    >
      Manage
    </Link>
  );
}
