import { ElevatedCardListItem } from '@noah-labs/core-web-ui/src/cards/ElevatedCardListItem';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { DefaultListItemText } from '@noah-labs/core-web-ui/src/lists/atoms/DefaultListItemText';
import { List } from '@noah-labs/core-web-ui/src/lists/List';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import type { TpSkeletonText } from '@noah-labs/core-web-ui/src/types';
import type { FullName, Maybe } from '@noah-labs/shared-schema-gql';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import { routes } from '../routes';

export type PpProfileDetailsScene = {
  email: TpSkeletonText;
  personalName?: Maybe<FullName>;
};
export function ProfileDetailsScene({
  email,
  personalName,
}: PpProfileDetailsScene): React.ReactElement {
  const name = personalName
    ? [personalName.FirstName, personalName.MiddleName, personalName.LastName]
        .filter(Boolean)
        .join(' ')
    : undefined;

  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeaderData backButton helpButton>
          <AppHeaderTitle>Personal Details</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa="personal-details-menu"
    >
      <Helmet>
        <title>{routes.base.title}</title>
      </Helmet>
      <SceneMain dense>
        <List disablePadding>
          <ElevatedCardListItem dataQa="menu-list-item--personal-name">
            <DefaultListItemText primary={routes.profile.fullName.title} secondary={name} />
          </ElevatedCardListItem>

          <ElevatedCardListItem dataQa="menu-list-item--email">
            <DefaultListItemText primary="Email" secondary={email} />
          </ElevatedCardListItem>
        </List>
      </SceneMain>
    </AppContainer>
  );
}
