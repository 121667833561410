import type { SVGProps } from 'react';

export function SvgEthereum(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10"
        fill="#627EEA"
      />
      <path d="M12.311 4.5v5.544l4.686 2.093z" fill="#fff" fillOpacity={0.602} />
      <path d="m12.311 4.5-4.686 7.637 4.686-2.093z" fill="#fff" />
      <path d="M12.311 15.73v3.767L17 13.01z" fill="#fff" fillOpacity={0.602} />
      <path d="M12.311 19.497v-3.768L7.625 13.01z" fill="#fff" />
      <path d="m12.311 14.858 4.686-2.72-4.686-2.093z" fill="#fff" fillOpacity={0.2} />
      <path d="m7.625 12.137 4.686 2.721v-4.813z" fill="#fff" fillOpacity={0.602} />
    </svg>
  );
}
