import { useContext } from 'react';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import type { CxLiveChat } from './LiveChatProvider';
import { LiveChatContext } from './LiveChatProvider';

export function useLiveChat(): CxLiveChat {
  const context = useContext(LiveChatContext);
  if (context === undefined) {
    return logger.fatal('useLiveChat must be used within an LiveChatProvider');
  }
  return context;
}
