import { LearnMoreButton } from '@noah-labs/core-web-ui/src/buttons/LearnMoreButton';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneIcon } from '@noah-labs/core-web-ui/src/scene/SceneIcon';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { CloseCircleIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CloseCircleIcon';
import { webLinks } from '@noah-labs/shared-constants';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import { routes as walletRoutes } from '../../wallet/routes';

export type PpCompleteFailedScene = {
  allowTryAgain?: boolean;
  isResetting?: boolean;
  onTryAgain?: () => void;
  reason: React.ReactNode;
};

export function CompleteFailedScene({
  allowTryAgain,
  isResetting,
  onTryAgain,
  reason,
}: PpCompleteFailedScene): React.ReactElement {
  const AppFooterSlot = allowTryAgain ? (
    <PrimaryButton
      color="primaryBrand"
      data-qa="try-again-button"
      loading={isResetting}
      onClick={(): void => {
        if (!onTryAgain) {
          return;
        }

        onTryAgain();
      }}
    >
      Try again
    </PrimaryButton>
  ) : (
    <PrimaryButton data-qa="kyc-close-button" href={walletRoutes.base.path}>
      Close
    </PrimaryButton>
  );
  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={AppFooterSlot}
      AppHeaderSlot={<AppHeaderData disableFade exitButton helpButton />}
      dataQa="completeFailed"
    >
      <Helmet>
        <title>Identity verification has failed</title>
      </Helmet>
      <SceneMain>
        <SceneIcon>
          <CloseCircleIcon color="error" />
        </SceneIcon>
        <SceneTitleLarge>Identity verification has failed</SceneTitleLarge>
        <SceneParagraph>{reason}</SceneParagraph>
        <SceneParagraph>
          Verification helps us check that you’re really you and keeps your account safe from fraud.{' '}
          <LearnMoreButton href={webLinks.privacyPolicy.path} target="_blank">
            Learn more in our Privacy Policy.
          </LearnMoreButton>
        </SceneParagraph>
      </SceneMain>
    </AppContainer>
  );
}
