/* eslint-disable react/no-array-index-key */
import { Fragment } from 'react';
import { Avatar, Skeleton } from '@mui/material';
import { ElevatedCardListItem } from '../cards/ElevatedCardListItem';
import { StructuredContent } from './atoms/StructuredContent';

type PpSkeletonQuestList = {
  rows?: number;
};

export function SkeletonQuestList({ rows = 2 }: PpSkeletonQuestList): React.ReactElement {
  return (
    <Fragment>
      {new Array(rows).fill(null).map((_, index) => (
        <ElevatedCardListItem key={index} dataQa="skeleton">
          <StructuredContent
            Icon={
              <Avatar variant="square">
                <Skeleton variant="rectangular" />
              </Avatar>
            }
            mainText={{
              primary: <Skeleton sx={{ px: '6rem' }} variant="text" />,
              secondary: <Skeleton variant="text" />,
            }}
          />
        </ElevatedCardListItem>
      ))}
    </Fragment>
  );
}
