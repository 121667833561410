import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-currencies';
import type { TpAmount, TpSimpleAmount } from '@noah-labs/shared-currencies/src/types';
import type { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { useUserFiatCurrency } from '../../../hooks/useUserFiatCurrency';
import { useCalculateFiatFromCrypto, useWalletParams } from '../data';
import { useCryptoUnit } from './useCryptoUnit';

type PpUseSendAmounts = {
  cryptoAmount: TpAmount;
  fiatAmount?: TpAmount;
};

type TpUseSendAmountsResponse = {
  cryptoUnit: CurrencyUnit;
  fetchedAt: string | undefined;
  fiatAmount: TpSimpleAmount;
  price: TpSimpleAmount;
};

export function useSendAmounts({
  cryptoAmount,
  fiatAmount,
}: PpUseSendAmounts): TpUseSendAmountsResponse {
  /**
   * Fetch the fiatAmount for the cryptoAmount in the paymentRequestData,
   * so that we can display it on the confirm & complete screeens.
   * Always fetch a new fiatAmount in case the stored value is out of date
   */

  const { CurrencyCode } = useWalletParams();

  const cryptoUnit = useCryptoUnit();
  const { fiatCurrency } = useUserFiatCurrency();
  const { data: priceData } = useCalculateFiatFromCrypto({
    cryptoAmount,
    cryptoCurrency: cryptoCurrencyFromCode(CurrencyCode),
    fiatCurrency,
    options: {
      // don't fetch if we already have a 'saved' fiatAmount
      enabled: Boolean(!fiatAmount && cryptoAmount),
    },
    priceProvider: 'market',
  });

  const newfiatAmount = fiatAmount?.toString() || priceData?.fiatAmount;

  return {
    cryptoUnit,
    fetchedAt: priceData?.fetchedAt,
    fiatAmount: newfiatAmount,
    price: priceData?.price,
  };
}
