import { useEffect } from 'react';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { getAppType } from '../../auth/utils';
import { updateUserInitCache } from '../../user/data/updateUserInitCache';
import { useUpdateGooglePlayStoreAppReviewedMetadataMutation } from '../../user/data/user.generated';
import { useUserInit } from '../../user/data/useUserInit';

const IN_APP_REVIEW_DIALOG_DELAY = 1000;

export function useInAppReviewTrigger(enabled: boolean): void {
  const queryClient = useQueryClient();
  const { data: userData } = useUserInit();
  const { mutateAsync: googlePlayStoreAppReviewedMutation } =
    useUpdateGooglePlayStoreAppReviewedMetadataMutation();

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const appType = getAppType();

    if (appType !== 'twa') {
      return;
    }

    if (!userData?.userProfile || userData.userProfile.Metadata.GooglePlayStoreAppReviewed) {
      return;
    }

    // setTimeout just to add a tiny delay to trigger the dialog after the component is rendered
    setTimeout(() => {
      // In-app review trigger (It's not a redirect, it'll trigger the ReviewActivity in the android code)
      window.location.assign('noah://review');
    }, IN_APP_REVIEW_DIALOG_DELAY);

    // Once we show the ReviewActivity we can update the flag in order to trigger the review only once
    async function updateGooglePlayStoreAppReviewed(): Promise<void> {
      try {
        const { userMetadataUpdate } = await googlePlayStoreAppReviewedMutation({
          Input: {
            GooglePlayStoreAppReviewed: dayjs().toISOString(),
          },
        });

        const userMetadata = userData?.userProfile.Metadata;
        const updatedUserMetadata = userMetadataUpdate.Metadata;
        const newUserMetadata = { Metadata: { ...userMetadata, ...updatedUserMetadata } };

        updateUserInitCache(queryClient, newUserMetadata);
      } catch (error) {
        // We don't need to show anything to the user
        logger.error(error);
      }
    }

    void updateGooglePlayStoreAppReviewed();
  }, [userData?.userProfile, queryClient, enabled, googlePlayStoreAppReviewedMutation]);
}
