import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { useToggleDialog } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { RemoveConfirmation } from '../../../wallet/components/payment/PaymentMethodsPicker/RemoveConfirmation';
import type { TpPaymentCard } from '../../../wallet/types';

type PpConfirmRemoveDialog = {
  cardDetails: TpPaymentCard;
  onRemoveCard: () => void;
};

export const ConfirmRemoveDialog = forwardRef<TpDialogToggle, PpConfirmRemoveDialog>(
  ({ cardDetails, onRemoveCard }, ref): JSX.Element => {
    const { open, toggle } = useToggleDialog({ ref });

    return (
      <LowDialog dataQa="confirm-delete" open={open} onClose={toggle}>
        <DialogTitle>Are you sure you want to remove this card?</DialogTitle>
        <DialogContent>
          <RemoveConfirmation card={cardDetails} />
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <PrimaryButton
              color="primaryBrand"
              onClick={(): void => {
                onRemoveCard();
                toggle();
              }}
            >
              Yes, Remove
            </PrimaryButton>
            <PrimaryButton variant="text" onClick={toggle}>
              Cancel
            </PrimaryButton>
          </ButtonGroup>
        </DialogActions>
      </LowDialog>
    );
  }
);

ConfirmRemoveDialog.displayName = ConfirmRemoveDialog.name;
