import React from 'react';
import { Stack } from '@mui/material';
import { Balance } from '@noah-labs/core-web-ui/src/balance/Balance';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import type { TpLightningAddressData } from '@noah-labs/core-web-ui/src/tools/parseAddressData';
import { TruncatedText } from '@noah-labs/core-web-ui/src/typography/TruncatedText';
import type { TpCryptoCurrencyUI, TpFiatCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';
import type { TpAmount } from '@noah-labs/shared-currencies/src/types';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import { AddressSummaryCard } from '../components';
import type { TpPayeeData } from '../controllers';
import { useCurrencyAmounts } from '../hooks/useCurrencyAmounts';
import type { TpCurrencyAmounts } from '../hooks/useCurrencyAmounts';

type TpAddressData = TpLightningAddressData | TpPayeeData;

export type PpConfirmScene = {
  FeeSlot?: React.ReactElement;
  TotalAmountSlots?: TpCurrencyAmounts;
  addressData?: TpAddressData;
  backButton?: boolean;
  backTo?: string;
  cryptoAmount: TpAmount;
  cryptoCurrency: TpCryptoCurrencyUI;
  ctaButtonLabel?: string;
  faqLink?: string;
  fiatAmount: TpAmount;
  fiatCurrency: TpFiatCurrencyUI;
  isCtaDisabled?: boolean;
  isLoading: boolean;
  onConfirm: () => Promise<void>;
  pageTitle: string;
};

export function ConfirmScene({
  addressData,
  backButton,
  backTo,
  cryptoAmount,
  cryptoCurrency,
  ctaButtonLabel = 'Send Now',
  FeeSlot,
  fiatAmount,
  fiatCurrency,
  isCtaDisabled = false,
  isLoading,
  onConfirm,
  pageTitle,
  TotalAmountSlots,
}: PpConfirmScene): React.ReactElement {
  const Amounts = useCurrencyAmounts({
    cryptoAmount,
    cryptoCurrency,
    fiatAmount,
    fiatCurrency,
    roundDown: true,
  });

  return (
    <AppContainer
      AppFooterSlot={
        <PrimaryButton
          color="primaryBrand"
          data-qa="confirm-button"
          disabled={isCtaDisabled}
          loading={isLoading}
          onClick={onConfirm}
        >
          {ctaButtonLabel}
        </PrimaryButton>
      }
      AppHeaderSlot={
        <AppHeaderData exitButton helpButton backButton={backButton} backTo={backTo}>
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa="confirm"
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SceneMain maxWidth="xs">
        <Stack spacing={6}>
          <Balance {...Amounts} />

          {addressData && (
            <AddressSummaryCard
              address={<TruncatedText prefix={12} text={addressData.address} />}
              cryptoCurrency={cryptoCurrency}
              FeeSlot={FeeSlot}
              network={addressData.network}
              TotalAmountSlot={TotalAmountSlots || Amounts}
            />
          )}
        </Stack>
      </SceneMain>
    </AppContainer>
  );
}
