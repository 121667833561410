import { useEffect, useState } from 'react';
import { Slide } from '@mui/material';
import { NewCtaCard } from '@noah-labs/core-web-ui/src/cards/NewCtaCard';
import type { PpCarouselBase } from '@noah-labs/core-web-ui/src/carousels/CtaCarousel';
import type { TpTimeout } from '@noah-labs/shared-tools/src/browser/utils';

const animationDuration = 250;

export type PpCarouselSlide = PpCarouselBase & {
  backgroundImage: string;
  color: 'pink' | 'brown' | 'blue';
  href: string;
  onCardClick: () => void;
  onClose: () => void;
  subtitle: string;
  title: string;
};

export function CarouselSlide({
  backgroundImage,
  color,
  hidden,
  href,
  onCardClick,
  onClose,
  subtitle,
  title,
}: PpCarouselSlide): React.ReactElement | null {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    let timer: TpTimeout;
    if (hidden) {
      timer = setTimeout(() => {
        setMounted(false);
      }, animationDuration);
    } else {
      setMounted(true);
    }

    return (): void => {
      clearTimeout(timer);
    };
  }, [hidden, setMounted]);

  if (!mounted) {
    return null;
  }

  return (
    <Slide appear={false} direction="right" enter={false} in={!hidden} timeout={animationDuration}>
      <NewCtaCard
        backgroundImage={backgroundImage}
        color={color}
        href={href}
        subtitle={subtitle}
        title={title}
        onClick={onCardClick}
        onClose={onClose}
      />
    </Slide>
  );
}
