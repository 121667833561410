//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgEnlarge } from '../icons/Enlarge';

export function EnlargeIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgEnlarge} data-qa="EnlargeIcon">
      <svg />
    </SvgIcon>
  );
}
