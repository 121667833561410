//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgOpenInWindow } from '../icons/OpenInWindow';

export function OpenInWindowIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgOpenInWindow} data-qa="OpenInWindowIcon">
      <svg />
    </SvgIcon>
  );
}
