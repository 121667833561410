import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Stories } from '@noah-labs/core-web-ui/src/carousels/Stories';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import { useDesktop } from '@noah-labs/core-web-ui/src/hooks/useDesktop';

export type PpWelcomeStoriesDialog = {
  onClose: () => void;
  open: boolean;
};

export function WelcomeStoriesDialog({
  onClose,
  open,
}: PpWelcomeStoriesDialog): React.ReactElement {
  const theme = useTheme();
  const isDesktop = useDesktop();

  const storiesHeaderColor = isDesktop ? 'grayscale.100' : 'grayscale.500';

  const stories = [
    {
      id: 'app',
      image:
        'https://res.cloudinary.com/noahapp/image/upload/v1690890534/Welcome%20stories/welcome%20stories%20static%20images/1_vivy4n.png',
      subtitle: 'Instantly send, receive, buy and sell bitcoin - all in one place.',
      title: (
        <Typography color={storiesHeaderColor} variant="headingL">
          Buying Bitcoin.{' '}
          <Typography color="brand.light" component="span" variant="headingL">
            Made Simple.
          </Typography>
        </Typography>
      ),
    },
    {
      id: 'zero-fees',
      image:
        'https://res.cloudinary.com/noahapp/image/upload/v1690895032/Welcome%20stories/welcome%20stories%20static%20images/2_1_kpyamz.png',
      subtitle: 'Enjoy instant and fee-free bitcoin payments using the Lightning Network.',
      title: (
        <Typography color="brand.light" variant="headingL">
          Zero Fees,{' '}
          <Typography color={storiesHeaderColor} component="span" variant="headingL">
            Zero Wait.
          </Typography>
        </Typography>
      ),
    },
    {
      id: 'buy-and-sell-btc',
      image:
        'https://res.cloudinary.com/noahapp/image/upload/v1690890534/Welcome%20stories/welcome%20stories%20static%20images/3_uhhfpd.png',
      subtitle: 'Buy Bitcoin instantly for low fees and unlock its potential in 115+ countries.',
      title: (
        <Typography color={storiesHeaderColor} variant="headingL">
          Buying Bitcoin.{' '}
          <Typography color="brand.light" component="span" variant="headingL">
            Made Simple.
          </Typography>
        </Typography>
      ),
    },
    {
      id: 'earn',
      image:
        'https://res.cloudinary.com/noahapp/image/upload/v1690895032/Welcome%20stories/welcome%20stories%20static%20images/4_1_edqqoj.png',
      subtitle:
        'Earn NOAH Points for everything you do. NOAH Points will be redeemable for Satoshis, soon.',
      title: (
        <Typography color={storiesHeaderColor} variant="headingL">
          Start Today,{' '}
          <Typography color="brand.light" component="span" variant="headingL">
            Earn Now.
          </Typography>
        </Typography>
      ),
    },

    {
      id: 'invite-friends',
      image:
        'https://res.cloudinary.com/noahapp/image/upload/v1690890535/Welcome%20stories/welcome%20stories%20static%20images/5_xvmrlu.png',
      subtitle: 'Get 100 NOAH points for each referred, who will also receive 100 points.',
      title: (
        <Typography color={storiesHeaderColor} variant="headingL">
          Invite Friends,{' '}
          <Typography color="brand.light" component="span" variant="headingL">
            Earn Points.
          </Typography>
        </Typography>
      ),
    },
  ];

  return (
    <LowDialog
      fullScreen
      BackdropProps={{
        sx: {
          backdropFilter: 'blur(10px)',
        },
      }}
      backgroundColor={isDesktop ? 'transparent' : undefined}
      open={open}
      PaperProps={{ sx: { overflow: 'hidden' } }}
      sx={{ background: theme.palette.grayscale[100], overflow: 'hidden' }}
      TransitionComponent={undefined}
      transitionDuration={500}
      onClose={onClose}
    >
      {open && <Stories stories={stories} timeout={9000} onClose={onClose} />}
    </LowDialog>
  );
}
