import { forwardRef, useCallback } from 'react';
import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import {
  type TpDialogToggle,
  useToggleDialog,
} from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import type { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { useOptimisticUserInitOptions } from '../../../user/data/useOptimisticUserInitOptions';
import { useUpdateUserFiatPaymentCurrencyCodeSettingMutation } from '../../../user/data/user.generated';
import { fiatCurrencyItems } from '../../data/currencies';
import { PreferredFiatCurrencyForm } from '../forms/PreferredFiatCurrencyForm';

export type PpPaymentCurrencyDialog = {
  fiatCurrency: FiatCurrencyCode;
  fiatPaymentCurrency: FiatCurrencyCode;
  initialOpen?: boolean;
  onCurrencyChange?: (currency: FiatCurrencyCode) => void;
};

const title = 'Choose your preferred\n payment currency';

export const PaymentCurrencyDialog = forwardRef<TpDialogToggle, PpPaymentCurrencyDialog>(
  ({ fiatCurrency, fiatPaymentCurrency, initialOpen, onCurrencyChange }, ref): JSX.Element => {
    const updateOptions = useOptimisticUserInitOptions();
    const { open, toggle } = useToggleDialog({ initialOpen, ref });

    const { mutate: mutateFiatCurrencyCode } =
      useUpdateUserFiatPaymentCurrencyCodeSettingMutation(updateOptions);

    const handleChange = useCallback(
      (value: FiatCurrencyCode) => {
        if (onCurrencyChange) {
          onCurrencyChange(value);
        }

        mutateFiatCurrencyCode({
          Input: {
            FiatCurrencyCode: fiatCurrency,
            FiatPaymentCurrencyCode: value,
          },
        });
        toggle();
      },
      [mutateFiatCurrencyCode, toggle, onCurrencyChange, fiatCurrency]
    );

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>
        <DialogContent>
          <PreferredFiatCurrencyForm
            hideValues
            items={fiatCurrencyItems}
            name="fiatCurrency"
            title="Selected currency will be used for buying & selling with your credit/debit card."
            value={fiatPaymentCurrency}
            onChange={handleChange}
          />
          <Typography color="text.light" marginTop={3} textAlign="center" variant="paragraphBodyS">
            More currencies coming soon
          </Typography>
        </DialogContent>
      </LowDialog>
    );
  }
);

PaymentCurrencyDialog.displayName = PaymentCurrencyDialog.name;
