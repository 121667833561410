import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { FormGrid } from '@noah-labs/core-web-ui/src/forms/FormGrid';
import { FormItem } from '@noah-labs/core-web-ui/src/forms/FormItem';
import { InputField } from '@noah-labs/core-web-ui/src/forms/InputField';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';
import type { TpSlippage } from '@noah-labs/shared-currencies/src/calculations';
import { btcToSats } from '@noah-labs/shared-currencies/src/conversions';
import type { TpFiatCurrency, TpSimpleAmount } from '@noah-labs/shared-currencies/src/types';
import { CurrencyDisplayType, CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { zeroToBlankOrValue } from '@noah-labs/shared-tools/src/browser/numbers';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import { Helmet } from 'react-helmet';
import type { Resolver, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import type * as yup from 'yup';
import { AppHeaderData } from '../../../components';
import { DualCurrencyAmountField } from '../components';
import type { TpPriceProvider } from '../data';

export type TpAmountForm = {
  cryptoAmount: TpSimpleAmount;
  description: string;
  // maps to the input field in AmountField,
  displayField: string;
  fetchedAt: string | undefined;
  fiatAmount: TpSimpleAmount;
  price: TpSimpleAmount;
  // maps to the primary field in AmountField, should never be undefined always at least a blank string
  primaryAmount: string;
  /**
   * maps to the secondary field in AmountField, can be:
   * - undefined when prices are not known
   * - 0 when price is known and value is empty
   * - or the actual calculated value
   */
  secondaryAmount: TpSimpleAmount;
};

export type PpEnterAmountScene = {
  ContentSlot?: React.ReactElement;
  FooterContentSlot?: React.ReactElement;
  PageTitleSlot?: React.ReactElement;
  SwitchCurrencySlot?: React.ReactElement;
  amountPlaceholder?: string;
  amountRequired?: boolean;
  backButton?: boolean;
  backTo?: string | undefined;
  cryptoAmount: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  cryptoUnit: CurrencyUnit;
  ctaButtonDisabled?: boolean;
  ctaButtonLabel?: string;
  description?: string;
  disableSwitch?: boolean;
  fiatAmount: string;
  fiatCurrency: TpFiatCurrency;
  htmlHeadTitle: string;
  isCryptoAmountNet?: boolean;
  onBlurValues?: (values: Pick<TpAmountForm, 'cryptoAmount' | 'fiatAmount'>) => void;
  onSubmit: SubmitHandler<TpAmountForm>;
  priceProvider: TpPriceProvider;
  primaryCurrency: CurrencyDisplayType | null | undefined;
  slippage?: TpSlippage;
  yupSchema?: yup.ObjectSchema<Partial<TpAmountForm>>;
};

export function EnterAmountScene({
  amountPlaceholder,
  amountRequired = false,
  backButton = false,
  backTo,
  ContentSlot,
  cryptoAmount,
  cryptoCurrency,
  cryptoUnit,
  ctaButtonDisabled = false,
  ctaButtonLabel = 'Continue',
  description,
  disableSwitch = false,
  fiatAmount,
  fiatCurrency,
  FooterContentSlot,
  htmlHeadTitle,
  isCryptoAmountNet,
  onBlurValues,
  onSubmit,
  PageTitleSlot,
  priceProvider,
  primaryCurrency,
  slippage,
  SwitchCurrencySlot,
  yupSchema,
}: PpEnterAmountScene): React.ReactElement {
  let primaryAmount;

  switch (primaryCurrency) {
    case CurrencyDisplayType.Fiat:
      primaryAmount = fiatAmount;
      break;

    case CurrencyDisplayType.Crypto:
      primaryAmount = cryptoAmount;
      if (cryptoUnit === CurrencyUnit.SATS) {
        primaryAmount = btcToSats(cryptoAmount);
      }
      break;

    default:
      break;
  }

  const methods = useForm<TpAmountForm>({
    defaultValues: {
      cryptoAmount,
      description,
      fetchedAt: '',
      fiatAmount,
      price: '',
      primaryAmount: zeroToBlankOrValue(primaryAmount),
      secondaryAmount: '',
    },
    mode: 'all',
    resolver: yupSchema && (yupResolver(yupSchema) as Resolver<TpAmountForm>),
  });

  const errorMessage =
    methods.formState.errors.cryptoAmount?.message || methods.formState.errors.fiatAmount?.message;

  useEffect(() => {
    if (!yupSchema) {
      return;
    }
    void methods.trigger();
  }, [yupSchema, methods]);

  return (
    <AppContainer
      AppFooterSlot={
        <Stack spacing={5}>
          {FooterContentSlot}
          <PrimaryButton
            color="primaryBrand"
            disabled={!methods.formState.isValid || ctaButtonDisabled}
            form="amount-form"
            loading={methods.formState.isSubmitting}
            type="submit"
          >
            {ctaButtonLabel}
          </PrimaryButton>
        </Stack>
      }
      AppHeaderSlot={
        <AppHeaderData exitButton helpButton backButton={backButton} backTo={backTo}>
          {PageTitleSlot}
        </AppHeaderData>
      }
      dataQa="enter-amount"
    >
      <Helmet>
        <title>{htmlHeadTitle}</title>
      </Helmet>
      <SceneMain dense>
        <FormProvider {...methods}>
          <form id="amount-form" onSubmit={methods.handleSubmit(onSubmit)}>
            <FormGrid>
              <FormItem fullWidth>
                <DualCurrencyAmountField
                  cryptoCurrency={cryptoCurrency}
                  cryptoUnit={cryptoUnit}
                  disableSwitch={disableSwitch}
                  fiatCurrency={fiatCurrency}
                  InputFieldAtomProps={{
                    dataQa: 'amount',
                    errorMessage,
                    placeholder: amountPlaceholder,
                    required: amountRequired,
                  }}
                  isCryptoAmountNet={isCryptoAmountNet}
                  priceProvider={priceProvider}
                  slippage={slippage}
                  SwitchCurrencySlot={SwitchCurrencySlot}
                  userPrimaryCurrency={primaryCurrency}
                  onBlurValues={onBlurValues}
                />
              </FormItem>
              {!isUndefinedOrNull(description) && (
                <FormItem fullWidth>
                  <InputField
                    fullWidth
                    dataQa="description"
                    label="Note (optional)"
                    name="description"
                    placeholder="Note..."
                    type="text"
                  />
                </FormItem>
              )}
            </FormGrid>
          </form>
        </FormProvider>
        <Box sx={{ mt: 6 }}>{ContentSlot}</Box>
      </SceneMain>
    </AppContainer>
  );
}
