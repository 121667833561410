import type { ReactElement } from 'react';
import { lazy, Suspense } from 'react';
import { CircularProgress } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { styledMixins } from '../theme/styledMixins';

const StyledQRCode = lazy(() => import('./StyledQRCode'));

const StyledContainer = styled('div')(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  'div, canvas': {
    ...styledMixins.fillParent,
  },
  padding: theme.spacing(1),
  position: 'relative',
  ...styledMixins.fillParent,
}));

const StyledLoader = styled('div')(() => ({
  position: 'absolute',
  ...styledMixins.stretchAll,
  ...styledMixins.rowCentered,
}));

export type PpQrCodeWithImage = {
  data?: string;
  height: number;
  imageUrl: string;
  width: number;
};

/**
 * The QR Code will always fill the parent container.
 * @param {number} height - Only affects the quality of the QR Code, not the height itself.
 * @param {number} width - Only affects the quality of the QR Code, not the width itself.
 */
export function QRCodeWithImage({
  data,
  height,
  imageUrl,
  width,
}: PpQrCodeWithImage): ReactElement {
  const theme = useTheme();

  return (
    <StyledContainer>
      <Suspense fallback={null}>
        <StyledQRCode
          backgroundOptions={{ color: theme.palette.grayscale.white }}
          cornersDotOptions={{ color: theme.palette.common.black, type: 'dot' }}
          cornersSquareOptions={{ color: theme.palette.common.black, type: 'extra-rounded' }}
          data={data}
          dotsOptions={{ color: theme.palette.common.black, type: 'dots' }}
          height={height}
          image={imageUrl}
          imageOptions={{
            crossOrigin: 'anonymous',
            hideBackgroundDots: true,
            imageSize: 0.6,
            margin: 15,
          }}
          width={width}
        />
      </Suspense>

      {!data && (
        <StyledLoader>
          <CircularProgress size={16} />
        </StyledLoader>
      )}
    </StyledContainer>
  );
}
