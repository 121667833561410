import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import bip21 from 'bip21';
import type {
  TpAppType,
  TpInvoiceCreated,
  TpInvoiceStatus,
  TpInvoiceUpdate,
  TpMessageEvent,
  TpPaymentInfo,
} from './types';

export class BitrefillService {
  readonly ref = 'yTbQ9ute';

  readonly messageOrigin = 'https://embed.bitrefill.com';

  readonly baseUrl = 'https://www.bitrefill.com/embed/';

  invoiceStatus: TpInvoiceStatus | undefined;

  paymentInfo: TpPaymentInfo | undefined;

  errorLog: unknown[] | undefined; // useful for debugging

  awaitingPayment = false;

  unsupportedCurrency = false;

  iframeUrl(email: string, appType: TpAppType): string | undefined {
    if (!email) {
      return undefined;
    }
    const url = new URL(this.baseUrl);
    url.searchParams.append('ref', this.ref);
    url.searchParams.append('paymentMethod', 'bitcoin');
    url.searchParams.append('email', email);
    url.searchParams.append('utm_source', this.ref);

    if (appType === 'twa') {
      url.searchParams.append('theme', 'light');
    }

    return url.toString();
  }

  parseInvoice(message: TpInvoiceCreated): void {
    switch (message.paymentCurrency) {
      case 'BTC':
        this.unsupportedCurrency = false;
        this.paymentInfo = {
          address: message.paymentAddress,
          amount: message.paymentAmount.toString(),
          currency: message.paymentCurrency,
          invoiceId: message.invoiceId,
        };

        if (message.paymentUri) {
          const options = bip21.decode(message.paymentUri).options as Record<string, string>;

          if (options.lightning) {
            this.paymentInfo.lnInvoice = options.lightning;
          }
        }

        break;
      case 'ETH':
      case 'USDC':
      default:
        this.unsupportedCurrency = true;
    }
  }

  invoiceUpdate(message: TpInvoiceUpdate): void {
    this.invoiceStatus = message.status;
    this.awaitingPayment = false;
  }

  parseMessage(data: unknown): void {
    try {
      if (typeof data !== 'string') {
        throw new Error('Message has an invalid format');
      }
      const message: TpMessageEvent = JSON.parse(data);
      logger.info('Parsed message from Bitrefill', message);
      switch (message.event) {
        case 'invoice_created':
          this.parseInvoice(message as TpInvoiceCreated);
          break;
        case 'payment_intent':
          if (this.unsupportedCurrency) {
            break;
          }
          this.awaitingPayment = true;
          break;
        case 'invoice_update':
          this.invoiceUpdate(message as TpInvoiceUpdate);
          break;
        default:
      }
    } catch (e) {
      if (!this.errorLog) {
        this.errorLog = [e];
      } else {
        this.errorLog.push(e);
      }
      logger.error('Error parsing message from Bitrefill', e, data);
    }
  }
}
