import React, { useCallback, useEffect } from 'react';
import { disableRefetchRetry } from '@noah-labs/core-services';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import type { TpLightningAddressData } from '@noah-labs/core-web-ui/src/tools/parseAddressData';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-currencies';
import { Network } from '@noah-labs/shared-schema-gql';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import { useHistory } from 'react-router-dom';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { networkForEnv } from '../../../../utils/networks';
import type { TpOnSign } from '../../../signing/controllers/Sign';
import { useSignWithdrawal } from '../../../signing/controllers/useSignWithdrawal';
import { LnurlErrorAlert } from '../../components';
import {
  useLnAddressInvoiceProxyQuery,
  useLnPaymentSendMutation,
  useWalletParams,
} from '../../data';
import { useWalletError } from '../../hooks';
import { routes } from '../../routes';
import { ConfirmScene } from '../../scenes';
import type { StLightningSendRouter } from './types';

export function Confirm({
  state,
  updateState,
}: TpStateMachine<StLightningSendRouter>): React.ReactElement {
  const history = useHistory();
  const pushAlert = usePushAlert();
  const { AccountType, CurrencyCode, params } = useWalletParams();
  const { fiatCurrency } = useUserFiatCurrency();

  const addressType = state.paymentRequestData?.addressType;
  const address = state.paymentRequestData?.address;
  const lnUrlLink = state.paymentRequestData?.lnUrlLink;

  const isLnAddress = addressType === 'lnaddress';
  const isLnUrl = addressType === 'lnurl';

  /**
   * Setup but disable a query to get a payment request from our API - will only be used if this is a LNAddress payment
   */
  const {
    error: lnAddressInvoiceError,
    isLoading: lnAddressInvoiceIsLoading,
    refetch: lnAddressInvoiceQuery,
  } = useLnAddressInvoiceProxyQuery(
    {
      Input: {
        Amount: state.cryptoAmount,
        LightningAddress: isLnAddress ? address : undefined,
        LnurlLink: lnUrlLink,
      },
    },
    { enabled: false, ...disableRefetchRetry }
  );

  /**
   * Setup the mutation to pay the payment request
   */
  const {
    error: lnPaymentSendError,
    isLoading: lnPaymentSendIsLoading,
    mutateAsync: lnPaymentSend,
  } = useLnPaymentSendMutation();

  const isLnAddressOrLnURL = isLnAddress || isLnUrl;

  useEffect(() => {
    /**
     * Skip this step if the payment request was entered directly (ie not from an LNAddress or LNURL)
     */
    if (!isUndefinedOrNull(state.paymentRequest) || !isLnAddressOrLnURL) {
      return;
    }

    async function queryLnInvoice(): Promise<void> {
      /**
       * If this is an LNAddress payment now we need to get the paymentRequest from our proxy
       */
      const { data } = await lnAddressInvoiceQuery({ throwOnError: false });
      switch (data?.lightningAddressInvoiceProxy.__typename) {
        case 'LightningAddressInvoiceProxySuccess': {
          const updatedData = state.paymentRequestData ?? ({} as TpLightningAddressData);
          updatedData.paymentHash = data.lightningAddressInvoiceProxy.PublicID;
          updateState({
            paymentRequest: data.lightningAddressInvoiceProxy.PaymentRequest,
            paymentRequestData: updatedData,
          });
          break;
        }
        case 'LnurlError':
          pushAlert(LnurlErrorAlert(data.lightningAddressInvoiceProxy));
          break;
        default:
      }
    }
    void queryLnInvoice();
  }, [
    isLnAddressOrLnURL,
    lnAddressInvoiceQuery,
    pushAlert,
    state.paymentRequest,
    state.paymentRequestData,
    updateState,
  ]);

  const sendPayment = useCallback(
    async ({ signature }: TpOnSign) => {
      try {
        /**
         * Request our API to pay the payment request
         */
        await lnPaymentSend({
          Input: {
            AccountType,
            Amount: state.cryptoAmount,
            CurrencyCode,
            PaymentRequest: state.paymentRequest || '',
            ...(signature && { Nonce: signature.nonce, Signature: signature.signature }),
            RequestedAmount: {
              Amount: state.fiatAmount,
              FetchedAt: state.fetchedAt,
              FiatCurrency: fiatCurrency.code,
              Price: state.price,
            },
          },
        });

        history.push(generatePath(routes.lightningSend.complete.path, params));
      } catch (e) {
        // useWalletError handles error
      }
    },
    [
      lnPaymentSend,
      AccountType,
      state.cryptoAmount,
      state.paymentRequest,
      state.fiatAmount,
      state.fetchedAt,
      state.price,
      CurrencyCode,
      fiatCurrency.code,
      history,
      params,
    ]
  );

  const isLoading = lnPaymentSendIsLoading || lnAddressInvoiceIsLoading;
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);

  const { loading: signLoading, sign } = useSignWithdrawal({
    network: networkForEnv(Network.Lightning),
    payload: {
      AccountType,
      Amount: state.cryptoAmount,
      CurrencyCode,
      Destination: state.paymentRequestData?.paymentHash || '',
      inputType: 'withdraw',
    },
  });

  const readyForPayment =
    !signLoading &&
    !isUndefinedOrNull(state.paymentRequest) &&
    !isUndefinedOrNull(state.paymentRequestData?.paymentHash);

  const onConfirm = useCallback(async () => {
    await sign(sendPayment);
  }, [sign, sendPayment]);

  const { ApiErrorScene } = useWalletError(lnPaymentSendError || lnAddressInvoiceError);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <ConfirmScene
      backButton
      addressData={state.paymentRequestData}
      cryptoAmount={state.cryptoAmount}
      cryptoCurrency={cryptoCurrency}
      fiatAmount={state.fiatAmount}
      fiatCurrency={fiatCurrency}
      isCtaDisabled={!readyForPayment}
      isLoading={isLoading}
      pageTitle={routes.lightningSend.confirm.title}
      onConfirm={onConfirm}
    />
  );
}
