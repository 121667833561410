import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDesktop } from '@noah-labs/core-web-ui/src/hooks/useDesktop';
import { CurrencyAvatar } from '@noah-labs/core-web-ui/src/images';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneHeaderCard } from '@noah-labs/core-web-ui/src/scene/SceneHeaderCard';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { TextWithIcon } from '@noah-labs/core-web-ui/src/typography/TextWithIcon';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';
import { Helmet } from 'react-helmet';
import { AppHeaderData, SidebarNavData } from '../../../components';
import { useBackHijack } from '../../../hooks';
import { routes } from '../routes';

export type PpAccountScene = {
  ActionsMenuSlot: React.ReactElement;
  BalanceSlot: React.ReactElement;
  TransactionsSlot: React.ReactElement;
  cryptoCurrency: TpCryptoCurrencyUI;
};

export function AccountScene({
  ActionsMenuSlot,
  BalanceSlot,
  cryptoCurrency,
  TransactionsSlot,
}: PpAccountScene): React.ReactElement {
  const { backTo, goBack } = useBackHijack(routes.base.path);
  const theme = useTheme();
  const isDesktop = useDesktop();
  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeaderData
          disableFade
          helpButton
          scanButton
          backButton={goBack}
          backgroundColor={theme.palette.grayscale.offWhite}
          backTo={backTo}
          fullWidth={isDesktop}
        >
          <TextWithIcon>
            <CurrencyAvatar Icon={cryptoCurrency.Icon} size={2.5} />
            <span>{cryptoCurrency.label}</span>
          </TextWithIcon>
        </AppHeaderData>
      }
      dataQa="account"
      SideNavbarSlot={<SidebarNavData />}
    >
      <Helmet>
        <title>{cryptoCurrency.label}</title>
      </Helmet>
      <SceneHeaderCard>
        {BalanceSlot}
        {ActionsMenuSlot}
      </SceneHeaderCard>
      <SceneMain>
        <Typography gutterBottom component="h1" variant="subHeadingS">
          Activity history
        </Typography>
        {TransactionsSlot}
      </SceneMain>
    </AppContainer>
  );
}
