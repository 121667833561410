import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { InlineLinkOrButton } from '@noah-labs/core-web-ui/src/buttons/InlineLinkOrButton';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { ListSection } from '@noah-labs/core-web-ui/src/lists/ListSection';
import { MenuItemSwitch } from '@noah-labs/core-web-ui/src/menus/MenuItemSwitch';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import type { SocialProvider } from '@noah-labs/fe-shared-data-access-auth';
import { toTitleCase } from '@noah-labs/shared-tools/src/browser/strings';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { AppHeaderData } from '../../../components';
import { TpAnalyticsEvent, useAnalytics } from '../../analytics';
import { orderedProviders } from '../../auth/constants';
import { UnlinkLowDialog } from '../components/dialogs';
import { routes } from '../routes';

export type PpLinkedAccountsScene = {
  availableProviders: SocialProvider[] | undefined;
  linkedProvidersInitial: SocialProvider[] | undefined;
  onLinkAccount: (provider: SocialProvider) => Promise<void>;
  onUnLinkAccount: (provider: SocialProvider) => Promise<SocialProvider[] | undefined>;
};

export function LinkedAccountsScene({
  availableProviders,
  linkedProvidersInitial,
  onLinkAccount,
  onUnLinkAccount,
}: PpLinkedAccountsScene): React.ReactElement {
  const analytics = useAnalytics();
  const pushAlert = usePushAlert();
  const history = useHistory();

  const unlinkDialog = useRef<TpDialogToggle>(null);
  const [loading, setLoading] = useState(false);
  const [linkedProviders, setLinkedProviders] = useState<SocialProvider[] | undefined>(undefined);

  const handleNewPasswordRedirect = useCallback(() => {
    analytics.track(TpAnalyticsEvent.NewPasswordLinkClicked);
    history.push({
      pathname: routes.newPassword.base.path,
      search: new URLSearchParams({ returnTo: routes.accounts.path }).toString(),
    });
  }, [analytics, history]);

  const handleAccountLinking = useCallback(
    async (provider: SocialProvider) => {
      try {
        analytics.track(TpAnalyticsEvent.SocialAuthLink, { provider });
        setLoading(true);
        await onLinkAccount(provider);
      } catch (error) {
        if (error instanceof Error) {
          pushAlert({
            dismissable: true,
            key: 'linkingAccountError',
            message: error.message,
            preventDuplicate: true,
            severity: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [analytics, onLinkAccount, setLoading, pushAlert]
  );

  const handleAccountUnLinking = useCallback(
    async (provider: SocialProvider) => {
      try {
        analytics.track(TpAnalyticsEvent.SocialAuthUnlink, { provider });
        setLoading(true);
        const remainingProviders = await onUnLinkAccount(provider);
        setLinkedProviders(remainingProviders);
      } catch (error) {
        if (error instanceof Error) {
          pushAlert({
            dismissable: true,
            key: 'unLinkingAccountError',
            message: error.message,
            preventDuplicate: true,
            severity: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [analytics, onUnLinkAccount, setLoading, pushAlert]
  );

  const onToggleLink = useCallback(
    async (provider: SocialProvider) => {
      if (linkedProviders?.includes(provider)) {
        await handleAccountUnLinking(provider);
        return;
      }

      await handleAccountLinking(provider);
    },
    [handleAccountLinking, handleAccountUnLinking, linkedProviders]
  );

  useEffect(() => {
    if (!linkedProvidersInitial) {
      return;
    }
    setLinkedProviders(linkedProvidersInitial);
  }, [linkedProvidersInitial]);

  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeaderData helpButton backTo={routes.settings.base.path}>
          <AppHeaderTitle>Linked accounts</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa="linked-accounts-menu"
    >
      <Helmet>
        <title>{routes.base.title}</title>
      </Helmet>

      <SceneMain dense>
        <Stack spacing={3}>
          <Typography color="text.light" textAlign="center" variant="paragraphBodyS">
            You can use these to quickly log in to your NOAH account
          </Typography>
          <ListSection>
            {orderedProviders.map((p) => (
              <Fragment key={p.name}>
                <MenuItemSwitch
                  checked={linkedProviders?.includes(p.name) ?? false}
                  dataQa={`${p.name}-sign-in-toggle`}
                  disabled={!availableProviders?.includes(p.name) || loading}
                  icon={p.icon}
                  label={`Connected with ${toTitleCase(p.name)}`}
                  onClick={(): Promise<void> => onToggleLink(p.name)}
                />
                {availableProviders && !availableProviders.includes(p.name) && (
                  <Typography color="text.light" mb={1} variant="paragraphBodyS">
                    Account linking or unlinking is not possible for this provider.{' '}
                    <InlineLinkOrButton onClick={handleNewPasswordRedirect}>
                      Set up a password to continue.
                    </InlineLinkOrButton>
                  </Typography>
                )}
              </Fragment>
            ))}
          </ListSection>
        </Stack>
      </SceneMain>
      <UnlinkLowDialog ref={unlinkDialog} />
    </AppContainer>
  );
}
