import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import type { TpSelectOption } from '@noah-labs/core-web-ui/src/forms/SearchSelect';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { FooterContentText } from '@noah-labs/core-web-ui/src/layout/FooterContentText';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SvgMastercardLight } from '@noah-labs/fe-shared-ui-assets/images/MastercardLight';
import { SvgVisaLight } from '@noah-labs/fe-shared-ui-assets/images/VisaLight';
import { LockIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/LockIcon';
import { getCkoCardDetailsSchema } from '@noah-labs/fe-shared-util-validation-schemas';
import type { FrameCardTokenizedEvent } from 'frames-react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { AppHeaderData } from '../../../components';
import { webConfigBrowser } from '../../../webConfigBrowser';
import type { TpTokenizedCardForm } from '../components';
import { TokenizedCardForm } from '../components';
import type { TpBillingAddressForm } from '../components/forms/BillingAddresses/schema';
import {
  billingAddressSchema,
  emptyBillingAddress,
} from '../components/forms/BillingAddresses/schema';

export type PpEnterPaymentInfoScene = {
  countries: TpSelectOption[] | undefined;
  ctaLabel: string;
  defaultAddress: TpBillingAddressForm | undefined | null;
  fullName: string | undefined;
  isLoading?: boolean;
  isVisible: boolean;
  onCardTokenized?: (cardTokenized: FrameCardTokenizedEvent, save: boolean) => Promise<void>;
  pageTitle: string;
  saveToggle: boolean;
  visiblePath: string;
};

const defaults: TpTokenizedCardForm = {
  billingAddress: emptyBillingAddress,
  cardholderName: '',
  cardNumber: '',
  cvv: '',
  expiryDate: '',
  saveCard: true,
};

const addPaymentCardFormId = 'addPaymentCard';
const nameMatchThreshold = webConfigBrowser.cko.cardHolderNameMatchThreshold;

export function EnterPaymentInfoScene({
  countries,
  ctaLabel,
  defaultAddress,
  fullName,
  isLoading = false,
  isVisible,
  onCardTokenized,
  pageTitle,
  saveToggle,
  visiblePath,
}: PpEnterPaymentInfoScene): React.ReactElement {
  const schema = useMemo(
    () =>
      getCkoCardDetailsSchema(fullName, nameMatchThreshold).extend({
        billingAddress: billingAddressSchema,
        saveCard: z.boolean(),
      }),
    [fullName]
  );
  const billingAddress = defaultAddress || defaults.billingAddress;
  const defaultValues = useMemo(() => ({ ...defaults, billingAddress }), [billingAddress]);
  const methods = useForm<TpTokenizedCardForm>({
    defaultValues,
    mode: 'onBlur',
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
    resolver: zodResolver(schema),
    values: defaultValues,
  });

  const styles = {
    cardIcon: css`
      width: 48px;
      vertical-align: middle;
    `,
  };

  return (
    <AppContainer
      footerSolidFade
      AppFooterSlot={
        <Stack spacing={3}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Stack alignSelf="center" direction="row" ml={1} spacing={0.5}>
              <SvgVisaLight css={styles.cardIcon} />
              <SvgMastercardLight css={styles.cardIcon} />
            </Stack>
            <FooterContentText>We accept all major card providers</FooterContentText>
          </Stack>
          <PrimaryButton
            color="primaryBrand"
            data-qa="add-payment-card-button"
            disabled={!methods.formState.isValid}
            endIcon={<LockIcon />}
            form={addPaymentCardFormId}
            loading={methods.formState.isSubmitting || isLoading}
            type="submit"
          >
            {ctaLabel}
          </PrimaryButton>
        </Stack>
      }
      AppHeaderSlot={
        <AppHeaderData backButton exitButton helpButton>
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa="add-payment-card"
      isVisible={isVisible}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SceneMain maxWidth="xs">
        <TokenizedCardForm
          countries={countries}
          defaultAddress={defaultAddress}
          formId={addPaymentCardFormId}
          methods={methods}
          saveToggle={saveToggle}
          visiblePath={visiblePath}
          onCardTokenized={onCardTokenized}
        />
      </SceneMain>
    </AppContainer>
  );
}
