import { useStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import type { CountryCode, FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import { CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { getDefaults } from '../../../../utils';
import { AuthRouteData } from '../../../auth';
import { KycReminderOrigin } from '../../../kyc/controllers/Reminder';
import { NeedsKycRouter } from '../../../kyc/utils/NeedsKycRouter';
import { useWalletParams } from '../../data';
import { routes } from '../../routes';
import type { TpPaymentBank, TpPaymentCard, TpPaymentMethod } from '../../types';
import { Complete } from './Complete';
import { Complete as CompleteWithMoonpay } from './CompleteWithMoonpay';
import { Confirm } from './Confirm';
import { ConfirmWithMoonpay } from './ConfirmWithMoonpay';
import { EnterAccountDetails } from './EnterAccountDetails';
import { EnterAmount } from './EnterAmount';
import { EnterPaymentInfo } from './EnterPaymentInfo';
import { SelectPayoutMethod } from './SelectPayoutMethod';
import { SellWithMoonpay } from './SellWithMoonpay';

export type StSellRouter = {
  cryptoAmount: string;
  cryptoUnit: CurrencyUnit;
  fetchedAt: string;
  fiatAmount: string;
  fiatCurrencyCode: FiatCurrencyCode;
  payoutMethod?: TpPaymentMethod;
  reference?: string;
  savePayoutBank?: boolean;
  savePayoutCard?: boolean;
  selectedCountry: CountryCode | undefined;
  selectedPayoutBank?: TpPaymentBank;
  selectedPayoutCard?: TpPaymentCard;
  transactionId?: string;
};

const { fiatCurrency } = getDefaults();

const emptyState: StSellRouter = {
  cryptoAmount: '',
  cryptoUnit: CurrencyUnit.Default,
  fetchedAt: '',
  fiatAmount: '',
  fiatCurrencyCode: fiatCurrency.code,
  selectedCountry: undefined,
  selectedPayoutCard: undefined,
  transactionId: undefined,
};

export function SellRouter(): React.ReactElement {
  const { params } = useWalletParams();
  const initialState = { ...emptyState };

  const sm = useStateMachine<StSellRouter>({
    emptyState,
    initialState,
    name: 'SellRouter',
  });

  const hasPaymentMethod = sm.state.selectedPayoutCard || sm.state.selectedPayoutBank;

  return (
    <Switch404>
      <Route exact path={[routes.sell.payoutMethod.path, routes.sell.enterPaymentInfo.path]}>
        <Route
          exact
          path={routes.sell.payoutMethod.path}
          render={(): React.ReactElement => (
            <AuthRouteData
              featureRedirect={generatePath(routes.sell.moonpay.base.path, params)}
              route={routes.sell.payoutMethod}
            >
              <SelectPayoutMethod {...sm} />
            </AuthRouteData>
          )}
        />
        {/* Keep the screen in the DOM to preload Frames card fields */}
        <Route
          exact
          path={[routes.sell.enterPaymentInfo.path, routes.sell.payoutMethod.path]}
          render={(): React.ReactElement => (
            <AuthRouteData route={routes.sell.enterPaymentInfo}>
              <EnterPaymentInfo {...sm} />
            </AuthRouteData>
          )}
        />
      </Route>
      <Route
        exact
        path={routes.sell.enterAccountDetails.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.sell.enterAccountDetails}>
            <EnterAccountDetails {...sm} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.sell.enterAmount.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.sell.enterAmount}>
            <EnterAmount {...sm} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.sell.confirm.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={
              !hasPaymentMethod && generatePath(routes.sell.payoutMethod.path, params)
            }
            route={routes.sell.confirm}
          >
            <NeedsKycRouter origin={KycReminderOrigin.Sell}>
              <Confirm {...sm} />
            </NeedsKycRouter>
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.sell.complete.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.sell.complete}>
            <NeedsKycRouter origin={KycReminderOrigin.Sell}>
              <Complete {...sm} />
            </NeedsKycRouter>
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.sell.moonpay.base.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.sell.moonpay.base}>
            <NeedsKycRouter origin={KycReminderOrigin.Sell}>
              <SellWithMoonpay />
            </NeedsKycRouter>
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.sell.moonpay.confirm.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.sell.moonpay.confirm}>
            <NeedsKycRouter origin={KycReminderOrigin.Sell}>
              <ConfirmWithMoonpay />
            </NeedsKycRouter>
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.sell.moonpay.complete.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.sell.moonpay.complete}>
            <NeedsKycRouter origin={KycReminderOrigin.Sell}>
              <CompleteWithMoonpay />
            </NeedsKycRouter>
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
