import type { SVGProps } from 'react';

export function SvgGooglepayLight(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg {...props} fill="none" viewBox="0 0 886 601" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M635.117 81H249.883C129.497 81 31 179.55 31 300s98.497 219 218.883 219h385.234C755.503 519 854 420.45 854 300S755.503 81 635.117 81"
        fill="#fff"
      />
      <path
        d="M635.117 98.739c27.032 0 53.298 5.365 78.032 15.877 23.968 10.184 45.418 24.747 64.023 43.253 18.496 18.505 33.051 40.077 43.229 64.058 10.507 24.747 15.87 51.027 15.87 78.073 0 27.047-5.363 53.326-15.87 78.073-10.178 23.981-24.733 45.443-43.229 64.058-18.496 18.505-40.055 33.069-64.023 43.252-24.734 10.512-51 15.878-78.032 15.878H249.883c-27.032 0-53.298-5.366-78.032-15.878-23.967-10.183-45.418-24.747-64.023-43.252-18.496-18.506-33.051-40.077-43.23-64.058C54.093 353.326 48.73 327.047 48.73 300s5.362-53.326 15.869-78.073c10.178-23.981 24.733-45.443 43.229-64.058 18.496-18.506 40.056-33.069 64.023-43.253 24.734-10.512 51-15.877 78.032-15.877zm0-17.739H249.883C129.497 81 31 179.55 31 300s98.497 219 218.883 219h385.234C755.503 519 854 420.45 854 300S755.503 81 635.117 81"
        fill="#3C4043"
      />
      <path
        d="M423.72 315.349v66.192h-21.006V218.087h55.688c14.114 0 26.148 4.704 35.995 14.113q15.098 14.114 15.098 34.463c0 13.895-5.033 25.383-15.098 34.682-9.737 9.3-21.772 13.895-35.995 13.895H423.72zm0-77.131v57.001h35.12c8.315 0 15.317-2.845 20.787-8.425 5.58-5.579 8.424-12.363 8.424-20.021 0-7.549-2.844-14.223-8.424-19.803-5.47-5.798-12.363-8.643-20.787-8.643h-35.12zm140.697 27.789q23.304 0 36.761 12.472 13.457 12.473 13.457 34.135v68.927h-20.021v-15.536h-.876c-8.643 12.8-20.24 19.146-34.682 19.146-12.363 0-22.647-3.611-30.962-10.941s-12.472-16.411-12.472-27.351q0-17.397 13.129-27.571c8.752-6.893 20.459-10.284 35.01-10.284 12.472 0 22.757 2.297 30.743 6.892v-4.813c0-7.331-2.844-13.458-8.643-18.6-5.798-5.142-12.582-7.658-20.35-7.658-11.706 0-21.006 4.923-27.789 14.879l-18.49-11.597c10.175-14.77 25.273-22.1 45.185-22.1m-27.133 81.18c0 5.47 2.298 10.065 7.003 13.676 4.595 3.61 10.065 5.47 16.301 5.47q13.293 0 23.632-9.847c6.893-6.564 10.394-14.223 10.394-23.084-6.565-5.143-15.646-7.768-27.352-7.768-8.534 0-15.645 2.078-21.334 6.126-5.799 4.267-8.644 9.409-8.644 15.427m191.572-77.57-70.021 161.047h-21.662l26.039-56.344-46.17-104.703h22.866l33.26 80.305h.437l32.385-80.305z"
        fill="#3C4043"
      />
      <path
        d="M340.166 302.002c0-6.849-.612-13.402-1.75-19.704h-88.051v36.104l50.71.011c-2.056 12.013-8.676 22.253-18.818 29.08v23.424h30.186c17.625-16.312 27.723-40.426 27.723-68.915"
        fill="#4285F4"
      />
      <path
        d="M282.268 347.493c-8.402 5.667-19.222 8.982-31.881 8.982-24.452 0-45.196-16.476-52.624-38.686h-31.138v24.157c15.427 30.612 47.133 51.618 83.762 51.618 25.317 0 46.586-8.325 62.067-22.658z"
        fill="#34A853"
      />
      <path
        d="M194.83 299.868c0-6.236 1.04-12.264 2.932-17.931V257.78h-31.137c-6.378 12.658-9.967 26.947-9.967 42.088s3.6 29.431 9.967 42.089l31.137-24.157a56.5 56.5 0 0 1-2.932-17.932"
        fill="#FABB05"
      />
      <path
        d="M250.387 243.25c13.818 0 26.192 4.76 35.962 14.059l26.75-26.728c-16.247-15.131-37.428-24.42-62.712-24.42-36.618 0-68.335 21.007-83.762 51.619l31.138 24.157c7.428-22.21 28.172-38.687 52.624-38.687"
        fill="#E94235"
      />
    </svg>
  );
}
