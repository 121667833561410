import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { useToggleDialog } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';

export type PpSecretPhraseSavingDialog = {
  initialOpen?: boolean;
  onCancelClick: () => void;
  onContinueClick: () => void;
};

export const SecretPhraseSavingDialog = forwardRef<TpDialogToggle, PpSecretPhraseSavingDialog>(
  ({ initialOpen, onCancelClick, onContinueClick }, ref) => {
    const { open, toggle } = useToggleDialog({ initialOpen, ref });

    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle>Have you saved your recovery phrase?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your recovery phrase is your key to your wallet if you forget your password. We can’t
            retrieve it for you as we don’t have access to it, so it’s crucial to keep it safely
            stored.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <PrimaryButton
              color="primaryBrand"
              data-qa="verify-account-btn"
              onClick={onContinueClick}
            >
              Yes, continue
            </PrimaryButton>
            <PrimaryButton variant="text" onClick={onCancelClick}>
              Cancel
            </PrimaryButton>
          </ButtonGroup>
        </DialogActions>
      </LowDialog>
    );
  }
);

SecretPhraseSavingDialog.displayName = 'SecretPhraseSavingDialog';
