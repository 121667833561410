import React from 'react';
import { Stack, Typography } from '@mui/material';
import { getTransactionTimeText } from '@noah-labs/core-web-ui/src/alerts/getTransactionTimeText';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import {
  StatusElevatedCard,
  TpAvatarStatus,
} from '@noah-labs/core-web-ui/src/cards/StatusElevatedCard';
import { useNavigation } from '@noah-labs/core-web-ui/src/hooks/useNavigation';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { FooterContentText } from '@noah-labs/core-web-ui/src/layout/FooterContentText';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import { webConfigBrowser } from '../../../webConfigBrowser';

export type PpCompleteScene = {
  BalanceSlot: React.ReactElement;
  enableAnimation?: boolean;
  processingTimeMs?: number;
  sceneTitle: string;
  transactionUrl?: string;
};

export function CompleteScene({
  BalanceSlot,
  enableAnimation = webConfigBrowser.settings.enableLoaderAnimations,
  processingTimeMs,
  sceneTitle,
  transactionUrl,
}: PpCompleteScene): React.ReactElement {
  const { exitTo } = useNavigation();

  const processingTime = processingTimeMs ? getTransactionTimeText(processingTimeMs) : undefined;

  return (
    <AppContainer
      AppFooterSlot={
        <Stack spacing={2} textAlign="center">
          {processingTime && <FooterContentText>{processingTime}</FooterContentText>}
          <ButtonGroup>
            <PrimaryButton data-qa="done-button" href={exitTo}>
              Done
            </PrimaryButton>
            <PrimaryButton
              data-qa="view-transaction-button"
              disabled={!transactionUrl}
              href={transactionUrl}
              variant="text"
            >
              View transactions
            </PrimaryButton>
          </ButtonGroup>
        </Stack>
      }
      AppHeaderSlot={<AppHeaderData exitButton helpButton />}
      dataQa="complete"
    >
      <Helmet>
        <title>{sceneTitle}</title>
      </Helmet>
      <SceneMain>
        <StatusElevatedCard enableAnimation={enableAnimation} status={TpAvatarStatus.pending}>
          <Stack marginTop={1} spacing={3} textAlign="center">
            <Typography color="primary.dark" variant="paragraphBodyM">
              Payment is on its way!
            </Typography>
            {BalanceSlot}
          </Stack>
        </StatusElevatedCard>
      </SceneMain>
    </AppContainer>
  );
}
