import { z } from 'zod';

export const billingAddressSchema = z.object({
  City: z.string().trim().min(1, 'City is required'),
  CountryCode: z.string().trim().min(1, 'Country is required'),
  PostCode: z.string().trim().min(1, 'Post / Zip code is required'),
  State: z.string().trim().min(1, 'State is required'),
  Street: z.string().trim().min(1, 'Address Line 1 is required'),
  Street2: z.string().trim().nullable().optional(),
});

export type TpBillingAddressSchema = z.infer<typeof billingAddressSchema>;
