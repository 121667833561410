//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgTelegram } from '../icons/Telegram';

export function TelegramIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgTelegram} data-qa="TelegramIcon">
      <svg />
    </SvgIcon>
  );
}
