import type { MutableRefObject } from 'react';
import { useEffect, useState } from 'react';

export function useIframeLocation(
  ref: MutableRefObject<HTMLIFrameElement | null>,
  enabled: boolean
): Location | null {
  const [location, setLocation] = useState<Location | null>(null);

  useEffect(() => {
    if (!enabled || !ref.current) {
      return undefined;
    }
    // Check the iframe location every 1/2 second
    const interval = setInterval(() => {
      try {
        // Because of cross-domain issues, if the iFrame src is undefined, it means that Moonpay is still using it.
        // However, if we can read the src, it means it must have forwarded back to our domain and we can read its location
        const iframeLocation = ref.current?.contentDocument?.location;
        if (iframeLocation) {
          setLocation(iframeLocation);
        }
      } catch (error) {
        // Have to catch the error here else the browser crashes / reloads, but do nothing
      }
    }, 500);

    return (): void => {
      clearInterval(interval);
    };
  }, [enabled, ref]);
  return location;
}
