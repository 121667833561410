import { Fragment } from 'react';
import type { SxProps, Theme } from '@mui/material';
import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { CenteredFullPage } from '../layout/CenteredFullPage';
import { NoahSpinner } from './NoahSpinner';

export type PpLoadingPage = {
  Icon?: React.ReactElement;
  isStatic?: boolean;
  message?: string;
  sx?: SxProps<Theme>;
};

export function LoadingPage({
  Icon,
  isStatic = false,
  message,
  sx,
}: PpLoadingPage): React.ReactElement {
  return (
    <Fragment>
      <Helmet>
        <title>{message || 'Loading...'}</title>
      </Helmet>
      <CenteredFullPage sx={sx}>
        {Icon || <NoahSpinner isStatic={isStatic} />}
        {message && <Typography sx={{ p: 4 }}>{message}</Typography>}
      </CenteredFullPage>
    </Fragment>
  );
}
