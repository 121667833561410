import { oryWebAuthnBufferEncode } from './utils';

type OryWebAuthnRegister = {
  id: string;
  rawId: string;
  response: {
    attestationObject: string;
    clientDataJSON: string;
  };
  type: string;
};

export async function getOryWebAuthnRegister(
  opt: CredentialCreationOptions
): Promise<OryWebAuthnRegister | undefined> {
  const credential = await navigator.credentials.create(opt);
  if (credential === null) {
    return undefined;
  }

  const { rawId, response } = credential as PublicKeyCredential;
  const { attestationObject, clientDataJSON } = response as AuthenticatorAttestationResponse;
  return {
    id: credential.id,
    rawId: oryWebAuthnBufferEncode(rawId),
    response: {
      attestationObject: oryWebAuthnBufferEncode(attestationObject),
      clientDataJSON: oryWebAuthnBufferEncode(clientDataJSON),
    },
    type: credential.type,
  };
}
