import React from 'react';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-currencies';
import { ActionsMenuData, CurrentBalanceData, TransactionsData } from '../../components';
import { useWalletParams } from '../../data';
import { useAvailableCurrencyActions } from '../../hooks';
import { routes } from '../../routes';
import { AccountScene } from '../../scenes';
import { getReceiveCryptoRoute } from '../../utils/getReceiveCryptoRoute';

export function Account(): React.ReactElement {
  const { CurrencyCode, params } = useWalletParams();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);

  const availableCurrencyActions = useAvailableCurrencyActions(CurrencyCode);

  const receivePath = getReceiveCryptoRoute(cryptoCurrency.networks);

  return (
    <AccountScene
      ActionsMenuSlot={
        <ActionsMenuData
          availableCurrencyActions={availableCurrencyActions}
          customRoutes={{
            receive: generatePath(receivePath, params),
            send: generatePath(routes.address.text.path, params),
          }}
        />
      }
      BalanceSlot={<CurrentBalanceData />}
      cryptoCurrency={cryptoCurrency}
      TransactionsSlot={<TransactionsData />}
    />
  );
}
