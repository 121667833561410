import type { TpFeatureFlagUI, TpFeatureUI } from '@noah-labs/core-web-ui/src/types';
import type { AuthGroups, FeatureFlag } from '@noah-labs/shared-schema-gql';

export function isGroupAllowed(
  groups: AuthGroups[] | undefined,
  route?: { groups?: Set<AuthGroups> }
): boolean {
  // if the user belongs to no groups, then the route is not allowed
  if (!groups) {
    return false;
  }

  // if the route requires no groups, then the route is allowerd
  if (!route?.groups) {
    return true;
  }

  // check if the user belongs to at least one group required by at least one group of the route
  return groups.some((group) => route.groups?.has(group));
}

export function getFeatureFlagForRoute(
  features: Map<TpFeatureUI, TpFeatureFlagUI> | undefined,
  route?: { feature?: TpFeatureUI }
): Omit<FeatureFlag, 'Feature'> {
  // if the route has no specific feature requirements, then the route is allowed
  if (!route?.feature) {
    return { Enabled: true };
  }

  // check if the user has this feature enabled, or if not found in the set of features
  return features?.get(route.feature) || { Enabled: false };
}
