import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { IconButton } from '@mui/material';
import { cssMixins } from '@noah-labs/core-web-ui/src/theme/cssMixins';
import { FacebookSquareIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/FacebookSquareIcon';
import { TelegramIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/TelegramIcon';
import { TwitterIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/TwitterIcon';
import { WhatsappIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/WhatsappIcon';
import { FacebookShareButton, TelegramShareButton, WhatsappShareButton } from 'react-share';
import type { TpEventProperties } from '../../../analytics';
import { TpAnalyticsEvent } from '../../../analytics';
import { TpSocialSharePlatform } from '../../types';

const noahTwitterHandle = '@NOAH_HQ';

type PpSocialShareButton = {
  button: TpSocialSharePlatform;
  iconStyles: SerializedStyles[];
  referralUrl: string;
  shareText: string;
  track: <T extends keyof TpEventProperties>(
    eventName: T,
    properties?: TpEventProperties[T]
  ) => void;
};

export function SocialShareButton({
  button,
  iconStyles,
  referralUrl,
  shareText,
  track,
}: PpSocialShareButton): React.ReactElement | null {
  const styles = {
    iconButton: css`
      padding: 0;
    `,
    socialButton: css`
      ${cssMixins.colCentered}
    `,
  };

  const twitterText = shareText.includes('NOAH')
    ? shareText.replace('NOAH', noahTwitterHandle)
    : `${shareText} ${noahTwitterHandle}`;

  switch (button) {
    case TpSocialSharePlatform.Facebook:
      return (
        <FacebookShareButton
          aria-label="facebook-share-button"
          css={styles.socialButton}
          quote={shareText}
          url={referralUrl}
          onClick={(): void =>
            track(TpAnalyticsEvent.SocialReferralCardClicked, { platform: 'Facebook' })
          }
        >
          <FacebookSquareIcon css={iconStyles} />
        </FacebookShareButton>
      );
    case TpSocialSharePlatform.Telegram:
      return (
        <TelegramShareButton
          aria-label="telegram-share-button"
          css={styles.socialButton}
          url={shareText}
          onClick={(): void =>
            track(TpAnalyticsEvent.SocialReferralCardClicked, { platform: 'Telegram' })
          }
        >
          <TelegramIcon css={iconStyles} />
        </TelegramShareButton>
      );
    case TpSocialSharePlatform.Twitter:
      return (
        <IconButton
          aria-label="twitter-share-button"
          component="a"
          css={(styles.socialButton, styles.iconButton)}
          href={`https://twitter.com/intent/tweet?url=${twitterText}`}
          target="_blank"
          onClick={(): void =>
            track(TpAnalyticsEvent.SocialReferralCardClicked, { platform: 'Twitter' })
          }
        >
          <TwitterIcon css={iconStyles} />
        </IconButton>
      );
    case TpSocialSharePlatform.Whatsapp:
      return (
        <WhatsappShareButton
          aria-label="whatsapp-share-button"
          css={styles.socialButton}
          url={shareText}
          onClick={(): void =>
            track(TpAnalyticsEvent.SocialReferralCardClicked, { platform: 'WhatsApp' })
          }
        >
          <WhatsappIcon css={iconStyles} />
        </WhatsappShareButton>
      );
    default:
      return null;
  }
}
