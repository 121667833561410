import { Fragment } from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useAutocomplete,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import type { TpMuiSvgIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/types';
import { ElevatedCard } from '../cards/ElevatedCard';
import { List } from '../lists/List';
import { ACContent, ACNoOptions, ACOptionLabel, ACTextField } from './atoms/SelectAtoms';
import { SkeletonSelectList } from './atoms/SkeletonSelectList';

export type TpSelectOption = {
  currency?: string;
  icon?: TpMuiSvgIcon;
  label: string;
  labelStart?: string;
  symbol?: string;
  value: string;
};

export type PpSearchSelect = {
  id: string;
  loading: boolean;
  onChange: (value: TpSelectOption | null) => void;
  options: Array<TpSelectOption> | undefined;
};

const emptyOptions: Array<TpSelectOption> = [];
/**
 * TODO: investigate slow opening performance - is related to the size of the list
 * Tried memo-izing the ListItemButton parts but it didn't help
 */
export function SearchSelect({
  id,
  loading,
  onChange,
  options = emptyOptions,
}: PpSearchSelect): React.ReactElement | null {
  const filterOptions = createFilterOptions<TpSelectOption>({
    stringify: (option): string => [option.labelStart, option.label].join(''),
  });

  const { getInputProps, getListboxProps, getOptionProps, getRootProps, groupedOptions } =
    useAutocomplete({
      clearOnBlur: false,
      disableCloseOnSelect: false,
      filterOptions,
      id: `${id}-select`,
      onChange: (_, newValue): void => {
        onChange(newValue);
      },
      open: true,
      options,
    });

  return (
    <Fragment>
      <ACTextField
        dataQa={`${id}-search-input`}
        inputProps={getInputProps()}
        rootProps={getRootProps()}
      />
      {loading ? (
        <SkeletonSelectList />
      ) : (
        <ACContent>
          {groupedOptions.length > 0 ? (
            <List {...getListboxProps()} spacing={0.5}>
              {(groupedOptions as Array<TpSelectOption>).map((option, index) => {
                const { icon: Icon } = option;
                return (
                  // key is in optionProps
                  // eslint-disable-next-line react/jsx-key
                  <ListItem
                    {...getOptionProps({ index, option })}
                    disablePadding
                    component="li"
                    data-qa={`${id}-${option.value}`}
                  >
                    <ElevatedCard>
                      <ListItemButton>
                        {option.symbol && (
                          <ListItemIcon sx={{ color: 'inherit' }}>{option.symbol}</ListItemIcon>
                        )}
                        {Icon && <Icon sx={{ fontSize: '2rem', mr: 1 }} />}
                        <ListItemText
                          primary={<ACOptionLabel {...option} />}
                          primaryTypographyProps={{
                            color: 'text.primary',
                          }}
                        />
                      </ListItemButton>
                    </ElevatedCard>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <ACNoOptions />
          )}
        </ACContent>
      )}
    </Fragment>
  );
}
