import { useCallback } from 'react';
import { useSearchParams } from '@noah-labs/core-web-ui/src/hooks/useSearchParams';
import { assetPaths } from '@noah-labs/fe-assets';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useAuth } from '../../auth/hooks/useAuth';
import { updateUserInitCache } from '../data/updateUserInitCache';
import { useUserProfileCreateMutation } from '../data/user.generated';
import { useUserError } from '../hooks/useUserError';
import type { TpUsernameForm } from '../scenes';
import { UsernameScene } from '../scenes';

export type PpUsername = {
  logoutTo: string;
  successTo: string;
  suffix: string;
};

export function Username({ logoutTo, successTo, suffix }: PpUsername): React.ReactElement {
  const { data: auth } = useAuth();
  const history = useHistory();
  const searchParams = useSearchParams();
  const referralCode = searchParams?.get(webConfigBrowser.settings.referralCodeParam);
  const queryClient = useQueryClient();
  const { error, mutateAsync } = useUserProfileCreateMutation();

  const onSubmit = useCallback(
    async (values: TpUsernameForm): Promise<void> => {
      try {
        const avatarUrl = `${assetPaths.images}/arkonaut-${Math.floor(Math.random() * 6) + 1}.png`;
        const data = await mutateAsync({
          Input: {
            AvatarUrl: avatarUrl,
            Country: values.country,
            ReferrerReferral: auth?.referralCode ?? referralCode,
            UsernameDisplay: values.usernameDisplay,
          },
        });
        // Directly update the cache in react-query for 'UserInit' - will cause that hook to rerender and cause auth routing to fire
        updateUserInitCache(queryClient, data.userProfileCreate);
        history.push(successTo);
      } catch (e) {
        // error is handled in useUserError
      }
    },
    [history, mutateAsync, queryClient, referralCode, auth?.referralCode, successTo]
  );

  const { ApiErrorScene } = useUserError(error);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <UsernameScene email={auth?.email} logoutTo={logoutTo} suffix={suffix} onSubmit={onSubmit} />
  );
}
