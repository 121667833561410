import { BitcoinIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/BitcoinIcon';
import { EuropeanUnionIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/EuropeanUnionIcon';
import { UnitedKingdomIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/UnitedKingdomIcon';
import { UnitedStatesIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/UnitedStatesIcon';
import { CurrencyUnit, FiatCurrencyCode } from '@noah-labs/shared-schema-gql';

export const fiatCurrencyItems = [
  {
    icon: UnitedStatesIcon,
    label: 'US Dollar',
    value: FiatCurrencyCode.USD,
  },
  {
    icon: UnitedKingdomIcon,
    label: 'Great British Pound',
    value: FiatCurrencyCode.GBP,
  },
  {
    icon: EuropeanUnionIcon,
    label: 'Euro',
    value: FiatCurrencyCode.EUR,
  },
];

export const unitCurrencyItems = [
  {
    icon: BitcoinIcon,
    label: 'Bitcoin',
    value: CurrencyUnit.Default,
  },
  {
    icon: BitcoinIcon,
    label: 'Satoshis',
    value: CurrencyUnit.SATS,
  },
];

// https://www.notion.so/thenoahapp/Currencies-allowed-via-manual-bank-transfer-f65c0711922b488db25e080c9139bad3
// TODO: move it to Appconfig
export const allowedBankCurrencies: Record<string, boolean> = {
  // Argentinian Peso
  ARS: true,
  // Brazilian real
  BRL: true,
  // Euro
  EUR: true,
  // British pound sterling
  GBP: true,
  // Mexican Peso
  MXN: true,
  // Nigerian naira
  NGN: true,
  // United States Dollar
  USD: true,
  // South African Rand
  ZAR: true,
};
