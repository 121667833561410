import type { FormEvent } from 'react';
import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { pinLength } from '@noah-labs/core-services';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { PinInput } from '@noah-labs/core-web-ui/src/forms/PinInput';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import type { Resolver, UseFormSetError } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { AppHeaderData } from '../../../components';
import type { TpPinForm } from '../../../utils';
import { verifyPinSchema } from '../../../utils';
import { routes } from '../routes';

export type PpEnterCurrentPinScene = {
  onSubmit: ({ pin }: TpPinForm, setError: UseFormSetError<TpPinForm>) => Promise<void>;
};

export function EnterCurrentPinScene({ onSubmit }: PpEnterCurrentPinScene): React.ReactElement {
  const methods = useForm<TpPinForm>({
    defaultValues: {
      pin: '',
    },
    mode: 'onChange',
    resolver: yupResolver(verifyPinSchema) as Resolver<TpPinForm>,
  });

  const { handleSubmit, setError } = methods;

  const onSubmitClick = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      await handleSubmit(async (value) => {
        await onSubmit(value, setError);
      })(e);
    },
    [handleSubmit, onSubmit, setError]
  );

  return (
    <AppContainer
      AppFooterSlot={
        <PrimaryButton
          data-qa="choose-pin-btn"
          disabled={!methods.formState.isValid}
          form={routes.updatePin.enterCurrentPin.id}
          loading={methods.formState.isSubmitting}
          type="submit"
        >
          Continue
        </PrimaryButton>
      }
      AppHeaderSlot={
        <AppHeaderData backButton helpButton>
          <AppHeaderTitle>{routes.updatePin.enterCurrentPin.title}</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa={routes.updatePin.enterCurrentPin.id}
    >
      <Helmet>
        <title>{routes.updatePin.enterCurrentPin.title}</title>
      </Helmet>
      <SceneHeader>
        <SceneTitleLarge>Enter your security PIN</SceneTitleLarge>
      </SceneHeader>
      <SceneMain>
        <FormProvider {...methods}>
          <form id={routes.updatePin.enterCurrentPin.id} onSubmit={onSubmitClick}>
            <PinInput name="pin" pinLength={pinLength} />
          </form>
        </FormProvider>
      </SceneMain>
    </AppContainer>
  );
}
