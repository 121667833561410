import React, { useCallback, useRef } from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';
import { isBTC } from '@noah-labs/shared-currencies/src/isBTC';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies/src/types';
import type { CurrencyCode } from '@noah-labs/shared-schema-gql';
import { CurrencyDisplayType, CurrencyUnit } from '@noah-labs/shared-schema-gql';
import { useFormContext } from 'react-hook-form';
import type { NumberFormatPropsBase, NumberFormatValues } from 'react-number-format';
import NumberFormat from 'react-number-format';
import type { PpInputFieldAtom } from './atoms/InputFieldAtom';
import { InputFieldAtom } from './atoms/InputFieldAtom';

const charThreshold = 12;
const minimumFontSizeInRem = 1.5;
const percentageToScaleDown = 0.045;

type PpScaleDownText = {
  currentFontSize: number;
  inputLength: number;
  originalFontSize: number;
};
function scaleDownText({
  currentFontSize,
  inputLength,
  originalFontSize,
}: PpScaleDownText): string {
  // Input is not above character threshold, no need to scale
  if (inputLength <= charThreshold) {
    return `${originalFontSize}rem`;
  }

  // Font size is already the lowest allowable, don't scale more
  if (currentFontSize <= minimumFontSizeInRem) {
    return `${currentFontSize}rem`;
  }

  // Reduce the font size by 4.5% for each charachter over the threshold
  const scaleMultiplier = inputLength - charThreshold;
  const scaleFactor = percentageToScaleDown * scaleMultiplier;
  const newFontSize = originalFontSize * (1 - scaleFactor);
  return `${newFontSize}rem`;
}

export type PpAmountField = {
  AmountFieldSecondary: React.ReactElement;
  InputFieldAtomProps?: {
    autoFocus?: boolean;
    dataQa?: string;
    disabled?: boolean;
    errorMessage?: string;
    placeholder?: string;
    required?: boolean;
  };
  cryptoUnit: CurrencyUnit;
  onBlur: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  primaryAmountFieldName: string;
  primaryCurrency: TpCryptoCurrencyUI | TpFiatCurrency;
  primaryCurrencyType: CurrencyDisplayType | null | undefined;
  value: string;
};

export function AmountField({
  AmountFieldSecondary,
  cryptoUnit,
  InputFieldAtomProps,
  onBlur,
  primaryAmountFieldName,
  primaryCurrency,
  primaryCurrencyType,
  value,
}: PpAmountField): React.ReactElement {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>();
  const { setValue } = useFormContext();

  const styles = {
    field: css`
      position: relative;
      width: 100%;
      margin: ${theme.spacing(4, 0)};

      .MuiFormHelperText-root {
        margin: ${theme.spacing(1, 0, 0)};
      }

      .MuiFormHelperText-root:not(.Mui-error) {
        color: ${theme.palette.text.secondary};
      }

      .MuiInputBase-input {
        text-align: center;
        padding: 0;
      }
      .MuiOutlinedInput-root {
        background-color: transparent;
      }
      .MuiOutlinedInput-notchedOutline {
        display: none;
      }
    `,
  };

  const handleValueChange = useCallback(
    (values: NumberFormatValues) => {
      setValue(primaryAmountFieldName, values.value);
      if (!inputRef.current) {
        return;
      }
      inputRef.current.style.fontSize = scaleDownText({
        currentFontSize: parseFloat(inputRef.current.style.fontSize),
        inputLength: inputRef.current.value.length,
        originalFontSize: parseFloat(theme.typography.headingXL?.fontSize as string),
      });
    },
    [primaryAmountFieldName, setValue, theme.typography.headingXL?.fontSize]
  );

  const isPrimaryCrypto = primaryCurrencyType === CurrencyDisplayType.Crypto;

  // Decides whether currency symbol should be a prefix or suffix -
  // We want to show a suffix for USDC/USDT
  const isSymbolPrefix =
    (isPrimaryCrypto &&
      cryptoUnit === CurrencyUnit.Default &&
      isBTC(primaryCurrency.code as CurrencyCode)) ||
    primaryCurrencyType === CurrencyDisplayType.Fiat;

  // Handles specific case for SATS being plural or not
  const userCryptoUnitDisplay =
    isPrimaryCrypto && cryptoUnit === CurrencyUnit.SATS && value === '1'
      ? cryptoUnit.substring(0, cryptoUnit.length - 1)
      : cryptoUnit;

  const cryptoUnitDisplay = isBTC(primaryCurrency.code as CurrencyCode)
    ? userCryptoUnitDisplay
    : primaryCurrency.code;

  const phAmount = InputFieldAtomProps?.placeholder || '0';
  const placeholder = isSymbolPrefix
    ? `${primaryCurrency.symbol}${phAmount}`
    : `${phAmount} ${cryptoUnitDisplay}`;

  const numberFormatProps: NumberFormatPropsBase = {
    allowNegative: false,
    customInput: InputFieldAtom,
    onValueChange: handleValueChange,
    prefix: isSymbolPrefix ? primaryCurrency.symbol : '',
    suffix: !isSymbolPrefix ? ` ${cryptoUnitDisplay}` : '',
    thousandSeparator: true,
  };

  const labelCcy =
    primaryCurrencyType === CurrencyDisplayType.Fiat ? primaryCurrency.code : primaryCurrency.label;

  const mergedInputFieldAtomProps: PpInputFieldAtom = {
    ...InputFieldAtomProps,
    autoFocus: true,
    css: styles.field,
    helperText: AmountFieldSecondary,
    inputRef,
    label: `Enter Amount in ${labelCcy}`,
    labelSrOnly: true,
    name: 'displayField',
    onBlur,
    placeholder,
    size: 'primaryAmount',
    value,
  };

  // @ts-expect-error size is being passed to the MUI TextField component
  return <NumberFormat {...numberFormatProps} {...mergedInputFieldAtomProps} />;
}
