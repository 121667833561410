import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LearnMoreButton } from '@noah-labs/core-web-ui/src/buttons/LearnMoreButton';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { ElevatedCardListItem } from '@noah-labs/core-web-ui/src/cards/ElevatedCardListItem';
import { FormGrid } from '@noah-labs/core-web-ui/src/forms/FormGrid';
import { FormItem } from '@noah-labs/core-web-ui/src/forms/FormItem';
import { InputField } from '@noah-labs/core-web-ui/src/forms/InputField';
import { CurrencyAvatar } from '@noah-labs/core-web-ui/src/images';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { StructuredContent } from '@noah-labs/core-web-ui/src/lists/atoms/StructuredContent';
import { List } from '@noah-labs/core-web-ui/src/lists/List';
import { ListSection } from '@noah-labs/core-web-ui/src/lists/ListSection';
import { SkeletonAddressSummaryCard } from '@noah-labs/core-web-ui/src/lists/SkeletonAddressSummaryCard';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import type { TpAddressData } from '@noah-labs/core-web-ui/src/tools/parseAddressData';
import { TextWithIcon } from '@noah-labs/core-web-ui/src/typography/TextWithIcon';
import { TruncatedText } from '@noah-labs/core-web-ui/src/typography/TruncatedText';
import { CloseCircleIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CloseCircleIcon';
import { CodeIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CodeIcon';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-currencies';
import type { CurrencyCode } from '@noah-labs/shared-schema-gql';
import { Helmet } from 'react-helmet';
import type { UseFormSetError } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { AppHeaderData } from '../../../components';
import { useBackHijack } from '../../../hooks';
import { AddressSummaryCard } from '../components';
import type { TpRecentAddresses } from '../hooks';
import { useDebounce } from '../hooks';
import { routes } from '../routes';
import { getAddressErrorMessageElement } from '../utils/errors';
import { getCurrencyInfo } from '../utils/getCurrencyInfo';

const defaultValues = { address: '' };
export type TpAddressForm = typeof defaultValues;

const addressFormSchema = yup.object({
  address: yup.string().required('Address is a required field'),
});

export type PpAddressManualScene = {
  addressData: TpAddressData | undefined;
  currencyCode: CurrencyCode;
  handleAddress: (
    code: string,
    currencyCode: CurrencyCode,
    setError: UseFormSetError<TpAddressForm>
  ) => void;
  handleNextUrl: () => void;
  handleRedirectToScan: () => void;
  isLoading: boolean;
  recentAddresses: TpRecentAddresses[];
  scannedAddress: string;
  scannerUnavailable: boolean;
};

export function AddressManualScene({
  addressData,
  currencyCode,
  handleAddress,
  handleNextUrl,
  handleRedirectToScan,
  isLoading,
  recentAddresses,
  scannedAddress,
  scannerUnavailable,
}: PpAddressManualScene): React.ReactElement {
  const theme = useTheme();
  const debounce = useDebounce();
  const { backTo, goBack } = useBackHijack(routes.selectCrypto.send.path);

  const methods = useForm<TpAddressForm>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(addressFormSchema),
  });
  const {
    formState: { errors },
    setError,
    setValue,
    watch,
  } = methods;

  const addressValue = watch('address');

  const styles = {
    addressListItem: css`
      &:not(:last-child) {
        margin-bottom: ${theme.spacing(1)};
      }
    `,
  };

  const ScanIconButton = scannerUnavailable ? undefined : (
    <IconButton
      aria-label="scan address"
      disabled={isLoading}
      edge="end"
      onClick={handleRedirectToScan}
    >
      <CodeIcon color="primary" />
    </IconButton>
  );

  const ClearIconButton = addressValue ? (
    <IconButton
      aria-label="clear address"
      disabled={isLoading}
      edge="end"
      onClick={(): void => setValue('address', '', { shouldValidate: true })}
    >
      <CloseCircleIcon color={isLoading ? 'disabled' : 'primary'} />
    </IconButton>
  ) : undefined;

  const { label, learnMoreLink, subTitle } = getCurrencyInfo(currencyCode);
  const helperText = getAddressErrorMessageElement(errors.address?.type);

  const showAlertAndRecentAddresses = !isLoading && !addressData && !helperText;
  const showAlert = showAlertAndRecentAddresses && subTitle && learnMoreLink;

  useEffect(() => {
    if (!addressValue && !addressData?.address) {
      return;
    }
    debounce(() => handleAddress(addressValue, currencyCode, setError), 500);
  }, [addressValue, currencyCode, addressData?.address, handleAddress, setError, debounce]);

  useEffect(() => {
    if (!scannedAddress) {
      return;
    }
    setValue('address', scannedAddress);
  }, [scannedAddress, setValue]);

  return (
    <AppContainer
      AppFooterSlot={
        <PrimaryButton
          color="primaryBrand"
          data-qa="continue-button"
          disabled={!addressData}
          form="addressForm"
          loading={isLoading}
          onClick={handleNextUrl}
        >
          Continue
        </PrimaryButton>
      }
      AppHeaderSlot={
        <AppHeaderData exitButton helpButton backButton={goBack} backTo={backTo}>
          <AppHeaderTitle>{routes.address.text.title}</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa="enter-address"
    >
      <Helmet>
        <title>{routes.address.text.title}</title>
      </Helmet>
      <SceneMain>
        <Stack spacing={2}>
          <FormProvider {...methods}>
            <form id="addressForm">
              <FormGrid>
                <FormItem fullWidth>
                  <InputField
                    fullWidth
                    required
                    aria-label={label}
                    dataQa="address"
                    disabled={isLoading}
                    endSlot={ClearIconButton || ScanIconButton}
                    helperText={helperText}
                    label="To"
                    name="address"
                    placeholder={label}
                  />
                </FormItem>
              </FormGrid>
            </form>
          </FormProvider>

          {showAlert && (
            <Alert severity="warning">
              {subTitle} <LearnMoreButton href={learnMoreLink} target="_blank" />
            </Alert>
          )}

          {scannerUnavailable && (
            <Alert data-qa="no-scanner-alert" severity="info">
              Camera unavailable, please enter the text address manually.
            </Alert>
          )}

          {isLoading && !addressData && <SkeletonAddressSummaryCard />}

          {addressData && (
            <React.Fragment>
              <AddressSummaryCard
                address={addressData.address}
                cryptoCurrency={cryptoCurrencyFromCode(addressData.currencyCode)}
                network={addressData.network}
              />
              <Typography color="text.light" variant="paragraphBodyS">
                Compatible address found. The list of addresses you can send to is determined by the
                currency and network.
              </Typography>
            </React.Fragment>
          )}
        </Stack>

        {showAlertAndRecentAddresses && recentAddresses.length > 0 && (
          <ListSection sx={{ mt: 4 }} title="Recent addresses" titleColor="text.dark">
            <List disablePadding data-qa="recent-addresses">
              {recentAddresses.map(({ address, Icon, network, NetworkIcon }) => (
                <ElevatedCardListItem
                  key={address}
                  css={styles.addressListItem}
                  dataQa={`address-${address}`}
                  onClick={(): void => {
                    setValue('address', address, { shouldValidate: true });
                  }}
                >
                  <StructuredContent
                    Icon={<CurrencyAvatar Icon={Icon} size={3} />}
                    mainText={{
                      primary: <TruncatedText prefix={12} text={address} />,
                      primaryTypographyProps: {
                        variant: 'paragraphBodySBold',
                      },
                      secondary: (
                        <TextWithIcon>
                          {NetworkIcon && (
                            <NetworkIcon sx={{ color: 'text.light', fontSize: '0.8rem' }} />
                          )}
                          <span>Via {network}</span>
                        </TextWithIcon>
                      ),
                    }}
                  />
                </ElevatedCardListItem>
              ))}
            </List>
          </ListSection>
        )}
      </SceneMain>
    </AppContainer>
  );
}
