import type { UiNode } from '@ory/client';

export async function assignLocationAndWait(path: string, delay = 3_000): Promise<void> {
  window.location.assign(path);
  await new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export function getPropertyByPath(obj: unknown, path: string[]): unknown {
  if (path.length === 0) {
    return obj;
  }
  const [property, ...rest] = path;
  if (typeof obj === 'object' && obj !== null && Object.hasOwn(obj, property)) {
    const objectWithProperty = obj as { [key: string]: unknown };
    return getPropertyByPath(objectWithProperty[property], rest);
  }
  return undefined;
}

export function getIsWebAuthnSupported(): boolean {
  return Boolean(window.PublicKeyCredential);
}

export function isWebAuthnCanceledByUserError(error: unknown): boolean {
  if (!(error instanceof Error)) {
    return false;
  }

  return error.name === 'NotAllowedError';
}

export function oryWebAuthnBufferEncode(value: ArrayBuffer): string {
  return btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(value))))
    .replaceAll('+', '-')
    .replaceAll('/', '_')
    .replaceAll('=', '');
}

export function oryWebAuthnBufferDecode(value: string): ArrayBuffer {
  return Uint8Array.from(atob(value.replaceAll('-', '+').replaceAll('_', '/')), (c) =>
    c.charCodeAt(0)
  );
}

export function findOryWebAuthnRemoveNodeByTokenName(
  nodes: UiNode[],
  displayName: string
): UiNode | undefined {
  return nodes.find(
    (n) =>
      'name' in n.attributes &&
      n.attributes.name === 'webauthn_remove' &&
      // workaround for removing token by name
      n.meta.label?.text.match(new RegExp(`\\b(${displayName})\\b`))
  );
}
