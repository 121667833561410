import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { Route } from 'react-router-dom';
import { HelpIconButtonData } from '../../components';
import { AuthRouteData } from '../auth';
import {
  Consent,
  CurrencyUnitSetting,
  ForgottenPasswordNewPassword,
  FullName,
  LinkedAccounts,
  PaymentCurrencySetting,
  PrimaryCurrencySetting,
  SecurityPin,
  Settings,
} from './controllers';
import { AccountLimits } from './controllers/AccountLimits';
import { DisplayCurrencySetting } from './controllers/DisplayCurrencySetting';
import { ProfileDetails } from './controllers/ProfileDetails';
import { TransactionPinThreshold } from './controllers/TransactionPinThreshold';
import { CreatePinRouter } from './CreatePinRouter';
import { PaymentMethodsRouter } from './PaymentMethodsRouter';
import { PinRevocationRouter } from './PinRevocationRouter';
import { RecoverPinWithSecretPhraseRouter } from './RecoverPinWithSecretPhraseRouter';
import { routes } from './routes';
import { ForgottenPasswordSuccessScene } from './scenes';
import { UpdatePinRouter } from './UpdatePinRouter';

export function Router(): React.ReactElement {
  return (
    <Switch404>
      {/* User Settings */}
      <Route
        exact
        path={routes.settings.base.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.settings.base}>
            <Settings />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={[
          routes.settings.newPaymentMethod.path,
          routes.settings.editPaymentMethod.path,
          routes.settings.paymentMethods.path,
        ]}
      >
        <PaymentMethodsRouter />
      </Route>
      <Route
        exact
        path={routes.settings.displayCurrency.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.settings.displayCurrency}>
            <DisplayCurrencySetting route={routes.settings.displayCurrency} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.settings.paymentCurrency.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.settings.paymentCurrency}>
            <PaymentCurrencySetting route={routes.settings.paymentCurrency} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.settings.bitcoinUnit.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.settings.bitcoinUnit}>
            <CurrencyUnitSetting />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.settings.primaryCurrency.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.settings.primaryCurrency}>
            <PrimaryCurrencySetting route={routes.settings.primaryCurrency} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.settings.accountLimits.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.settings.accountLimits}>
            <AccountLimits />
          </AuthRouteData>
        )}
      />

      {/* Settings Consent Route */}
      <Route
        exact
        path={routes.settings.consent.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.settings.consent}>
            <Consent
              /**
               * The Segment analytics script needs to be reloaded if there have been any changes so need use a real page navigation
               * This is why we have the nextAction and nextActionDirty functions passed to the Consent controller
               */
              nextActionDirty={(): void => window.location.reload()}
            />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.accounts.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.accounts}>
            <LinkedAccounts />
          </AuthRouteData>
        )}
      />

      {/* User Profile */}
      <Route
        exact
        path={routes.profile.base.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.profile.base}>
            <ProfileDetails />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.profile.fullName.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.profile.fullName}>
            <FullName />
          </AuthRouteData>
        )}
      />

      {/* Security pin */}
      <Route
        exact
        path={routes.settings.securityPin.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.settings.securityPin}>
            <SecurityPin />
          </AuthRouteData>
        )}
      />

      <Route
        path={[
          routes.setupPin.createPin.path,
          routes.setupPin.secretPhrase.path,
          routes.setupPin.success.path,
        ]}
      >
        <CreatePinRouter />
      </Route>

      <Route
        path={[
          routes.forgotPin.enterPhrase.path,
          routes.forgotPin.createPin.path,
          routes.forgotPin.success.path,
        ]}
      >
        <RecoverPinWithSecretPhraseRouter />
      </Route>

      <Route
        path={[
          routes.updatePin.enterCurrentPin.path,
          routes.updatePin.createPin.path,
          routes.updatePin.success.path,
        ]}
      >
        <UpdatePinRouter />
      </Route>

      <Route
        exact
        path={routes.transactionPinThreshold.update.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.transactionPinThreshold.update}>
            <TransactionPinThreshold />
          </AuthRouteData>
        )}
      />

      <Route path={[routes.pinRevocation.revoke.path, routes.pinRevocation.unrevoke.path]}>
        <PinRevocationRouter />
      </Route>

      {/* Password update */}
      <Route
        exact
        path={routes.newPassword.base.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.newPassword.base}>
            <ForgottenPasswordNewPassword helpButton={<HelpIconButtonData />} />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.newPassword.complete.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.newPassword.complete}>
            <ForgottenPasswordSuccessScene helpButton={<HelpIconButtonData />} />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
