import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { getNetCryptoAmount } from '../../../../../utils';
import { useCalculateCryptoFromFiat } from '../../../data';
import type { TpAmountForm } from '../../../scenes';
import type { PpUsePrimaryBase } from './types';
import { TpDualCurrencyAmountFieldNames } from './types';

const validate = { shouldValidate: true };

type PpUsePrimaryFiat = PpUsePrimaryBase & {
  isCryptoAmountNet: boolean;
};
export function usePrimaryFiat({
  cryptoCurrency,
  enabled,
  fiatCurrency,
  isCryptoAmountNet,
  priceProvider,
  primaryAmount,
  slippage,
}: PpUsePrimaryFiat): void {
  const { setValue } = useFormContext<TpAmountForm>();
  const { data } = useCalculateCryptoFromFiat({
    cryptoCurrency,
    fiatAmount: primaryAmount,
    fiatCurrency,
    options: { enabled },
    priceProvider,
    slippage,
  });

  // calculate the net crypto amount if necessary
  // pointless to put this in a memo since it needs to be calculated everytime the value changes anyway
  const cryptoAmountData = isCryptoAmountNet
    ? getNetCryptoAmount({
        cryptoCurrency,
        fiatAmount: primaryAmount,
        price: data?.price,
      })
    : data?.cryptoAmount;

  useEffect(() => {
    if (!enabled) {
      return;
    }

    // Sets the secondary display field - no validation is run on this
    setValue(TpDualCurrencyAmountFieldNames.secondary, cryptoAmountData);

    // Set the fiat and crypto amount fields for validation and submission
    setValue(TpDualCurrencyAmountFieldNames.fiat, primaryAmount, validate);
    setValue(TpDualCurrencyAmountFieldNames.crypto, cryptoAmountData, validate);
  }, [cryptoAmountData, enabled, primaryAmount, setValue]);

  // update the price and fetchedAt values in a separate hook as they will change a lot less often
  useEffect(() => {
    if (!enabled) {
      return;
    }

    setValue(TpDualCurrencyAmountFieldNames.price, data?.price);
    setValue(TpDualCurrencyAmountFieldNames.fetchedAt, data?.fetchedAt);
  }, [data?.fetchedAt, data?.price, enabled, setValue]);
}
