import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { MnemonicInput } from '@noah-labs/core-web-ui/src/forms/MnemonicInput';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import type { Resolver, UseFormSetError } from 'react-hook-form';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { AppHeaderData, GetInTouchLink } from '../../../components';
import type { TpPhraseForm } from '../../../utils';
import { phraseSchema } from '../../../utils';
import { routes } from '../routes';

export type PpEnterSecretPhraseScene = {
  onSubmit: ({ phrase }: TpPhraseForm, setError: UseFormSetError<TpPhraseForm>) => Promise<void>;
};

export function EnterSecretPhraseScene({ onSubmit }: PpEnterSecretPhraseScene): React.ReactElement {
  const methods = useForm<TpPhraseForm>({
    defaultValues: {
      phrase: '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(phraseSchema) as Resolver<TpPhraseForm>,
  });

  return (
    <AppContainer
      AppFooterSlot={
        <PrimaryButton
          data-qa="submit-phrase-btn"
          disabled={!methods.formState.isValid}
          form={routes.forgotPin.enterPhrase.id}
          loading={methods.formState.isSubmitting}
          type="submit"
        >
          Continue
        </PrimaryButton>
      }
      AppHeaderSlot={
        <AppHeaderData backButton helpButton>
          <AppHeaderTitle>{routes.forgotPin.enterPhrase.title}</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa={routes.forgotPin.enterPhrase.id}
    >
      <Helmet>
        <title>{routes.forgotPin.enterPhrase.title}</title>
      </Helmet>
      <SceneHeader>
        <SceneTitleLarge>Enter your secret recovery phrase</SceneTitleLarge>
        <SceneParagraph>
          This is the 24 word phrase you were given when signing up to NOAH.
        </SceneParagraph>
      </SceneHeader>
      <SceneMain>
        <Stack spacing={4}>
          <FormProvider {...methods}>
            <form
              id={routes.forgotPin.enterPhrase.id}
              onSubmit={(e): void => {
                void methods.handleSubmit(async (values) => {
                  await onSubmit(values, methods.setError);
                })(e);
              }}
            >
              <Controller
                control={methods.control}
                name="phrase"
                render={({ field, fieldState }): React.ReactElement => (
                  <MnemonicInput
                    fullWidth
                    dataQa="phrase"
                    errorMessage={fieldState.error?.message}
                    label="Your recovery phrase"
                    placeholder="Enter each word separated by a space"
                    words={24}
                    {...field}
                  />
                )}
              />
            </form>
          </FormProvider>
          <SceneParagraph>
            Lost recovery phrase? <GetInTouchLink text="Get in touch." />
          </SceneParagraph>
        </Stack>
      </SceneMain>
    </AppContainer>
  );
}
