import { useContext } from 'react';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import type { CxDialogs } from './DialogsProvider';
import { DialogsContext } from './DialogsProvider';

/**
 * useDialogs is a shortcut to the DialogsContext
 * and provides a limit set of functions to suit most use cases.
 */
export function useDialogs(): CxDialogs {
  const context = useContext(DialogsContext);
  if (context === undefined) {
    return logger.fatal('useDialogs must be used within a DialogsProvider');
  }
  return context;
}
