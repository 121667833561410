import React from 'react';
import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { StatusElevatedCard } from '@noah-labs/core-web-ui/src/cards/StatusElevatedCard';
import { useNavigation } from '@noah-labs/core-web-ui/src/hooks/useNavigation';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { FooterContentText } from '@noah-labs/core-web-ui/src/layout/FooterContentText';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { Helmet } from 'react-helmet';
import { AppHeaderData, GetInTouchLink } from '../../../components';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { getCheckoutAvatarStatus } from '../controllers/buy/getAvatarStatus';
import { routes } from '../routes';
import { TpCheckoutPaymentStatus } from '../types';

export type PpCompleteBuyScene = {
  BalanceSlot: React.ReactElement;
  enableAnimation?: boolean;
  paymentStatus: TpCheckoutPaymentStatus;
  transactionUrl?: string;
};

export function CompleteBuyScene({
  BalanceSlot,
  enableAnimation = webConfigBrowser.settings.enableLoaderAnimations,
  paymentStatus,
  transactionUrl,
}: PpCompleteBuyScene): React.ReactElement {
  const theme = useTheme();
  const styles = {
    balance: css`
      margin-top: ${theme.spacing(3)};
    `,
    content: css`
      margin-top: ${theme.spacing(1)};
    `,
  };

  const { exitTo } = useNavigation();

  const isSuccessful = paymentStatus === TpCheckoutPaymentStatus.success;
  const isFailed = paymentStatus === TpCheckoutPaymentStatus.failure;

  return (
    <AppContainer
      AppFooterSlot={
        <Stack spacing={2} textAlign="center">
          {isSuccessful && (
            <FooterContentText>
              Arriving within minutes in your account, check status in the transaction details.
            </FooterContentText>
          )}
          <ButtonGroup>
            <PrimaryButton data-qa="done-button" href={exitTo}>
              Done
            </PrimaryButton>
            {isSuccessful && (
              <PrimaryButton
                data-qa="view-transaction-button"
                disabled={!transactionUrl}
                href={transactionUrl}
                variant="text"
              >
                View transaction
              </PrimaryButton>
            )}
          </ButtonGroup>
        </Stack>
      }
      AppHeaderSlot={<AppHeaderData exitButton helpButton />}
      dataQa="complete"
    >
      <Helmet>
        <title>{routes.buy.complete.title}</title>
      </Helmet>
      <SceneMain>
        <StatusElevatedCard
          enableAnimation={enableAnimation}
          status={getCheckoutAvatarStatus(paymentStatus)}
        >
          <Stack css={styles.content} spacing={1} textAlign="center">
            {isSuccessful && (
              <Typography color="primary.dark" variant="paragraphBodyM">
                Successfully purchased
              </Typography>
            )}
            {isFailed && (
              <React.Fragment>
                <Typography color="primary.dark" variant="paragraphBodyM">
                  Purchase failed
                </Typography>
                <Typography color="grayscale.700" variant="paragraphBodyS">
                  {transactionUrl ? (
                    `Payment was declined by your card issuer, please contact your bank and try
                      again.`
                  ) : (
                    <React.Fragment>
                      Sorry, something went wrong when processing your purchase, please try again
                      later. If the problem persists, please <GetInTouchLink />.
                    </React.Fragment>
                  )}
                </Typography>
              </React.Fragment>
            )}
          </Stack>
          <div css={styles.balance}>{BalanceSlot}</div>
        </StatusElevatedCard>
      </SceneMain>
    </AppContainer>
  );
}
