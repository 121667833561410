import type { CircularProgressProps } from '@mui/material';
import { Box, CircularProgress } from '@mui/material';
import { circularProgressClasses } from '@mui/material/CircularProgress';

export function CircularSpinner(props: CircularProgressProps): React.ReactElement {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        size={32}
        sx={{
          color: (theme) => theme.palette.grayscale[300],
        }}
        thickness={4}
        variant="determinate"
        {...props}
        value={100}
      />
      <CircularProgress
        disableShrink
        size={32}
        sx={{
          animationDuration: '550ms',
          color: (theme) => theme.palette.grayscale[500],
          left: 0,
          position: 'absolute',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        thickness={4}
        variant="indeterminate"
        {...props}
      />
    </Box>
  );
}
