import React, { Fragment, useCallback, useRef } from 'react';
import { css } from '@emotion/react';
import { ListItemButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ElevatedCard } from '@noah-labs/core-web-ui/src/cards/ElevatedCard';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { ChevronDownIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronDownIcon';
import { mapPaymentCards, useFiatPaymentMethodsQuery } from '../../../data';
import type { TpPaymentCard, TpPaymentMethod, TpPaymentMethodChange } from '../../../types';
import { PaymentMethod } from '../PaymentMethod';
import { PaymentMethodsDialog } from './PaymentMethodsDialog';

export type PpPaymentMethodsPicker = {
  applePayEnabled: boolean;
  googlePayEnabled: boolean;
  onChange: ({ card, type }: TpPaymentMethodChange) => void;
  onSubmitCardDetailsRedirect: string;
  paymentMethod: TpPaymentMethod | undefined;
  selectedPaymentCard: TpPaymentCard | undefined;
};

export function PaymentMethodsPicker({
  applePayEnabled,
  googlePayEnabled,
  onChange,
  onSubmitCardDetailsRedirect,
  paymentMethod,
  selectedPaymentCard,
}: PpPaymentMethodsPicker): React.ReactElement {
  const theme = useTheme();
  const styles = {
    chevron: css`
      color: ${theme.palette.text.light};
    `,
    chooseMethod: css`
      min-height: ${theme.spacing(7)};
    `,
    toggleButton: css`
      padding: ${theme.spacing(0, 2)};
      flex-wrap: wrap;
    `,
  };

  const dialogRef = useRef<TpDialogToggle>(null);

  const { data: paymentCards } = useFiatPaymentMethodsQuery(undefined, {
    select: mapPaymentCards,
  });

  const handleChange = useCallback(
    ({ card, type }: TpPaymentMethodChange) => {
      onChange({ card, type });
      dialogRef.current?.toggle();
    },
    [onChange]
  );

  return (
    <Fragment>
      <ElevatedCard>
        <ListItemButton
          css={styles.toggleButton}
          data-qa="payment-method-picker"
          onClick={(): void => dialogRef.current?.toggle()}
        >
          <PaymentMethod
            method={paymentMethod}
            paymentCard={selectedPaymentCard}
            ToggleSlot={<ChevronDownIcon css={styles.chevron} />}
          />
        </ListItemButton>
      </ElevatedCard>
      <PaymentMethodsDialog
        ref={dialogRef}
        applePayEnabled={applePayEnabled}
        googlePayEnabled={googlePayEnabled}
        handleChange={handleChange}
        paymentCards={paymentCards}
        onSubmitCardDetailsRedirect={onSubmitCardDetailsRedirect}
      />
    </Fragment>
  );
}
