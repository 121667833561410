import { getEnvCurrencyCode } from '@noah-labs/shared-currencies';
import type { AccountType } from '@noah-labs/shared-schema-gql';
import { CurrencyCode } from '@noah-labs/shared-schema-gql';
import dayjs from 'dayjs';
import { getDefaults } from '../../../utils';
import { isProd } from '../../../webConfigBrowser';

export type TpDefaultWalletAccount = {
  AccountType: AccountType;
  Balance: { Available: string; Lifetime: string; __typename: 'AccountBalanceCurrent' };
  Created: string;
  CurrencyCode: CurrencyCode;
  ID: string;
  Version: number;
  VersionBalance: number;
};

const defaults = getDefaults();

/**
 * defaultAccount is an empty wallet account with default currency code and account type
 * It is used to 'mock' the UI when a user first signs up and does not yet have a real account
 */
export const defaultAccount: TpDefaultWalletAccount = {
  AccountType: defaults.accountType,
  Balance: { __typename: 'AccountBalanceCurrent', Available: '0', Lifetime: '0' },
  Created: dayjs().toISOString(),
  CurrencyCode: getEnvCurrencyCode(CurrencyCode.BTC, isProd),
  ID: 'DefaultAccountBalanceBTC',
  Version: 1,
  VersionBalance: 12,
};

export const defaultAccountEth: TpDefaultWalletAccount = {
  AccountType: defaults.accountType,
  Balance: { __typename: 'AccountBalanceCurrent', Available: '0', Lifetime: '0' },
  Created: dayjs().toISOString(),
  CurrencyCode: getEnvCurrencyCode(CurrencyCode.ETH, isProd),
  ID: 'DefaultAccountBalanceETH',
  Version: 1,
  VersionBalance: 12,
};

export const defaultAccountUsdc: TpDefaultWalletAccount = {
  AccountType: defaults.accountType,
  Balance: { __typename: 'AccountBalanceCurrent', Available: '0', Lifetime: '0' },
  Created: dayjs().toISOString(),
  CurrencyCode: getEnvCurrencyCode(CurrencyCode.USDC, isProd),
  ID: 'DefaultAccountBalanceUSDC',
  Version: 1,
  VersionBalance: 12,
};

export const defaultAccountUsdt: TpDefaultWalletAccount = {
  AccountType: defaults.accountType,
  Balance: { __typename: 'AccountBalanceCurrent', Available: '0', Lifetime: '0' },
  Created: dayjs().toISOString(),
  CurrencyCode: getEnvCurrencyCode(CurrencyCode.USDT, isProd),
  ID: 'DefaultAccountBalanceUSDT',
  Version: 1,
  VersionBalance: 12,
};
