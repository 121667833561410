import { useEffect } from 'react';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { useOry } from '@noah-labs/fe-shared-data-access-auth';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { useAuth } from '../hooks/useAuth';
import { routes } from '../routes';

export function SignOut(): React.ReactElement {
  const { ory } = useOry();
  const { onSignOut } = useAuth();

  useEffect(() => {
    logger.attempt('signing out');

    async function signOut(): Promise<void> {
      try {
        // Create a "logout flow" in Ory Identities
        const { data: flow } = await ory.createBrowserLogoutFlow();
        // Use the received token to "update" the flow and thus perform the logout
        await ory.updateLogoutFlow({ token: flow.logout_token });
        await onSignOut();
      } catch (error) {
        // The user could not be logged out. This typically happens if the token does not match the session, or is otherwise malformed or missing
        logger.error(error);
      }
      // Logout was succesful
      window.location.assign(routes.signIn.path);
    }

    void signOut();
  }, [onSignOut, ory]);

  return <LoadingPage message="Signing off, so long Arkonaut...!" />;
}
