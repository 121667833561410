import { useRouteMatch } from 'react-router-dom';
import { HelpIconButtonData } from '../../components';
import { AuthRouteData } from './AuthRouteData';
import { useValidateReferralCode } from './hooks/useValidateReferralCode';
import type { PpRouter } from './Router';
import { Router } from './Router';
import { routes } from './routes';

type PpAuthRouterData = {
  referralCode: string | null;
} & Omit<PpRouter, 'AuthRouteData' | 'helpButton'>;

export function AuthRouterData({
  authedPath,
  captcha,
  forgottenPasswordReturnTo,
  referralCode,
  updateGuestSardineConfig,
}: PpAuthRouterData): React.ReactElement {
  const isSignupRoute = useRouteMatch(routes.signUp.path);
  useValidateReferralCode({
    enabled: !!isSignupRoute?.isExact && !!referralCode,
    referralCode,
  });

  return (
    <Router
      authedPath={authedPath}
      AuthRouteData={AuthRouteData}
      captcha={captcha}
      forgottenPasswordReturnTo={forgottenPasswordReturnTo}
      helpButton={<HelpIconButtonData />}
      referralCode={referralCode}
      updateGuestSardineConfig={updateGuestSardineConfig}
    />
  );
}
