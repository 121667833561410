import { Fragment, useState } from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import { moonpayApi } from '@noah-labs/core-services';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';

type PpMoonpayBuy = {
  queryParams: string | undefined;
};

export function MoonpayBuy({ queryParams }: PpMoonpayBuy): React.ReactElement | null {
  const [isLoading, setIsLoading] = useState(true);
  const url = moonpayApi.moonpayDomain('buy');
  const theme = useTheme();

  const styles = {
    iframe: css`
      /* TODO: calculate exactly what min height this should be */
      min-height: calc(95vh - (${theme.shape.appHeaderHeight} * 3));
    `,
  };

  return (
    <Fragment>
      {(isLoading || !queryParams) && <LoadingPage message="Loading Moonpay..." />}
      {queryParams && (
        // eslint-disable-next-line react/iframe-missing-sandbox
        <iframe
          allow="accelerometer; autoplay; camera; gyroscope; payment"
          css={isLoading ? undefined : styles.iframe}
          frameBorder="0"
          height={isLoading ? '0' : '100%'}
          src={`${url}${queryParams}`}
          title="moonpay-buy"
          width="100%"
          onLoad={(): void => setIsLoading(false)}
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      )}
    </Fragment>
  );
}
