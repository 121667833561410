import { useCallback } from 'react';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import type { RegistrationFlow } from '@ory/client';
import type { AxiosError } from 'axios';
import { HttpStatusCode, isAxiosError } from 'axios';
import { useOry } from '../data';
import type { AuthSetError } from '../types';
import { getOryCsrfToken, getOryFlowId, isRedirectBrowserError } from '../utils';
import { getOryUiError } from './utils';

export function useOryRecoveryVerifyCodeFlowCallback(
  onAfterRecovery: (settingsFlowId: string) => Promise<void>
): (values: { code: string }, setError: AuthSetError<{ code: string }>) => Promise<void> {
  const { ory } = useOry();

  return useCallback(
    async (values, setError): Promise<void> => {
      const flowId = getOryFlowId() || '';

      try {
        const { data: flow } = await ory.getRecoveryFlow({
          id: flowId,
        });

        const csrfToken = getOryCsrfToken(flow.ui.nodes);
        const { data } = await ory.updateRecoveryFlow({
          flow: flow.id,
          updateRecoveryFlowBody: {
            code: values.code,
            csrf_token: csrfToken,
            method: 'code',
          },
        });

        const uiErrorMessage = getOryUiError(data.ui.messages);
        if (uiErrorMessage) {
          setError('root.serverError', uiErrorMessage);
        }
      } catch (error: unknown) {
        logger.error(error);

        if (!isAxiosError(error)) {
          setError('root.serverError', {
            message: 'Something went wrong.',
            type: 'custom',
          });
          return;
        }

        const oryError = error as AxiosError<RegistrationFlow>;
        if (isRedirectBrowserError(error)) {
          const { data: flow } = await ory.getRecoveryFlow({
            id: flowId,
          });
          const returnTo = flow.return_to;

          const { data: settingsFlow } = await ory.createBrowserSettingsFlow();

          if (!returnTo || !settingsFlow.id) {
            setError('root.serverError', {
              message: 'Something went wrong.',
              type: 'custom',
            });
            return;
          }

          await onAfterRecovery(settingsFlow.id);
        }

        if (oryError.response?.status !== HttpStatusCode.BadRequest) {
          setError('root.serverError', {
            message: 'Something went wrong.',
            type: 'custom',
          });
          return;
        }

        const { ui } = oryError.response.data;
        const uiServerMessage = getOryUiError(ui.messages);
        if (uiServerMessage) {
          setError('root.serverError', uiServerMessage);
        }
      }
    },
    [ory, onAfterRecovery]
  );
}
