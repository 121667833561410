import { useCallback } from 'react';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { CurrencyAvatar } from '@noah-labs/core-web-ui/src/images';
import {
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemIcon,
  ListItemPrimaryText,
  ListItemStartContent,
} from '@noah-labs/core-web-ui/src/lists/ListItem';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-currencies';
import { Feature, Network } from '@noah-labs/shared-schema-gql';
import { useHistory } from 'react-router-dom';
import { useFeature } from '../../../user/hooks/useFeature';
import { useWalletParams } from '../../data';
import { routes } from '../../routes';
import { SelectNetworkScene } from '../../scenes/SelectNetwork';
import { getCryptoNetworksToDisplay } from '../../utils/getCryptoNetworksToDisplay';
import type { StReceiveRouter } from './types';

const NETWORKS_TO_DISPLAY = [
  Network.Ethereum,
  Network.EthereumTestSepolia,
  Network.PolygonPos,
  Network.PolygonTestMumbai,
  Network.Tron,
  Network.TronTestShasta,
];

export function SelectNetwork({
  updateState,
}: TpStateMachine<StReceiveRouter>): React.ReactElement {
  const depositFF = useFeature(Feature.Deposit);
  const { CurrencyCode, params } = useWalletParams();
  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const history = useHistory();

  const networks = getCryptoNetworksToDisplay(CurrencyCode, depositFF, NETWORKS_TO_DISPLAY);

  const onNetworkSelect = useCallback(
    (network: Network) => {
      updateState({ network });
      history.push(generatePath(routes.receive.base.path, params));
    },
    [history, params, updateState]
  );

  const NetworksSlot = networks?.map((network) => (
    <ListItem key={network} data-qa={`network-${network}`}>
      <ListItemButton onClick={(): void => onNetworkSelect(network)}>
        <ListItemCard>
          <ListItemContent>
            <ListItemIcon>
              <CurrencyAvatar Icon={cryptoCurrency.NetworkIcons?.[network] || null} />
            </ListItemIcon>
            <ListItemStartContent>
              <ListItemPrimaryText>{network}</ListItemPrimaryText>
            </ListItemStartContent>
          </ListItemContent>
        </ListItemCard>
      </ListItemButton>
    </ListItem>
  ));
  return (
    <SelectNetworkScene
      NetworksSlot={NetworksSlot}
      subtitle="Choose the currency network you want to receive funds on."
      title={routes.receive.network.title}
    />
  );
}
