//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgCheckbox } from '../icons/Checkbox';

export function CheckboxIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgCheckbox} data-qa="CheckboxIcon">
      <svg />
    </SvgIcon>
  );
}
