import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import type { ButtonProps } from '@mui/material';
import { Button, ButtonBase, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export type PpOnlyIconButton = ButtonProps & {
  circular?: boolean;
  innerButtonCss?: SerializedStyles;
  outsideLabel?: React.ReactNode;
};

export function OnlyIconButton({
  children,
  circular = true,
  color = 'primary',
  disabled,
  innerButtonCss,
  outsideLabel,
  size = 'mediumIcon',
  variant = 'contained',
  ...props
}: PpOnlyIconButton): React.ReactElement {
  const theme = useTheme();
  const styles = {
    button: css`
      /* Set a max width so that the label doesn't affect the spacing */
      max-width: ${theme.spacing(6)};
      overflow: visible;
    `,
    innerButton: css`
      border-radius: ${circular ? '50%' : theme.borderRadius.xs};
    `,
  };

  return (
    <ButtonBase css={styles.button} disabled={disabled} {...props}>
      <Stack alignItems="center" spacing={1}>
        <Button
          color={color}
          component="span"
          css={[styles.innerButton, innerButtonCss]}
          disabled={disabled}
          size={size}
          variant={variant}
        >
          {children}
        </Button>
        {outsideLabel && (
          <Typography noWrap variant="paragraphBodyS">
            {outsideLabel}
          </Typography>
        )}
      </Stack>
    </ButtonBase>
  );
}
