import React, { Fragment, useCallback, useReducer, useRef } from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import type { SafeWrapper } from '@noah-labs/core-services';
import { mnemonicLength } from '@noah-labs/core-services';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { OnlyIconButton } from '@noah-labs/core-web-ui/src/buttons/OnlyIconButton';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { ElevatedCard } from '@noah-labs/core-web-ui/src/cards/ElevatedCard';
import { MnemonicInput } from '@noah-labs/core-web-ui/src/forms/MnemonicInput';
import { useClickToCopy } from '@noah-labs/core-web-ui/src/hooks/useClickToCopy';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { DownloadIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/DownloadIcon';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { AppHeaderData } from '../../../components';
import { TpAnalyticsEvent, useAnalytics } from '../../analytics';
import { RecoveryPhraseDownloadFailed } from '../components';
import { SecretPhraseSavingDialog } from '../components/dialogs/SecretPhraseSavingDialog';
import { routes } from '../routes';

export type PpSecretPhraseScene = {
  phrase: SafeWrapper<string> | undefined;
  successTo: string;
};

export function SecretPhraseScene({ phrase, successTo }: PpSecretPhraseScene): React.ReactElement {
  const history = useHistory();
  const pushAlert = usePushAlert();
  const secretPhraseSavingDialog = useRef<TpDialogToggle>(null);
  const [visible, toggle] = useReducer((s) => !s, false);

  const theme = useTheme();

  const analytics = useAnalytics();

  const { copied, copyHandler } = useClickToCopy({
    onCopy: () => analytics.track(TpAnalyticsEvent.SecretPhraseCopyClicked),
    text: phrase?.value.secret,
  });

  const styles = {
    card: css`
      padding: ${theme.spacing(2)};
    `,
  };

  const onSaveClick = useCallback(() => {
    try {
      analytics.track(TpAnalyticsEvent.RecoveryPhraseDownloadClicked);

      if (!phrase?.value.secret) {
        return;
      }

      const blob = new Blob([phrase.value.secret], { type: 'text/plain' });

      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');

      link.href = url;
      link.download = 'NOAH Recovery Phrase.txt';

      link.click();
    } catch (error: unknown) {
      logger.error('Failed to download recovery phrase', (error as Error).name);
      pushAlert(RecoveryPhraseDownloadFailed);
    }
  }, [analytics, phrase, pushAlert]);

  return (
    <Fragment>
      <AppContainer
        AppFooterSlot={
          <PrimaryButton
            color="primaryBrand"
            data-qa="continue-btn"
            onClick={(): void => secretPhraseSavingDialog.current?.toggle()}
          >
            Continue
          </PrimaryButton>
        }
        AppHeaderSlot={<AppHeaderData backButton helpButton />}
        dataQa={routes.setupPin.secretPhrase.id}
      >
        <Helmet>
          <title>{routes.setupPin.secretPhrase.title}</title>
        </Helmet>
        <SceneHeader>
          <SceneTitleLarge>PIN Recovery</SceneTitleLarge>
          <SceneParagraph>
            In case you forget your PIN, you can use your recovery phrase to reset it and regain
            access to your account.
          </SceneParagraph>
          <SceneParagraph>
            This phrase is generated on your device and we don’t have access to it, so please save
            these words in a secure location and never share them with anyone.
          </SceneParagraph>
        </SceneHeader>
        <SceneMain>
          <ElevatedCard css={styles.card}>
            <MnemonicInput
              disabled
              fullWidth
              dataQa="phrase"
              inputProps={{
                style: visible
                  ? {}
                  : {
                      filter: 'blur(3px)',
                    },
              }}
              name="phrase"
              value={phrase?.value.secret}
              words={mnemonicLength}
            />
            <ButtonGroup direction="row" mt={2}>
              <PrimaryButton color="secondary" size="small" onClick={toggle}>
                {visible ? 'Hide phrase' : 'Show phrase'}
              </PrimaryButton>
              <PrimaryButton color="primary" size="small" onClick={copyHandler}>
                {copied ? 'Copied' : 'Copy'}
              </PrimaryButton>
              <OnlyIconButton color="primaryDark" onClick={onSaveClick}>
                <DownloadIcon />
              </OnlyIconButton>
            </ButtonGroup>
          </ElevatedCard>
        </SceneMain>
      </AppContainer>

      <SecretPhraseSavingDialog
        ref={secretPhraseSavingDialog}
        onCancelClick={(): void => secretPhraseSavingDialog.current?.toggle()}
        onContinueClick={(): void => history.replace(successTo)}
      />
    </Fragment>
  );
}
