import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Avatar = styled(Box)<BoxProps>(() => ({
  height: 'fit-content',
  position: 'relative',
  width: 'fit-content',
}));

export const AvatarIcon = styled(Box)<BoxProps>(({ theme }) => ({
  '& svg': {
    height: '100%',
    width: '100%',
  },
  height: theme.spacing(4),
  width: theme.spacing(4),
}));

export const AvatarBadge = styled(Box)<BoxProps>(() => ({
  bottom: 0,
  position: 'absolute',
  right: 0,
}));
