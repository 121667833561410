import type { TpMoonpayTransactionStatus } from '@noah-labs/core-services';

export function getTitles(status: TpMoonpayTransactionStatus | undefined): {
  pageTitle: string;
  subtitle?: string;
} {
  switch (status) {
    case 'completed':
      return {
        pageTitle: 'Purchase successful',
      };
    case 'failed':
      return {
        pageTitle: 'Purchase failed',
      };
    case 'waitingAuthorization':
      return {
        pageTitle: 'Purchase in progress',
        subtitle: 'Your purchase hasn’t yet been authorised, please return to MoonPay to do this.',
      };
    case 'waitingPayment':
      return {
        pageTitle: 'Purchase in progress',
        subtitle:
          'You’re yet to complete the payment in MoonPay, please return to MoonPay to do this.',
      };
    case 'pending':
    default:
      return {
        pageTitle: 'Purchase processing',
      };
  }
}
