import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';
import { ContentCard } from '../cards/ContentCard';
import { CurrencyAvatar } from '../images';

export type PpAvailableBalance = {
  ContentSlot?: React.ReactNode;
  PrimaryAmountSlot: React.ReactNode;
  SecondaryAmountSlot: React.ReactNode;
  cryptoCurrency: TpCryptoCurrencyUI;
};

export function AvailableBalance({
  ContentSlot,
  cryptoCurrency,
  PrimaryAmountSlot,
  SecondaryAmountSlot,
}: PpAvailableBalance): React.ReactElement {
  const title = `${cryptoCurrency.label} Balance`;

  return (
    <ContentCard
      ContentSlot={ContentSlot}
      endText={{ primary: PrimaryAmountSlot, secondary: SecondaryAmountSlot }}
      Icon={<CurrencyAvatar Icon={cryptoCurrency.Icon} size={3} />}
      mainText={{ primary: title }}
    />
  );
}
