import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Link, Stack } from '@mui/material';
import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { AppLogo } from '@noah-labs/core-web-ui/src/images';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeader } from '@noah-labs/core-web-ui/src/layout/AppHeader';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import type { SocialProvider } from '@noah-labs/fe-shared-data-access-auth';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { toTitleCase } from '@noah-labs/shared-tools/src/browser/strings';
import { Helmet } from 'react-helmet';
import type { ErrorOption, Resolver, UseFormSetError } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import type { TpSignInState } from '../components';
import { SignInBody } from '../components';
import { SocialAuthButton } from '../components/SocialAuthButton';
import { orderedProviders } from '../constants';
import { useAuthError } from '../hooks/useAuthError';

export type TpSignInForm = {
  email: string;
  password: string;
  root?: { serverError: void };
};

export type PpSignInScene = {
  autocompleteEmail: string | undefined;
  autostartWebAuthn?: boolean;
  error: ErrorOption | undefined;
  getHasSecurityKey: (email: string) => Promise<boolean>;
  helpButton?: React.ReactNode;
  onGoToPasswordLogin: () => void;
  onSignIn: (values: TpSignInForm, setError: UseFormSetError<TpSignInForm>) => Promise<void>;
  onSocialSignIn: (
    provider: SocialProvider,
    setError: UseFormSetError<TpSignInForm>
  ) => Promise<void>;
  sceneState: TpSignInState;
  signupUrl: string;
};

export function SignInScene({
  autocompleteEmail,
  error,
  helpButton,
  onGoToPasswordLogin,
  onSignIn,
  onSocialSignIn,
  sceneState,
  signupUrl,
}: PpSignInScene): React.ReactElement {
  const signInSchema = yup.object({
    email: yup
      .string()
      .required('Email is a required field.')
      .email('Email must be a valid email address.'),
    password: yup.lazy(() => {
      if (sceneState === 'password') {
        return yup.string().required('Password is a required field.');
      }
      return yup.string();
    }),
  });

  const methods = useForm<TpSignInForm>({
    defaultValues: {
      email: autocompleteEmail ?? '',
      password: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(signInSchema) as Resolver<TpSignInForm>,
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = methods;

  useEffect(() => {
    if (sceneState !== 'initial' || !autocompleteEmail) {
      return;
    }
    handleSubmit((values) => onSignIn(values, setError))().catch((e) => {
      logger.error(e);
    });
  }, [sceneState, autocompleteEmail, setError, onSignIn, handleSubmit]);

  useAuthError({ error: errors.root?.serverError });

  useEffect(() => {
    if (!error) {
      return;
    }
    setError('root.serverError', error);
  }, [setError, error]);

  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeader endIconsSlot={helpButton}>
          <AppLogo />
        </AppHeader>
      }
      dataQa="sign-in"
    >
      <Helmet>
        <title>Login</title>
      </Helmet>
      <SceneHeader textAlign="center">
        <SceneTitleLarge>Login</SceneTitleLarge>
        <SceneParagraph>
          Don’t have an account?{' '}
          <Link data-qa="sign-up-link" href={signupUrl}>
            Sign up
          </Link>
        </SceneParagraph>
      </SceneHeader>
      <SceneMain>
        <Stack justifyContent="center" spacing={4}>
          <FormProvider {...methods}>
            <SignInBody
              loading={isSubmitting}
              state={sceneState}
              onGoToPasswordLogin={onGoToPasswordLogin}
              onSubmit={handleSubmit((values) => onSignIn(values, setError))}
            />
          </FormProvider>
          <Divider>Or</Divider>
          <ButtonGroup>
            {orderedProviders.map((p) => (
              <SocialAuthButton
                key={p.name}
                icon={p.icon}
                label={`Login with ${toTitleCase(p.name)}`}
                onClick={(): Promise<void> => onSocialSignIn(p.name, setError)}
              />
            ))}
          </ButtonGroup>
        </Stack>
      </SceneMain>
    </AppContainer>
  );
}
