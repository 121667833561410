import { Fragment } from 'react';
import { InputField } from '@noah-labs/core-web-ui/src/forms/InputField';

export const SortCodeFormFields = (
  <Fragment>
    <InputField
      fullWidth
      required
      dataQa="account-number"
      label="Account number"
      name="accountNumber"
    />
    <InputField fullWidth required dataQa="bankCode" label="Sort code" name="bankCode" />
  </Fragment>
);

export const UsBankFormFields = (
  <Fragment>
    <InputField
      fullWidth
      required
      dataQa="account-number"
      label="Account number"
      name="accountNumber"
    />
    <InputField fullWidth required dataQa="bankCode" label="Wire routing number" name="bankCode" />
  </Fragment>
);

export const DefaultBankFormFields = (
  <Fragment>
    <InputField fullWidth required dataQa="iban" label="IBAN" name="accountNumber" />
    <InputField fullWidth required dataQa="swift-code" label="BIC / SWIFT" name="bankCode" />
  </Fragment>
);
