import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { LearnMoreButton } from '@noah-labs/core-web-ui/src/buttons/LearnMoreButton';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import { useToggleDialog } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { webLinks } from '@noah-labs/shared-constants';
import type { CurrencyCode } from '@noah-labs/shared-schema-gql';
import { getStablecoinsDialogLabel } from '../../utils/getStablecoinsDialogLabel';

export type PpUsdStablecoinsNetworkDialog = {
  currencyCode: CurrencyCode;
  initialOpen?: boolean;
};

export const UsdStablecoinsNetworkDialog = forwardRef<
  TpDialogToggle,
  PpUsdStablecoinsNetworkDialog
>(({ currencyCode, initialOpen }, ref): JSX.Element => {
  const { open, toggle } = useToggleDialog({ initialOpen, ref });

  return (
    <LowDialog open={open} onClose={toggle}>
      <DialogTitle>
        Use the Ethereum, Polygon or Tron Network for sending and receiving {currencyCode}
      </DialogTitle>
      <DialogContent>
        <DialogContentText paragraph>
          Cryptocurrency transactions occur over specific networks, each with its own fees and
          processing times. Make sure the chosen network matches the recipient’s to avoid issues.
        </DialogContentText>
        <DialogContentText>
          <DialogContentText
            paragraph
            color="text.dark"
            component="span"
            variant="paragraphBodyMBold"
          >
            When you send {currencyCode} ({getStablecoinsDialogLabel(currencyCode)}) with NOAH, it’s
            important to use the right network.{' '}
          </DialogContentText>
          Double-check the network compatibility of the {currencyCode} address before sending funds
          to avoid any potential loss.
        </DialogContentText>
        <DialogContentText>
          <LearnMoreButton
            href={webLinks.blog.supportedNetworks}
            target="_blank"
            variant="paragraphBodyM"
          >
            Learn more about the cryptocurrency networks we support.
          </LearnMoreButton>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton color="primaryBrand" onClick={toggle}>
          Got It
        </PrimaryButton>
      </DialogActions>
    </LowDialog>
  );
});

UsdStablecoinsNetworkDialog.displayName = UsdStablecoinsNetworkDialog.name;
