import { useEffect, useMemo, useState } from 'react';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { useLocation } from 'react-router-dom';
import { useSigning } from '../../signing/context';
import { useWalletError } from '../../wallet/hooks';
import { PinSuccessScene } from '../scenes/PinSuccess';

export type PpUnrevokePin = {
  successTo: string;
};

type TpParams = {
  documentType: 'NonCustodyKey';
  revokeCode: string;
  seq: number;
  userId: string;
};

function getParams(search: string): TpParams {
  const params = new URLSearchParams(search);
  const documentType = params.get('documentType');
  const revokeCode = params.get('revokeCode');
  const seq = Number(params.get('seq'));
  const userId = params.get('userId');

  if (!revokeCode || Number.isNaN(seq) || !userId) {
    throw new Error('Missing required parameter');
  }

  if (documentType !== 'NonCustodyKey') {
    throw new Error('Invalid document type');
  }

  return {
    documentType,
    revokeCode,
    seq,
    userId,
  };
}

export function UnrevokePin({ successTo }: PpUnrevokePin): React.ReactElement {
  const { unrevoke } = useSigning();
  const { search } = useLocation();

  const [actioned, setActioned] = useState(false);
  const [error, setError] = useState<unknown>();
  const { ApiErrorScene } = useWalletError(error, true);

  const params = useMemo(() => {
    try {
      return getParams(search);
    } catch (err) {
      setError(err);
      return undefined;
    }
  }, [search]);

  useEffect(() => {
    if (error || actioned || !params) {
      return;
    }

    if (typeof unrevoke !== 'function') {
      return;
    }

    const { documentType, revokeCode, seq, userId } = params;

    void (async (): Promise<void> => {
      try {
        await unrevoke({
          documentType,
          revokeCode,
          seq,
          userId,
        });
        setActioned(true);
      } catch (err) {
        setError(err);
      }
    })();
  }, [actioned, error, params, unrevoke]);

  if (actioned) {
    return (
      <PinSuccessScene
        pageTitle="PIN Unrevoked"
        sceneDescription="Your PIN revocation has been cancelled. No further action is required."
        sceneTitle="PIN Unrevoked"
        successTo={successTo}
      />
    );
  }

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return <LoadingPage />;
}
