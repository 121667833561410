import { useEffect, useState } from 'react';
import { getOryFlowId, useOryCreateSettingsFlow } from '@noah-labs/fe-shared-data-access-auth';
import { useHistory } from 'react-router-dom';

type FlowError = { message: string };
export function useUpdateNewPasswordFlow(returnTo: string): {
  error: FlowError | undefined;
  isLoading: boolean;
} {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<FlowError | undefined>(undefined);

  const history = useHistory();
  const onSettingsFlowCreate = useOryCreateSettingsFlow(returnTo);

  useEffect(() => {
    const flowId = getOryFlowId();
    if (flowId) {
      return;
    }

    async function createSettingsFlow(): Promise<void> {
      try {
        setIsLoading(true);

        const settingsFlowId = await onSettingsFlowCreate();
        if (!settingsFlowId) {
          throw new Error('Flow id is missing');
        }

        history.replace({
          search: new URLSearchParams({ flow: settingsFlowId }).toString(),
        });
      } catch (err) {
        if (err instanceof Error) {
          setError({ message: err.message });
        }
      } finally {
        setIsLoading(false);
      }
    }
    void createSettingsFlow();
  }, [history, onSettingsFlowCreate]);

  return { error, isLoading };
}
