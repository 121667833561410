import { useMemo } from 'react';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { Feature } from '@noah-labs/shared-schema-gql';
import type { FrameCardTokenizedEvent } from 'frames-react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useCountriesForFeature } from '../../../../hooks';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { getUserFullDisplayName } from '../../../../utils';
import { useCountryFromCode } from '../../../user/data/useCountryFromCode';
import { useUserInit } from '../../../user/data/useUserInit';
import { BillingDefaults } from '../../../user/hooks/useBillingDefaults';
import { routes } from '../../routes';
import { EnterPaymentInfoScene } from '../../scenes';
import { TpPaymentMethod } from '../../types';
import { parseCardScheme } from '../../utils/utils';
import type { StSellRouter } from './SellRouter';

type PpEnterPaymentInfo = TpStateMachine<StSellRouter>;

export function EnterPaymentInfo({ updateState }: PpEnterPaymentInfo): React.ReactElement {
  const { data: userData } = useUserInit();
  const { data: countriesData } = useCountriesForFeature(
    userData?.userProfile.HomeAddress?.CountryCode,
    [Feature.CkoSell]
  );
  const country = useCountryFromCode(userData?.userProfile.HomeAddress?.CountryCode);
  const history = useHistory();
  const match = useRouteMatch(routes.sell.enterPaymentInfo.path);
  const { state: locationState } = useLocation<{ successTo: string } | undefined>();
  const { fiatPaymentCurrency } = useUserFiatCurrency();

  const fullName = getUserFullDisplayName(userData?.userProfile.PersonalName);

  const defaultAddress = useMemo(() => {
    if (!userData?.userProfile.HomeAddress || !country) {
      return undefined;
    }

    return BillingDefaults(userData.userProfile.HomeAddress, country);
  }, [country, userData?.userProfile.HomeAddress]);

  const onCardTokenized = useMemo(() => {
    if (typeof locationState?.successTo !== 'string') {
      return undefined;
    }

    return async (cardTokenized: FrameCardTokenizedEvent, save: boolean) => {
      updateState({
        fiatCurrencyCode: fiatPaymentCurrency.code,
        payoutMethod: TpPaymentMethod.Card,
        savePayoutCard: save,
        selectedPayoutCard: {
          billingAddress: undefined,
          id: cardTokenized.token,
          issuer: cardTokenized.issuer,
          last4: cardTokenized.last4,
          scheme: parseCardScheme(cardTokenized.scheme || ''),
          type: 'new',
        },
      });

      history.push(locationState.successTo);
    };
  }, [locationState?.successTo, updateState, fiatPaymentCurrency.code, history]);

  return (
    <EnterPaymentInfoScene
      saveToggle
      countries={countriesData?.countries}
      ctaLabel="Add Card"
      defaultAddress={defaultAddress}
      fullName={fullName}
      isVisible={!!match?.isExact}
      pageTitle={routes.buy.payment.title}
      visiblePath={routes.sell.enterPaymentInfo.path}
      onCardTokenized={onCardTokenized}
    />
  );
}
