import React, { useMemo, useRef } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Divider, Grid, Typography } from '@mui/material';
import { InlineLinkOrButton } from '@noah-labs/core-web-ui/src/buttons/InlineLinkOrButton';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { InputField } from '@noah-labs/core-web-ui/src/forms/InputField';
import type { TpSelectOption } from '@noah-labs/core-web-ui/src/forms/SearchSelect';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { Helmet } from 'react-helmet';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { AppHeaderData } from '../../../components';
import { BillingAddressesForm } from '../../wallet/components';
import type { TpBillingAddressForm } from '../../wallet/components/forms/BillingAddresses/schema';
import {
  billingAddressSchema,
  emptyBillingAddress,
} from '../../wallet/components/forms/BillingAddresses/schema';
import type { TpPaymentCard } from '../../wallet/types';
import { ConfirmRemoveDialog } from '../components/dialogs/ConfirmRemoveDialog';

export type TpEditCardForm = {
  billingAddress: TpBillingAddressForm;
};

const defaults: TpEditCardForm = {
  billingAddress: emptyBillingAddress,
};

const editBillingAddressId = 'editBillingAddress';
const schema = z.object({
  billingAddress: billingAddressSchema,
});
export type PpEditPaymentMethodScene = {
  cardDetails: TpPaymentCard;
  countries: TpSelectOption[] | undefined;
  defaultAddress: TpBillingAddressForm | undefined | null;
  fullName: string | undefined;
  onRemoveCard: () => void;
  onSubmit: SubmitHandler<TpEditCardForm>;
  pageTitle: string;
};
export function EditPaymentMethodScene({
  cardDetails,
  countries,
  defaultAddress,
  fullName,
  onRemoveCard,
  onSubmit,
  pageTitle,
}: PpEditPaymentMethodScene): React.ReactElement {
  const billingAddress = defaultAddress || defaults.billingAddress;
  const defaultValues = useMemo(() => ({ ...defaults, billingAddress }), [billingAddress]);
  const methods = useForm<TpEditCardForm>({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(schema),
    values: defaultValues,
  });

  const showConfirmRemoveDialog = useRef<TpDialogToggle>(null);

  return (
    <AppContainer
      AppFooterSlot={
        <PrimaryButton
          color="primaryBrand"
          disabled={!methods.formState.isDirty}
          form={editBillingAddressId}
          loading={methods.formState.isSubmitting}
          type="submit"
        >
          Save
        </PrimaryButton>
      }
      AppHeaderSlot={
        <AppHeaderData backButton exitButton helpButton>
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa="add-payment-card"
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SceneMain dense maxWidth="xs">
        <FormProvider {...methods}>
          <form id={editBillingAddressId} onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  required
                  dataQa="name"
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Cardholder Name"
                  name="cardholderName"
                  value={fullName}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  fullWidth
                  required
                  dataQa="cardNumber"
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Card Number"
                  name="cardNumber"
                  value={`**** **** **** ${cardDetails.last4}`}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  fullWidth
                  required
                  dataQa="expiryDate"
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Expiry Date"
                  name="expiryDate"
                  value="****"
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  fullWidth
                  required
                  dataQa="cvv"
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Cvv"
                  name="cvv"
                  value="***"
                />
              </Grid>
              <Grid item xs={12}>
                <InlineLinkOrButton
                  variant="paragraphBodySBold"
                  onClick={(): void => showConfirmRemoveDialog.current?.toggle()}
                >
                  Remove payment method
                </InlineLinkOrButton>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="paragraphBodyLBold">Billing address</Typography>
              </Grid>
              <Grid item xs={12}>
                <BillingAddressesForm countries={countries} defaultAddress={defaultAddress} />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <ConfirmRemoveDialog
          ref={showConfirmRemoveDialog}
          cardDetails={cardDetails}
          onRemoveCard={onRemoveCard}
        />
      </SceneMain>
    </AppContainer>
  );
}
