import type { ReactElement } from 'react';
import React from 'react';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import type { IMessage } from '@novu/notification-center';
import { NotificationCenter } from '@novu/notification-center';
import {
  headerHeight,
  notificationListDesktopHeight,
  notificationListMobileHeight,
} from '../constants';
import type { SetScreenFn } from '../controllers/NotificationsCenter';

export type RenderHeaderParams = { setScreen: SetScreenFn };

type NotificationsCenterProps = {
  renderEmptyMessage: () => ReactElement;
  renderHeader: (params: RenderHeaderParams) => ReactElement;
  renderNotificationItem: (message: IMessage) => ReactElement;
};

function renderFooter(): ReactElement {
  return <div />;
}

export function NotificationsCenter({
  renderEmptyMessage,
  renderHeader,
  renderNotificationItem,
}: NotificationsCenterProps): React.ReactElement | null {
  const theme = useTheme();

  const styles = {
    screen: css`
      ${theme.breakpoints.up('md')} {
        z-index: ${theme.zIndex.appBar - 1};
      }

      .nc-layout-wrapper {
        background: ${theme.palette.grayscale.offWhite};
        height: ${notificationListMobileHeight};
        width: 100vw;
        border-radius: 0px;
        padding: 0px;

        ${theme.breakpoints.up('md')} {
          width: 100%;
          height: ${notificationListDesktopHeight};
        }
      }

      .nc-notifications-list {
        min-height: ${notificationListMobileHeight};

        ${theme.breakpoints.up('md')} {
          min-height: calc(${notificationListDesktopHeight} - ${headerHeight});
        }

        .infinite-scroll-component__outerdiv {
          height: 100%;
        }

        .infinite-scroll-component {
          ${theme.breakpoints.up('md')} {
            min-height: calc(${notificationListDesktopHeight} - ${headerHeight}) !important;
            max-height: calc(${notificationListDesktopHeight} - ${headerHeight}) !important;
            height: calc(${notificationListDesktopHeight} - ${headerHeight}) !important;
          }

          display: flex;
          flex-direction: column;
          min-height: calc(${notificationListMobileHeight} - ${headerHeight}) !important;
          height: calc(${notificationListMobileHeight} - ${headerHeight}) !important;
          overflow: scroll;
          padding: ${theme.spacing(2, 3)};
        }
      }

      .nc-preferences-item-title {
        font-weight: ${theme.typography.fontWeightMedium};
      }

      .nc-preferences-item-channels {
        color: ${theme.palette.grayscale[700]};
        opacity: 0.7;
      }

      .nc-preferences-channel-label {
        color: ${theme.palette.grayscale[600]};
      }
    `,
  };

  return (
    <div css={styles.screen}>
      <NotificationCenter
        showUserPreferences
        colorScheme="light"
        emptyState={renderEmptyMessage()}
        footer={renderFooter}
        // @ts-expect-error novu types are outdated
        header={(params: RenderHeaderParams): ReactElement => renderHeader(params)}
        listItem={(message): ReactElement => renderNotificationItem(message)}
        theme={{
          light: {
            loaderColor: theme.palette.brand.light,
            userPreferences: {
              accordion: {
                background: theme.palette.grayscale.white,
                boxShadow: theme.shadows[2],
                fontColor: theme.palette.text.primary,
              },
              accordionItem: {
                switch: {
                  backgroundChecked: `${theme.palette.brand.light} !important`,
                },
              },
            },
          },
        }}
      />
    </div>
  );
}
