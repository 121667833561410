import type { PpHeaderIconButton } from '@noah-labs/core-web-ui/src/buttons/HeaderIconButton';
import { HeaderIconButton } from '@noah-labs/core-web-ui/src/buttons/HeaderIconButton';
import { useNavigation } from '@noah-labs/core-web-ui/src/hooks/useNavigation';
import { CloseIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CloseIcon';

type PpExitIconButtonData = Pick<PpHeaderIconButton, 'edge'>;

export function ExitIconButtonData({ edge }: PpExitIconButtonData): React.ReactElement {
  const { exitTo } = useNavigation();

  return (
    <HeaderIconButton aria-label="exit" data-qa="exit-button" edge={edge} href={exitTo}>
      <CloseIcon />
    </HeaderIconButton>
  );
}
