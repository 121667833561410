import { fiatCurrencyFromCode } from '@noah-labs/fe-shared-ui-currencies';
import type { FiatCurrencyCode, ManualRampCurrencySettings } from '@noah-labs/shared-schema-gql';
import { webConfigBrowser } from '../../../webConfigBrowser';
import type { TpPaymentBank, TpPaymentCard } from '../types';
import { TpPaymentMethod } from '../types';
import { getPayoutEstimateTime } from '../utils/utils';

const { cko, manualRamp } = webConfigBrowser;

export const manualRampEta = '1-2 business days';
export const cardFee = `${cko.feePercentage}% fee (min $${cko.feeMinimumFiatAmount})`;

export function getManualRampCurrencyConfig(
  currency: FiatCurrencyCode
): ManualRampCurrencySettings | undefined | null {
  const manualRampCurrencyConfig = manualRamp.settings.find((c) => c?.FiatCurrency === currency);
  return manualRampCurrencyConfig;
}

function getBankCurrencyFee(currency: FiatCurrencyCode): string {
  const manualRampCurrencyConfig = getManualRampCurrencyConfig(currency);

  if (!manualRampCurrencyConfig) {
    return '';
  }

  const { FeeMinimumFiatAmount, FeePercentage, FiatCurrency } = manualRampCurrencyConfig;
  const currencySymbol = fiatCurrencyFromCode(FiatCurrency).symbol;

  const bankFee = `${FeePercentage}% fee (min ${currencySymbol}${FeeMinimumFiatAmount})`;
  return bankFee;
}

export function getBankCurrencyFeeAndEta(currency: FiatCurrencyCode): string {
  const bankCurrencyFee = getBankCurrencyFee(currency);
  return [manualRampEta, bankCurrencyFee].join(' • ');
}

export function getPayoutFeeAndEta(method: TpPaymentCard | TpPaymentBank): string {
  if (method.payoutMethod === TpPaymentMethod.Card && method.estimateHours) {
    return [getPayoutEstimateTime(method.estimateHours), cardFee].join(' • ');
  }

  const payoutMethod = method as TpPaymentBank;
  return getBankCurrencyFeeAndEta(payoutMethod.accountCurrency);
}
