import type { PpCountdown } from '@noah-labs/core-web-ui/src/timing/Countdown';
import { Countdown } from '@noah-labs/core-web-ui/src/timing/Countdown';
import dayjs from 'dayjs';
import { webConfigBrowser } from '../../../../webConfigBrowser';

type PpInvoiceExpires = Pick<PpCountdown, 'expiryTime' | 'initialTimeDiff'>;

export function InvoiceExpires({
  expiryTime,
  initialTimeDiff,
}: PpInvoiceExpires): React.ReactElement {
  const lt1H = initialTimeDiff < dayjs.duration({ hours: 1 }).asMilliseconds();

  return (
    <Countdown
      enabled={webConfigBrowser.settings.enableTimers}
      expiredLabel="Your invoice is expired."
      expiryTime={expiryTime}
      format={lt1H ? 'mm:ss' : 'HH:mm:ss'}
      initialTimeDiff={initialTimeDiff}
      prefixLabel="Invoice expires in"
      variant="paragraphBodyS"
    />
  );
}
