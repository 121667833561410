import type { TpMoonpaySellTransactionStatus } from '@noah-labs/core-services';
import { TransactionStatus } from '@noah-labs/shared-schema-gql';

export function getAvatarStatus(mpStatus: TpMoonpaySellTransactionStatus): TransactionStatus {
  switch (mpStatus) {
    case 'failed':
      return TransactionStatus.Failed;
    case 'completed':
      return TransactionStatus.Settled;
    case 'pending':
    case 'waitingForDeposit':
    default:
      return TransactionStatus.Pending;
  }
}
