import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BalancedStack } from '@noah-labs/core-web-ui/src/layout/BalancedStack';
import { ChevronLeftIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronLeftIcon';
import { headerHeight } from '../constants';

type HeaderProps = {
  onOpenSettingsClick: () => void;
  onReturnClick: () => void;
  showSettingsButton: boolean;
  title: string;
};

export function Header({
  onOpenSettingsClick,
  onReturnClick,
  showSettingsButton,
  title,
}: HeaderProps): ReactElement {
  const theme = useTheme();

  const styles = {
    button: css`
      margin: ${theme.spacing(0, 3)};
    `,
    invisibleSettingsButton: css`
      visibility: hidden;
    `,
  };

  const settingsButtonStyles = !showSettingsButton ? styles.invisibleSettingsButton : undefined;

  return (
    <BalancedStack
      EndIcons={
        <IconButton
          color="primary"
          css={[styles.button, settingsButtonStyles]}
          onClick={onOpenSettingsClick}
        >
          <SettingsIcon />
        </IconButton>
      }
      height={headerHeight}
      StartIcons={
        <IconButton color="primary" css={styles.button} onClick={onReturnClick}>
          <ChevronLeftIcon />
        </IconButton>
      }
    >
      <Typography variant="paragraphBodyLBold">{title}</Typography>
    </BalancedStack>
  );
}
