import { TransactionStatus } from '@noah-labs/shared-schema-gql';
import { duration } from '@noah-labs/shared-tools/src/browser/duration';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import { getBlockchainExplorerLink } from '../../../utils';
import { useUserInit } from '../../user/data/useUserInit';
import type { PpTransactionDetails } from '../components/transactions/TransactionDetails';
import { getEnhancedTxData } from './transactions';
import { useTransactionQuery } from './wallet.generated';

export function useTransactionDetails(id: string | undefined | null): PpTransactionDetails | null {
  const userInit = useUserInit();
  const { data } = useTransactionQuery(
    { Input: { TransactionID: id || '' } },
    {
      enabled: Boolean(id),
      /**
       * refetch every second if the transaction is pending
       */
      refetchInterval: (d): number | false => {
        if (!d?.transaction || d.transaction.Status === TransactionStatus.Pending) {
          return duration.seconds(1);
        }
        return false;
      },
    }
  );

  const tx = data?.transaction;
  const primaryCurrency = userInit.data?.userProfile.PrimaryCurrency;

  if (!tx) {
    return null;
  }

  const enhancedTx = getEnhancedTxData({
    tx,
  });

  const isPending = tx.Status === TransactionStatus.Pending;
  const feeAmount = isPending ? undefined : enhancedTx.feeAmount;
  const hasFee = enhancedTx.isRamp || !isUndefinedOrNull(enhancedTx.feeAmount);

  const txProps = {
    ...enhancedTx,
    dataQa: { noahId: tx.NoahID, orderId: tx.WithdrawOrderID, publicId: tx.PublicID },
    description: tx.Description,
    feeAmount,
    hasFee,
    isRamp: enhancedTx.isRamp,
    NetworkIcon: enhancedTx.CurrencyIcon,
    primaryCurrency,
    publicUrl: getBlockchainExplorerLink(tx.Network, tx.PublicID),
    transactionId: tx.NoahID || tx.WithdrawOrderID,
    // TODO: the transaction requires additional info on the sender/receiver
    // 1. if it's a NOAH-NOAH tx then we should store usernames
    // 2. if the user is paying an LN invoice to an external wallet then we need
    // either the LN address or invoice code
  };

  return txProps;
}
