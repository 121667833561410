import { useCallback } from 'react';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { FiatAmount } from '@noah-labs/core-web-ui/src/numbers/FiatAmount';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { cryptoCurrencyFromCode } from '@noah-labs/fe-shared-ui-currencies';
import type { TpSlippage } from '@noah-labs/shared-currencies/src/calculations';
import type { CountryCode } from '@noah-labs/shared-schema-gql';
import {
  CurrencyDisplayType,
  FiatPaymentMethodTokenProvider,
  FiatPaymentType,
} from '@noah-labs/shared-schema-gql';
import { duration } from '@noah-labs/shared-tools/src/browser/duration';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { webConfigBrowser } from '../../../../webConfigBrowser';
import type { TpOnSign } from '../../../signing/controllers/Sign';
import { useSignWithdrawal } from '../../../signing/controllers/useSignWithdrawal';
import { useFiatCurrencyFromCode } from '../../../user/data/useFiatCurrencyFromCode';
import { useKycNotApprovedAndCheckoutEnabled } from '../../../user/hooks/useKycNotApprovedAndCheckoutEnabled';
import { UnsupportedPayoutCardAlert } from '../../components';
import {
  getManualRampCurrencyConfig,
  useFiatPaymentMethodBankSaveMutation,
  useFiatPaymentMethodProviderSaveMutation,
  useFiatPayoutMutation,
  useWalletParams,
} from '../../data';
import { useWalletError } from '../../hooks';
import { useAmountsWithFee } from '../../hooks/useAmountsWithFee';
import { useCurrencyAmountsWithFee } from '../../hooks/useCurrencyAmountsWithFee';
import { routes } from '../../routes';
import { ConfirmSellScene } from '../../scenes';
import { TpCheckoutPayoutStatus, TpPaymentMethod } from '../../types';
import { getPayoutTansferDestinationType } from '../../utils/utils';
import type { StSellRouter } from './SellRouter';

const { feeMinimumFiatAmount, feePercentage, slippagePercentage } = webConfigBrowser.cko;

const checkoutFees = {
  feeMinimumFiatAmount,
  feePercentage,
};

const slippage: TpSlippage = {
  slippage: slippagePercentage,
  type: 'negative',
};

type PpConfirm = TpStateMachine<StSellRouter>;

export function Confirm({ state, updateState }: PpConfirm): React.ReactElement {
  const { AccountType, CurrencyCode, params } = useWalletParams();
  const history = useHistory();
  const pushAlert = usePushAlert();
  const kycRequired = useKycNotApprovedAndCheckoutEnabled();

  const { error: fiatPayoutError, mutateAsync: mutateFiatPayout } = useFiatPayoutMutation();
  const {
    error: fiatPaymentMethodProviderSaveError,
    mutateAsync: mutateFiatPaymentMethodProviderSave,
  } = useFiatPaymentMethodProviderSaveMutation();
  const { error: fiatPaymentMethodBankSaveError, mutateAsync: mutateFiatPaymentMethodBankSave } =
    useFiatPaymentMethodBankSaveMutation();

  const cryptoCurrency = cryptoCurrencyFromCode(CurrencyCode);
  const fiatCurrency = useFiatCurrencyFromCode(state.fiatCurrencyCode);

  const isBankPayoutMethod = state.payoutMethod === TpPaymentMethod.BankTransfer;

  const manualRampCurrencyConfig = getManualRampCurrencyConfig(state.fiatCurrencyCode);
  const currencyData = isBankPayoutMethod && manualRampCurrencyConfig;
  const fees = currencyData
    ? {
        feeMinimumFiatAmount: currencyData.FeeMinimumFiatAmount,
        feePercentage: currencyData.FeePercentage,
      }
    : checkoutFees;

  const {
    feeCryptoAmount,
    feeFiatAmount,
    fetchedAt,
    netCryptoAmount,
    netFiatAmount,
    price,
    totalCryptoAmount,
    totalFiatAmount,
  } = useAmountsWithFee({
    cryptoCurrency,
    fees,
    fiatAmount: state.fiatAmount,
    fiatCurrency,
    isAmountNet: true,
    options: {
      refetchInterval: duration.seconds(3),
    },
    priceProvider: 'sell',
    slippage,
  });

  const { fee, net, total } = useCurrencyAmountsWithFee({
    cryptoAmount: totalCryptoAmount,
    cryptoCurrency,
    feeCryptoAmount,
    feeFiatAmount,
    fiatAmount: totalFiatAmount,
    fiatCurrency,
    netCryptoAmount,
    netFiatAmount,
    primaryCurrency: CurrencyDisplayType.Fiat,
  });

  /**
   * Submits the payment request
   */
  const { isLoading: isSubmittingPayment, mutateAsync: submitPayout } = useMutation(
    async ({ signature }: TpOnSign) => {
      if (!price || !fetchedAt) {
        return;
      }
      let paymentId = state.selectedPayoutCard?.id;

      try {
        switch (state.payoutMethod) {
          case TpPaymentMethod.Card: {
            paymentId = state.selectedPayoutCard?.id;

            if (state.selectedPayoutCard?.type === 'new') {
              const fpmProviderSaveData = await mutateFiatPaymentMethodProviderSave({
                Input: {
                  Provider: FiatPaymentMethodTokenProvider.Checkout,
                  SaveForFutureUse: Boolean(state.savePayoutCard),
                  Token: state.selectedPayoutCard.id,
                },
              });

              if (!fpmProviderSaveData.fiatPaymentMethodProviderSave.DynamoID) {
                throw new Error('Failed to save payment method');
              }

              const isPayoutSupported =
                fpmProviderSaveData.fiatPaymentMethodProviderSave.Details.Payout.EstimateHours;

              if (!isPayoutSupported) {
                pushAlert(UnsupportedPayoutCardAlert);
                return;
              }

              paymentId = fpmProviderSaveData.fiatPaymentMethodProviderSave.DynamoID;
            }
            break;
          }

          case TpPaymentMethod.BankTransfer: {
            paymentId = state.selectedPayoutBank?.id;

            if (state.selectedPayoutBank?.type === 'new') {
              const fpmBankSaveData = await mutateFiatPaymentMethodBankSave({
                Input: {
                  AccountCurrency: state.selectedPayoutBank.accountCurrency,
                  AccountHolderAddress: state.selectedPayoutBank.accountHolderAddress,
                  AccountHolderName: state.selectedPayoutBank.accountHolderName,
                  AccountNumber: state.selectedPayoutBank.accountNumber,
                  BankCode: state.selectedPayoutBank.bankCode,
                  BankCountry: state.selectedCountry as CountryCode,
                  Reusable: Boolean(state.savePayoutBank),
                  System: state.selectedPayoutBank.system,
                },
              });

              if (!fpmBankSaveData.fiatPaymentMethodBankSave.DynamoID) {
                throw new Error('Failed to save payment method');
              }

              paymentId = fpmBankSaveData.fiatPaymentMethodBankSave.DynamoID;
            }
            break;
          }

          default:
            throw new Error('Missing payout method');
        }

        const { fiatPayout } = await mutateFiatPayout({
          Input: {
            MaximumDeduction: totalCryptoAmount,
            PaymentDetails: {
              ID: paymentId,
              Type: FiatPaymentType.FiatPaymentMethodID,
            },
            RequestedAmount: {
              Amount: netFiatAmount,
              FetchedAt: fetchedAt,
              FiatCurrency: fiatCurrency.code,
              Price: price,
            },
            ...(signature && { Nonce: signature.nonce, Signature: signature.signature }),
            SourceCurrency: cryptoCurrency.code,
          },
        });

        updateState({
          transactionId: fiatPayout.Entries[0].EventId,
        });

        if (fiatPayout.FailedEntryCount) {
          history.push(
            generatePath(routes.sell.complete.path, {
              ...params,
              payoutStatus: TpCheckoutPayoutStatus.failure,
            })
          );
          return;
        }

        history.push(
          generatePath(routes.sell.complete.path, {
            ...params,
            payoutStatus: TpCheckoutPayoutStatus.pending,
          })
        );
      } catch (e) {
        // useWalletError handles error
      }
    }
  );

  const { loading: signLoading, sign } = useSignWithdrawal({
    payload: {
      AccountType,
      Amount: totalCryptoAmount,
      CurrencyCode,
      Destination: getPayoutTansferDestinationType(state.payoutMethod),
      inputType: 'payout',
      RequestedAmount: {
        Amount: netFiatAmount,
        FiatCurrency: fiatCurrency.code,
      },
    },
  });

  const readyForPayment = !signLoading && !isUndefinedOrNull(fetchedAt);

  const onConfirm = useCallback(async () => {
    await sign(submitPayout);
  }, [sign, submitPayout]);

  const { ApiErrorScene } = useWalletError(
    fiatPayoutError || fiatPaymentMethodProviderSaveError || fiatPaymentMethodBankSaveError
  );
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <ConfirmSellScene
      cryptoCurrency={cryptoCurrency}
      CryptoPriceSlot={<FiatAmount amount={price} currency={fiatCurrency} />}
      ctaDisabled={!readyForPayment}
      FeeAmountSlot={fee.PrimaryAmountSlot}
      fiatCurrency={fiatCurrency}
      isLoading={isSubmittingPayment}
      kycRequired={kycRequired}
      NetFiatAmountSlot={net.PrimaryAmountSlot}
      pageTitle={routes.sell.confirm.title}
      payoutMethod={state.payoutMethod}
      payoutMethodHref={generatePath(routes.sell.payoutMethod.path, params)}
      selectedPayoutBank={state.selectedPayoutBank}
      selectedPayoutCard={state.selectedPayoutCard}
      TotalCryptoAmountSlot={total.SecondaryAmountSlot}
      onConfirm={onConfirm}
    />
  );
}
