import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { compareStrings } from '@noah-labs/shared-tools/src/browser/strings';
import { Redirect, Route, useParams } from 'react-router-dom';
import { useUserInit } from '../../user/data/useUserInit';
import { isQuestFeatureEnabled, useQuestsAndBadgesQuery } from '../data';
import { useQuestStatusById } from '../hooks/useQuestStatusById';
import { getBadgeButtonCopy, getBadgeButtonLink } from '../mappings';
import { routes } from '../routes';
import { BadgeScene } from '../scenes/Badge';
import { TpRewardsTabs } from '../types';

export function BadgeData(): React.ReactElement {
  const params = useParams<{ id: string }>();
  const { data: userInitData, features } = useUserInit();

  const { data: badge, isLoading } = useQuestsAndBadgesQuery(undefined, {
    enabled: !!params.id,
    select: (data) =>
      data.questsAndBadges.UserBadges.find((b) => compareStrings(b.BadgeTypeID, params.id)),
  });

  const { data: questStatus } = useQuestStatusById(badge?.QuestID);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!badge) {
    return (
      <Route
        // eslint-disable-next-line react/no-unstable-nested-components
        component={(): React.ReactElement => (
          <Redirect
            to={generatePath(routes.tabs.path, {
              tab: TpRewardsTabs.Badges,
            })}
          />
        )}
      />
    );
  }

  const questFeatureEnabled = questStatus
    ? isQuestFeatureEnabled(questStatus.Quest, features)
    : undefined;

  return (
    <BadgeScene
      badge={badge}
      buttonCopy={getBadgeButtonCopy(badge.QuestID, questFeatureEnabled)}
      buttonLink={getBadgeButtonLink(badge.QuestID, questFeatureEnabled)}
      questFeatureEnabled={questFeatureEnabled}
      username={userInitData?.userProfile.UsernameDisplay}
    />
  );
}
