import { Fragment } from 'react';
import type { TpPushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { GetInTouchLink } from '../buttons';

export function AlertError(key: string, error: React.ReactNode): TpPushAlert {
  return {
    autoHideDuration: 3000,
    key,
    message: error,
    preventDuplicate: true,
    severity: 'error',
  };
}

/**
 * Errors
 */
export const ApiUnknown: TpPushAlert = AlertError(
  'unknown',
  <Fragment>
    Sorry, something went wrong. Please try again and if the problem persists, please{' '}
    <GetInTouchLink color="inherit" underline="always" />.
  </Fragment>
);
