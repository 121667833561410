import { useRef } from 'react';
import type { TypographyOwnProps } from '@mui/material';
import { InfoTooltip } from '@noah-labs/core-web-ui/src/buttons/InfoTooltip';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { StructuredContent } from '@noah-labs/core-web-ui/src/lists/atoms/StructuredContent';
import { TextOrSkeleton } from '@noah-labs/core-web-ui/src/typography/TextOrSkeleton';
import { ProgressBar } from '@noah-labs/core-web-ui/src/utility/ProgressBar';
import { DailySendLimitsDialog } from '../dialogs/DailySendLimitsDialog';

export type PpTransactionsLimitsIndicator = {
  dailyLimit: number;
  remainingTransactions: number;
};

const primaryTypographyProps: TypographyOwnProps = {
  color: 'text.light',
  variant: 'paragraphBodyS',
};

export function TransactionsLimitsIndicator({
  dailyLimit,
  remainingTransactions,
}: PpTransactionsLimitsIndicator): React.ReactElement {
  const limitDialogRef = useRef<TpDialogToggle>(null);

  const progress = (dailyLimit - remainingTransactions) * 0.1;
  const progressText = `${dailyLimit - remainingTransactions}/${dailyLimit}`;
  const remainingTransactionsText = `${remainingTransactions} transactions left`;

  return (
    <div>
      <StructuredContent
        mainText={{
          disableTypography: true,
          primary: (
            <InfoTooltip
              color="text.light"
              variant="paragraphBodySBold"
              onClick={(): void => limitDialogRef.current?.toggle()}
            >
              Daily send limit
            </InfoTooltip>
          ),
        }}
      />
      <ProgressBar progress={progress || 0} sx={{ mb: 1, mt: 1.5 }} />
      <StructuredContent
        endText={{
          primary: <TextOrSkeleton>{remainingTransactionsText}</TextOrSkeleton>,
          primaryTypographyProps,
        }}
        mainText={{
          primary: <TextOrSkeleton>{progressText}</TextOrSkeleton>,
          primaryTypographyProps,
        }}
      />
      <DailySendLimitsDialog ref={limitDialogRef} limit={dailyLimit} />
    </div>
  );
}
