import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { Redirect, Route, useParams } from 'react-router-dom';
import { useUserInit } from '../../user/data/useUserInit';
import { isQuestFeatureEnabled } from '../data';
import { useQuestStatusById } from '../hooks/useQuestStatusById';
import { routes } from '../routes';
import { QuestScene } from '../scenes/Quest';
import { TpRewardsTabs } from '../types';

export function QuestData(): React.ReactElement | null {
  const params = useParams<{ id: string }>();
  const { features } = useUserInit();

  const { data: questStatus, isLoading } = useQuestStatusById(params.id);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!questStatus || !isQuestFeatureEnabled(questStatus.Quest, features)) {
    return (
      <Route
        // eslint-disable-next-line react/no-unstable-nested-components
        component={(): React.ReactElement => (
          <Redirect
            to={generatePath(routes.tabs.path, {
              tab: TpRewardsTabs.Quests,
            })}
          />
        )}
      />
    );
  }

  return <QuestScene questStatus={questStatus} />;
}
