import React from 'react';
import { CurrencyAvatar } from '@noah-labs/core-web-ui/src/images';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { TextWithIcon } from '@noah-labs/core-web-ui/src/typography/TextWithIcon';
import type { TpMuiSvgIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/types';
import type {
  CurrencyCode,
  CurrencyDisplayType,
  CurrencyUnit,
  FiatCurrencyCode,
} from '@noah-labs/shared-schema-gql';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components';
import type { PreferredCryptoCurrencyForm } from '../../wallet/components/forms/PreferredCryptoCurrencyForm';
import type { PreferredFiatCurrencyForm } from '../../wallet/components/forms/PreferredFiatCurrencyForm';

type TpRadioOption = {
  icon?: TpMuiSvgIcon;
  label: string;
  value: string;
};

export type PpUserSettingOptionsScene<T> = {
  ContentSlot?: React.ReactElement;
  CurrencyForm: typeof PreferredCryptoCurrencyForm | typeof PreferredFiatCurrencyForm;
  HeaderIcon?: TpMuiSvgIcon;
  dataQa?: string;
  formTitle?: string;
  handleChange: (value: T) => void;
  hideValues?: boolean;
  items: Array<TpRadioOption>;
  pageTitle: string;
  value?: string | null;
};

type TpUserSettingsCurrencyOptions =
  | CurrencyDisplayType
  | CurrencyCode.BTC
  | CurrencyUnit
  | FiatCurrencyCode;

export function UserSettingOptionsScene<T extends TpUserSettingsCurrencyOptions>({
  ContentSlot,
  CurrencyForm,
  dataQa,
  formTitle,
  handleChange,
  HeaderIcon,
  hideValues,
  items,
  pageTitle,
  value,
}: PpUserSettingOptionsScene<T>): React.ReactElement {
  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeaderData backButton helpButton>
          {HeaderIcon ? (
            <TextWithIcon>
              <CurrencyAvatar Icon={HeaderIcon} size={2.5} />
              <span>{pageTitle}</span>
            </TextWithIcon>
          ) : (
            <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
          )}
        </AppHeaderData>
      }
      dataQa={dataQa}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <SceneMain dense>
        {value && (
          <CurrencyForm
            hideValues={hideValues}
            items={items}
            title={formTitle}
            value={value}
            onChange={handleChange}
          />
        )}
        {ContentSlot}
      </SceneMain>
    </AppContainer>
  );
}
