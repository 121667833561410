import { useCallback } from 'react';
import { useMoonpaySellQuoteQuery, useMoonpaySellTransactionQuery } from '@noah-labs/core-services';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { cryptoCurrencyFromCode, fiatCurrencyFromString } from '@noah-labs/fe-shared-ui-currencies';
import { getEnvCurrencyCode } from '@noah-labs/shared-currencies';
import type { CurrencyCode } from '@noah-labs/shared-schema-gql';
import { Network, NoahErrorType, TransferDestinationType } from '@noah-labs/shared-schema-gql';
import { safeBN } from '@noah-labs/shared-tools/src/browser/numbers';
import { compareStrings } from '@noah-labs/shared-tools/src/browser/strings';
import { useHistory } from 'react-router-dom';
import { createAppSyncError } from '../../../../errors';
import { getDefaults } from '../../../../utils';
import { networkForEnv } from '../../../../utils/networks';
import { isProd } from '../../../../webConfigBrowser';
import { NetworkFeeItem } from '../../components/withdraw/NetworkFeeItem';
import { useWalletParams, useWithdrawOrderCreateMutation } from '../../data';
import { useWalletError } from '../../hooks';
import { useCurrencyAmounts } from '../../hooks/useCurrencyAmounts';
import { useWithdrawalAllowance } from '../../hooks/useWithdrawalAllowance';
import { useWithdrawFeeWithTransaction } from '../../hooks/useWithdrawFeeWithTransaction';
import { routes } from '../../routes';
import { ConfirmScene } from '../../scenes';
import { getWithdrawFeeAmounts } from '../../utils/getWithdrawFeeAmounts';

const { fiatCurrency: defaultFiatCurrency } = getDefaults();

export function ConfirmWithMoonpay(): React.ReactElement {
  const { AccountType, params } = useWalletParams();
  const history = useHistory();

  const searchParams = new URLSearchParams(window.location.search);
  const transactionId = searchParams.get('transactionId') || '';

  const { data: transactionData, isFetched: transactionIsFetched } =
    useMoonpaySellTransactionQuery(transactionId);
  const {
    error: accountWithdrawError,
    isLoading: accountWithdrawIsLoading,
    mutateAsync: accountWithdraw,
  } = useWithdrawOrderCreateMutation();

  const baseCurrencyCode = transactionData?.baseCurrency.code;
  const quoteCurrencyCode = transactionData?.quoteCurrency.code;
  const cryptoCode = getEnvCurrencyCode(baseCurrencyCode, isProd);
  const cryptoCurrency = cryptoCurrencyFromCode(cryptoCode);
  const fiatCurrency = fiatCurrencyFromString(quoteCurrencyCode);
  const fiatCurrencyWithFallback = fiatCurrency || defaultFiatCurrency;
  const moonpayDepositWallet = transactionData?.depositWallet;
  const cryptoAmount = transactionData?.baseCurrencyAmount;
  const network = networkForEnv(Network.Bitcoin);

  const allowance = useWithdrawalAllowance({
    accountType: AccountType,
    cryptoCurrency,
    network,
  });

  const { data: quoteData } = useMoonpaySellQuoteQuery({
    baseCurrencyAmount: cryptoAmount,
    baseCurrencyCode,
    quoteCurrencyCode,
  });
  const fiatAmountBN = safeBN(quoteData?.quoteCurrencyAmount);

  const enableWithdrawFeeQuery =
    !accountWithdrawIsLoading && !!fiatCurrency && !!moonpayDepositWallet?.walletAddress;

  const feeData = useWithdrawFeeWithTransaction({
    address: moonpayDepositWallet?.walletAddress || '',
    cryptoAmount: cryptoAmount?.toString() || '',
    cryptoCurrency,
    enabled: enableWithdrawFeeQuery,
    fiatCurrency: fiatCurrencyWithFallback,
    network,
  });

  const totalAmounts = getWithdrawFeeAmounts({
    cryptoAmount: cryptoAmount?.toString() || '',
    feeCryptoAmount: feeData.feeCryptoAmount,
    feeFiatAmount: feeData.feeFiatAmount,
    fiatAmount: fiatAmountBN.toString(),
  });

  const sufficientBalance =
    feeData.isFeeFetched && allowance?.balanceUserCrypto?.gte(totalAmounts.cryptoAmountWithFee);

  const FeeAmounts = useCurrencyAmounts({
    cryptoAmount: feeData.feeCryptoAmount,
    cryptoCurrency,
    fiatAmount: feeData.feeCryptoAmount ? feeData.feeFiatAmount : undefined,
    fiatCurrency: fiatCurrencyWithFallback,
    roundDown: true,
  });

  const TotalAmounts = useCurrencyAmounts({
    cryptoAmount: feeData.isFeeFetched ? totalAmounts.cryptoAmountWithFee : undefined,
    cryptoCurrency,
    fiatAmount: feeData.isFeeFetched ? totalAmounts.fiatAmountWithFee : undefined,
    fiatCurrency: fiatCurrencyWithFallback,
    roundDown: true,
  });

  const waitingForDeposit = compareStrings(transactionData?.status, 'waitingForDeposit');
  const isReadyToWithdraw = cryptoAmount && moonpayDepositWallet;

  const onConfirm = useCallback(async () => {
    if (!waitingForDeposit || !isReadyToWithdraw) {
      return;
    }

    const fetchedAt = new Date().toISOString();
    const cryptoAmountBig = safeBN(cryptoAmount);
    const price = fiatAmountBN.dividedBy(cryptoAmountBig).toFixed(2);

    try {
      await accountWithdraw({
        Input: {
          AccountType,
          Amount: totalAmounts.cryptoAmountWithFee,
          CurrencyCode: cryptoCurrency.code,
          Destination: {
            DestinationAddress: {
              Address: moonpayDepositWallet.walletAddress,
              Ref: moonpayDepositWallet.walletAddressTag,
            },
            DestinationType: TransferDestinationType.Moonpay,
            ID: transactionId,
          },
          FeeQuote: feeData.feeQuote,
          Network: network,
          RequestedAmount: {
            Amount: fiatAmountBN.toString(),
            FetchedAt: fetchedAt,
            FiatCurrency: fiatCurrencyWithFallback.code,
            Price: price,
          },
        },
      });

      history.push({
        pathname: generatePath(routes.sell.moonpay.complete.path, params),
        search: new URLSearchParams({ transactionId }).toString(),
      });
    } catch {
      // Handled by useWalletError
    }
  }, [
    AccountType,
    history,
    accountWithdraw,
    cryptoAmount,
    fiatAmountBN,
    isReadyToWithdraw,
    moonpayDepositWallet?.walletAddress,
    moonpayDepositWallet?.walletAddressTag,
    transactionId,
    waitingForDeposit,
    feeData.feeQuote,
    network,
    params,
    totalAmounts.cryptoAmountWithFee,
    cryptoCurrency.code,
    fiatCurrencyWithFallback.code,
  ]);

  // Error handling
  const detailsMissing =
    transactionIsFetched && (!cryptoAmount || !moonpayDepositWallet)
      ? createAppSyncError(NoahErrorType.TransactionDetailsMissing)
      : null;
  const currencyNotAvailable =
    transactionIsFetched && !fiatCurrency
      ? createAppSyncError(NoahErrorType.UnsupportedCurrency)
      : null;
  const { ApiErrorScene } = useWalletError(
    accountWithdrawError || detailsMissing || currencyNotAvailable
  );
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  if (!transactionData) {
    return <LoadingPage />;
  }

  return (
    <ConfirmScene
      backButton
      addressData={{
        address: moonpayDepositWallet?.walletAddress || '',
        currencyCode: cryptoCurrency.code as CurrencyCode.BTC | CurrencyCode.BTC_TEST,
        Icon: cryptoCurrency.Icon,
        network,
        processingTime: 0,
      }}
      cryptoAmount={cryptoAmount?.toString() || ''}
      cryptoCurrency={cryptoCurrency}
      FeeSlot={<NetworkFeeItem cryptoCurrency={cryptoCurrency} {...FeeAmounts} />}
      fiatAmount={fiatAmountBN.toString()}
      fiatCurrency={fiatCurrencyWithFallback}
      isCtaDisabled={!sufficientBalance}
      isLoading={accountWithdrawIsLoading}
      pageTitle={routes.sell.moonpay.confirm.title}
      TotalAmountSlots={TotalAmounts}
      onConfirm={onConfirm}
    />
  );
}
