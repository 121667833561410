import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

type TpGetCarouselTimingProps = {
  end: dayjs.Dayjs;
  start: dayjs.Dayjs;
};

type TpGetCarouselCardTiming = {
  countDown: string;
  isActive: boolean;
};

export function getCarouselCardTiming({
  end,
  start,
}: TpGetCarouselTimingProps): TpGetCarouselCardTiming {
  const countDown = `${dayjs.duration(dayjs(end).diff(dayjs())).humanize()} left`;

  const isActive = dayjs().isBetween(start, end);

  return { countDown, isActive };
}
