/* eslint-disable react/no-unstable-nested-components */
import { Fragment } from 'react';
import { Dialogs } from '@noah-labs/core-web-ui/src/dialogs/Dialogs';
import { useSearchParams } from '@noah-labs/core-web-ui/src/hooks/useSearchParams';
import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { Helmet } from 'react-helmet';
import { Redirect, Route, useLocation, useRouteMatch } from 'react-router-dom';
import { useCreatePinReminder } from '../hooks/useCreatePinReminder';
import { useScrollToTop } from '../hooks/useScrollToTop';
import { useIdentify, usePageView } from '../modules/analytics';
import { Router as AppsRouter, routes as appsRoutes } from '../modules/apps';
import { AuthRouterData, DisabledFeatureData } from '../modules/auth';
import { routes as authRoutes } from '../modules/auth/routes';
import { Router as IntegrationRouter, routes as integrationRoutes } from '../modules/integrations';
import { KycRouter, routes as kycRoutes } from '../modules/kyc';
import { useLoginKycReminder } from '../modules/kyc/hooks/useLoginKycReminder';
import { Router as MarketingRouter, routes as marketingRoutes } from '../modules/marketing';
import { Router as RewardsRouter, routes as rewardsRoutes } from '../modules/rewards';
import { useSardine } from '../modules/sardine';
import {
  RestrictedRouter as UserRestrictedRouter,
  Router as UserRouter,
  routes as userRoutes,
} from '../modules/user';
import { useUserInit } from '../modules/user/data/useUserInit';
import { Router as WalletRouter, routes as walletRoutes } from '../modules/wallet';
import { webConfigBrowser } from '../webConfigBrowser';

const defaultAuthedPath = walletRoutes.base.path;

function App(): React.ReactElement {
  const userInit = useUserInit();
  const { updateGuestSardineConfig } = useSardine();

  useScrollToTop();
  useIdentify();
  usePageView();
  useCreatePinReminder();
  useLoginKycReminder();

  // get conditionals redirects
  const isRoot = useRouteMatch('/');
  const isResetPassword = useRouteMatch([
    userRoutes.newPassword.base.path,
    userRoutes.newPassword.complete.path,
  ]);

  // set the isRedirectingFromAuth variable
  const searchParams = useSearchParams();
  const { state } = useLocation<{ from: string } | undefined>();

  // if the userprofile has been loaded but is missing a UsernameDisplay, we need to demand it (unless resetting a password)
  const redirectToUsername =
    !isResetPassword && userInit.isFetched && !userInit.data?.userProfile.UsernameDisplay;

  // If the user does not yet have email preferences set, we need to force them to the consent screens
  const redirectToConsent =
    userInit.isFetched && userInit.userSettingsConsent?.EmailContact === undefined;

  const referralCode = searchParams?.get(webConfigBrowser.settings.referralCodeParam) ?? null;
  // If the user is accessing the app through a referral link, we need to redirect them to signup
  const redirectToSignUp = isRoot?.isExact && !!referralCode;

  const captchaApi = webConfigBrowser.aws.captchaApi ?? undefined;

  return (
    <Fragment>
      <Helmet defaultTitle="Welcome" titleTemplate="%s | NOAH" />
      <Switch404>
        {/*
         * Auth routes are be available to all users
         */}
        <Route path={authRoutes.base.path}>
          <AuthRouterData
            authedPath={defaultAuthedPath}
            captcha={webConfigBrowser.aws.captchaEnabled ? captchaApi : undefined}
            forgottenPasswordReturnTo={userRoutes.newPassword.base.path}
            referralCode={referralCode}
            updateGuestSardineConfig={updateGuestSardineConfig}
          />
        </Route>
        <Route
          exact
          component={(): React.ReactElement => (
            <Redirect
              to={
                redirectToSignUp
                  ? authRoutes.signUp.path
                  : { pathname: authRoutes.signIn.path, state }
              }
            />
          )}
          path="/"
        />

        {/*
         * Enforced Routes
         * - enforces users to the username and consent screens if not yet set
         * - UserRestrictedRouter route must be before its Redirect
         * - there should be no routes above these redirects, else they will be accessible to the all users
         */}
        <Route
          path={[userRoutes.username.path, userRoutes.consent.path, userRoutes.acceptUpdates.path]}
        >
          <UserRestrictedRouter
            logoutTo={authRoutes.signOut.path}
            successTo={defaultAuthedPath}
            usernameDisplay={userInit.data?.userProfile.UsernameDisplay}
          />
        </Route>
        {redirectToUsername && (
          <Route component={(): React.ReactElement => <Redirect to={userRoutes.username.path} />} />
        )}
        {redirectToConsent && (
          <Route component={(): React.ReactElement => <Redirect to={userRoutes.consent.path} />} />
        )}

        {/*
         * Regular Routes
         */}
        <Route path={marketingRoutes.base.path}>
          <MarketingRouter />
        </Route>
        <Route path={userRoutes.base.path}>
          <UserRouter />
        </Route>
        <Route path={rewardsRoutes.base.path}>
          <RewardsRouter />
        </Route>
        <Route path={integrationRoutes.base.path}>
          <IntegrationRouter />
        </Route>
        <Route path={appsRoutes.base.path}>
          <AppsRouter />
        </Route>

        <Route path={kycRoutes.base.path}>
          <KycRouter />
        </Route>

        <Route path={walletRoutes.base.path}>
          <WalletRouter />
        </Route>
        <Route path="/feature-disabled">
          <DisabledFeatureData />
        </Route>
      </Switch404>
      <Dialogs />
    </Fragment>
  );
}

export default App;
