import { ButtonGroup } from '@noah-labs/core-web-ui/src/buttons/ButtonGroup';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneImage } from '@noah-labs/core-web-ui/src/scene/SceneImage';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { AppHeaderData } from '../../../components';
import DontMissOutOnUpdates from '../../../images/dont-miss-out-on-updates.svg';

export type PpMarketingUpdatesScene = {
  onSubmit: (accept: boolean) => void;
};

export function MarketingUpdatesScene({ onSubmit }: PpMarketingUpdatesScene): React.ReactElement {
  return (
    <AppContainer
      footerHasContent
      AppFooterSlot={
        <ButtonGroup>
          <PrimaryButton
            color="primaryBrand"
            data-qa="consent-updates-yes"
            onClick={(): void => onSubmit(true)}
          >
            Yes
          </PrimaryButton>
          <PrimaryButton
            data-qa="consent-updates-not-now"
            variant="text"
            onClick={(): void => onSubmit(false)}
          >
            Not now
          </PrimaryButton>
        </ButtonGroup>
      }
      AppHeaderSlot={<AppHeaderData helpButton />}
      mainSxProps={{ textAlign: 'center' }}
    >
      <SceneMain>
        <SceneImage alt="" src={DontMissOutOnUpdates} />
        <SceneTitleLarge>Don’t miss out on updates</SceneTitleLarge>
        <SceneParagraph>
          Get updates by email about product updates, NOAH point giveaways and promotional offers.
          Your data will never be sold.
        </SceneParagraph>
      </SceneMain>
    </AppContainer>
  );
}
