/* eslint-disable react/no-array-index-key, react/no-multi-comp */
import { Fragment } from 'react';
import { Skeleton } from '@mui/material';
import { ElevatedCardListItem } from '../cards/ElevatedCardListItem';
import { CurrencyAvatar } from '../images';
import { StructuredContent } from './atoms/StructuredContent';

type PpSkeletonAccountCardList = {
  rows?: number;
};

function CircularSkeleton(): React.ReactElement {
  return <Skeleton variant="circular" />;
}

export function SkeletonAccountCardList({
  rows = 2,
}: PpSkeletonAccountCardList): React.ReactElement {
  return (
    <Fragment>
      {new Array(rows).fill(null).map((_, index) => (
        <ElevatedCardListItem key={index} dataQa="skeleton">
          <StructuredContent
            endText={{
              primary: <Skeleton variant="text" />,
              secondary: <Skeleton sx={{ px: '1rem' }} variant="text" />,
            }}
            Icon={<CurrencyAvatar Icon={CircularSkeleton} />}
            mainText={{
              primary: <Skeleton sx={{ px: '6rem' }} variant="text" />,
              secondary: <Skeleton variant="text" />,
            }}
          />
        </ElevatedCardListItem>
      ))}
    </Fragment>
  );
}
