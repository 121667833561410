import { idAndPath } from '@noah-labs/shared-constants';
import { AuthGroups, Feature } from '@noah-labs/shared-schema-gql';

const pbGroup = new Set([AuthGroups.personal_basic]);
const guestGroup = new Set([AuthGroups.guest]);

export const routes = {
  acceptUpdates: {
    ...idAndPath('/user/accept-updates'),
    groups: pbGroup,
    title: "Don't miss out on updates",
  },
  accounts: {
    ...idAndPath('/user/accounts'),
    groups: pbGroup,
    title: 'Linked Accounts',
  },
  base: {
    ...idAndPath('/user'),
    groups: guestGroup,
    title: 'User Home',
  },
  consent: {
    ...idAndPath('/user/consent'),
    groups: pbGroup,
    title: 'We value your privacy',
  },
  forgotPin: {
    createPin: {
      ...idAndPath('/user/forgot-pin/create-pin'),
      feature: Feature.Pin,
      groups: pbGroup,
      title: 'Create PIN',
    },
    enterPhrase: {
      ...idAndPath('/user/forgot-pin/enter-phrase'),
      feature: Feature.Pin,
      groups: pbGroup,
      title: 'Forgot PIN',
    },
    success: {
      ...idAndPath('/user/forgot-pin/success'),
      feature: Feature.Pin,
      groups: pbGroup,
      title: 'PIN Updated',
    },
  },
  newPassword: {
    base: {
      ...idAndPath('/user/new-password'),
      groups: pbGroup,
      title: 'New password',
    },
    complete: {
      ...idAndPath('/user/new-password/complete'),
      groups: pbGroup,
      title: 'Success',
    },
  },
  pinRevocation: {
    revoke: {
      ...idAndPath('/user/revoke'),
      feature: Feature.Pin,
      groups: pbGroup,
      title: 'Revoke',
    },
    unrevoke: {
      ...idAndPath('/user/unrevoke'),
      groups: guestGroup,
      title: 'Unrevoke',
    },
  },
  profile: {
    base: {
      ...idAndPath('/user/profile'),
      groups: pbGroup,
      title: 'Personal Details',
    },
    fullName: {
      ...idAndPath('/user/profile/full-name'),
      groups: pbGroup,
      title: 'Name',
    },
  },
  settings: {
    accountLimits: {
      ...idAndPath('/user/limits'),
      groups: pbGroup,
      title: 'Account Limits',
    },
    base: {
      ...idAndPath('/user/settings'),
      groups: pbGroup,
      title: 'Settings',
    },
    bitcoinUnit: {
      ...idAndPath('/user/settings/bitcoin-unit'),
      groups: pbGroup,
      title: 'Bitcoin unit',
    },
    consent: {
      ...idAndPath('/user/settings/consent'),
      groups: pbGroup,
      title: 'We value your privacy',
    },
    displayCurrency: {
      ...idAndPath('/user/settings/display-currency'),
      groups: pbGroup,
      title: 'Display currency',
    },
    editPaymentMethod: {
      ...idAndPath('/user/settings/payment-methods/:id'),
      groups: pbGroup,
      title: 'Edit Payment Method',
    },
    newPaymentMethod: {
      ...idAndPath('/user/settings/new-payment-method'),
      groups: pbGroup,
      title: 'New Payment Method',
    },
    paymentCurrency: {
      ...idAndPath('/user/settings/payment-currency'),
      groups: pbGroup,
      title: 'Payment currency',
    },
    paymentMethods: {
      ...idAndPath('/user/settings/payment-methods'),
      groups: pbGroup,
      title: 'Saved Payment Methods',
    },
    primaryCurrency: {
      ...idAndPath('/user/settings/primary-currency'),
      groups: pbGroup,
      title: 'Primary currency',
    },
    securityPin: {
      ...idAndPath('/user/settings/security-pin'),
      feature: Feature.Pin,
      groups: pbGroup,
      title: 'Security PIN',
    },
  },
  setupPin: {
    createPin: {
      ...idAndPath('/user/setup-pin/create-pin'),
      feature: Feature.Pin,
      groups: pbGroup,
      title: 'Choose your transaction PIN',
    },
    secretPhrase: {
      ...idAndPath('/user/setup-pin/secret-phrase'),
      feature: Feature.Pin,
      groups: pbGroup,
      title: 'PIN Recovery',
    },
    success: {
      ...idAndPath('/user/setup-pin/success'),
      feature: Feature.Pin,
      groups: pbGroup,
      title: 'PIN Created',
    },
  },
  transactionPinThreshold: {
    update: {
      ...idAndPath('/user/transaction-pin-threshold'),
      feature: Feature.Pin,
      groups: pbGroup,
      title: 'Transaction PIN Threshold',
    },
  },
  updatePin: {
    createPin: {
      ...idAndPath('/user/update-pin/create-pin'),
      feature: Feature.Pin,
      groups: pbGroup,
      title: 'Update PIN',
    },
    enterCurrentPin: {
      ...idAndPath('/user/update-pin/enter-current-pin'),
      feature: Feature.Pin,
      groups: pbGroup,
      title: 'Update PIN',
    },
    success: {
      ...idAndPath('/user/update-pin/success'),
      feature: Feature.Pin,
      groups: pbGroup,
      title: 'PIN Updated',
    },
  },
  username: {
    ...idAndPath('/user/username'),
    groups: pbGroup,
    title: 'Choose a username',
  },
};
