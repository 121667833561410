import { forwardRef } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { LowDialog } from '@noah-labs/core-web-ui/src/dialogs/LowDialog';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { useToggleDialog } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { CurrencyCode } from '@noah-labs/shared-schema-gql';
import { ReceiveBTCDialogContent } from './content/ReceiveBTCDialogContent';
import { ReceiveETHDialogContent } from './content/ReceiveETHDialogContent';
import { ReceiveUSDCDialogContent } from './content/ReceiveUSDCDialogContent';
import { ReceiveUSDTDialogContent } from './content/ReceiveUSDTDialogContent';

type PpReceiveLearnMoreDialog = {
  cryptoCurrency: CurrencyCode;
};

export const ReceiveLearnMoreDialog = forwardRef<TpDialogToggle, PpReceiveLearnMoreDialog>(
  ({ cryptoCurrency }, ref): JSX.Element => {
    const { open, toggle } = useToggleDialog({ ref });

    let title;
    let content;

    switch (cryptoCurrency) {
      case CurrencyCode.USDC:
      case CurrencyCode.USDC_TEST:
        title = 'What is USDC?';
        content = <ReceiveUSDCDialogContent />;
        break;
      case CurrencyCode.ETH:
      case CurrencyCode.ETH_TEST_SEPOLIA:
        title = 'Understanding Ether (ETH)';
        content = <ReceiveETHDialogContent />;
        break;
      case CurrencyCode.USDT:
      case CurrencyCode.USDT_TEST:
        title = 'What is USDT?';
        content = <ReceiveUSDTDialogContent />;
        break;
      default:
        title = 'On-Chain Bitcoin Transactions and Your On-Chain Address';
        content = <ReceiveBTCDialogContent />;
        break;
    }
    return (
      <LowDialog open={open} onClose={toggle}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <PrimaryButton color="primaryBrand" onClick={toggle}>
            Got it
          </PrimaryButton>
        </DialogActions>
      </LowDialog>
    );
  }
);

ReceiveLearnMoreDialog.displayName = ReceiveLearnMoreDialog.name;
