import type { MouseEvent } from 'react';
import { Fragment, useCallback, useRef } from 'react';
import { Balance } from '@noah-labs/core-web-ui/src/balance/Balance';
import { ChevronDownButton } from '@noah-labs/core-web-ui/src/buttons/ChevronDownButton';
import type { TpDialogToggle } from '@noah-labs/core-web-ui/src/hooks/useToggleDialog';
import { FiatAmount } from '@noah-labs/core-web-ui/src/numbers/FiatAmount';
import { useUserFiatCurrency } from '../../../../hooks/useUserFiatCurrency';
import { useUserInit } from '../../../user/data/useUserInit';
import { useUserAvailableFiatBalance } from '../../data/useUserAvailableFiatBalance';
import { PreferredCurrenciesDialog } from '../dialogs';

/**
 * Displays a known crypto currency amount, calculates an approximate fiat amount
 * and displays the preferred currencies dialog component
 */
export function CurrentBalanceDataWithCurrencyDialog(): React.ReactElement {
  const preferredCurrencyDialog = useRef<TpDialogToggle>(null);
  const { fiatCurrency } = useUserFiatCurrency();
  const { data: userInitData } = useUserInit();
  const { availableBalance, isFetched: isBalanceFetched } = useUserAvailableFiatBalance();

  const amount = isBalanceFetched ? availableBalance : undefined;

  const handleToggle = useCallback((event?: MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    preferredCurrencyDialog.current?.toggle();
  }, []);

  const userUnitCurrency = userInitData?.userProfile.DisplayUnit;
  const userFiatCurrency = userInitData?.userProfile.FiatCurrencyCode;

  const currencies = {
    fiatCurrency: userFiatCurrency,
    unitCurrency: userUnitCurrency,
  };

  return (
    <Fragment>
      <Balance
        IconSlot={<ChevronDownButton onClick={handleToggle} />}
        label="Your balance"
        PrimaryAmountSlot={<FiatAmount roundDown amount={amount} currency={fiatCurrency} />}
      />
      <PreferredCurrenciesDialog ref={preferredCurrencyDialog} currencies={currencies} />
    </Fragment>
  );
}
