//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgTron } from '../icons/Tron';

export function TronIcon(props: Omit<SvgIconProps, 'component' | 'children'>): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgTron} data-qa="TronIcon">
      <svg />
    </SvgIcon>
  );
}
