import { css } from '@emotion/react';
import type { SxProps, Theme } from '@mui/material';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { coreThemeConstants } from '@noah-labs/core-web-ui/src/theme/coreThemeConstants';
import { cssMixins } from '@noah-labs/core-web-ui/src/theme/cssMixins';
import { Countdown } from '@noah-labs/core-web-ui/src/timing/Countdown';
import { StopwatchIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/StopwatchIcon';
import dayjs from 'dayjs';

export type PpFlashTimer = {
  enabled?: boolean;
  ends: string | undefined | null;
  sx?: SxProps<Theme>;
};

function getTimeFormat(initialTimeDiff: number): string {
  if (initialTimeDiff < dayjs.duration({ hours: 1 }).asMilliseconds()) {
    return 'mm[m] ss[s]';
  }
  if (initialTimeDiff < dayjs.duration({ days: 1 }).asMilliseconds()) {
    return 'H[h] mm[m] ss[s]';
  }
  return 'D[d] H[h] mm[m]';
}

export function FlashTimer({ enabled = true, ends, sx }: PpFlashTimer): React.ReactElement {
  const theme = useTheme();
  const initialTimeDiff = dayjs(ends).diff(dayjs());
  const styles = {
    container: css`
      ${cssMixins.rowCentered}
      padding: ${theme.spacing(1.25, 1)};
      color: ${theme.palette.grayscale.white};
      background: ${theme.palette.gradients.primary};
    `,
    icon: css`
      font-size: 0.875rem;
    `,
    title: css`
      /* TODO Font variant missing */
      font-family: ${coreThemeConstants.fontFamily.expanded};
      font-style: normal;
      font-weight: 900;
      font-size: 0.875rem;
      text-transform: uppercase;
      line-height: 0.75rem;
    `,
  };

  return (
    <Stack css={styles.container} direction="column" spacing={0.5} sx={sx}>
      <Typography color="grayscale.white" css={styles.title}>
        LIMITED TIME QUEST
      </Typography>
      <Stack alignItems="center" direction="row" spacing={0.5}>
        <StopwatchIcon css={styles.icon} />
        <Countdown
          enabled={enabled}
          expiryTime={ends}
          format={getTimeFormat(initialTimeDiff)}
          initialTimeDiff={initialTimeDiff}
          suffixLabel="left"
          variant="paragraphBodySBold"
        />
      </Stack>
    </Stack>
  );
}
