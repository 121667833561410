import { useBackHijack } from '../../../hooks';
import { useSigning } from '../../signing/context';
import { routes } from '../routes';
import { SecurityPinScene } from '../scenes';

export function SecurityPin(): React.ReactElement {
  const { backTo } = useBackHijack(routes.settings.base.path);
  const { sdStatus } = useSigning();

  return (
    <SecurityPinScene backTo={backTo} pinSetupRequired={Boolean(sdStatus?.pinSetupRequired)} />
  );
}
