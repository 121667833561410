/* eslint-disable react/no-multi-comp */
import { ListItemIcon } from '@mui/material';
import type { ListItemIconProps } from '@mui/material';
import { ChevronRightIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronRightIcon';
import type { PpElevatedCardListItem } from '../cards/ElevatedCardListItem';
import { ElevatedCardListItem } from '../cards/ElevatedCardListItem';
import type { PpWC } from '../types';

export function PrimaryMenuIcon(props: ListItemIconProps): React.ReactElement {
  return <ListItemIcon sx={{ color: 'inherit' }} {...props} />;
}

export type PpPrimaryMenuItem = PpElevatedCardListItem & {
  chevron?: boolean;
};

export function PrimaryMenuItem({
  chevron = true,
  children,
  ...rest
}: PpWC<PpPrimaryMenuItem>): React.ReactElement {
  return (
    <ElevatedCardListItem {...rest} spacing={1}>
      {children}
      {chevron && <ChevronRightIcon fontSize="small" sx={{ color: 'grayscale.700' }} />}
    </ElevatedCardListItem>
  );
}
