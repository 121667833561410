import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { cssMixins } from '@noah-labs/core-web-ui/src/theme/cssMixins';
import type { TpSkeletonText } from '@noah-labs/core-web-ui/src/types';
import { TextOrSkeleton } from '@noah-labs/core-web-ui/src/typography/TextOrSkeleton';

type PpQuestBadgeIcon = {
  BadgeSlot: React.ReactElement;
  np: number;
  questName: TpSkeletonText;
};

export function QuestPageBadgeAvatar({
  BadgeSlot,
  np,
  questName,
}: PpQuestBadgeIcon): React.ReactElement {
  const theme = useTheme();
  const styles = {
    overlayText: css`
      ${cssMixins.colCentered};
      position: absolute;
      z-index: 1;
      text-align: center;
      &::before {
        position: absolute;
        content: '';
        z-index: -1;
        width: 400%;
        height: 400%;
        background: ${theme.palette.gradients.darkRadial};
      }
    `,
    points: css`
      color: ${theme.palette.grayscale.white};
      ${cssMixins.gradientTextWhite180};
    `,
    questName: css`
      color: ${theme.palette.grayscale.white};
      ${cssMixins.gradientTextWhite145};
      padding: ${theme.spacing(1, 1)};
      margin-bottom: ${theme.spacing(2)};
    `,
    wrapper: css`
      ${cssMixins.colCentered}
      position: relative;
      align-self: center;
      width: 100%;
    `,
  };
  return (
    <div css={styles.wrapper}>
      {BadgeSlot}
      <div css={styles.overlayText}>
        <Typography component="h1" css={styles.questName} variant="headingL">
          <TextOrSkeleton>{questName}</TextOrSkeleton>
        </Typography>
        <Typography css={styles.points} variant="paragraphBodyLBold">
          +<TextOrSkeleton>{np}</TextOrSkeleton> Points
        </Typography>
      </div>
    </div>
  );
}
