import type { SVGProps } from 'react';

export function SvgTron(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22c5.525 0 10-4.478 10-10S17.525 2 12 2 2 6.478 2 12s4.479 10 10 10"
        fill="#FF060A"
      />
      <path
        d="M18.813 9.918c-.64-.564-1.528-1.424-2.248-2.032l-.044-.026a1 1 0 0 0-.236-.128c-1.741-.308-9.845-1.75-10-1.732a.3.3 0 0 0-.125.044l-.04.031a.44.44 0 0 0-.111.172L6 6.273v.168c.91 2.415 4.514 10.32 5.225 12.18.044.128.124.366.275.379h.036c.08 0 .426-.436.426-.436s6.19-7.13 6.816-7.888q.12-.14.213-.3a.43.43 0 0 0-.022-.247.56.56 0 0 0-.156-.211m-5.269.833 2.64-2.08 1.55 1.357zm-1.026-.137L7.973 7.071l7.357 1.291zm.408.925 4.652-.713-5.318 6.094.666-5.38Zm-5.57-4.111 4.784 3.856-.693 5.64z"
        fill="#fff"
      />
    </svg>
  );
}
