import { useCallback, useState } from 'react';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeader } from '@noah-labs/core-web-ui/src/layout/AppHeader';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { Helmet } from 'react-helmet';
import type { UseFormSetError } from 'react-hook-form';
import { ResendVerificationCode } from '../components/ResendVerificationCode';
import { useAuthError } from '../hooks/useAuthError';
import { routes } from '../routes';

export type TpVerifyAccountForm = {
  root?: { serverError: void };
  verificationCode: string;
};

export type TpResendCode = {
  root?: { serverError: void };
};

export type PpVerifyEmailEmailSentScene = {
  email: string;
  helpButton?: React.ReactNode;
  loading: boolean;
  onRefresh: () => Promise<void>;
  onResend: (email: string, setError: UseFormSetError<TpResendCode>) => Promise<void>;
};

export function VerifyEmailEmailSentScene({
  email,
  helpButton,
  loading,
  onRefresh,
  onResend,
}: PpVerifyEmailEmailSentScene): React.ReactElement {
  const [isResending, setIsResending] = useState(false);
  const [error, setError] = useState<unknown>();

  useAuthError({ error });

  const onResendCallback = useCallback(async () => {
    try {
      setIsResending(true);
      await onResend(email, (_, e) => setError(e));
    } finally {
      setIsResending(false);
    }
  }, [email, onResend, setError]);

  return (
    <AppContainer
      wideFooter
      AppFooterSlot={
        <PrimaryButton
          color="primaryBrand"
          data-qa="continue-button"
          disabled={loading}
          loading={loading}
          onClick={onRefresh}
        >
          Continue
        </PrimaryButton>
      }
      AppHeaderSlot={<AppHeader backTo={routes.signOut.path} endIconsSlot={helpButton} />}
      dataQa="verify-account"
    >
      <Helmet>
        <title>Verify your email</title>
      </Helmet>
      <SceneHeader>
        <SceneTitleLarge>Verify your email</SceneTitleLarge>
        <SceneParagraph>
          To continue, please verify your email address by clicking on the link in your verification
          email and return to NOAH.
        </SceneParagraph>
      </SceneHeader>
      <SceneMain>
        <ResendVerificationCode loading={isResending} onResend={onResendCallback} />
      </SceneMain>
    </AppContainer>
  );
}
