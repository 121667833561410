import { css } from '@emotion/react';
import { ButtonBase, CardContent, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ElevatedCard } from '@noah-labs/core-web-ui/src/cards/ElevatedCard';
import { CurrencyAvatar } from '@noah-labs/core-web-ui/src/images';
import { StructuredContent } from '@noah-labs/core-web-ui/src/lists/atoms/StructuredContent';
import { ChevronRightIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronRightIcon';
import type { TpCryptoCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';
import type { TpPaymentBank, TpPaymentCard, TpPaymentMethod } from '../../types';
import { PaymentMethod } from '../payment/PaymentMethod';

export type PpAvailableBalanceWithPaymentMethod = {
  PrimaryAmountSlot: React.ReactNode;
  SecondaryAmountSlot: React.ReactNode;
  cryptoCurrency: TpCryptoCurrencyUI;
  href?: string;
  method: TpPaymentMethod | undefined;
  onPaymentMethodClick?: () => void;
  paymentBank?: TpPaymentBank;
  paymentCard?: TpPaymentCard;
};

export function AvailableBalanceWithPaymentMethod({
  cryptoCurrency,
  href,
  method,
  onPaymentMethodClick,
  paymentBank,
  paymentCard,
  PrimaryAmountSlot,
  SecondaryAmountSlot,
}: PpAvailableBalanceWithPaymentMethod): React.ReactElement {
  const title = `${cryptoCurrency.label} Balance`;

  const theme = useTheme();

  const styles = {
    chevron: css`
      color: ${theme.palette.text.light};
    `,
  };

  return (
    <ElevatedCard>
      <CardContent>
        <StructuredContent
          endText={{
            primary: PrimaryAmountSlot,
            primaryTypographyProps: {
              color: 'grayscale.500',
              variant: 'paragraphBodySBold',
            },
            secondary: SecondaryAmountSlot,
            secondaryTypographyProps: {
              variant: 'paragraphBodyS',
            },
          }}
          Icon={<CurrencyAvatar Icon={cryptoCurrency.Icon} size={3} />}
          mainText={{
            primary: title,
            primaryTypographyProps: {
              color: 'grayscale.500',
              variant: 'paragraphBodySBold',
            },
          }}
        />
        <Divider sx={{ my: 1.5 }} />
        <ButtonBase {...(href && { href })} sx={{ width: '100%' }} onClick={onPaymentMethodClick}>
          <PaymentMethod
            hideAddress
            method={method}
            minHeight={theme.spacing(4.5)}
            paymentBank={paymentBank}
            paymentCard={paymentCard}
            {...((href || onPaymentMethodClick) && {
              ToggleSlot: <ChevronRightIcon css={styles.chevron} />,
            })}
          />
        </ButtonBase>
      </CardContent>
    </ElevatedCard>
  );
}
