import { useCallback, useMemo } from 'react';
import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import { type CountryCode, Feature } from '@noah-labs/shared-schema-gql';
import { compareStrings } from '@noah-labs/shared-tools/src/browser/strings';
import { useQueryClient } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useCountriesForFeature } from '../../../hooks';
import { getUserFullDisplayName } from '../../../utils';
import { PaymentCardUpdateErrorAlert } from '../../wallet/components';
import { useOptimisticDeletePaymentCard } from '../../wallet/components/payment/PaymentMethodsPicker/useOptimisticDeletePaymentCard';
import { useFiatPaymentMethodsQuery, useFiatPaymentMethodUpdateMutation } from '../../wallet/data';
import type { TpPaymentCard } from '../../wallet/types';
import { useCountryFromCode } from '../data/useCountryFromCode';
import { useUserInit } from '../data/useUserInit';
import { useUserError } from '../hooks/useUserError';
import { routes } from '../routes';
import type { TpEditCardForm } from '../scenes';
import { EditPaymentMethodScene } from '../scenes';

const fpmQueryKey = ['FiatPaymentMethods'];

export function EditPaymentMethod(): React.ReactElement {
  const { data: userData, error, isLoading } = useUserInit();
  const { data: countriesData } = useCountriesForFeature(
    userData?.userProfile.HomeAddress?.CountryCode,
    [[Feature.CkoBuy, Feature.CkoSell]]
  );
  const country = useCountryFromCode(userData?.userProfile.HomeAddress?.CountryCode);
  const match = useRouteMatch<{ id: string }>(routes.settings.editPaymentMethod);
  const pushAlert = usePushAlert();
  const history = useHistory();
  const queryClient = useQueryClient();

  const fullName = getUserFullDisplayName(userData?.userProfile.PersonalName);
  const { data: card } = useFiatPaymentMethodsQuery(undefined, {
    select: (data): TpPaymentCard | undefined => {
      const decodedId = decodeURIComponent(match?.params.id ?? '');
      const maybeCard = data.fiatPaymentMethods.Items?.find((c) =>
        compareStrings(c.DynamoID, decodedId)
      );
      if (!maybeCard) {
        return undefined;
      }

      switch (maybeCard.Details.__typename) {
        case 'FiatPaymentCard':
          return {
            billingAddress: maybeCard.Details.BillingAddress,
            id: maybeCard.DynamoID,
            issuer: maybeCard.Details.Issuer,
            last4: maybeCard.Details.Last4,
            scheme: maybeCard.Details.Scheme,
            type: 'saved',
          };
        default:
          return undefined;
      }
    },
  });

  const defaultAddress = useMemo(() => {
    if (!country || !card?.billingAddress) {
      return undefined;
    }

    return {
      ...card.billingAddress,
      CountryName: country.name,
    };
  }, [card?.billingAddress, country]);

  const { mutateAsync: updateFiatPaymentMethod } = useFiatPaymentMethodUpdateMutation();
  const { mutateAsync: removeCard } = useOptimisticDeletePaymentCard();

  const onRemoveCard = useCallback(async () => {
    if (!card) {
      return;
    }

    try {
      await removeCard({
        Input: {
          DynamoID: card.id,
        },
      });

      pushAlert({
        key: 'removeSuccessful',
        message: 'Payment method removed',
        severity: 'success',
      });

      history.push(routes.settings.paymentMethods.path);
    } catch (err) {
      // handled in useOptimisticDeletePaymentCard
    }
  }, [card, history, pushAlert, removeCard]);

  const onSubmit = useCallback(
    async (data: TpEditCardForm) => {
      if (!card?.id) {
        return;
      }

      try {
        await updateFiatPaymentMethod({
          Input: {
            BillingAddress: {
              City: data.billingAddress.City,
              CountryCode: data.billingAddress.CountryCode as CountryCode,
              PostCode: data.billingAddress.PostCode,
              State: data.billingAddress.State,
              Street: data.billingAddress.Street,
              Street2: data.billingAddress.Street2,
            },
            DynamoID: card.id,
          },
        });

        await queryClient.invalidateQueries(fpmQueryKey);

        pushAlert({
          key: 'fiatPaymentUpdateSuccessful',
          message: 'Payment method updated',
          severity: 'success',
        });

        history.push(routes.settings.paymentMethods.path);
      } catch (err) {
        pushAlert(PaymentCardUpdateErrorAlert);
        logger.error(err);
      }
    },
    [card?.id, history, queryClient, pushAlert, updateFiatPaymentMethod]
  );

  const { ApiErrorScene } = useUserError(error);

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  if (isLoading || !card) {
    return <LoadingPage />;
  }

  return (
    <EditPaymentMethodScene
      cardDetails={card}
      countries={countriesData?.countries}
      defaultAddress={defaultAddress}
      fullName={fullName}
      pageTitle={routes.settings.editPaymentMethod.title}
      onRemoveCard={onRemoveCard}
      onSubmit={onSubmit}
    />
  );
}
