import { usePushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import type { UseMutationResult } from 'react-query';
import { useQueryClient } from 'react-query';
import type {
  FiatPaymentMethodDeleteMutationVariables,
  FiatPaymentMethodsQuery,
} from '../../../data';
import { useFiatPaymentMethodDeleteMutation } from '../../../data';
import { PaymentCardDeleteErrorAlert } from '../../alerts';

const queryKey = ['FiatPaymentMethods'];

type TpQueryContext = {
  previousValue: FiatPaymentMethodsQuery | undefined;
};

export function useOptimisticDeletePaymentCard(): UseMutationResult<
  unknown,
  unknown,
  FiatPaymentMethodDeleteMutationVariables,
  TpQueryContext
> {
  const queryClient = useQueryClient();
  const pushAlert = usePushAlert();

  return useFiatPaymentMethodDeleteMutation<unknown, TpQueryContext>({
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_err, _input, context): void => {
      pushAlert(PaymentCardDeleteErrorAlert);
      queryClient.setQueryData<FiatPaymentMethodsQuery | undefined>(
        queryKey,
        () => context?.previousValue
      );
    },
    onMutate: async (input): Promise<TpQueryContext> => {
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const previousValue = queryClient.getQueryData<FiatPaymentMethodsQuery>(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData<FiatPaymentMethodsQuery | undefined>(queryKey, (oldData) => {
        if (!oldData?.fiatPaymentMethods) {
          return oldData;
        }
        return {
          fiatPaymentMethods: {
            Items: oldData.fiatPaymentMethods.Items?.filter(
              (card) => card.DynamoID !== input.Input.DynamoID
            ),
          },
        };
      });

      return { previousValue };
    },
    // Always refetch after error or success
    onSettled: (): void => {
      void queryClient.invalidateQueries(queryKey);
    },
  });
}
