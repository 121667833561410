import type { TpMoonpaySellTransactionStatus } from '@noah-labs/core-services';

export function getTitles(status: TpMoonpaySellTransactionStatus | undefined): {
  pageTitle: string;
} {
  switch (status) {
    case 'completed': {
      return {
        pageTitle: 'Sold successfully',
      };
    }
    case 'failed': {
      return {
        pageTitle: 'Sell Transaction failed',
      };
    }
    case 'waitingForDeposit': {
      return {
        pageTitle: 'Sale transaction processing',
      };
    }
    case 'pending':
      return {
        pageTitle: 'Transaction pending',
      };
    default:
      return {
        pageTitle: '',
      };
  }
}
