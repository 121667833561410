import React from 'react';
import { useSpringCarousel } from 'react-spring-carousel';

export type PpCarouselBase = {
  hidden: boolean;
};

type PpCtaCarousel = {
  children?: React.ReactNode;
};

export function CtaCarousel({ children }: PpCtaCarousel): React.ReactElement | null {
  const childSlides = React.Children.toArray(children).filter(
    (child): child is React.ReactElement<PpCarouselBase> =>
      React.isValidElement(child) && !(child as React.ReactElement<PpCarouselBase>).props.hidden
  );

  const { carouselFragment } = useSpringCarousel({
    gutter: 8,
    items: childSlides.length
      ? childSlides.map((slide, index) => ({
          id: `id-${index}`,
          renderItem: slide,
        }))
      : [{ id: 'empty', renderItem: null }],
    shouldResizeOnWindowResize: true,
  });

  return <div>{carouselFragment}</div>;
}
