import { useCallback, useEffect } from 'react';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { ErrorPage } from '@noah-labs/core-web-ui/src/utility/ErrorPage';
import { LoadingPage } from '@noah-labs/core-web-ui/src/utility/LoadingPage';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import SumsubWebSdk from '@sumsub/websdk-react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { AppHeaderData } from '../../../components';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useAuth } from '../../auth/hooks/useAuth';
import { useSumsubAccessTokenMutation } from '../../sumsub';
import { useUserInit } from '../../user/data/useUserInit';
import { routes } from '../routes';

type TpSumsubEventName =
  | 'idCheck.onReady'
  | 'idCheck.onResize'
  | 'idCheck.onApplicantLoaded'
  | 'idCheck.onInitialized'
  | 'idCheck.onApplicantSubmitted'
  | 'idCheck.onApplicantStatusChanged';

type TpSumsubEventOnApplicantStatusChangedPayload = {
  attemptCnt: number;
  attemptId: string;
  confirmed?: boolean;
  createDate: string;
  elapsedSincePendingMs?: number;
  elapsedSinceQueuedMs?: number;
  levelAutoCheckMode: unknown;
  levelName: 'Full KYC for Individuals' | 'EDD KYC for Individuals';
  priority: number;
  reprocessing: boolean;
  reviewDate?: string;
  reviewId: string;
  reviewResult?: {
    reviewAnswer: 'GREEN' | 'RED';
  };
  reviewStatus: 'completed' | 'pending';
};

export function Sumsub(): React.ReactElement {
  const {
    data: sumSubData,
    error: sumSubError,
    isLoading: isSumsubLoading,
    mutateAsync: sumSubAccessToken,
  } = useSumsubAccessTokenMutation();

  const auth = useAuth();
  const { refetch: userInitRefetch } = useUserInit();
  const history = useHistory();

  const accessToken = sumSubData?.sumSubAccessToken.Token;

  const getAccessToken = useCallback(async (): Promise<string | undefined> => {
    try {
      const { sumsub } = webConfigBrowser;
      if (!sumsub.fullLevelName) {
        logger.error('sumsub level name is not defined');
        return undefined;
      }

      const data = await sumSubAccessToken({
        Input: {
          LevelName: sumsub.fullLevelName,
        },
      });
      return data.sumSubAccessToken.Token;
    } catch (err) {
      logger.error(err);
      return undefined;
    }
  }, [sumSubAccessToken]);

  const onMessage = useCallback(
    async (event: TpSumsubEventName, payload: TpSumsubEventOnApplicantStatusChangedPayload) => {
      /**
       * Events:
       * First open:
       * - onReady
       * - onResize
       * - onApplicantLoaded
       * - onInitialized
       *
       * Low Risk User
       * - onApplicantStatusChanged
       * {
       *  "reviewId" : "zmmVw",
       *  "attemptId" : "YbZQu",
       *  "attemptCnt" : 1,
       *  "elapsedSincePendingMs" : 51327,
       *  "elapsedSinceQueuedMs" : 51327,
       *  "reprocessing" : true,
       *  "levelName" : "Full KYC for Individuals",
       *  "levelAutoCheckMode" : null,
       *  "createDate" : "2024-02-21 14:43:40+0000",
       *  "reviewDate" : "2024-02-21 14:44:32+0000",
       *  "reviewResult" : {
       *    "reviewAnswer" : "GREEN"
       *  },
       *  "reviewStatus" : "completed",
       *  "confirmed" : true,
       *  "priority" : 0
       * },
       *
       * Low Risk User, Answering High Risk question
       * - onApplicantStatusChanged
       * {
       *  "reviewId" : "LZQud",
       *  "attemptId" : "Jxabi",
       *  "attemptCnt" : 0,
       *  "reprocessing" : false,
       *  "levelName" : "Full KYC for Individuals",
       *  "levelAutoCheckMode" : null,
       *  "createDate" : "2024-02-21 14:30:33+0000",
       *  "reviewStatus" : "pending",
       *  "priority" : 0
       * }
       *
       */

      /**
       * We can close the iframe if:
       * - user is low / med risk (full KYC) and has completed the flow (user is auto-reviewed)
       * - user was high risk and asked to do EDD after submission (user will definitely need manual review)
       */
      const anyKycComplete =
        event === 'idCheck.onApplicantStatusChanged' && payload.reviewStatus === 'completed';

      const eddKycSubmitted =
        event === 'idCheck.onApplicantStatusChanged' &&
        payload.levelName === 'EDD KYC for Individuals' &&
        payload.reviewStatus === 'pending';

      if (anyKycComplete || eddKycSubmitted) {
        try {
          await userInitRefetch();
        } catch (err) {
          // can 'ignore' the error, not critical to user completing this flow
          logger.error(err);
        }
        history.replace(routes.complete.path);
      }
    },
    [history, userInitRefetch]
  );

  useEffect(() => {
    void getAccessToken();
  }, [getAccessToken]);

  if (sumSubError) {
    return <ErrorPage message="Sorry, something went wrong" />;
  }

  if (isSumsubLoading || auth.isLoading || !accessToken) {
    return <LoadingPage />;
  }

  return (
    <AppContainer AppHeaderSlot={<AppHeaderData disableFade exitButton />}>
      <Helmet>
        <title>{routes.base.title}</title>
      </Helmet>
      <SceneMain sx={{ paddingY: 0 }}>
        <SumsubWebSdk
          accessToken={accessToken}
          config={{
            email: auth.data?.email,
          }}
          expirationHandler={getAccessToken}
          onMessage={onMessage}
        />
      </SceneMain>
    </AppContainer>
  );
}
