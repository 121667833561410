import React from 'react';
import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { Route } from 'react-router-dom';
import { AuthRouteData } from '../auth';
import { KycReminderOrigin } from '../kyc/controllers/Reminder';
import { NeedsKycRouter } from '../kyc/utils/NeedsKycRouter';
import { WelcomeStoriesDialogData } from '../welcome';
import {
  AccountRouter,
  Accounts,
  AddressRouter,
  BuyRouter,
  LightningSendRouter,
  SellRouter,
  WithdrawRouter,
} from './controllers';
import { ReceiveRouter } from './controllers/receive/ReceiveRouter';
import { SelectCryptoRouter } from './controllers/select-crypto/SelectCryptoRouter';
import { AddressProvider } from './hooks';
import { routes } from './routes';

export function Router(): React.ReactElement {
  return (
    <AddressProvider>
      <Switch404>
        <Route
          exact
          path={routes.base.path}
          render={(): React.ReactElement => (
            <AuthRouteData route={routes.base}>
              <Accounts />
              <WelcomeStoriesDialogData />
            </AuthRouteData>
          )}
        />

        <Route path={routes.selectCrypto.base.path}>
          <SelectCryptoRouter />
        </Route>

        <Route path={routes.receive.base.path}>
          <NeedsKycRouter origin={KycReminderOrigin.Deposit}>
            <ReceiveRouter />
          </NeedsKycRouter>
        </Route>

        <Route path={routes.lightningSend.base.path}>
          <LightningSendRouter />
        </Route>
        <Route path={routes.withdraw.base.path}>
          <WithdrawRouter />
        </Route>
        <AuthRouteData path={routes.buy.base.path} route={routes.buy.base}>
          <BuyRouter />
        </AuthRouteData>
        <Route path={routes.sell.base.path}>
          <SellRouter />
        </Route>
        <Route path={routes.address.base.path}>
          <AddressRouter />
        </Route>
        <Route path={routes.account.base.path}>
          <AccountRouter />
        </Route>
      </Switch404>
    </AddressProvider>
  );
}
