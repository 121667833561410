import React, { useEffect } from 'react';
import { useStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import type { TpLightningAddressData } from '@noah-labs/core-web-ui/src/tools/parseAddressData';
import { CurrencyUnit, SardineFlows } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AuthRouteData } from '../../../auth';
import { KycReminderOrigin } from '../../../kyc/controllers/Reminder';
import { NeedsKycRouter } from '../../../kyc/utils/NeedsKycRouter';
import { useSardineFlow } from '../../../sardine';
import { useWalletParams } from '../../data';
import { useAddress, useSendAmounts } from '../../hooks';
import { routes } from '../../routes';
import { ExpiredInvoiceScene } from '../../scenes/ExpiredInvoice';
import { Complete } from './Complete';
import { Confirm } from './Confirm';
import { EnterAmount } from './EnterAmount';
import { ExternalRequest } from './ExternalRequest';
import type { StLightningSendRouter } from './types';

const emptyState: StLightningSendRouter = {
  cryptoAmount: '',
  cryptoUnit: CurrencyUnit.Default,
  fetchedAt: '',
  fiatAmount: '',
  lightningAddressProxy: undefined,
  paymentRequest: undefined,
  paymentRequestData: undefined,
  price: '',
};
export function LightningSendRouter(): React.ReactElement {
  useSardineFlow({ flow: SardineFlows.CryptoWithdraw });
  const { data, setData } = useAddress();

  /**
   * Remove payment request from the address context on unmount
   */
  useEffect(() => () => setData(undefined), [setData]);

  let initialState: StLightningSendRouter | undefined;
  if (data) {
    initialState = {
      ...emptyState,
      cryptoAmount: data.amount || '',
      lightningAddressProxy: data.lightningAddressProxy,
      // can use 'as' here because we know it will only be routed here if parsedAddressData is TpLightningAddressData
      paymentRequestData: data as TpLightningAddressData,
    };

    // If an invoice is entered directly, set it as the paymentRequest
    if (data.addressType === 'lnbc') {
      initialState.paymentRequest = data.address;
    }
  }

  const sm = useStateMachine<StLightningSendRouter>({
    emptyState,
    initialState,
    name: 'LightningSendRouter',
  });

  /**
   * Get params and fetch the fiatAmount for the cryptoAmount in the paymentRequestData,
   * so that we can display it on the confirm & complete screeens.
   * Always fetch a new fiatAmount in case the stored value is out of date
   */
  const { params } = useWalletParams();

  const { cryptoUnit, fetchedAt, fiatAmount, price } = useSendAmounts({
    cryptoAmount: sm.state.cryptoAmount,
    fiatAmount: sm.state.fiatAmount,
  });

  const { updateState } = sm;
  useEffect(() => {
    updateState({ cryptoUnit, fetchedAt, fiatAmount, price });
  }, [cryptoUnit, fetchedAt, fiatAmount, price, updateState]);

  /**
   * Setup invalid screen redirects, we always need a cryptoAmount and a paymentRequest for lightning send
   */

  // Go back to the scan screen in case we don't have the paymentRequestData address
  const enterAmountInvalidRedirect =
    !sm.state.paymentRequestData?.address && generatePath(routes.address.text.path, params);

  // Go back to enterAmount screen in case we don't have an amount on the confirm screen
  const confirmAndCompleteInvalidRedirect =
    !sm.state.cryptoAmount && generatePath(routes.lightningSend.enterAmount.path, params);

  return (
    <Switch404>
      <Route
        exact
        path={routes.lightningSend.enterAmount.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={enterAmountInvalidRedirect}
            route={routes.lightningSend.enterAmount}
          >
            <NeedsKycRouter bypassIfUserHasBalance origin={KycReminderOrigin.Withdraw}>
              <EnterAmount {...sm} />
            </NeedsKycRouter>
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.lightningSend.confirm.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={confirmAndCompleteInvalidRedirect}
            route={routes.lightningSend.confirm}
          >
            <NeedsKycRouter bypassIfUserHasBalance origin={KycReminderOrigin.Withdraw}>
              <Confirm {...sm} />
            </NeedsKycRouter>
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.lightningSend.complete.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={confirmAndCompleteInvalidRedirect}
            route={routes.lightningSend.complete}
          >
            <Complete {...sm} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.lightningSend.invoiceExpired.path}
        render={(): React.ReactElement => (
          <AuthRouteData
            invalidRedirect={enterAmountInvalidRedirect}
            route={routes.lightningSend.invoiceExpired}
          >
            <ExpiredInvoiceScene pageTitle={routes.lightningSend.invoiceExpired.title} />
          </AuthRouteData>
        )}
      />

      <Route
        exact
        path={routes.lightningSend.request.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.lightningSend.request}>
            <ExternalRequest {...sm} />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
