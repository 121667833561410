import { Fragment } from 'react';
import type { TpPushAlert } from '@noah-labs/core-web-ui/src/alerts/usePushAlert';
import { AlertError, GetInTouchLink } from '../../../../components';

/**
 * Errors
 */
export const ApiInvalidUsername: TpPushAlert = AlertError(
  'invalidUsername',
  'Sorry, your Public Username is not valid. It must be between 3 and 25 characters long, containing only alphanumeric, hypens, underscores and dots: ( _, -, . ). Please update it and try again.'
);

export const ApiUsernameExists: TpPushAlert = AlertError(
  'usernameAlreadyExists',
  'The username you have selected is already in use. Please choose a different username.'
);

export const ApiUserProfileExists: TpPushAlert = AlertError(
  'userProfileExists',
  <Fragment>
    Sorry, your profile has already been registered. Please{' '}
    <GetInTouchLink color="inherit" underline="always" />.
  </Fragment>
);

export const ApiUserProfileUpdate: TpPushAlert = AlertError(
  'userProfileUpdate',
  'There was an error saving your profile details'
);

export const ApiUsernameProfanity: TpPushAlert = AlertError(
  'usernameProfanity',
  'The username you have selected may contain a word that has been blocked by our profanity filter. Please choose a different username.'
);

/**
 * Security pin
 */
export const PinCreated: TpPushAlert = {
  dismissable: true,
  key: 'pin-created',
  message: 'Your PIN has been set successfully',
  severity: 'success',
};
export const RecoveryPhraseDownloadFailed: TpPushAlert = AlertError(
  'recoveryPhraseDownloadFailed',
  'An error occurred while attempting to download your recovery phrase. Please copy it manually and make sure to store it in a highly secure and private location.'
);
export const TransactionPinThresholdUpdated: TpPushAlert = {
  dismissable: true,
  key: 'transactionPinThresholdUpdated',
  message: 'Your PIN threshold for transactions has been updated successfully!',
  severity: 'success',
};
export const TransactionPinThresholdUpdateFailed: TpPushAlert = AlertError(
  'transactionPinThresholdUpdateFailed',
  'An error occurred while attempting to update your PIN threshold for transactions. Please try again.'
);
