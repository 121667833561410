import type { SVGProps } from 'react';

export function SvgLightningCircle(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10"
        fill="#7B1AF7"
      />
      <path
        d="m7.66 12.216 6.666-5.745c.29-.186.567 0 .39.319l-2.128 4.185h3.795s.602 0 0 .496l-6.56 5.78c-.462.39-.78.177-.462-.425l2.057-4.079H7.66s-.603 0 0-.531"
        fill="#fff"
      />
    </svg>
  );
}
