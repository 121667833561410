import type { TpCryptoCurrencyUI, TpFiatCurrencyUI } from '@noah-labs/fe-shared-ui-currencies';
import type { TpSimpleAmount } from '@noah-labs/shared-currencies/src/types';
import type { Network } from '@noah-labs/shared-schema-gql';
import { duration } from '@noah-labs/shared-tools/src/browser/duration';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useCalculateFiatFromCrypto, useWithdrawFeeQuery } from '../data';

type PpUseWithdrawFeeWithTransaction = {
  address: string;
  cryptoAmount: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  enabled: boolean;
  fiatCurrency: TpFiatCurrencyUI;
  network: Network;
};

export type TpUseWithdrawFeeWithTransaction = {
  feeCryptoAmount: TpSimpleAmount;
  feeError: unknown;
  feeFetchedAt: number;
  feeFiatAmount: TpSimpleAmount;
  feeQuote: string | null | undefined;
  isFeeFetched: boolean;
};

const quoteExpirySecThreshold = 15;
const withdrawFeeInterval = webConfigBrowser.networkFee.quoteExpirySec - quoteExpirySecThreshold;

export function useWithdrawFeeWithTransaction({
  address,
  cryptoAmount,
  cryptoCurrency,
  enabled,
  fiatCurrency,
  network,
}: PpUseWithdrawFeeWithTransaction): TpUseWithdrawFeeWithTransaction {
  const {
    data: feeData,
    dataUpdatedAt: withdrawFeeFetchedAt,
    error: withdrawFeeError,
    isFetched: isWithdrawFeeFetched,
  } = useWithdrawFeeQuery(
    {
      Input: {
        Currency: cryptoCurrency.code,
        Network: network,
        Transaction: {
          Amount: cryptoAmount,
          DestinationAddress: {
            Address: address,
          },
        },
      },
    },
    {
      enabled,
      refetchInterval: duration.seconds(withdrawFeeInterval),
    }
  );

  const { data: priceData } = useCalculateFiatFromCrypto({
    cryptoAmount: feeData?.withdrawFee.NetworkFee,
    cryptoCurrency,
    fiatCurrency,
    priceProvider: 'market',
  });

  return {
    feeCryptoAmount: feeData?.withdrawFee.NetworkFee,
    feeError: withdrawFeeError,
    feeFetchedAt: withdrawFeeFetchedAt,
    feeFiatAmount: priceData?.fiatAmount,
    feeQuote: feeData?.withdrawFee.FeeQuote,
    isFeeFetched: isWithdrawFeeFetched && !!feeData,
  };
}
