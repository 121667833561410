import React from 'react';
import type { PpWOC } from '../types';
import { List } from './List';
import { SkeletonAccountCardList } from './SkeletonAccountCardList';

export type PpAccountCardList = PpWOC & {
  isFetched?: boolean;
};

export function AccountCardList({
  children,
  isFetched = false,
}: PpAccountCardList): React.ReactElement {
  if (!isFetched) {
    return <SkeletonAccountCardList />;
  }

  return (
    <List disablePadding spacing={1}>
      {children}
    </List>
  );
}
