import React from 'react';
import { FiatAmount } from '@noah-labs/core-web-ui/src/numbers/FiatAmount';
import type { TpSkeletonText } from '@noah-labs/core-web-ui/src/types';
import type { TpFiatCurrency } from '@noah-labs/shared-currencies/src/types';

export type PpCryptoPrice = {
  cryptoCurrencyCode: string;
  fiatCurrency: TpFiatCurrency;
  price: TpSkeletonText;
};

export function CryptoPrice({
  cryptoCurrencyCode,
  fiatCurrency,
  price,
}: PpCryptoPrice): React.ReactElement {
  return (
    <span>
      1 {cryptoCurrencyCode} = <FiatAmount amount={price} currency={fiatCurrency} />
    </span>
  );
}
