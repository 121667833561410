//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgDownload } from '../icons/Download';

export function DownloadIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgDownload} data-qa="DownloadIcon">
      <svg />
    </SvgIcon>
  );
}
