import type { TypographyOwnProps } from '@mui/material';
import { Divider, Skeleton, Stack } from '@mui/material';
import { ElevatedCard } from '../cards/ElevatedCard';
import { StructuredContent } from './atoms/StructuredContent';

const mainTextProps: TypographyOwnProps = {
  color: 'text.light',
  variant: 'paragraphBodyS',
};
const itemSx = { height: 36 };

export function SkeletonAddressSummaryCard(): React.ReactElement {
  return (
    <ElevatedCard sx={{ padding: [2, 2] }}>
      <Stack spacing={1.5} width="100%">
        <StructuredContent
          endText={{
            primary: <Skeleton variant="rectangular" />,
          }}
          mainText={{
            primary: 'Send',
            primaryTypographyProps: mainTextProps,
          }}
          sx={itemSx}
        />
        <Divider />
        <StructuredContent
          endText={{
            primary: <Skeleton variant="rectangular" />,
          }}
          mainText={{
            primary: 'To',
            primaryTypographyProps: mainTextProps,
          }}
          sx={itemSx}
        />
        <Divider />
        <StructuredContent
          endText={{
            primary: <Skeleton variant="rectangular" />,
          }}
          mainText={{
            primary: 'Network',
            primaryTypographyProps: mainTextProps,
          }}
          sx={itemSx}
        />
      </Stack>
    </ElevatedCard>
  );
}
