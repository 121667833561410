import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { Route } from 'react-router-dom';
import { AuthRouteData } from '../auth';
import { routes as walletRoutes } from '../wallet/routes';
import { RevokePin, UnrevokePin } from './controllers';
import { routes } from './routes';

export function PinRevocationRouter(): React.ReactElement {
  return (
    <Switch404>
      {/* 
       Authenticated route to revoke/unrevoke the PIN.
       */}
      <Route
        path={routes.pinRevocation.revoke.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.pinRevocation.revoke}>
            <RevokePin successTo={walletRoutes.base.path} />
          </AuthRouteData>
        )}
      />
      {/* 
       Unauthenticated route to unrevoke the PIN.
       */}
      <Route
        path={routes.pinRevocation.unrevoke.path}
        render={(): React.ReactElement => <UnrevokePin successTo={walletRoutes.base.path} />}
      />
    </Switch404>
  );
}
