import { useRef } from 'react';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Stack } from '@mui/material';
import { PrimaryButton } from '@noah-labs/core-web-ui/src/buttons/PrimaryButton';
import { FormGrid } from '@noah-labs/core-web-ui/src/forms/FormGrid';
import { FormItem } from '@noah-labs/core-web-ui/src/forms/FormItem';
import { InputField } from '@noah-labs/core-web-ui/src/forms/InputField';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { FooterContentText } from '@noah-labs/core-web-ui/src/layout/FooterContentText';
import { SceneHeader } from '@noah-labs/core-web-ui/src/scene/SceneHeader';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { SceneParagraph, SceneTitleLarge } from '@noah-labs/core-web-ui/src/scene/Typography';
import { TextOrSkeleton } from '@noah-labs/core-web-ui/src/typography/TextOrSkeleton';
import { usernameFromEmail } from '@noah-labs/shared-tools/src/browser/strings';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { AppHeaderData } from '../../../components';
import { routes } from '../routes';

export type TpUsernameForm = {
  country: string | undefined;
  usernameDisplay: string;
};

const usernameFormSchema = yup.object({
  country: yup.string().max(255),
  usernameDisplay: yup
    .string()
    .label('Your username')
    .min(3)
    .max(25)
    .required()
    .matches(/^[a-zA-Z0-9-_.]*$/, 'Your username must contain only letters, numbers, -, _ or .'),
});

export type PpUsernameScene = {
  email: string | undefined;
  logoutTo: string;
  onSubmit: SubmitHandler<TpUsernameForm>;
  suffix: string;
};

export function UsernameScene({
  email,
  logoutTo,
  onSubmit,
  suffix,
}: PpUsernameScene): React.ReactElement {
  const methods = useForm<TpUsernameForm>({
    defaultValues: {
      usernameDisplay: email ? usernameFromEmail(email) : '',
    },
    resolver: yupResolver(usernameFormSchema),
  });

  const hpRef = useRef<HTMLInputElement>(null);

  return (
    <AppContainer
      AppFooterSlot={
        <Stack spacing={3}>
          <FooterContentText>
            Not <TextOrSkeleton>{email}</TextOrSkeleton>?{' '}
            <Link href={logoutTo}>Log&nbsp;out and start over.</Link>
          </FooterContentText>
          <PrimaryButton
            disabled={!methods.formState.isValid}
            form={routes.username.id}
            loading={methods.formState.isSubmitting}
            type="submit"
          >
            Next
          </PrimaryButton>
        </Stack>
      }
      AppHeaderSlot={<AppHeaderData helpButton />}
      dataQa={routes.username.id}
    >
      <Helmet>
        <title>{routes.username.title}</title>
      </Helmet>
      <SceneHeader>
        <SceneTitleLarge>{routes.username.title}</SceneTitleLarge>
        <SceneParagraph>
          It will be used as your lightning payment address and as your referral code. It will be
          public and cannot be changed so please pick carefully!
        </SceneParagraph>
      </SceneHeader>
      <SceneMain>
        <FormProvider {...methods}>
          <form
            id={routes.username.id}
            onSubmit={(e): void => {
              /**
               * Honeypot (country): get input value from the ref instead of the form state.
               * The field is invisible and can only be filled directly in the DOM by a bot, so the value doesn't end up in the form state.
               */
              void methods.handleSubmit(async (formData) => {
                await onSubmit({
                  ...formData,
                  country: hpRef.current?.value,
                });
              })(e);
            }}
          >
            <FormGrid>
              <FormItem fullWidth>
                <InputField
                  autoFocus
                  fullWidth
                  dataQa={routes.username.id}
                  endSlot={suffix}
                  label="Username"
                  // use usernameDisplay to prevent password managers trying to fill it
                  name="usernameDisplay"
                  type="text"
                />
                {/* This field is a honey pot, humans are not expected to see or fill it */}
                <InputField
                  autoComplete="off"
                  css={css`
                    position: absolute;
                    left: -9999px;
                    z-index: -999;
                  `}
                  dataQa="country"
                  inputRef={hpRef}
                  label="Country"
                  name="country"
                  tabIndex={-1}
                  type="text"
                />
              </FormItem>
            </FormGrid>
          </form>
        </FormProvider>
      </SceneMain>
    </AppContainer>
  );
}
