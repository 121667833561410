import { useCallback } from 'react';
import { logger } from '@noah-labs/shared-logger/src/browser/logger';
import type { RegistrationFlow } from '@ory/client';
import type { AxiosError } from 'axios';
import { HttpStatusCode, isAxiosError } from 'axios';
import { useOry } from '../data';
import type { AuthError } from '../types';
import { getOryCsrfToken } from '../utils/getOryCsrfToken';
import { getOryUiError } from './utils';

export function useOryRecoverySendCodeFlowCallback(returnTo: string): (
  values: {
    email: string;
  },
  setError: (name: `root.${string}`, error: AuthError) => void
) => Promise<string | undefined> {
  const { ory } = useOry();

  return useCallback(
    async (values, setError): Promise<string | undefined> => {
      try {
        const { data: flow } = await ory.createBrowserRecoveryFlow({
          returnTo,
        });

        const csrfToken = getOryCsrfToken(flow.ui.nodes);
        await ory.updateRecoveryFlow({
          flow: flow.id,
          updateRecoveryFlowBody: {
            csrf_token: csrfToken,
            email: values.email,
            method: 'code',
          },
        });
        return flow.id;
      } catch (error: unknown) {
        logger.error(error);

        if (!isAxiosError(error)) {
          setError('root.serverError', {
            message: 'Something went wrong.',
            type: 'custom',
          });
          return undefined;
        }

        const oryError = error as AxiosError<RegistrationFlow>;
        if (oryError.response?.status !== HttpStatusCode.BadRequest) {
          setError('root.serverError', {
            message: 'Something went wrong.',
            type: 'custom',
          });
          return undefined;
        }

        const { ui } = oryError.response.data;
        const uiServerMessage = getOryUiError(ui.messages);
        if (uiServerMessage) {
          setError('root.serverError', uiServerMessage);
        }
        return undefined;
      }
    },
    [ory, returnTo]
  );
}
