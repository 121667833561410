import type { ReactElement } from 'react';
import { useCallback } from 'react';
import { IncorrectPinError, LockedPinError, SafeWrapper } from '@noah-labs/core-services';
import type { UseFormSetError } from 'react-hook-form';
import type { TpPinForm } from '../../../utils';
import { useSigning } from '../../signing/context';
import { SignDialog } from '../../signing/dialogs/Sign';
import type { TpPayloadSignature, TpUserLimitSignable } from '../../signing/types';

type TpOnTransactionPinThresholdUpdateSignSuccess = (params: {
  dailyLimitSignature: TpPayloadSignature;
  singleTransactionLimitSignature: TpPayloadSignature;
}) => Promise<void>;

type PpSignTransactionPinThresholdUpdate = {
  dailyLimitSignaturePayload: TpUserLimitSignable;
  onSignSuccess: TpOnTransactionPinThresholdUpdateSignSuccess;
  singleTransactionLimitSignaturePayload: TpUserLimitSignable;
};

export function SignTransactionPinThresholdUpdateDialog({
  dailyLimitSignaturePayload,
  onSignSuccess,
  singleTransactionLimitSignaturePayload,
}: PpSignTransactionPinThresholdUpdate): ReactElement {
  const { createSignature } = useSigning();

  const onSubmit = useCallback(
    async ({ pin }: TpPinForm, setError: UseFormSetError<TpPinForm>): Promise<void> => {
      try {
        const [dailyLimitSignature, singleTransactionLimitSignature] = await Promise.all([
          createSignature(new SafeWrapper(pin), dailyLimitSignaturePayload),
          createSignature(new SafeWrapper(pin), singleTransactionLimitSignaturePayload),
        ]);

        if (!dailyLimitSignature || !singleTransactionLimitSignature) {
          throw new Error('Could not sign transaction');
        }

        await onSignSuccess({ dailyLimitSignature, singleTransactionLimitSignature });
      } catch (error) {
        if (error instanceof IncorrectPinError || error instanceof LockedPinError) {
          setError('pin', { message: (error as Error).message });

          return;
        }

        setError('pin', { message: 'Something went wrong. Check your PIN and try again.' });
      }
    },
    [
      createSignature,
      onSignSuccess,
      dailyLimitSignaturePayload,
      singleTransactionLimitSignaturePayload,
    ]
  );

  return <SignDialog onSubmit={onSubmit} />;
}
