import { FormGrid } from '@noah-labs/core-web-ui/src/forms/FormGrid';
import { FormItem } from '@noah-labs/core-web-ui/src/forms/FormItem';
import { InputField } from '@noah-labs/core-web-ui/src/forms/InputField';
import { AppContainer } from '@noah-labs/core-web-ui/src/layout/AppContainer';
import { AppHeaderTitle } from '@noah-labs/core-web-ui/src/layout/AppHeaderTitle';
import { SceneMain } from '@noah-labs/core-web-ui/src/scene/SceneMain';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { AppHeaderData } from '../../../components';
import { routes } from '../routes';

export type PpFullNameScene = {
  firstName: string;
  lastName: string;
  middleName: string;
};

export function FullNameScene({
  firstName,
  lastName,
  middleName,
}: PpFullNameScene): React.ReactElement {
  const screenTitle = routes.profile.fullName.title;

  // This form has no validation because this data will be submitted by the KYC flow
  const methods = useForm<PpFullNameScene>({
    defaultValues: {
      firstName,
      lastName,
      middleName,
    },
  });

  return (
    <AppContainer
      AppHeaderSlot={
        <AppHeaderData backButton helpButton>
          <AppHeaderTitle>{screenTitle}</AppHeaderTitle>
        </AppHeaderData>
      }
      dataQa="edit-personal-name"
    >
      <Helmet>
        <title>{screenTitle}</title>
      </Helmet>
      <SceneMain dense>
        <FormProvider {...methods}>
          <form id={routes.profile.fullName.id}>
            <FormGrid>
              <FormItem fullWidth>
                <InputField
                  fullWidth
                  inputProps={{ readOnly: true }}
                  label="First name"
                  name="firstName"
                  type="text"
                />
              </FormItem>
              <FormItem fullWidth>
                <InputField
                  fullWidth
                  inputProps={{ readOnly: true }}
                  label="Middle name"
                  name="middleName"
                  type="text"
                />
              </FormItem>
              <FormItem fullWidth>
                <InputField
                  fullWidth
                  inputProps={{ readOnly: true }}
                  label="Last name"
                  name="lastName"
                  type="text"
                />
              </FormItem>
            </FormGrid>
          </form>
        </FormProvider>
      </SceneMain>
    </AppContainer>
  );
}
