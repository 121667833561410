import React from 'react';
import { Switch404 } from '@noah-labs/core-web-ui/src/navigation/Switch404';
import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { Redirect, Route } from 'react-router-dom';
import { AuthRouteData } from '../auth';
import { BadgeData, InviteFriends, QuestData, Rewards } from './controllers';
import { routes } from './routes';
import { TpRewardsTabs } from './types';

export function Router(): React.ReactElement {
  return (
    <Switch404>
      {/* Path is /rewards, redirect to the the quests tab */}
      <Route
        exact
        // eslint-disable-next-line react/no-unstable-nested-components
        component={(): React.ReactElement => (
          <Redirect
            to={generatePath(routes.tabs.path, {
              tab: TpRewardsTabs.Quests,
            })}
          />
        )}
        path={routes.base.path}
      />
      <Route
        exact
        path={routes.tabs.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.base}>
            <Rewards />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.badge.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.badge}>
            <BadgeData />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.quest.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.quest}>
            <QuestData />
          </AuthRouteData>
        )}
      />
      <Route
        exact
        path={routes.inviteFriends.path}
        render={(): React.ReactElement => (
          <AuthRouteData route={routes.inviteFriends}>
            <InviteFriends />
          </AuthRouteData>
        )}
      />
    </Switch404>
  );
}
