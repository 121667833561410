import { forwardRef } from 'react';
import type { TextFieldProps } from '@mui/material';
import { InputAdornment, TextField } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

export type PpInputFieldAtom = Omit<TextFieldProps, 'error' | 'id' | 'name'> & {
  dataQa?: string;
  endSlot?: React.ReactNode;
  errorMessage?: string;
  labelSrOnly?: boolean;
  name: string;
  startSlot?: React.ReactNode;
};

export const InputFieldAtom = forwardRef<HTMLDivElement, PpInputFieldAtom>(
  (
    {
      dataQa,
      endSlot,
      errorMessage,
      helperText,
      inputProps,
      InputProps,
      label,
      labelSrOnly = false,
      name,
      placeholder,
      startSlot,
      ...rest
    },
    ref
  ) => {
    const textLabel = typeof label === 'string' ? label : undefined;

    return (
      <TextField
        {...rest}
        ref={ref}
        error={Boolean(errorMessage)}
        helperText={errorMessage || helperText}
        id={name}
        InputLabelProps={{
          sx: labelSrOnly ? visuallyHidden : undefined,
        }}
        inputProps={{
          ...inputProps,
          'data-qa': dataQa ? `${dataQa}-input` : 'input',
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          ...InputProps,
          ...(endSlot && {
            endAdornment: <InputAdornment position="end">{endSlot}</InputAdornment>,
          }),
          ...(startSlot && {
            startAdornment: <InputAdornment position="start">{startSlot}</InputAdornment>,
          }),
        }}
        label={label}
        name={name}
        placeholder={placeholder ?? textLabel}
      />
    );
  }
);
InputFieldAtom.displayName = InputFieldAtom.name;
