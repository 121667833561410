import { useCallback } from 'react';
import { useDestinations } from '@noah-labs/core-services';
import type { TpStateMachine } from '@noah-labs/core-web-ui/src/hooks/useStateMachine';
import { useHistory } from 'react-router-dom';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useAuth } from '../../auth/hooks/useAuth';
import type { StRegistration } from '../RestrictedRouter';
import type { TpConsentFormValues } from '../scenes';
import { ConsentScene } from '../scenes';

type PpTermsAndCookies = {
  logoutTo?: string;
  successTo: string;
} & TpStateMachine<StRegistration>;

export function TermsAndCookies({
  logoutTo,
  state: { Cookies, Terms },
  successTo,
  updateState,
}: PpTermsAndCookies): React.ReactElement {
  const history = useHistory();
  const { data: { email = '' } = {} } = useAuth();
  const { data: { groupedDestinations } = {} } = useDestinations([
    webConfigBrowser.segment.writeKey,
    webConfigBrowser.analytics.cdnUrl,
  ]);

  /**
   * Update the statemachine with the user consent prefs and route to the next screen
   */
  const onSubmit = useCallback(
    (values: TpConsentFormValues, isDirty: boolean) => {
      updateState({ ...values, isDirty });
      history.push(successTo);
    },
    [history, successTo, updateState]
  );

  return (
    <ConsentScene
      destinations={groupedDestinations}
      email={email}
      logoutTo={logoutTo}
      userSettingsConsent={{ Cookies, EmailContact: false, Terms }}
      variant="registration"
      onSubmit={onSubmit}
    />
  );
}
