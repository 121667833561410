import { generatePath } from '@noah-labs/core-web-ui/src/tools/generatePath';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';
import { useBackHijack } from '../../../../hooks';
import { TransactionDialog } from '../../components/dialogs/TransactionDialog';
import { useWalletParams } from '../../data';
import { useTransactionDetails } from '../../data/useTransactionDetails';
import { routes } from '../../routes';

type PpTransaction = {
  id: string | undefined | null;
};

export function Transaction({ id }: PpTransaction): React.ReactElement | null {
  const hasId = !isUndefinedOrNull(id);
  const txId = decodeURIComponent(id ?? '');
  const txProps = useTransactionDetails(txId);
  const { params } = useWalletParams();
  const { backTo } = useBackHijack(generatePath(routes.account.base.path, params));

  return <TransactionDialog {...txProps} backTo={backTo} open={hasId} />;
}
