import { useUserFiatCurrency } from '../../../hooks/useUserFiatCurrency';
import { useUserEstimateBalanceQueryPoll } from './useUserEstimateBalanceQueryPoll';

type TpUseUserAvailableFiatBalance = {
  availableBalance: string | undefined;
  isFetched: boolean;
};

export function useUserAvailableFiatBalance(): TpUseUserAvailableFiatBalance {
  const { fiatCurrency } = useUserFiatCurrency();
  const { data, isFetched } = useUserEstimateBalanceQueryPoll({
    Input: {
      TargetAsset: fiatCurrency.code,
    },
  });

  return {
    availableBalance: data?.userEstimateBalance.Available,
    isFetched,
  };
}
