//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgNoahSquare } from '../icons/NoahSquare';

export function NoahSquareIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgNoahSquare} data-qa="NoahSquareIcon">
      <svg />
    </SvgIcon>
  );
}
